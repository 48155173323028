import { useRoute } from '@react-navigation/native';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text, Dimensions } from 'react-native';
import { useParams } from 'react-router';
import styled from 'rn-css';
import {
	Button,
	DropDownPickerSmall,
	GoBackButton,
	SectionHeading,
	Table,
} from '../components';
import TableComponent from '../components/common/table-component/TableComponent';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { palettes } from '../config';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { TUseRoute } from '../types/exportedTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { dateFns } from '../utils/dateFns';
const SingleOrderView = () => {
	const HEADERS = [
		{
			headerLabel: 'PRODUCTS',
			width: getResponsiveStyle(230),
		},
		{
			headerLabel: 'DESCRIPTION',
			width: getResponsiveStyle(230),
		},

		{
			headerLabel: 'SKU',
			width: getResponsiveStyle(120),
		},
		{
			headerLabel: 'PRICE',
			width: getResponsiveStyle(120),
		},
		{
			headerLabel: 'QTY',
			width: getResponsiveStyle(90),
		},
		{
			headerLabel: 'COMMISSION',
			width: getResponsiveStyle(150),
		},
		{
			headerLabel: 'SUB TOTAL',
			width: getResponsiveStyle(90),
		},
	];
	const FIELDS = [
		{ name: 'SubTotals' },
		{ name: 'Shipping' },
		{ name: 'Discount' },
		{ name: 'Total Tax' },
		{ name: 'Total Ordered Amount' },
		{ name: 'Total Vendor Amount' },
		{ name: 'Total Admin Commission' },
	];
	const routes = useRoute<TUseRoute<'OrderDetails'>>();
	const [OrderProducts, setOrderProducts] = useState([]);
	const [hovered, setHovered] = useState<number>();
	const [date, setDate] = useState<string>();
	const [OrderAccount, setOrderAccount] = useState<string[]>();
	const { _id, orderDetails: OrderDetails } = routes.params;
	const { isLessThanDesktopBase, isMobileView } =
		useIsSpecificViewportWidth();

	const ContentContainer = styled.View``;

	console.log(routes.params);

	useEffect(() => {
		let sub = 0;
		let brandEarning = 0;
		let tax = 0;
		OrderDetails?.items?.map((item, index) => {
			// const brandshare = !item?.affiliatedSellerId
			// 	? 0.85 * item?.price * item?.quantity
			// 	: 0.64167 * item?.price * item?.quantity;
			const brandshare = 0.96 * (item?.price + (index === 0 ? OrderDetails.deliveryFee : 0)) * item?.quantity
			const vat = item?.vat
				? (item.vat * item.price * item.quantity) / 100
				: 0;
			tax += vat;
			brandEarning += brandshare;
		});

		const ocpusShare = (0.04 * (OrderDetails.purchasedTotalAmount + OrderDetails.deliveryFee)) - (0.25 + 0.015 * (OrderDetails.purchasedTotalAmount + OrderDetails.deliveryFee));
		const dateObj = new Date(Number(OrderDetails.purchasedOn));
		let dateString =
			dateFns.mdy(dateObj) + ' ' + dateFns.formatAMPM(dateObj);
		setDate(dateString);
		setOrderAccount([
			`${parseFloat(OrderDetails.purchasedTotalAmount).toFixed(2)}`,
			`${OrderDetails.deliveryFee}.00`,
			'0.00',
			tax.toFixed(2).toString(),
			`${parseFloat(OrderDetails.purchasedTotalAmount).toFixed(2)}`,
			brandEarning.toFixed(2).toString(),
			ocpusShare.toFixed(2).toString(),
		]);
		OrderDetails
			? setOrderProducts(
				OrderDetails?.items?.map(item => {
					let price =
						item?.variant?.option?.values?.find(
							val => val.name === item.optionValue
						).price ?? item?.starterKit?.price;
					let qty = item.quantity;
					sub = sub + parseFloat(price) * qty;
					return {
						name:
							item?.itemType === 'STARTER_KIT'
								? item?.starterKit?.name
								: item?.variant?.associatedProduct?.name +
								' ' +
								item?.variant?.option?.name +
								'-' +
								item?.variant?.option?.values?.find(
									val => val.name === item.optionValue
								).name,
						shortDescription:
							item?.itemType === 'STARTER_KIT'
								? item?.starterKit?.description
								: item?.variant?.associatedProduct
									?.shortDescription,
						sku:
							item?.variant?.option?.inventory?.quantity ??
							'N/A',

						price:
							getSymbolFromCurrency('eur') +
							parseFloat(price).toFixed(2) ?? 'N/A',
						quantity: qty,
						commission: ocpusShare.toFixed(2),

						subTotal:
							getSymbolFromCurrency('eur') +
							parseFloat('' + sub + '').toFixed(2) ??
							'N/A',
						// watch: '',
						// _id: item?.variant?._id,
						// images: item?.variant?.image.src,
						// variants: item?.variant,
					};
				})
			)
			: null;
	}, [OrderDetails]);
	console.log(OrderDetails);
	return (
		<DesktopTemplate>
			<GoBackButton history="Dashboard" />

			<View
				style={{
					marginTop: getResponsiveStyle(28),
					flexDirection: 'row',
					display: 'flex',

					justifyContent: 'space-between',
				}}
			>
				<View>
					<Text
						style={{
							fontSize: getResponsiveStyle(18, 'font'),
							fontWeight: '400',
						}}
					>{`Order  #${OrderDetails.orderId}`}</Text>
					<Text style={[hoverTextStyle1(9, hovered)]}>{date}</Text>
				</View>
				{/* <View
					style={{
						marginRight: 0,
						flexDirection: 'row',
						display: 'flex',
					}}
				>
					<Button title="Ship" outlineColor={palettes.grey[1]} />
					<Button title="Invoice" outlineColor={palettes.grey[1]} />
					<Button
						title="Send Email"
						outlineColor={palettes.grey[1]}
					/>
					<Button
						title="Cancel Order"
						outlineColor={palettes.grey[1]}
					/>
					<Button title="Print" outlineColor={palettes.grey[1]} />
				</View> */}
			</View>

			<View
				style={[
					styles.profileListContainer,
					{
						borderBottomColor: palettes.grey[5],
						borderBottomWidth: 1,
						marginTop: getResponsiveStyle(54),
					},
				]}
			>
				<Table
					type="singleOrder"
					headers={HEADERS}
					content={OrderProducts}
				/>
			</View>
			<View
				style={[
					styles.profileListContainer,
					{
						borderBottomColor: palettes.grey[5],
						borderBottomWidth: 1,
					},
				]}
			>
				<View
					style={[
						styles.profileListContainer,
						{
							borderBottomColor: palettes.grey[5],
							borderBottomWidth: 0,
							marginLeft: getResponsiveStyle(830),
						},
					]}
				>
					{!isMobileView && <View style={styles.sectionDivider} />}
					<View
						style={{
							// flexBasis: isLessThanDesktopBase ? 'auto' : '45%',
							// flex: 1,
							width: getResponsiveStyle(160),
							paddingTop: '50%',
						}}
					>
						{FIELDS.map((field, index) => {
							return (
								<ContentContainer
									onMouseEnter={() => setHovered(index)}
									onMouseLeave={() => setHovered(undefined)}
								>
									<Text
										style={[
											hoverTextStyle1(index, hovered),
										]}
									>
										{field.name}
									</Text>
								</ContentContainer>
							);
						})}
					</View>
					{!isMobileView && <View style={styles.sectionDivider} />}
					<View
						style={{
							paddingTop: '50%',
							width: getResponsiveStyle(90),
						}}
					>
						{OrderAccount?.map((field, index) => {
							return (
								<ContentContainer
									onMouseEnter={() => setHovered(index)}
									onMouseLeave={() => setHovered(undefined)}
								>
									<Text
										style={[
											hoverTextStyle1(index, hovered),
										]}
									>
										{getSymbolFromCurrency('eur') + field}
									</Text>
								</ContentContainer>
							);
						})}
					</View>
				</View>
			</View>
		</DesktopTemplate>
	);
};
const styles = StyleSheet.create({
	sectionDivider: {
		width: 1,
		height: '100%',
		backgroundColor: palettes.grey[5],
	},

	profileListContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		// maxWidth: '100%',
		// justifyContent: 'space-between',
		gap: getResponsiveStyle(10),
	},
});
const hoverTextStyle1 = (index: number, hover?: number) => {
	return [
		{
			paddingTop: '10px',
			paddingBottom: '10px',
			paddingRight: '10px',
			paddingLeft: '0',
			color: palettes.grey[1],
		},
		index === hover
			? {
				color: 'black',
				fontWeight: 'bold',
			}
			: { color: palettes.grey[1] },
	];
};
export default SingleOrderView;
