import React from "react";
import { View } from "react-native";

type Props = {
  flex: number;
};

const DumbComponentForFlex = ({ flex }: Props) => {
  return (
    <View
      style={{
        flex,
      }}
    />
  );
};

export default DumbComponentForFlex;
