import { Formik } from 'formik';
import React from 'react';
import { View } from 'react-native';
import { Button, Input } from '../..';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import * as Yup from 'yup';
import useCreateNewPassword from '../../../api/auth/useCreateNewPassword';
const validationSchema = Yup.object({
	token: Yup.string().required('Required'),
	password: Yup.string().required('Required'),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref('password')], 'Passwords does not match')
		.required('Required'),
});

type TInitialValues = Yup.InferType<typeof validationSchema>;

const initialValues: TInitialValues = {
	password: '',
	confirmPassword: '',
	token: '',
};

const ResetPasswordForm = () => {
	const { executeNewPasswordMutation } = useCreateNewPassword();
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={values => {
				const { password, token } = values;
				executeNewPasswordMutation(password, token);
			}}
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				errors,
				touched,
				isValid,
				dirty,
			}) => (
				<View style={styleContainer()}>
					{console.log({ errors, touched })}
					<Input
						containerStyles={styleInput()}
						onChangeText={handleChange('token')}
						onBlur={handleBlur('token')}
						label={'Token' + '*'}
						error={
							touched.token && errors.token ? errors.token : ''
						}
					/>
					<Input
						label={'Password' + '*'}
						containerStyles={styleInput()}
						onChangeText={handleChange('password')}
						onBlur={handleBlur('password')}
						secureTextEntry={true}
						error={
							touched.password && errors.password
								? errors.password
								: ''
						}
					/>
					<Input
						label={'Confirm Password' + '*'}
						containerStyles={styleInput()}
						onChangeText={handleChange('confirmPassword')}
						onBlur={handleBlur('confirmPassword')}
						secureTextEntry={true}
						error={
							touched.confirmPassword && errors.confirmPassword
								? errors.confirmPassword
								: ''
						}
					/>
					<Button
						title={'Next'}
						size="lg"
						variant={dirty && isValid ? 'primary' : 'disabled'}
						onPress={handleSubmit}
					/>
				</View>
			)}
		</Formik>
	);
};

const styleContainer = () => {
	return { marginBottom: getResponsiveStyle(46) };
};

const styleInput = () => {
	return { marginBottom: getResponsiveStyle(24) };
};

export default ResetPasswordForm;
