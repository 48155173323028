import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import useGetMedia from '../api/common/useGetMedia';

import { EventImagePlaceholder } from '../components/common/event-card/EventImagePlaceholder';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import DescriptionContent from '../components/events/descriptionContent/DescriptionContent';
import DescriptionHeader from '../components/events/descriptionHeader/DescriptionHeader';
import DescriptionTabs from '../components/events/descriptionTabs/DescriptionTabs';
import DetailSidebar from '../components/events/detail-sidebar/DetailSidebar';
import LocationSection from '../components/events/location-section/LocationSection';
import PeopleScrollSection from '../components/events/people-scroll-section/PeopleScrollSection';
import ProductScrollSection from '../components/events/product-scroll-section/ProductScrollSection';
import { palettes } from '../config';
import { GET_SINGLE_EVENT } from '../graphql/events/queries';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { setCurrentEvent } from '../redux/getEventSlice';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { useHandleUnmount } from '../hooks/useHandleUnmount';
import Loader from '../components/common/loader/Loader';
import { TEventDescriptionSection, TODO } from '../types/types';

type Props = {
	route: TODO;
};

function EventDescriptionScreen(props: Props) {

	const { shouldHideScreen } = useHandleUnmount();

	const SECTION_TABS = [
		{ label: 'Description', value: 'Description' },
		{ label: 'Products', value: 'Products' },
		{ label: 'People', value: 'People' },
		{ label: 'Location', value: 'Location' },
		{ label: 'Reviews', value: 'Reviews' },
	];

	const SECTION: TEventDescriptionSection =
		'Description' as TEventDescriptionSection;

	const getTabs = (section: TEventDescriptionSection) => {
		return SECTION_TABS;
	};
	const [selectedTab, setSelectedTab] = React.useState<string>(
		getTabs(SECTION)[0].value
	);
	const [image, setImage] = useState<TMedia>();
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const dispatch = useDispatch();

	useEffect(() => {
		document
			.getElementById(selectedTab)
			?.scrollIntoView({ behavior: 'smooth' });
		console.log({ selectedTab });
	}, [selectedTab]);

	// const { executeSingleEventQuery, loading, data } = useGetSingleEvent();

	const onPress = (tab: string) => {
		setSelectedTab(tab);
		// TODO: Set wondows scroll here
	};

	const eventId = props.route?.params?.eventId;

	const { data: eventData, loading: eventLoading } = useQuery(
		GET_SINGLE_EVENT,
		{
			variables: {
				_id: eventId,
			},
		}
	);

	const event = eventData?.getEvent?.data;

	const { executeMediaQuery, data: mediaData } = useGetMedia();

	useEffect(() => {
		if (event?.eventMedia?.length) {
			executeMediaQuery({
				_id: event?.eventMedia[0],
			});
		}
	}, [eventData]);

	useEffect(() => {
		const image = mediaData?.getMedia?.data;
		if (image) setImage(image);
	}, [mediaData]);
	if (event) {
		dispatch(setCurrentEvent(event));
	}

	return shouldHideScreen ? null : (
		<DesktopTemplate
			navigationBarProps={{
				hasGoBackButton:true,
			}}
		>
			{eventLoading ? (
				<Loader/>
			) : event ? (
				Object.keys(event).length > 0 && (
					<ScrollView style={Styles.container}>
						{!!image && !!image?.src ? (
							<Image
								source={{
									uri: image?.src,
								}}
								style={Styles.image}
							/>
						) : (
							<View
								style={{
									height: getResponsiveStyle(
										480,
										'dimensions'
									),
									borderRadius: 6,
									overflow: 'hidden',
								}}
							>
								<EventImagePlaceholder />
							</View>
						)}

						<DescriptionHeader
							title={event.name}
							subtitle={event.typeOfEvent}
							startDate={event.startingEventDateTime}
                            endDate={event.endingEventDate}
                            request={!event.isApproved}
                            id={event._id}
						/>
						<DescriptionTabs
							onPress={onPress}
							selectedTab={selectedTab}
							tabs={getTabs(SECTION)}
						/>
						<View style={[Styles.layout, styleContainer()]}>
							<View style={Styles.content}>
								<View>
									<DescriptionContent
										content={event.description}
									/>
								</View>
								<View nativeID="Products">
									<ProductScrollSection />
								</View>
								<View nativeID="People">
									<PeopleScrollSection
										invitedPeople={event.invitedPeople}
									/>
								</View>
								{event.typeOfEvent === 'PHYSICAL' && (
									<View nativeID="Location">
										<LocationSection />
									</View>
								)}

							
							</View>
							{!isLessThanDesktopBase && (
								<>
									<View
										style={[
											Styles.layoutDivider,
											styleContainerDivider(),
										]}
									></View>
									<View
										style={[
											Styles.sidebar,
											styleContainerSidebar(),
										]}
									>
										<DetailSidebar />
									</View>
								</>
							)}
						</View>
					</ScrollView>
				)
			) : (
				<Text>{'Event not found'}</Text>
			)}
		</DesktopTemplate>
	);
}

const Styles = StyleSheet.create({
	container: {
		width: '100%',
	},
	image: {
		width: '100%',
		height: getResponsiveStyle(480),
		resizeMode: 'cover',
		borderRadius: 6,
	},
	layout: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		// marginTop: 40,
	},
	content: {
		flex: 1,
	},
	layoutDivider: {
		width: 1,
		height: '100%',
		backgroundColor: palettes.grey[5],
	},
	sidebar: {
		// width: 312,
		flexBasis: getResponsiveStyle(312),
		flexGrow: 0,
		flexShrink: 0,
		// flex: "0 0 312px"
	},
});

export default EventDescriptionScreen;

function styleContainer() {
	return {
		marginTop: getResponsiveStyle(40),
		// gap: getResponsiveStyle(32),
	};
}
function styleContainerDivider() {
	return {
		marginLeft: getResponsiveStyle(40),
		// gap: getResponsiveStyle(32),
	};
}
function styleContainerSidebar() {
	return {
		paddingHorizontal: getResponsiveStyle(32),
		// gap: getResponsiveStyle(32),
	};
}
