import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import React from 'react';
import { View, Text, Pressable } from 'react-native';

type Props = {
	type: 'left' | 'right';
	label: string;
	selected: boolean;
	onPress: () => void;
};

const BooleanButton = ({ label, type, selected, onPress }: Props) => {
	// add cursor pointer
	return (
		<Pressable
			onPress={onPress}
			style={[
				{
					width: getResponsiveStyle(64, 'dimensions'),
					height: getResponsiveStyle(48, 'dimensions'),
					borderWidth: 1,
					borderColor: palettes.grey[4],
					justifyContent: 'center',
					alignItems: 'center',
				},
				type === 'left' && {
					borderTopLeftRadius: 6,
					borderBottomLeftRadius: 6,
				},
				type === 'right' && {
					borderTopRightRadius: 6,
					borderBottomRightRadius: 6,
				},
				selected && {
					borderColor: palettes.primary[0],
				},
			]}
		>
			<Text
				style={[
					{
						fontSize: getResponsiveStyle(14, 'font'),
						lineHeight: getResponsiveStyle(14, 'font'),
						fontWeight: '600',
					},
					selected && {
						color: palettes.primary[0],
					},
					!selected && {
						color: palettes.dark[2],
					},
				]}
			>
				{label}
			</Text>
		</Pressable>
	);
};

export default BooleanButton;
