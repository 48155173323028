import React from "react";
import { Pressable } from "react-native";
import Svg, { Path, SvgProps } from "react-native-svg";


const AddUser = (props: SvgProps) => {
  return (
    <Pressable>
      <Svg width={16} height={16} fill="none" viewBox="0 0 16 16" {...props}>
        <Path d="M0 0h24v24H0z" />
        <Path
          d="M9.421 10.763a.5.5 0 0 0 0-1v1Zm0 6.737a.5.5 0 0 0 0-1v1Zm.842-4.368a.5.5 0 1 0 0 1v-1Zm5.053 1a.5.5 0 0 0 0-1v1Zm-3.027 2.026a.5.5 0 1 0 1 0h-1Zm1-5.053a.5.5 0 1 0-1 0h1ZM9.764 4.368a2.868 2.868 0 0 1-2.868 2.869v1a3.868 3.868 0 0 0 3.868-3.869h-1ZM6.895 7.237a2.868 2.868 0 0 1-2.869-2.869h-1a3.868 3.868 0 0 0 3.869 3.869v-1ZM4.026 4.368A2.868 2.868 0 0 1 6.895 1.5v-1a3.868 3.868 0 0 0-3.869 3.868h1ZM6.895 1.5a2.868 2.868 0 0 1 2.868 2.868h1A3.868 3.868 0 0 0 6.895.5v1Zm-2.527 9.263h5.053v-1H4.368v1ZM9.421 16.5H4.368v1h5.053v-1Zm-5.053 0A2.868 2.868 0 0 1 1.5 13.632h-1A3.868 3.868 0 0 0 4.368 17.5v-1Zm0-6.737A3.868 3.868 0 0 0 .5 13.632h1a2.868 2.868 0 0 1 2.868-2.869v-1Zm5.895 4.369h5.053v-1h-5.053v1Zm3.027 2.026v-5.053h-1v5.053h1Z"
          fill="#6645F4"
        />
      </Svg>
    </Pressable>
  );
};

export default AddUser;
