import { useMutation, useQuery } from "@apollo/client";
import { TokenResult } from "@stripe/stripe-js";
import { SetStateAction, useState } from "react";
import { CREATE_BRAND_CONNECT_ACCOUNT, ONBOARD_ACCOUNT } from "../../graphql/stripe/mutations";
import { GET_BRAND_CONNECT_ACCOUNT } from "../../graphql/stripe/queries";
import { useSetAlert } from "../../hooks/useSetAlerts";
import { getCountryValueFromLabel } from "../../utils/getCountryValueFromLabel";

type TParams = {
    getConnetData: any;
    token: string
    countryLabel: string
    connectAccount: string
    setConnectAccount: React.Dispatch<SetStateAction<string>>
    onboardingFinish: boolean | string
    setOnboardingFinish: React.Dispatch<SetStateAction<boolean | string>>
    brandId: string
    setModalState: React.Dispatch<SetStateAction<boolean>>
}

export const useOnBoardingFlow = ({
    token,
    getConnetData,
    countryLabel,
    connectAccount,
    onboardingFinish,
    setConnectAccount,
    setOnboardingFinish,
    setModalState,
    brandId }: TParams) => {

    const { setAlert } = useSetAlert()
    const [onBoardAccount] = useMutation(ONBOARD_ACCOUNT);
    const [createConnectAccount, { data: createconnectdata, error: connectError }] = useMutation(CREATE_BRAND_CONNECT_ACCOUNT);

    const onBoard = async () => {
        if (
            getConnetData?.getBrandConnectAccount === null ||
            getConnetData?.getBrandConnectAccount?.data === null
        ) {
            // if (!connectID) {
            console.log("firstt")
            setConnectAccount('No Connect account');
            createConnectAccount({
                variables: {
                    args: {
                        country: getCountryValueFromLabel(countryLabel)?.toUpperCase(),
                        token: token,
                        brandId: brandId
                    },
                },
                awaitRefetchQueries: true,
                refetchQueries: [{
                    query: GET_BRAND_CONNECT_ACCOUNT, variables: {
                        brandId
                    }
                }],
                onCompleted: async data => {
                    if (
                        data &&
                        data.createBrandConnectAccount &&
                        data.createBrandConnectAccount?.data
                    ) {

                        await setConnectAccount('Account Created');
                        await onBoardAccount({
                            variables: {
                                args: {
                                    account: data.createBrandConnectAccount?.data?.id,
                                    type: data.createBrandConnectAccount?.data?.type,
                                    brandId
                                },
                            },
                            awaitRefetchQueries: true,
                            refetchQueries: [{
                                query: GET_BRAND_CONNECT_ACCOUNT, variables: {
                                    brandId
                                }
                            }],
                            onCompleted: () => {
                                setOnboardingFinish(true);
                                // dispatch(setCreateBankModalState());
                            },
                            onError: () => {
                                console.log("error hereeee")
                                setModalState(false)
                                setAlert('An error occured', 'danger')
                            }
                        });
                    } else {
                        setAlert("An error occured", 'danger');
                        setModalState(false)
                    }
                },
                onError: error => {
                    console.log(error)
                    setAlert(error.message, 'danger');
                    setModalState(false)
                },
            });
        } else if (getConnetData?.getBrandConnectAccount?.data?.flag === false) {
            console.log("second")
            setOnboardingFinish(false);
            await onBoardAccount({
                variables: {
                    args: {
                        account:
                            getConnetData?.getBrandConnectAccount?.data
                                ?.connectAccountId,
                        type: getConnetData?.getBrandConnectAccount?.data?.type,
                        brandId
                    },
                },
                awaitRefetchQueries: true,
                refetchQueries: [{
                    query: GET_BRAND_CONNECT_ACCOUNT, variables: {
                        brandId
                    }
                }],
                onCompleted: () => setOnboardingFinish(true)
                // onCompleted: data => data && console.log(data),
            });

        } else {
            console.log("third")
            setOnboardingFinish(true);
        }
        return { connectAccount, onboardingFinish, createconnectdata }
    }

    return { onBoard, connectError }
};