import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import styled from 'rn-css';
import { Button, Flag } from '../..';
import { palettes } from '../../../config';
import { useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { AcceptOrReject } from '../../../types/userTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { AddUserIcon } from '../../common/icons';

const AddIconContainer = styled.View<{ primary: string[] }>`
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	padding-bottom: 4;
	&:hover {
		border-bottom: 1px solid ${({ primary }) => primary[0]};
	}
`;

type Props = {
	name: string;
	id: string;
	profileImage: string;
	isBrandProfile?: boolean;
	isShowBalance?: boolean;
	containerStyles?: TStyle;
	hasAddIcon?: boolean;
	time?: string;
	hasAccountVerified?: boolean;
	acceptOrRejectMutation?: (variables?: AcceptOrReject) => void;
	hasAcceptorReject?: boolean;
	country?: string;
	city?: string;
	withdrawable?: boolean
	setModalState?: React.Dispatch<React.SetStateAction<boolean>>
};

const Profile = ({
	name,
	id,
	profileImage,
	isBrandProfile = false,
	hasAddIcon = false,
	hasAccountVerified = false,
	hasAcceptorReject = false,
	isShowBalance,
	acceptOrRejectMutation,
	time,
	containerStyles,
	country,
	city,
	withdrawable,
	setModalState,
}: Props) => {
	const [statusLoading, setStatusLoading] = useState<boolean>(false)
	const [rejectLoading, setRejectLoading] = useState<boolean>(false)
	const availableBalanceOnStripe = useAppSelector(state => state.auth.availableAdminBalanceOnStripe)
	const pendingBalnceInStripe = useAppSelector(state => state.auth.pendingBalanceInStripe)
	const totalBalanceOnStripe = availableBalanceOnStripe + pendingBalnceInStripe
	const totalPendingTransferAmount = useAppSelector(state => state.auth.totalPendingTransfers.totalPendingTransfers) / 100
	const totalPendingTransfersNowAvailableOnStripe = useAppSelector(state => state.auth.totalPendingTransfers.totalPendingTransfersNowAvailableOnStripe) / 100
	const totalPendingTransfersPendingOnStripe = useAppSelector(state => state.auth.totalPendingTransfers.totalPendingTransfersPendingOnStripe) / 100
	const ocpusStripeShare = totalBalanceOnStripe - totalPendingTransferAmount
	const ocpusAvailableBalance = availableBalanceOnStripe - totalPendingTransfersNowAvailableOnStripe
	const ocpusPendingBalance = pendingBalnceInStripe - totalPendingTransfersPendingOnStripe
	const primaryColors = palettes.primary;

	const { isUserLoggedIn, loggedInUserDetails } = useAppSelector(
		({ auth: { isUserLoggedIn, loggedInUserDetails } }) => ({
			isUserLoggedIn,
			loggedInUserDetails,
		})
	);

	// console.log(totalPendingTransferAmount)
	const handleClickWithdraw = () => {
		setModalState && setModalState(true)
	}
	return (
		<>

			<View style={[styles.container, containerStyles]}>
				<View style={styles.imageNameLocationContainer}>
					<Image
						source={{ uri: profileImage }}
						style={[styles.profileImage, styleProfileImage()]}
					/>
					<View style={styles.nameLocationContainer}>
						<Text
							style={[styles.name, styleName()]}
							numberOfLines={1}
						>
							{name}
						</Text>
						{!isBrandProfile && (
							<>
								<Text
									style={[styles.location, styleLocation()]}
									numberOfLines={2}
								>
									{city}, <Flag countryCode={country} />
								</Text>
							</>
						)}
						{hasAccountVerified && !hasAddIcon && !time && (
							<View style={styleAccountVerifiedContainer()}>
								<Text
									style={[
										styles.accountVerified,
										styleAccountVerified(primaryColors),
									]}
								>
									{'Account Verified'}
								</Text>
							</View>
						)}
						{!hasAccountVerified && !hasAddIcon && !time && (
							<View style={styleAccountVerifiedContainer()}>
								<Text
									style={[
										styles.accountVerified,
										styleAccountVerified(primaryColors),
									]}
								>
									{'Unverified'}
								</Text>
							</View>
						)}
					</View>
				</View>
				{hasAddIcon && !hasAccountVerified && !time && (
					<View style={styles.addUserContainer}>
						<AddIconContainer primary={primaryColors}>
							<AddUserIcon style={styleAddUserIcon()} />
							<Text style={styleAddUserText(primaryColors)}>
								{'Add'}
							</Text>
						</AddIconContainer>
					</View>
				)}
				{hasAcceptorReject && (
					<View
						style={{
							flexDirection: 'row',
							justifyContent: 'center',
							paddingRight: 20,
						}}
					>
						<>
							<Button
								title="Accept"
								onPress={() => {
									setStatusLoading(true);
									acceptOrRejectMutation &&
										acceptOrRejectMutation({
											variables: {
												id: id,
												status: 'ACTIVE',
											},
										});
								}}
								variant={rejectLoading ? 'disabled' : 'grey'}
								loading={statusLoading}
								outerContainerProps={{
									style: {
										flexBasis: getResponsiveStyle(112),
									},
								}}
							/>
							<Button
								title="Reject"
								loading={rejectLoading}
								variant={statusLoading ? 'disabled' : 'outline'}
								onPress={() => {
									setRejectLoading(true);
									acceptOrRejectMutation &&
										acceptOrRejectMutation({
											variables: {
												id: id,
												status: 'DISABLED',
											},
										});
								}}
								outlineColor={palettes.red[0]}
								outerContainerProps={{
									style: {
										width: getResponsiveStyle(90),
									},
								}}
							/>
						</>
					</View>
				)}
				{time && !hasAddIcon && !hasAccountVerified && (
					<View style={styles.timeContainer}>
						<Text style={[styles.timeText, styleTimeText()]}>
							{time}
						</Text>
					</View>
				)}
			</View>
			{withdrawable && loggedInUserDetails?.role !== "BRAND_ADMIN" &&
				<View style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', marginBottom: -20 }}>
					<View style={{ marginBottom: 20 }}>
						<Text style={{ fontWeight: '700' }}>
							<Text style={styles.textStyle}>Ocpus Total Balance</Text>
							: {getSymbolFromCurrency('eur')}{ocpusStripeShare.toFixed(2)}</Text>
						{/* <Text style={{ fontWeight: '700' }}>
						<Text style={styles.textStyle}>Brands Stripe Share</Text>
						: {getSymbolFromCurrency('eur')}{allBrandsEarnings}</Text> */}

						<Text style={{ fontWeight: '700' }}>
							<Text style={styles.textStyle}>Pending balance</Text>
							: {getSymbolFromCurrency('eur')}{ocpusPendingBalance?.toFixed(2)}</Text>
						<Text style={{ fontWeight: '700' }}>
							<Text style={styles.textStyle}>Available balance</Text>
							: {getSymbolFromCurrency('eur')}{ocpusAvailableBalance?.toFixed(2)}</Text>

					</View>
					<Button
						title="Withdraw"
						variant={availableBalanceOnStripe > 0 ? "primary" : 'disabled'}
						onPress={handleClickWithdraw}
						size='sm'
					/>
				</View>
			}

		</>
	);
};

const styles = StyleSheet.create({
	accountVerified: {
		fontWeight: '700',
	},
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},
	profileImage: {
		borderRadius: 9999,
	},
	imageNameLocationContainer: {
		flexDirection: 'row',
		maxWidth: '60%',
	},
	nameLocationContainer: {
		marginTop: 5,
		width: '100%',
	},
	name: {
		fontWeight: '700',
		color: palettes.dark[0],
	},
	location: {
		color: palettes.grey[0],
	},
	addUserContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	timeContainer: {
		justifyContent: 'center',
	},
	timeText: {
		textAlign: 'right',

		color: palettes.grey[0],
	},
	textStyle: {
		color: 'green',
		fontSize: 16,
		marginBottom: 10
	}
});

export default Profile;
function styleTimeText() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
	};
}

function styleAddUserText(primary: string[]) {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		color: primary[0],
	};
}

function styleAddUserIcon() {
	return {
		width: getResponsiveStyle(14, 'dimensions'),
		height: getResponsiveStyle(16, 'dimensions'),
		marginRight: getResponsiveStyle(5),
	};
}

function styleAccountVerified(primary: string[]) {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		color: primary[0],
	};
}

function styleAccountVerifiedContainer() {
	return { marginTop: getResponsiveStyle(4) };
}

function styleLocation() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
	};
}

function styleProfileImage() {
	return {
		width: getResponsiveStyle(40, 'dimensions'),
		height: getResponsiveStyle(40, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleName() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginBottom: getResponsiveStyle(2),
	};
}
