import { useLazyQuery } from '@apollo/client';
import { GET_USER_METRICS_CHANGE } from '../../graphql/auth/queries';

export const useGetUserMetricChange = () => {
	const [_getAllUsers, state] = useLazyQuery(GET_USER_METRICS_CHANGE);

	const getUserMetricChange = (typeOfAccount: string) => {
		_getAllUsers({
			variables: {
				typeOfAccount,
			},
		});
	};

	return {
		getUserMetricChange,
		...state,
	};
};
