import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	FlatList,
	Pressable,
	StyleSheet,
	useWindowDimensions,
	View,
} from 'react-native';
import { DraggableScrollView, EventCard, SectionHeading } from '../..';
import { breakpoints } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { AcceptOrRejectEvent, TGetAllEventsData } from '../../../types/eventTypes';
import { TStyle } from '../../../types/TStyle';
import { createArray } from '../../../utils/createArray';
import { dateFns } from '../../../utils/dateFns';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import NotAvailableMessage from '../not-available-message/NotAvailableMessage';

type Props = {
	heading?: string;
	hasViewAllLabel?: boolean;
	hasBorderBottom?: boolean;
	containerStyle?: TStyle;
	cardProps?: {
		type?: string;
	};
	hasFilters?: boolean;
	navigationDestination?: string;
	hasRequest?:boolean
	data: TGetAllEventsData[];
	clickable:boolean;
	acceptOrRejectMutation?: (variables?: AcceptOrRejectEvent) => void;

};

const ScrollEventSection = ({
	heading,
	hasViewAllLabel = false,
	hasBorderBottom = false,
	containerStyle,
	cardProps,
	hasFilters,
	hasRequest=true,
	acceptOrRejectMutation,
	navigationDestination,
	clickable=true,
	data,
}: Props) => {
	const ToRender = createArray(10);
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const {t}=useTranslation()

	const renderItem = (
		isLessThanDesktopBase: boolean,
		data: TGetAllEventsData,
		index: number
	) => {
		// console.log({ data }, 'event data');
		return (
			<EventCard
				size="small"
				id={data._id}
				containerStyles={styleEventCardContainer(
					isLessThanDesktopBase,
					// data,
					index
				)}
				acceptOrRejectMutation={acceptOrRejectMutation}
				media={data.eventMedia}
				hasRequestButton={hasRequest}
				month={dateFns.month(+data.startingEventDateTime)}
				date={dateFns.date(+data.startingEventDateTime)}
				time={data.startingEventDateTime}
				clickable={clickable}
				
				title={data.name}
				description={data.description}
			/>
		);
	};

	return (
		<View style={styleContainer(containerStyle, width)}>
			<SectionHeading
				heading={heading}
				hasViewAllLabel={hasViewAllLabel}
				hasBorderBottom={hasBorderBottom}
				hasFilters={hasFilters}
				stylesObject={{
					headingStyles: {
						fontWeight: '700',
					},
				}}
				navigationDestination={navigationDestination}
			/>
			{data && data.length?<Pressable>
				<DraggableScrollView
					horizontal
					showsHorizontalScrollIndicator={false}
					style={styleDraggableScroll(isLessThanDesktopBase)}
					data={data}
					renderItem={({ item, index }: any) =>
						renderItem(isLessThanDesktopBase, item, index)
					}
				/>
			</Pressable>:<NotAvailableMessage message="No Event Found"/>}
		</View>
	);
};

// const getResponsiveMarginBottom = (width: number) => {
// 	const desktopMarginBottom = getResponsiveStyle(81);
// 	const mobileMarginBottom = getResponsiveStyle(33);
// 	return width >= breakpoints.desktopBase
// 		? desktopMarginBottom
// 		: mobileMarginBottom;
// };
const styleDraggableScroll = (isLessThanDesktopBase: boolean): TStyle => {
	return [
		styles.eventRow,
		{
			width: `calc(100% + ${
				isLessThanDesktopBase ? 0 : getResponsiveStyle(32)
			}px)`,
		},
	];
};

const styleEventCardContainer = (
	isLessThanDesktopBase: boolean,
	// data: TGetAllEventsData[],
	index: number
): TStyle => {
	return {
		width: getResponsiveStyle(320, 'dimensions'),
		marginRight: isLessThanDesktopBase
			? getResponsiveStyle(16)
			: getResponsiveStyle(32),
		marginBottom: getResponsiveStyle(8),
		maxWidth: '100%',
	} as TStyle;
};

const styleContainer = (containerStyle: TStyle, width: number): TStyle => {
	return [
		{
			marginBottom: getResponsiveStyle(20),
		},
		containerStyle,
	];
};

const styles = StyleSheet.create({
	eventRow: {
		flexDirection: 'row',
		maxWidth: 'max-content',
	},
});

export default ScrollEventSection;
