import { Get_ALL_EVENTS } from '../../graphql/events/queries';
import { TGetAllEventsResponse, TStatusOfEvent } from '../../types/eventTypes';
import { TFind, TPaginatedOptions } from '../../types/types';
import { useQueryTemplate } from '../template/useQueryTemplate';

export const useGetAllUpcomingEvents = (statusOfEvent?: TStatusOfEvent) => {
	const onCompleted = () => {
		console.log('oncompleted');
	};

	const { getAllData: getAllEvents, ...states } = useQueryTemplate<
		TGetAllEventsResponse,
		TPaginatedOptions & TFind
	>({
		onCompleted,
		query: Get_ALL_EVENTS,
		...(!!statusOfEvent && { variables: { status: statusOfEvent } }),
	});

	return { getAllEvents, ...states };
};
