import { useQuery } from '@apollo/client';
import { GET_USER } from '../../graphql/auth/queries';
import { setIsUserLoggedIn, setLoggedInUserDetails } from '../../redux/authSlice';
import { useAppDispatch } from '../../redux/store';
import { TGetUserResponse } from '../../types/authTypes';


const useGetUser = () => {
	const dispatch = useAppDispatch();

	const onCompleted = (responseData: TGetUserResponse) => {
		if (responseData?.getUser?.success && responseData?.getUser?.data) {
			const data = responseData.getUser.data;
			dispatch(
				setLoggedInUserDetails({
					isUserLoggedIn: true,
					...data,
				})
			);
		}
	};
	const onError = () => {
		dispatch(setIsUserLoggedIn(false));
	};

	const { data, loading, error } = useQuery<TGetUserResponse>(GET_USER, {
		onCompleted,
		onError,
	});

	return {
		data,
		loading,
		error,
	};
};

export default useGetUser;
