import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import AllEventsSection from '../components/common/all-events-section/AllEventsSection';
import Loader from '../components/common/loader/Loader';
import NotAvailableMessage from '../components/common/not-available-message/NotAvailableMessage';
import PageHeader from '../components/common/page-header/PageHeader';
import ScrollEventSection from '../components/common/scroll-events-section/ScrollEventSection';
import ScrollTabs from '../components/common/scroll-tabs/ScrollTabs';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import ViewAllText from '../components/common/view-all-text/ViewAllText';
import { EDIT_EVENT_STATUS } from '../graphql/events/mutations';
import {
	Get_ALL_EVENTS,
	GET_EVENT_GRAPH_DATA,
} from '../graphql/events/queries';
import { TGetAllEventsData, TGetAllEventsResponse } from '../types/eventTypes';
import { TStatsforEvent, TStatsforTable } from '../types/userTypes';
import { dateFns } from '../utils/dateFns';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';

const SECTION_TABS: string[] = ['All Events', 'New Events'];

function EventsScreen() {
	const [tabSelected, setTabSelected] = useState(SECTION_TABS[0]);

	const [allUpcomingEvents, setAllUpcomingEvents] = useState<
		TGetAllEventsData[] | undefined
	>([]);
	const [allUpcomingPendingEvents, setAllUpcomingPendingEvents] = useState<
		TGetAllEventsData[] | undefined
	>([]);

	const [tabEvents, setTabEvents] = useState<TGetAllEventsData[] | undefined>(
		[]
	);
	const [eventStats, seteventStats] = useState<TStatsforEvent>();
	const [PrevGraphData, setPrevGraphData] = useState<number[]>();
	const [CurrGraphData, setCurrGraphData] = useState<number[]>();

	// upcoming events
	const [getAllUpcomingEvents, { loading: loadingGetAllUpcomingEvents }] =
		useLazyQuery<TGetAllEventsResponse>(Get_ALL_EVENTS, {
			onCompleted: (data: TGetAllEventsResponse) => {
				if (
					data?.getAllEvents?.data &&
					data?.getAllEvents?.success &&
					data?.getAllEvents?.data?.data
				) {
					console.log(data.getAllEvents);
					seteventStats({
						totalnum: data.getAllEvents.data.total,
						active: data.getAllEvents.data.totalActive,
						upcoming: data.getAllEvents.data.totalUpcoming,
						newRequest: data.getAllEvents.data.newRequest,
					});
					setAllUpcomingEvents(data.getAllEvents.data.data.filter(
						item => item.isApproved === true
					));
				} else {
					setAllUpcomingEvents([]);
				}
			},
			onError: error => {
				console.log(error);
				setAllUpcomingEvents([]);
			},
		});
	const [getNewReqEvents, { loading: loadingNewReqEvents }] =
		useLazyQuery<TGetAllEventsResponse>(Get_ALL_EVENTS, {
			onCompleted: (data: TGetAllEventsResponse) => {
				if (
					data?.getAllEvents?.data &&
					data?.getAllEvents?.success &&
					data?.getAllEvents?.data?.data
				) {
					console.log(data.getAllEvents);

					setAllUpcomingPendingEvents(
						data.getAllEvents.data.data.filter(x => x.isRejected === false)


					);
				} else {
					setAllUpcomingPendingEvents(
						[]
					);
				}
			},
			onError: error => {
				console.log(error);
				setAllUpcomingPendingEvents([]);
			},
		});


	// all events
	const [getAllEvents, { loading: loadingGetAllEvents }] =
		useLazyQuery<TGetAllEventsResponse>(Get_ALL_EVENTS, {
			fetchPolicy: 'cache-and-network',
			variables: {
				limit: 10,
			},
			onCompleted: (data: TGetAllEventsResponse) => {
				if (
					data.getAllEvents.data &&
					data.getAllEvents.success &&
					data.getAllEvents.data.data
				) {
					setTabEvents(data.getAllEvents.data.data);
					console.log('woos: ' + data.getAllEvents.data.data)
				} else {
					setTabEvents([]);
				}
			},
			onError: error => {
				console.log(error);
				error && setTabEvents([]);
			},
		});

	// all new events
	const [getAllNewEvents, { loading: loadingGetAllNewEvents }] =
		useLazyQuery<TGetAllEventsResponse>(Get_ALL_EVENTS, {
			fetchPolicy: 'cache-and-network',
			variables: {
				limit: 10,
			},
			onCompleted: (data: TGetAllEventsResponse) => {
				if (
					data.getAllEvents.data &&
					data.getAllEvents.success &&
					data.getAllEvents.data.data
				) {
					setTabEvents(data.getAllEvents.data.data);
				} else {
					setTabEvents([]);
				}
			},
			onError: error => {
				console.log(error);
				error && setTabEvents([]);
			},
		});

	const onPressTab = (tab: string) => {
		setTabSelected(tab);
		if (tab === 'New Events') {
			getAllNewEvents({
				variables: {
					find: {
						startingEventDateTime: {
							$gte: new Date(),
							$lte: dateFns.addDays(7, new Date()),
						},
					},
				},
			});
		} else {
			getAllEvents({
				variables: {
					sort: { createdAt: -1 },
				}
			});
		}
	};

	useEffect(() => {
		getAllEvents({
			variables: {
				sort: { createdAt: -1 },
			}
		});
		getNewReqEvents({
			variables: {
				status: "PENDING_APPROVAL"
			}
		})
		getAllUpcomingEvents({
			variables: {
				find: {
					startingEventDateTime: {
						$gte: new Date(),
					},

				},

			},
		});
	}, []);

	const loadingUnifiedTabEvents = useMemo(
		() =>
			getSingleLoadingState(loadingGetAllEvents, loadingGetAllNewEvents),
		[loadingGetAllEvents, loadingGetAllNewEvents]
	);
	useQuery(GET_EVENT_GRAPH_DATA, {
		variables: {
			type: 'HOST',
		},
		onCompleted: data => {
			if (
				data.getEventsGraphData.success &&
				data.getEventsGraphData.data
			) {
				let currMonth = data.getEventsGraphData.data.currentMonth.map(
					(value: { dataPoint: number }) => {
						return value.dataPoint;
					}
				);
				let prevMonth = data.getEventsGraphData.data.previousMonth.map(
					(value: { dataPoint: number }) => {
						return value.dataPoint;
					}
				);
				setPrevGraphData(prevMonth);
				setCurrGraphData(currMonth);
			}
		},
	});
	const [acceptOrRejectEvent, { loading: acceptOrRejectEventLoading }] =
		useMutation(EDIT_EVENT_STATUS, {
			awaitRefetchQueries: true,
			refetchQueries: [Get_ALL_EVENTS],
		});
	return (
		<DesktopTemplate>
			<PageHeader
				pageHeader="All Events"
				prevData={PrevGraphData}
				currData={CurrGraphData}
				chartItemValues={[
					{
						title: 'All Events',
						percentageDifference: 10,
						percentageIncrease: true,
						value: `${eventStats ? eventStats.totalnum : 0}`,
					},
					{
						title: 'Active Events',
						percentageDifference: 10,
						percentageIncrease: true,
						value: `${eventStats ? eventStats.active : 0}`,
					},
					{
						title: 'Upcomming Events',
						percentageDifference: 10,
						percentageIncrease: false,
						value: `${eventStats ? eventStats.upcoming : 0}`,
					},
					{
						title: 'New Request',
						percentageDifference: 10,
						percentageIncrease: true,
						value: `${eventStats ? eventStats.newRequest : 0}`,
					},
				]}
			/>

			<View style={{ marginTop: getResponsiveStyle(54) }}>
				{loadingGetAllUpcomingEvents ? (
					<Loader />
				) : (
					<>
						{allUpcomingPendingEvents && allUpcomingPendingEvents?.length > 0 && (
							<ScrollEventSection
								heading={'New Event Request'}
								hasViewAllLabel
								cardProps={{
									type: 'Request',
								}}
								hasRequest={true}
								navigationDestination="UpcomingEvents"
								acceptOrRejectMutation={acceptOrRejectEvent}
								data={allUpcomingPendingEvents}
							/>
						)}
						{allUpcomingEvents && (
							<ScrollEventSection
								heading={'Upcoming Events'}
								hasViewAllLabel
								cardProps={{
									type: 'Request',
								}}
								hasRequest={false}
								navigationDestination="UpcomingEvents"
								data={allUpcomingEvents}
							/>
						)}
					</>
				)}
			</View>
			<View
				style={{
					marginTop: getResponsiveStyle(46),
				}}
			>
				<View style={styles.headerContainer}>
					<ScrollTabs
						tabs={SECTION_TABS}
						onPress={onPressTab}
						selectedTab={tabSelected}
					/>
					<ViewAllText
						title="View All Events"
						navigationDestination="AllEvents"
					/>
				</View>
				{loadingUnifiedTabEvents ? (
					<Loader />
				) : (
					tabEvents && tabEvents?.length > 0 ? <AllEventsSection data={tabEvents} /> : <NotAvailableMessage message="No Events" />
				)}
			</View>
		</DesktopTemplate>
	);
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'column',
	},

	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: 10,
		justifyContent: 'space-between',
		marginBottom: getResponsiveStyle(24),
	},

	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
	},

	tableContainer: {
		width: '100%',
	},
});

export default EventsScreen;
function useSetAlerts(): {} {
	throw new Error('Function not implemented.');
}
