import React from 'react';
import {
	StyleSheet,
	Text,
	TextProps,
	TouchableOpacity,
	TouchableOpacityProps,
	View,
	ViewProps,
} from 'react-native';
import { Pressable } from 'react-native-web-hover';
import { palettes } from '../../../config';
import { TStyle, TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import styled from 'rn-css';
import Loader from '../loader/Loader';

type Props = {
	textProps?: TextProps;
	innerContainerProps?: TouchableOpacityProps;
	outerContainerProps?: ViewProps;
	title: string|JSX.Element;
	variant?: TVariant;
	size?: TSize;
	onPress?: () => void;
	disabled?: boolean;
	outlineColor?: string;
	loading?: boolean;
	radius?: number;
	width?: number;
};
type TSize = 'sm' | 'md' | 'lg';
type TVariant = 'outline' | 'grey' | 'primary' | 'disabled' | 'grey-outline';

type TVariantStyles = {
	[K in TVariant]: {
		['container']: TStyle;
		['text']: TStyleInputOrText;
		['hover']: TStyle;
	};
};

const StyledPressable = styled(Pressable)<{ disabled: boolean }>`
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const primary = palettes.primary;

const Button = ({
	textProps,
	innerContainerProps,
	outerContainerProps,
	title,
	variant = 'outline',
	size = 'sm',
	outlineColor,
	onPress = () => {},
	loading = false,
	radius,
	width,
}: Props) => {
	// Moved variantStyles here to make the ouline colour dynamic

	const variantStyles: TVariantStyles = {
		outline: {
			container: {
				borderColor: outlineColor ?? primary[0],
			},
			text: {
				color: outlineColor ?? primary[0],
			},
			hover: {
				borderColor: `${outlineColor ?? primary[0]}20`,
			},
		},
		grey: {
			container: {
				borderColor: palettes.grey[3],
			},
			text: {
				color: palettes.grey[8],
			},
			hover: {
				borderColor: '#E0e4e9',
			},
		},
		primary: {
			container: {
				borderColor: primary[0],
				backgroundColor: primary[0],
			},
			text: {
				color: 'white',
			},
			hover: {
				// backgroundColor: "#5131DA",
				// borderColor: "transparent",
				// borderWidth: 0,
			},
		},
		disabled: {
			container: {
				borderColor: palettes.grey[3],
				backgroundColor: palettes.grey[9],
			},
			text: {
				color: palettes.grey[1],
			},
			hover: {},
		},
		'grey-outline': {
			container: {
				borderColor: palettes.grey[4],
				backgroundColor: 'white',
			},
			text: {
				color: palettes.dark[2],
			},
			hover: {
				borderColor: `${palettes.grey[3]}20`,
			},
		},
	};

	const outerContainerStyles = outerContainerProps?.style;
	const innerContainerStyles = innerContainerProps?.style;
	const textStyles = textProps?.style;
	delete innerContainerProps?.style;
	delete outerContainerProps?.style;
	delete textProps?.style;

	const innerContainerRadius = radius || 6;
	const hoverContainerRadius = (radius as number) + 2 || 8;
	const buttonWidth = width || 10;
	return (
		<StyledPressable
			style={outerContainerStyles}
			disabled={variant === 'disabled'}
		>
			{({ hovered }) => {
				return (
					<View
						style={[
							commonStyles.outerContainer,
							hovered && commonStyles.hover,
							styleContainerRadius(hoverContainerRadius),
							hovered && variantStyles[variant].hover,
							loading && variantStyles['disabled'].hover,
						]}
						{...outerContainerProps}
					>
						<TouchableOpacity
							style={[
								commonStyles.innerContainer,
								variantStyles[variant].container,
								styleContainer(size, buttonWidth),
								styleContainerRadius(innerContainerRadius),
								innerContainerStyles,
								loading && variantStyles['disabled'].container,
							]}
							{...innerContainerProps}
							onPress={
								variant === 'disabled' ? () => {} : onPress
							}
							disabled={variant === 'disabled'}
						>
							<Text
								style={[
									commonStyles.text,
									variantStyles[variant].text,
									styleText(size),
									textStyles,
									loading && variantStyles['disabled'].text,
								]}
								{...textProps}
							>
								{title}
							</Text>
							{loading && (
								<Loader
									size={getResponsiveStyle(16, 'dimensions')}
									containerStyles={
										commonStyles.loaderContainer
									}
								/>
							)}
						</TouchableOpacity>
					</View>
				);
			}}
		</StyledPressable>
	);
};

const commonStyles = StyleSheet.create({
	loaderContainer: {
		position: 'relative',
		marginLeft: getResponsiveStyle(16),
	},
	outerContainer: {
		borderWidth: 3,
		borderColor: 'transparent',
	},
	innerContainer: {
		// borderRadius: 6,
		borderWidth: 1,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'row',
	},
	text: {
		fontWeight: '600',
		textAlign: 'center',
	},
	hover: {
		borderWidth: 3,
		// borderRadius: 8,
	},
});

export default Button;

const styleContainer = (size: TSize, width: number) => {
	return {
		paddingHorizontal: getResponsiveStyle(width),
		// paddingVertical: getResponsiveStyle(9),
		height: getResponsiveStyle(size === 'sm' ? 32 : 48, 'dimensions'),
	};
};

const styleContainerRadius = (size: number) => {
	return {
		borderRadius: size,
	};
};

const styleText = (size: TSize) => {
	return {
		fontSize: getResponsiveStyle(size === 'sm' ? 12 : 16, 'font'),
		lineHeight: getResponsiveStyle(size === 'sm' ? 12 : 16, 'font'),
	};
};
