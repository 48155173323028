import React, { useEffect, useMemo, useState } from 'react';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Button from '../../common/button/Button';
import Input from '../../common/Input';
import Spacing from '../../common/spacing/spacing';
import { Dropdown } from '../..';
import { useGetAllBrands } from '../../../api/brands/useGetAllBrands';
import { Picker } from '@react-native-picker/picker';
import { Formik } from 'formik';
import Dropdown2 from '../../common/dropdown2/dropdown2';
import {
	createStarterKitsInitialValues,
	createStarterKitValidationSchema,
	TStarterKitsFormValues,
} from './FormikStarterKitsConstants';
import { TDropdownObject } from '../../../types/types';
import useGetAllProducts from '../../../api/product/useGetAllProducts';
import {
	Product,
	StarterKit,
	TEditStarterKitResponse,
	TGetAllProductsResponse,
	TGetStarterKitDetailsResponse,
} from '../../../types/productTypes';
import useGetStarterKitDetails from '../../../api/starterKits/useGetStarterKitDetails';
import useEditStarterKit from '../../../api/product/useEditStarterKit';
import Loader from '../../common/loader/Loader';
import { View } from 'react-native';
import { useSetAlert } from '../../../hooks/useSetAlerts';

type Props = {
	onSubmit: (values: any) => void;
	starterKitCreateLoading: boolean;
	editStarterKit?: boolean;
	starterKit?: StarterKit;
	closeModal?: () => void;
};
const CreateStarterKitModalContent = ({
	onSubmit,
	starterKitCreateLoading,
	editStarterKit = false,
	starterKit,
	closeModal = () => {},
}: Props) => {
	const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
	const [selectedBrandId, setSelectedBrandId] = useState('');
	const [productsDropDownOnEdit, setProductsDropDownOnEdit] = useState<
		TDropdownObject[]
	>([]);
	const [productsDropDownOnCreate, setProductsDropDownOnCreate] = useState<
		TDropdownObject[]
	>([]);
	const [brandProducts, setBrandProducts] = useState<TDropdownObject[]>([]);
	const [editInitialValues, setEditInitialValues] =
		useState<TStarterKitsFormValues>({} as TStarterKitsFormValues);
	const { getAllBrands, data: brandData } = useGetAllBrands();
	const { setAlert } = useSetAlert();

	const onCompletedGetAllProducts = (data: TGetAllProductsResponse) => {
		if (data.getAllProducts.success) {
			const productsArray: any = [];
			const products = data?.getAllProducts?.data?.data;
			products?.map((product: Product) => {
				product.variants.map(variant => {
					variant?.option?.values?.map(value => {
						productsArray.push({
							label:
								product?.name +
								' ' +
								variant?.value +
								' ' +
								value?.name,
							value: variant?._id,
						});
					});
				});
			});
			setProductsDropDownOnCreate(productsArray);
			setBrandProducts(productsArray);
		}
	};

	const onCompletedGetStarterKitDetails = (
		data: TGetStarterKitDetailsResponse
	) => {
		if (data.getStarterKit.success) {
			const selectedVariants_: string[] = [];
			data?.getStarterKit?.data?.variants.map((variant: any) => {
				selectedVariants_.push(variant._id);
			});
			setSelectedProducts(selectedVariants_);
			if (data.getStarterKit.data) {
				setEditInitialValues({
					name: data.getStarterKit?.data?.name,
					compareAtPrice: parseInt(
						data.getStarterKit?.data?.compareAtPrice
					),
					starterKitId: data.getStarterKit?.data?.starterKitId ?? '',
					vat: data.getStarterKit?.data?.vat,
					brand: data.getStarterKit?.data?.brand?._id,
					price: parseInt(data.getStarterKit?.data?.price),
					description: data.getStarterKit?.data?.description,
					variants: selectedVariants_,
				});
			}
			console.log({ editInitialValues });
		}
	};

	const onCompletedEditStarterKit = (data: TEditStarterKitResponse) => {
		closeModal();
		if (data.editStarterKit.success) {
			setAlert('StarterKit Edited Successfully', 'normal');
		} else {
			setAlert('Unable to edit starterkit', 'danger');
		}
	};

	const onErrorEditStarterKit = () => {
		setAlert('Unable to edit starterkit', 'danger');
	};
	const { executeAllProductQuery } = useGetAllProducts({
		onCompletedGetAllProducts,
	});

	const {
		executeGetStarterKitDetails,
		loading: starterkitDetailsLoading,
		data,
	} = useGetStarterKitDetails({ onCompletedGetStarterKitDetails });
	const { executeStarterKitEditMutation, loading: editStarterkitLoading } =
		useEditStarterKit({ onCompletedEditStarterKit, onErrorEditStarterKit });

	useEffect(() => {
		getAllBrands();
	}, []);

	useEffect(() => {
		if (starterKit) {
			setSelectedBrandId(starterKit?.brand?._id ?? '');

			executeGetStarterKitDetails(starterKit._id);
		}
	}, [starterKit]);

	useEffect(() => {
		if (selectedBrandId) executeAllProductQuery({ brand: selectedBrandId });
	}, [selectedBrandId]);

	useEffect(() => {
		if (productsDropDownOnCreate.length && selectedProducts.length) {
			setProductsDropDownOnEdit(
				productsDropDownOnCreate.filter(
					product => !selectedProducts.includes(product.value)
				)
			);
			setSelectedProducts([]);
		}
	}, [productsDropDownOnCreate, selectedProducts]);

	if (editStarterKit && starterkitDetailsLoading) {
		return (
			<View style={{ marginTop: '40%' }}>
				<Loader />
			</View>
		);
	} else {
		return (
			<Formik
				enableReinitialize
				initialValues={
					editStarterKit
						? editInitialValues
						: createStarterKitsInitialValues
				}
				validationSchema={createStarterKitValidationSchema}
				onSubmit={values => {
					editStarterKit
						? executeStarterKitEditMutation(starterKit?._id, {
								brand: values.brand,
								compareAtPrice: Number(values.compareAtPrice),
								starterKitId: values.starterKitId,
								description: values.description,
								name: values.name,
								vat: +values.vat,
								price: Number(values.price),
								variants: values.variants,
						  })
						: onSubmit(values);

					// editStarterKit && closeModal()
				}}
			>
				{({
					handleChange,
					handleBlur,
					setFieldValue,
					handleSubmit,
					touched,
					errors,
					values,
				}) => (
					<>
						<Spacing orientation="column" spacing={33}>
							<Input
								label="Collection Name"
								onChangeText={handleChange('name')}
								onBlur={handleBlur('name')}
								error={
									touched.name && errors.name
										? errors.name
										: ''
								}
								value={values.name}
							/>
							<Input
								label="Description"
								onChangeText={handleChange('description')}
								onBlur={handleBlur('description')}
								error={
									touched.description && errors.description
										? errors.description
										: ''
								}
								value={values.description}
								style={{
									paddingVertical: getResponsiveStyle(20),
									height: getResponsiveStyle(
										60,
										'dimensions'
									),
								}}
							/>
							<Input
								label="Starter Kit ID*"
								onChangeText={handleChange('starterKitId')}
								onBlur={handleBlur('starterKitId')}
								error={
									touched.starterKitId && errors.starterKitId
										? errors.starterKitId
										: ''
								}
								value={values.starterKitId}
							/>
							<Dropdown
								labelProps={{
									label: 'Brand:',
								}}
								pickerProps={{
									onValueChange: value => {
										setFieldValue('brand', value);
										setFieldValue('products', []);
										setSelectedBrandId(value as string);
									},
									selectedValue: values.brand,
									children: !editStarterKit ? (
										<>
											<Picker.Item
												label={'Please Select'}
												value=""
											/>

											{brandData?.map((brand: any) => (
												<Picker.Item
													label={brand?.companyName}
													value={brand?._id}
												/>
											))}
										</>
									) : (
										brandData?.map((brand: any) => (
											<Picker.Item
												label={brand?.companyName}
												value={brand?._id}
											/>
										))
									),
								}}
							/>
							<Dropdown2
								isSearchable
								key={'dropdown'}
								options={
									editStarterKit
										? productsDropDownOnEdit
										: productsDropDownOnCreate
								}
								inputProps={{
									placeholder: 'Products hint',
								}}
								labelProps={{
									label: 'Products*',
								}}
								values={brandProducts?.filter((item: any) =>
									values?.variants?.includes(item.value)
								)}
								onChangeValue={value => {
									console.log({ variants: values });
									setFieldValue('variants', [
										...values.variants,
										value.value,
									]);
									editStarterKit
										? setProductsDropDownOnEdit(prev =>
												prev.filter(
													prod =>
														prod.value !==
														value.value
												)
										  )
										: setProductsDropDownOnCreate(prev =>
												prev.filter(
													prod =>
														prod.value !==
														value.value
												)
										  );
								}}
								onPressCrossOnBadge={value => {
									let filteredProducts;
									filteredProducts = values.variants.filter(
										item => item !== value
									);

									setFieldValue('variants', filteredProducts);
									editStarterKit
										? setProductsDropDownOnEdit([
												...productsDropDownOnEdit,
												{
													label:
														brandProducts.find(
															prod =>
																prod.value ===
																value
														)?.label ?? '',
													value,
												},
										  ])
										: setProductsDropDownOnCreate([
												...productsDropDownOnCreate,
												{
													label:
														brandProducts.find(
															prod =>
																prod.value ===
																value
														)?.label ?? '',
													value,
												},
										  ]);
								}}
							/>
							<Spacing spacing={8} withEqualWidth>
								<Input
									label="Price (€)*"
									onChangeText={handleChange('price')}
									onBlur={handleBlur('price')}
									error={
										touched.price && errors.price
											? errors.price
											: ''
									}
									value={values.price}
								/>
								<Input
									label="Compare Price (€)*"
									onChangeText={handleChange(
										'compareAtPrice'
									)}
									onBlur={handleBlur('compareAtPrice')}
									error={
										touched.compareAtPrice &&
										errors.compareAtPrice
											? errors.compareAtPrice
											: ''
									}
									value={values.compareAtPrice}
								/>
							</Spacing>
							<Input
								label="VAT (%)*"
								onChangeText={handleChange('vat')}
								onBlur={handleBlur('vat')}
								error={
									touched.vat && errors.vat ? errors.vat : ''
								}
								value={values.vat}
							/>

							<Button
								title="Save"
								variant="primary"
								size="lg"
								onPress={handleSubmit}
								loading={
									starterKitCreateLoading ||
									editStarterkitLoading
								}
							/>
						</Spacing>
					</>
				)}
			</Formik>
		);
	}
};

export default CreateStarterKitModalContent;
