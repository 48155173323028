import { DocumentNode, useLazyQuery } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import {
	TOnCompleteApiCall,
	TOnErrorApiCall,
	TReturnUseApiHookLazyQuery,
} from '../../types/types';

type TReturn<T, U> = {
	getAllData: (variables?: U) => void;
} & TReturnUseApiHookLazyQuery<T, U>;

type Params<T, U> = {
	onCompleted?: TOnCompleteApiCall<T>;
	onError?: TOnErrorApiCall;
	variables?: U;
	isLazy?: boolean;
	query: DocumentNode;
};

export const useQueryTemplate = <T, U>(params: Params<T, U>): TReturn<T, U> => {
	const [executeQuery, states] = useLazyQuery<T, U>(params.query, {
		onCompleted: params?.onCompleted,
		onError: params?.onError,
		variables: params?.variables,
	});

	const getAllData = useCallback(
		async (variables?: U) => {
			return await executeQuery({
				variables,
			});
		},
		[params?.variables]
	);

	useEffect(() => {
		if (params?.isLazy === undefined || !params.isLazy) {
			executeQuery({
				variables: params?.variables,
			});
		}
	}, []);

	return { getAllData, ...states };
};
