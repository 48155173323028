import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ProfileCards from '../profile-cards/ProfileCards';
import { useNavigation } from '@react-navigation/native';
import { TCommonUserResponseData } from '../../../types/authTypes';
import { AcceptOrReject, TUserDataForTable } from '../../../types/userTypes';
import NotAvailableMessage from '../not-available-message/NotAvailableMessage';
import { TUseNavigation } from '../../../types/types';

type Props = {
	header: string;
	cardProps?: {
		isBrand?: boolean;
		isRequest?: boolean;
		showGender?: boolean;
	};
	newRequest?: TUserDataForTable[];
	navigationDestination?: string;
	acceptOrRejectMutation?: (variables?: AcceptOrReject) => void;
};

const PeopleScrollSection = ({
	header,
	cardProps,
	newRequest,
	navigationDestination,
	acceptOrRejectMutation
}: Props) => {
	const navigator = useNavigation<TUseNavigation>();
	return (
		<View style={[Styles.container]}>
			<View style={Styles.headerContainer}>
				<Text style={Styles.title}>{header}</Text>
				<Text
					style={Styles.hoverText}
					onPress={() => navigator.navigate(navigationDestination)}
				>
					View All
				</Text>
			</View>
			
			<View style={Styles.sliderContainer}>
			{newRequest && newRequest.length?
				<ProfileCards
					isBrand={cardProps?.isBrand}
					isRequest={cardProps?.isRequest}
					showGender={cardProps?.showGender}
					newRequest={newRequest}
					acceptOrRejectMutation={acceptOrRejectMutation}
				/>:<NotAvailableMessage message={'No '+ header}/>}
			</View>
		</View>
	);
};

const Styles = StyleSheet.create({
	container: {
		width: '100%',
		marginTop: getResponsiveStyle(54),
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	title: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	sliderContainer: {
		width: '100%',
		marginTop: getResponsiveStyle(18),
	},
	hoverText: {
		// marginTop: 20,
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[2],
		cursor: 'pointer',
	},
});
export default PeopleScrollSection;
