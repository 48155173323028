import React, { useState } from 'react';
import {
	StyleSheet,
	View,
	Text,
	TouchableOpacity,
	TouchableHighlight,
} from 'react-native';
import { palettes } from '../../../config';
import { CheckIcon } from '../icons';
import { Pressable } from 'react-native-web-hover';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import styled from 'rn-css';
import { Label } from '../..';

type Props = {
	hintText?: string | JSX.Element;
	label?: string;
	isChecked?: boolean;
	onChange?: (prevState: boolean) => void;
};

const Test = styled.View``;

const Checkbox = ({ hintText, label, isChecked = false, onChange }: Props) => {
	return (
		<>
			{label && <Label label={label} />}
			<View style={styles.container}>
				<Pressable
					style={({ pressed, focused }) =>
						styleOuterContainer(pressed, focused)
					}
				>
					{({ pressed, focused }) => (
						<Text
							onPress={() => {
								onChange && onChange(isChecked);
							}}
							style={[
								styles.innerContainer,
								(pressed || focused || isChecked) &&
									styles.onEventInnerContainer,
							]}
						>
							{isChecked && (
								<CheckIcon color={palettes.primary[0]} />
							)}
						</Text>
					)}
				</Pressable>
				{hintText && (
					<Text style={{ marginLeft: getResponsiveStyle(11) }}>
						{hintText}
					</Text>
				)}
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		height: 'max-content',
	},
	innerContainer: {
		borderRadius: 6,
		width: 25,
		height: 25,
		borderColor: palettes.grey[4],
		borderWidth: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	pressableContainer: {
		borderWidth: 3,
		borderColor: 'transparent',
		height: 'max-content',
	},
	onEventOuterContainer: {
		borderColor: palettes.primary[1],
		borderRadius: 8,
	},
	onEventInnerContainer: {
		borderColor: palettes.primary[0],
	},
});

export default Checkbox;
function styleOuterContainer(pressed: boolean, focused: boolean | undefined) {
	console.log({ pressed, focused });
	return [
		styles.pressableContainer,
		(pressed || focused) && styles.onEventOuterContainer,
	];
}
