import React from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';
import { palettes } from '../../../config';
import AllEventsSection from '../all-events-section/AllEventsSection';
import {
	TGetAllEventsData,
	TGetAllEventsResponse,
} from '../../../types/eventTypes';
import { Get_ALL_EVENTS } from '../../../graphql/events/queries';
import { useLazyQuery } from '@apollo/client';

const PROFILES = [1, 2, 3, 5, 6, 8];

export const ProfileStack = ({ profileLink }: { profileLink: string[] }) => {
	// const [isInvited, setIsInvited] =useState()
	// const [getAllEvents, { loading: loadingGetAllEvents }] =
	// 	useLazyQuery<TGetAllEventsResponse>(Get_ALL_EVENTS, {
	// 		fetchPolicy: 'cache-and-network',
	// 		onCompleted: (data: TGetAllEventsResponse) => {
	// 			if (
	// 				data.getAllEvents.data &&
	// 				data.getAllEvents.success &&
	// 				data.getAllEvents.data.data
	// 			) {
	// 				console.log(data.getAllEvents.data.data)
	// 				setIsInvited(
	//           data.getAllEvents.data.data.map(events =>({
	//             invited:events.invitedPeople
	//           }))
	//           );
	// 			} else {
	// 				setIsInvited([]);
	// 			}
	// 		},
	// 		onError: error => {
	// 			error && setIsInvited([]);
	// 		},
	// 	});

	return (
		<View style={styles.profileStachContainer}>
			{!!profileLink &&
				profileLink?.slice(0, 4)?.map((link, index) => {
					if (index <= 2) {
						return (
							<Image
								style={[
									styles.profilePicture,
									{ left: index * 16 },
								]}
								source={
									link || 'https://via.placeholder.com/500'
								}
								key={index}
							/>
						);
					} else {
						return (
							<View
								style={[
									styles.profilePlaceholder,
									{ left: index * 16 },
								]}
								key={index}
							>
								<Text style={styles.profilePlaceholderText}>
									+{PROFILES.length - 3}
								</Text>
							</View>
						);
					}
				})}
		</View>
	);
};

const styles = StyleSheet.create({
	profileStachContainer: {
		position: 'relative',
		height: 28,
	},
	profilePicture: {
		borderRadius: 9999,
		width: 28,
		height: 28,
		position: 'absolute',
		borderColor: 'white',
		borderWidth: 1,
	},
	profilePlaceholder: {
		borderRadius: 9999,
		backgroundColor: palettes.grey[4],
		width: 28,
		height: 28,
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
	},
	profilePlaceholderText: {
		fontWeight: '700',
		fontSize: 10,
		lineHeight: 14,
		color: palettes.dark[4],
	},
});
