import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { SIGN_IN } from '../../graphql/auth/mutations';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { setLoggedInUserDetails } from '../../redux/authSlice';
import { setCreateUserDetails } from '../../redux/createUserSlice';
import { useAppDispatch } from '../../redux/store';
import { TCommonUserResponseData, TLoginResponse } from '../../types/authTypes';
import { TOnErrorApiCall, TUseNavigation } from '../../types/types';

const isFirstLogin = (data: TCommonUserResponseData) => {
	return data.isInvitedAdmin;
};

const useLogin = (_hideAlert?: boolean) => {
	const navigation = useNavigation<TUseNavigation>();
	const [hideAlert, _] = useState<boolean>(_hideAlert ?? false);
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();

	const onError: TOnErrorApiCall = error => {
		if (error) {
			setAlert(error.message, 'danger');
		}
	};

	const onCompleted = useCallback((data: TLoginResponse) => {

		if (
			data?.adminLogin?.success && data?.adminLogin?.data
		) {
			const responseData = data.adminLogin.data;

			!hideAlert
			if (responseData.isInvitedAdmin && (responseData.username === "")) {
				const email = responseData.email;
				const firstname = responseData.personalInformation.firstName
				const lastname = responseData.personalInformation.lastName
				const role = responseData.role
				const affiliatedBrand = responseData?.affiliatedBrand
				const accountType = responseData.typeOfAccount
				dispatch(setCreateUserDetails({ email: email, affiliatedBrand, typeOfAccount: accountType, role: role, personalInformation: { firstName: firstname, lastName: lastname } }))
				navigation.navigate('Signup');
			} else {
				dispatch(
					setLoggedInUserDetails({
						isUserLoggedIn: true,
						...(responseData as TCommonUserResponseData),
					})
				);
			}
		} else {
			setAlert(data.adminLogin.message, 'danger');
		}
	}, []);

	const [login, { data, error, loading }] = useMutation<TLoginResponse>(
		SIGN_IN,
		{
			onCompleted,
			onError,
		}
	);

	const executeLoginMutation = ({
		email,
		password,
	}: {
		email: string;
		password: string;
	}) => {
		login({
			variables: {
				email,
				password,
			},
		});
	};
	return { executeLoginMutation, data, error, loading };
};

export default useLogin;
