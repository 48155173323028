import React from 'react';
import { useNavigation } from '@react-navigation/native';
import {
	AuthTemplate,
	DesktopNavigationBar,
	GoBackButton,
	Logo,
} from '../components';
import { TUseNavigation } from '../types/types';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import { TStyle } from '../types/TStyle';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
// import SignupForm from '../components/auth/signup/signupform';
import { palettes } from '../config';
import SignupForm from '../components/auth/signup/SignupForm';

function SignupScreen() {
	const navigation = useNavigation<TUseNavigation>();
	const { isDesktop } = useIsSpecificViewportWidth();
	const primaryColors = palettes.primary;
	return (
		<View style={styles.container}>
			<View style={styles.topbar}>
				{/* <GoBackButton/> */}
				<View style={styles.navbarlogo}>
					<Logo
						tint={primaryColors[0]}
						style={[styles.logo]}
						width={38}
						height={38}
						scale={1.3}
						viewBox="0 0 38 38"
						onPress={() => {}}
					/>
				</View>
			</View>
			<AuthTemplate
				heading={'Create Account'}
				subText={'Create your Account to fully Experience the app.'}
				contentStyles={styleMainContent(isDesktop)}
				authType={'signup'}
				scrollItems={[]}
			>
				<SignupForm />
			</AuthTemplate>
		</View>
	);
}
const styles = StyleSheet.create({
	desktopNavigationContainer: {
		width: '100%',
	},
	container: {
		flexDirection: 'column',
		minWidth: '100vw',
		height: '100vh',
	},
	topbar: {
		minWidth: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		height: 80,
		paddingLeft: 30,
		alignItems: 'center',
	},
	navbarlogo: {
		flexGrow: 1,
		flexDirection: 'row',
		justifyContent: 'center',
	},
	input: {
		width: '100%',
	},
	flex1: {
		flex: 1,
	},
	logo: {
		minHeight: 38,
	},
});

export default SignupScreen;

function styleMainContent(isDesktop: boolean): TStyle {
	return {
		marginTop: getResponsiveStyle(isDesktop ? 0 : 40),
	};
}
