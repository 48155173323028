import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Text } from 'react-native';
import { Get_ALL_EVENTS, GET_UPCOMING_EVENTS } from '../../../graphql/events/queries';
import {
	TGetAllEventsData,
	TGetAllEventsResponse,
} from '../../../types/eventTypes';
import { TPaginatedOptions, TTypeOfAccount } from '../../../types/types';
import AllEventsSection from '../all-events-section/AllEventsSection';
import Loader from '../loader/Loader';

type TUserType = 'seller' | 'host' | 'guest' | 'brand';
const USER_TYPE: TUserType = 'brand';

export const getQueryFieldForUser = (
	typeOfAccount?: TTypeOfAccount,
	userId?: string
) => {
	switch (typeOfAccount) {
		case 'CONSUMER':
			return { invitedPeople: userId };
		case 'HOST':
			return { affiliatedHosts: userId ,isHostessApplicationOpen:false};
		case 'SELLER':
			return {
				affiliatedSeller: userId,
			};
		case 'BRAND':
			return {affiliatedBrands:userId};
		default:
			return {};
	}
};
type Props = {
	id: string;
	typeOfAccount: TTypeOfAccount;
};
const ProfileScreen = ({ id, typeOfAccount }: Props) => {
	const [AllEvents, setAllEvents] = useState<TGetAllEventsData[]>();
	const onCompleted = (
		type: 'all' | 'published' | 'past' | 'upcoming',
		data: TGetAllEventsResponse
	) => {
		if (
			data.getAllEvents &&
			data.getAllEvents.data &&
			data.getAllEvents.success
		) {
			const dataToSet = data.getAllEvents.data?.data;

			switch (type) {
				case 'all':
					setAllEvents(dataToSet);
					break;
				default:
					break;
			}
		}
	};
    const { loading: allEventsLoading } = useQuery<TGetAllEventsResponse>(
        Get_ALL_EVENTS,
        
		{
            variables: {
                find: getQueryFieldForUser(typeOfAccount,id),
            },
			onCompleted: data => {
				onCompleted('all', data);
			},
		}
	);

	return (
		<>
       {
        allEventsLoading?<Loader/>:AllEvents?
        <AllEventsSection data={AllEvents} />:<Text>No Events Available</Text>}
		</>
	);
};
export default ProfileScreen;
