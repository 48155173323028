import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Hoverable } from 'react-native-web-hover';
import { palettes } from '../../../../../config';
import { TUseNavigation } from '../../../../../types/exportedTypes';
import { getResponsiveStyle } from '../../../../../utils/getResponsiveStyle';

type Props = {
	scrollItems: TStepTemplateScrollItems[];
};

const SidebarNavigationScroll = ({ scrollItems }: Props) => {
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const navigation = useNavigation<TUseNavigation>();
	const route = useRoute();

	useEffect(() => {
		setSelectedIndex(
			scrollItems.findIndex(item => item.screen === route.name)
		);
	}, [route.name]);

	return (
		<View style={styleContainer()}>
			{scrollItems
				.filter(item => item.label !== '')
				.map((item, index) => {
					const isSelected = index === selectedIndex;
					const isCompleted = !isSelected && index < selectedIndex;
					return (
						<Hoverable key={item.screen}>
							{({ hovered }) => (
								<Pressable
									style={[
										styles.itemContainer,
										styleItemContainer(),
										isSelected &&
											styles.itemContainerSelected,
									]}
									onPress={() =>
										navigation.navigate(item.screen)
									}
								>
									<Text
										style={[
											styles.itemText,
											styleItemText(),
											isSelected &&
												styles.itemTextSelected,
											isCompleted &&
												styles.itemTextCompleted,
											hovered && styles.itemTextHovered,
										]}
									>
										{item.label}
									</Text>
								</Pressable>
							)}
						</Hoverable>
					);
				})}
		</View>
	);
};

const styles = StyleSheet.create({
	itemContainer: {
		borderRadius: 6,
	},
	itemContainerSelected: {
		backgroundColor: palettes.blue[1],
	},
	itemText: {
		fontWeight: '600',
		color: palettes.grey[1],
	},
	itemTextSelected: {
		color: palettes.dark[0],
	},
	itemTextCompleted: {
		textDecorationLine: 'line-through',
	},
	itemTextHovered: {
		color: palettes.dark[0],
	},
});

export default SidebarNavigationScroll;

const styleContainer = () => {
	return { marginTop: getResponsiveStyle(200) };
};

const styleItemText = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
};

const styleItemContainer = () => {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingVertical: getResponsiveStyle(17),
	};
};
