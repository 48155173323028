import React from 'react';
import { Dimensions, View, Text, StyleSheet } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { palettes } from '../../../config';
type Props = {
	height?: number;
	width?: number;
	time?: (string | undefined)[];
	date?: Date;
	data1?: number[];
	data2?: number[];
};
const Chart = ({
	data1 = [0, 0, 0, 0],
	data2 = [0],
	time = ['0', '', '', '', '30'],
	date = new Date(),
}: Props) => {
	const dat1 = [0, ...data1];
	let ranges: Map<number, number> = new Map([
		[1, 1],
		[2, 1],
		[3, 1],
		[4, 1],
		[5, 1],
		[6, 1],
		[7, 1],
		[8, 2],
		[9, 2],
		[10, 2],
		[11, 2],
		[12, 2],
		[13, 2],
		[14, 2],
		[15, 3],
		[16, 3],
		[17, 3],
		[18, 3],
		[19, 3],
		[20, 3],
		[21, 3],
		[22, 4],
		[23, 4],
		[24, 4],
		[25, 4],
		[26, 4],
		[27, 4],
		[28, 4],
		[29, 4],
		[30, 4],
		[31, 4],
	]);
	const today: number = new Date().getUTCDate();
	console.log(today);
	const range = ranges.get(today);
	console.log(range);
	const dat2 = [0, ...data2.slice(0, range)];
	console.log(dat2);

	time[dat2.length - 1] = 'Today'; //` ${(date.getDate() < 10 ? '0' : '') +date.getDate()}-${(date.getMonth() < 10 ? '0' : '') +date.getMonth()}`;

	const primaryColors = palettes.primary;
	const data = {
		labels: time,
		datasets: [
			{
				data: dat1,
				strokeWidth: 1,
				color: (opacity = 1) => '#ed7028',
				withDots: false,
			},
			{
				data: dat2,

				strokeWidth: 1,
				color: (opacity = 1) => primaryColors[0],
			},
		],
		// renderBezierShadow={()=>{

		// }}
	};
	return (
		<>
			<View style={Styles.container} nativeID={'line-chart'}>
				<LineChart
					data={data}
					width={Dimensions.get('window').width / 1.1}
					height={getResponsiveStyle(490)}
					withHorizontalLines={false}
					withHorizontalLabels={false}
					yAxisLabel=""
					yAxisSuffix=""
					bezier
					// yAxisInterval={0.1}

					onDataPointClick={data => {
						console.log(data, 'data');
					}}
					withVerticalLabels={false}
					hidePointsAtIndex={[...Array(dat2.length - 1).keys()]}
					// optional, defaults to 1
					withShadow={true}
					chartConfig={{
						backgroundColor: '#FFFFFF',
						backgroundGradientFrom: '#FFFFFF',
						backgroundGradientTo: '#FFFFFF',
						// fillShadowGradient:primaryColors[0] ,
						// fillShadowGradientOpacity:0.3,
						horizontalOffset: 0,

						strokeWidth: 1,
						// propsForLabels: {
						// 	fontSize: getResponsiveStyle(0, 'font'),
						// },

						useShadowColorFromDataset: true,

						decimalPlaces: 2, // optional, defaults to 2dp
						color: (opacity = 1) => primaryColors[0],
						// labelColor: (opacity = 1) => {
						// 	return 'purple';
						// },

						style: {
							borderRadius: 16,

							paddingRight: 0,
							paddingBottom: 0,
							marginBottom: 0,
						},

						propsForBackgroundLines: {
							strokeWidth: '1',
							stroke: 'lightgrey',
							opacity: '0.8',
						},

						fillShadowGradientFromOpacity: 0.2,
						fillShadowGradientTo: 'white',
						fillShadowGradientToOpacity: 0,
						propsForDots: {
							r: '6',
							strokeWidth: '2.5',
							stroke: primaryColors[0],

							fill: 'white',
						},
					}}
					style={Styles.image}
				/>
				{/* <View>
					{time &&
						time.length &&
						time.map((data, index) => (
							<Text
								key={index}
								numberOfLines={1}
								style={[styleUserData()]}
							>
								{data}
							</Text>
						))}
				</View> */}
				<View style={[Styles.labelcontainer, styleContainer()]}>
					{time.map((data, index) => (
						<View key={index} style={[styleLabel(index)]}>
							<Text
								style={[styleLabelTxt(index, dat2.length - 1)]}
							>
								{data}
							</Text>
						</View>
					))}
				</View>
			</View>
		</>
	);
};

const Styles = StyleSheet.create({
	container: {
		width: '100%',
		height: getResponsiveStyle(400),

		color: 'purple',
		fontFamily: 'Arial',
	},
	image: {
		width: '100%',
		height: '100%',
		paddingRight: 0,
	},

	labelcontainer: {
		flexDirection: 'row',
		paddingLeft: 30,
	},
});
const styleLabel = (index: number) => {
	return [
		{
			width: Dimensions.get('window').width / 5.5,
			fontSize: getResponsiveStyle(10, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
		},
		index === 0 && { paddingLeft: '35px' },
	];
};
const styleLabelTxt = (index: number, today: number) => {
	return [
		{
			fontSize: getResponsiveStyle(14, 'font'),
			// fontFamily: 'Arial',
			fontWeight: 'bold',
			color: palettes.grey[0],
		},
		index === today && { color: palettes.purple[0] },
	];
};

const styleContainer = () => {
	return {
		marginBottom: getResponsiveStyle(10),
		paddingHorizontal: getResponsiveStyle(15),
	};
};

export default Chart;
