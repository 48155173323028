import { useState } from 'react';
import { TProfileData } from '../../components/dashboard/profile-list/ProfileList';
import { GET_ALL_BRANDS } from '../../graphql/brands/queries';
import {
	TGetAllBrandsData,
	TGetAllBrandsResponse,
} from '../../types/brandTypes';
import { useQueryTemplate } from '../template/useQueryTemplate';

type TParams = {
	getAllData: boolean;
};

export const useGetAllBrands = (params?: TParams) => {
	const [brandsData, setBrandsData] = useState<
		undefined | TProfileData[] | any
	>();
	const onCompleted = (data: TGetAllBrandsResponse) => {
		if (data?.getAllBrands?.success && data?.getAllBrands?.data) {
			if (!params?.getAllData) {
				setBrandsData(() =>
					data.getAllBrands.data?.data.map(brand => ({
						email: brand.email,
						description: brand.description,
						address: brand.address,
						profileImage: brand.profileImage,
						_id: brand._id,
						companyName: brand.name,
					}))
				);
			} else {
				setBrandsData(
					data.getAllBrands.data?.data.map(brand => ({
						_id: brand._id,
						profileImage: brand.profileImage,
						companyName: brand.name,
						location: brand.address.countryLabel,
						followers: 70,
						products: 70,
						events: 70,
						earnings: 70,
					}))
				);
			}
		}
	};

	const {
		getAllData: getAllBrands,
		data,
		...states
	} = useQueryTemplate<TGetAllBrandsResponse, undefined>({
		query: GET_ALL_BRANDS,
		onCompleted,
	});

	return { getAllBrands, data: brandsData, ...states };
};
