import { useNavigation } from '@react-navigation/native';
import React from 'react';
import {
	KeyboardAvoidingView,
	Platform,
	ScrollView,
	StyleSheet,
	View,
} from 'react-native';
import {
	AuthHeading,
	DesktopNavigationBar,
	DumbComponentForFlex,
} from '../../..';
import { palettes } from '../../../../config';
import { useIsSpecificViewportWidth } from '../../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../../redux/store';

import { TStyle, TStyleInputOrText } from '../../../../types/TStyle';
import {
	TDesktopNavigationBar,
	TStepTemplateScrollItems,
	TUseNavigation,
} from '../../../../types/types';
import { getResponsiveStyle } from '../../../../utils/getResponsiveStyle';
import Logo from '../../Logo';
import SidebarNavigationScroll from './sidebar-navigation-scroll/SidebarNavigationScroll';
import TopNavigationScroll from './top-navigation-scroll/TopNavigationScroll';

type TNavProps = Omit<
	TDesktopNavigationBar,
	'containerStyles' | 'hasCart' | 'hasCreateEvent'
>;

type Props = {
	heading: string;
	subText: string;
	contentStyles?: TStyle;
	authType?: 'login' | 'signup';
	scrollItems: TStepTemplateScrollItems[];
	navHeaderPropsDesktop?: TNavProps;
};
const primaryColors = palettes.primary;
const AuthTemplate: React.FC<Props> = ({
	heading,
	subText,
	contentStyles,
	authType = 'signup',
	scrollItems,
	navHeaderPropsDesktop,
	children,
}) => {
	const { isLessThanDesktopBase, isDesktop } = useIsSpecificViewportWidth();
	const navigation = useNavigation<TUseNavigation>();
	const isUserLoggedIn = useAppSelector(state => state.auth.isUserLoggedIn);
	return (
		<ScrollView
			contentContainerStyle={styles.containerContent}
			style={[styles.container, styleContainer()]}
			showsVerticalScrollIndicator={false}
		>
			{isDesktop && isUserLoggedIn && (
				<Logo
				tint={primaryColors[0]}
				style={[styles.logo]}
				width={38}
				height={38}
				scale={1.3}
				viewBox="0 0 38 38"
				onPress={() => {}}
			/>
				
			)}
			<KeyboardAvoidingView
				behavior={Platform.OS === 'ios' ? 'position' : 'padding'}
				style={[
					styleKeyboardAvoidingView(isLessThanDesktopBase),
					styles.keyboardAvoidingView,
				]}
			>
				{authType === 'signup' && !isLessThanDesktopBase && (
					<SidebarNavigationScroll scrollItems={scrollItems} />
				)}

				{isDesktop && (
					<DumbComponentForFlex
						flex={+`${scrollItems.length ? 0.69 : 1}`}
					/>
				)}
				<View
					style={styleContentWrapper(isLessThanDesktopBase, authType)}
				>
					{authType === 'login' && isLessThanDesktopBase && (
						<Logo
							tint={primaryColors[0]}
							style={[styles.logo]}
							width={38}
							height={38}
							scale={1.3}
							viewBox="0 0 38 38"
							onPress={() => {}}
						/>
					)}
					{authType === 'signup' && isLessThanDesktopBase && (
						<TopNavigationScroll scrollItems={scrollItems} />
					)}

					<AuthHeading
						text={subText}
						title={heading}
						authType={authType}
					/>

					<View style={[styles.content, contentStyles]}>
						{children}
					</View>
				</View>
				{isDesktop && <DumbComponentForFlex flex={1} />}
			</KeyboardAvoidingView>
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	desktopNavigationContainer: {
		width: '100%',
	},
	keyboardAvoidingView: {
		width: '100%',
		flexDirection: 'row',
	},
	content: {
		width: '100%',
	},
	containerContent: {
		alignItems: 'center',
		minHeight: '100vh',
	},
	container: {
		width: '100vw',
		minHeight: '100vh',
	},
	logo: { alignSelf: 'center', minHeight: 38 },
});

export default AuthTemplate;

const styleContainer = () => {
	return {
		paddingHorizontal: getResponsiveStyle(15),
		paddingVertical: getResponsiveStyle(15),
	} as TStyle;
};

function styleContentWrapper(
	isLessThanDesktopBase: boolean,
	authType: string
): TStyle {
	return {
		maxWidth: getResponsiveStyle(464, 'dimensions'),
		width: '100%',
		alignItems:
			isLessThanDesktopBase || authType === 'signup'
				? 'center'
				: 'flex-start',
	};
}

function styleKeyboardAvoidingView(
	isLessThanDesktopBase: boolean
): TStyleInputOrText {
	return {
		justifyContent: isLessThanDesktopBase ? 'center' : 'flex-start',
	};
}
