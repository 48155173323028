import * as yup from 'yup';
import { yupsRequiredString } from '../../../utils/yupsRequiredString';

export const createStarterKitValidationSchema = yup.object({
    name: yupsRequiredString(),
    description: yupsRequiredString(),
    price: yup.number().required('Required'),
    starterKitId:yupsRequiredString(),
    compareAtPrice: yup.number().required('Required'),
    brand: yupsRequiredString(),
    vat:yup.number().required('Required'),
    variants: yup.array().of(yup.string()).required(),
});

export type TStarterKitsFormValues = yup.InferType<
    typeof createStarterKitValidationSchema
>;

export const createStarterKitsInitialValues: TStarterKitsFormValues = {
    name: '',
    description: '',
    price: +'',
    starterKitId:'',
    compareAtPrice: +'',
    brand: '',
    variants: [],
    vat:+''
};
