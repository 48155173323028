import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const Link = styled.Text`
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${palettes.primary[0]};
	}
`;

const LoginFooter = () => {
	return (
		<View style={[styles.container, styleContainer()]}>
			<Text style={[styles.textCommon, styleTextCommon()]}>
				Need help? <Link style={styles.link}>Click here</Link> or email
				us at: {'\n'} <Link style={styles.link}>support@ocpus.io</Link>
			</Text>
		</View>
	);
};

export default LoginFooter;

function styleContainer() {
	return { marginBottom: getResponsiveStyle(31) };
}

function styleTextCommon(): TStyleInputOrText {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(24, 'font'),
	};
}

const styles = StyleSheet.create({
	container: {
		width: '100%',
	},
	link: {
		color: palettes.primary[0],
		paddingBottom: 2,
		fontWeight: '600',
	},
	textCommon: {
		textAlign: 'center',
		letterSpacing: 0.18,
	},
});
