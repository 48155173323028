import { gql } from '@apollo/client';

export const GET_APP_CONFIG = gql`
	query getAppConfig($key: String!) {
		getAppConfig(key: $key) {
			statusCode
			success
			message
			data {
				... on AppConfig {
					key
					value {
						value1
						value2
						value3
					}
					description
					createdBy
				}
			}
		}
	}
`;
export const GET_TAGS = gql`
	query getTags($key: String) {
		getTags(key: $key) {
			statusCode
			success
			message
			data {
				... on All {
					data {
						... on AppConfig {
							key
							value {
								value1
								value2
								value3
							}
							description
                            createdBy
                            lastModifiedBy

						}
					}
				}
			}
		}
	}
`;
