import { gql } from '@apollo/client';

export const ADD_APP_CONFIG = gql`
	mutation addAppConfig(
		$key: String!
		$value: ValueInput!
		$description: String!
	) {
		addAppConfig(key: $key, value: $value, description: $description) {
			statusCode
			success
			message
			data {
				... on AppConfig {
					key
					value {
						value1
						value2
						value3
					}
					description
					createdBy
				}
			}
		}
	}
`;

export const EDIT_APP_CONFIG = gql`
	mutation editAppConfig(
		$key: String!
		$value: ValueInput!
		$description: String!
	) {
		editAppConfig(key: $key, value: $value, description: $description) {
			statusCode
			success
			message
			data {
				... on AppConfig {
					key
					value {
						value1
						value2
						value3
					}
					description
					createdBy
				}
			}
		}
	}
`;
export const DELETE_APP_CONFIG = gql`
	mutation deleteAppConfig($key: String!) {
		deleteAppConfig(key: $key) {
			statusCode
			success
			message
		}
	}
`;
