import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, Text, View } from 'react-native';
import HeroImage from '../assets/login/login-hero-image.png';
import {
	Button,
	LoginFooter,
	LoginForm,
	Logo,
	TermsAndConditions,
} from '../components';
import { palettes } from '../config';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { TStyle, TStyleInputOrText } from '../types/TStyle';
import { TUseNavigation } from '../types/types';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';

const LoginScreen = () => {
	// need to import this for responsiveness to work
	// dont ask why
	const { t } = useTranslation();
	const { isDesktop } = useIsSpecificViewportWidth();
	const navigation = useNavigation<TUseNavigation>();
	const handlePressLogin = (email: string, password: string) => {
		// TODO: handle login
		// props.navigation.navigate('Dashboard');
		// console.log(email, password);
	};

	const handlePressForgetPassword = () => {
		// TODO: handle forget password
		navigation.navigate('ForgotPassword');
	};

	return (
		<View style={styles.container}>
			{isDesktop && (
				<View style={styles.imageContainer}>
					<Image
						source={HeroImage}
						style={[styles.image, styleImage()]}
					/>
					<Logo
						tint="white"
						width={getResponsiveStyle(40, 'dimensions')}
						height={getResponsiveStyle(36, 'dimensions')}
						viewBox={`0 0 36 36`}
						containerStyles={styleLogoContainer()}
						style={styleLogo()}
					/>
				</View>
			)}
			<View style={styles.formContainer}>
				<View style={styleContainer()}>
					<Text
						style={[
							styles.title,
							styleTitle(),
							textAlignment(isDesktop),
						]}
					>
						Welcome Back!
					</Text>
					<Text
						style={[
							styles.text,
							styleText(),
							textAlignment(isDesktop),
						]}
					>
						Login with your email address
					</Text>
				</View>
				<View style={styleLoginFormContainer()}>
					<LoginForm
						onSubmit={handlePressLogin}
						handlePressForgetPassword={handlePressForgetPassword}
					/>
				</View>

				{isDesktop && <LoginFooter />}
				{isDesktop && <TermsAndConditions />}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	imageContainer: {
		// width: '100%',
		flex: 1,
		minHeight: '100%',
		position: 'relative',
		overflow: 'hidden',
		backgroundColor: palettes.dark[0],
	},
	image: {
		width: '100%',
		height: '100%',
		resizeMode: 'contain',
	},
	container: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		width: '100vw',
		height: '100%',
		overflow: 'hidden',
	},
	containerButtonSignUp: {
		justifyContent: 'center',
		width: 345,
		height: 48,
		borderRadius: 6,
		borderWidth: 1,
		borderColor: palettes.primary[0],
		backgroundColor: '#fff',
	},

	textButtonSignUp: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 16,
		lineHeight: 16,
		textAlign: 'center',
		color: palettes.primary[0],
	},
	formContainer: {
		maxWidth: '100%',
		flexBasis: 864,
		paddingHorizontal: 160,
		justifyContent: 'center',
		alignItems: 'center',
	},
	title: {
		fontStyle: 'normal',
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		marginTop: 10,
		color: palettes.dark[4],
	},
});

export default LoginScreen;
function styleImage() {
	return {
		marginTop: getResponsiveStyle(80),
		marginBottom: getResponsiveStyle(20),
	};
}

function styleLogoContainer(): TStyle {
	return {
		position: 'absolute',
		left: 18,
		top: 18,
	};
}

function styleLogo() {
	return {
		width: getResponsiveStyle(40, 'dimensions'),
		height: getResponsiveStyle(36, 'dimensions'),
	};
}

function styleLoginFormContainer() {
	return {
		marginTop: getResponsiveStyle(60),
		marginBottom: getResponsiveStyle(90),
		width: '100%',
	} as TStyle;
}

const styleContainer = () => {
	return {
		marginTop: getResponsiveStyle(45),
	};
};

const styleText = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
	};
};

const styleTitle = () => {
	return {
		fontSize: getResponsiveStyle(24, 'font'),
		lineHeight: getResponsiveStyle(32, 'font'),
	};
};
function textAlignment(isDesktop: boolean): TStyleInputOrText {
	return {
		textAlign: isDesktop ? 'center' : 'left',
	};
}
