import React from 'react';
import { EventCard } from '../..';
import { FlatList, StyleSheet } from 'react-native';
import { TGetAllEventsData } from '../../../types/eventTypes';
import { dateFns } from '../../../utils/dateFns';

type Props = {
	events: TGetAllEventsData[];
};

export const AllEventsMobile = ({ events }: Props) => {
	const renderItem = ({ item }: { item: TGetAllEventsData }) => {
		return (
			<EventCard
				media={item.eventMedia}
				size="large"
				containerStyles={styles.eventCardMobile}
				date={dateFns.date(+item.startingEventDateTime)}
				month={dateFns.month(+item.startingEventDateTime)}
				description={item.description}
				title={item.name}
				hasRequestButton={false}
			/>
		);
	};

	return <FlatList data={events} renderItem={renderItem} />;
};

const styles = StyleSheet.create({
	eventCardMobile: {
		width: 'calc(100vw - 32px)',
	},
});
