import { gql } from '@apollo/client';

export const GET_ALL_STARTERKITS = gql`
    query getAllStarterKits {
        getAllStarterKits(
            populate: [
              "brand"
              {
                path: "variants"
                model: "Variant"
                populate: [{ path: "associatedProduct", model: "Product" }]
              }
            ]
          ) {
            statusCode
            success
            data {
              total
              data {
                ... on StarterKit {
                  _id
                  name
                  description
                  starterKitId
                  variants {
                    _id
                    image {
                      src
                    }
                    option {
                      values {
                        price
                        name
                      }
                    }
                    associatedProduct {
                      _id
                      name
                      shortDescription
                    }
                  }
                  brand {
                    _id
                    name
                  }
                  vat
                  price
                  compareAtPrice
                }
              }
            }
          }
    }
`;

export const GET_STARTERKIT_DETAILS = gql`
    query getStarterKit($_id: String) {
        getStarterKit(
            _id: $_id
            populate: [
                "brand"
                {
                    path: "variants"
                    model: "Variant"
                    populate: [{ path: "associatedProduct", model: "Product" }]
                }
            ]
        ) {
            statusCode
            success
            data {
                ... on StarterKit {
                    _id
                    name
                    description
                    starterKitId
                    variants {
                      _id
                      value
                      image {
                        src
                      }
                      option {
                        values {
                          price
                          name
                        }
                      }
                      associatedProduct {
                        _id
                        name
                        shortDescription
                      }
                    }
                    brand {
                      _id
                      name
                    }
                    vat
                    price
                    compareAtPrice
                  }
            }
        }
    }
`;

