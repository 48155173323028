import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

import { useAppSelector } from '../../../redux/store';
import ProfileCards from '../../common/profile-cards/ProfileCards';
import { InvitedPeople } from '../../../types/types';

const PeopleScrollSection = ({
	invitedPeople,
}: {
	invitedPeople: InvitedPeople;
}) => {
	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		isUserLoggedIn: state.auth.isUserLoggedIn,
	}));

	const isConsumer = loggedInUserDetails?.typeOfAccount === 'CONSUMER';
	return (
		<View style={[Styles.container, styleContainer()]}>
			<View style={Styles.headerContainer}>
				<Text style={Styles.title}>{'Invited People'}</Text>
				{/* <Text style={Styles.hoverText}>{t('View All')}</Text> */}
			</View>
			<View style={Styles.sliderContainer}>
				<ProfileCards
					showGender={true}
					isBrand={false}
					newRequest={invitedPeople}
				/>
			</View>
		</View>
	);
};

const Styles = StyleSheet.create({
	container: {
		// width: '100%',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	title: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	sliderContainer: {
		width: '100%',
		marginTop: getResponsiveStyle(29),
	},
	hoverText: {
		// marginTop: 20,
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[2],
		cursor: 'pointer',
	},
});
export default PeopleScrollSection;

function styleContainer() {
	return {
		// marginTop: getResponsiveStyle(55),
		// marginBottom: getResponsiveStyle(41),
	};
}
