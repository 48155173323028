import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useGetAllUsersLazy } from '../api/auth/useGetAllUsersLazy';
import { useGetUserMetricChange } from '../api/auth/useGetUserMetricChange';
import Loader from '../components/common/loader/Loader';
import PageHeader from '../components/common/page-header/PageHeader';
import TableComponent from '../components/common/table-component/TableComponent';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import ViewAllText from '../components/common/view-all-text/ViewAllText';
import { palettes } from '../config';
import {
	GET_ALL_CONSUMERS,
	GET_USER_TABLE_STATS,
} from '../graphql/auth/queries';
import { GET_USER_GRAPH_DATA } from '../graphql/common/queries';
import { TFind, TGetAllUsersFind, TPaginatedOptions } from '../types/types';
import {
	TGetAllConsumersResponse,
	TGetAllUsersResponse,
	TStatsforTable,
	TUserDataForTable,
} from '../types/userTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import { manipulateString } from '../utils/manipulateString';

const TABS = ['All Guests', 'New Guests'];

const HEADERS = [
	{
		headerLabel: 'Name',
		width: getResponsiveStyle(180),
	},
	{
		headerLabel: 'Status',
		width: getResponsiveStyle(80),
	},
	{
		headerLabel: 'Location',
		width: getResponsiveStyle(150),
	},
	{
		headerLabel: 'Followers',
		width: getResponsiveStyle(100),
	},
	{
		headerLabel: 'Events Attended',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Purchase Amount',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Email',
		width: getResponsiveStyle(150),
	},
];

function GuestsScreen() {
	const [selectedTab, setSelectedTab] = useState(TABS[0]);
	const [allConsumersData, setAllConsumersData] =
		useState<TUserDataForTable[]>();
	const [stats, setStats] = useState<TStatsforTable>();
	const [PrevGraphData, setPrevGraphData] = useState<number[]>();
	const [CurrGraphData, setCurrGraphData] = useState<number[]>();

	const [
		getAllConsumers,
		{ loading: consumersLoading, data: consumersData },
	] = useLazyQuery<TGetAllUsersResponse, TPaginatedOptions & TFind>(
		GET_ALL_CONSUMERS,
		{
			fetchPolicy: 'cache-and-network',
			onCompleted: data => {
				if (data.getAllUsers.data && data.getAllUsers.data.data) {
					setStats({
						totalnum: data.getAllUsers.data.total,
						active: data.getAllUsers.data.totalActive,
						inactive: data.getAllUsers.data.totalInactive,
						newRequest: data.getAllUsers.data.newRequest,
					});
					getUserStats({
						variables: {
							id: data.getAllUsers.data.data.map(o => o._id),
						},
					});
				}
			},
		}
	);
	const [getUserStats, { loading: loadingStats }] = useLazyQuery(
		GET_USER_TABLE_STATS,
		{
			onCompleted: resp => {
				console.log(resp);

				let arrTotal = consumersData?.getAllUsers?.data?.data?.map(
					consumer => ({
						image:
							consumer?.personalInformation?.profileImageLink ??
							null,
						name: consumer?.username,
						status: manipulateString.capitalize(
							consumer.profileStatus.toLowerCase()
						),
						location:
							consumer?.personalInformation?.address?.city +
							', ' +
							consumer?.personalInformation?.address
								?.countryLabel,
						followers: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === consumer?._id
						).followersCount,
						eventsAttended: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === consumer?._id
						).eventsCount,
						purchaseAmount: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === consumer?._id
						)?.productsCount,
						email: consumer?.email,
						_id: consumer?._id,
					})
				);
				console.log(arrTotal);

				setAllConsumersData(arrTotal);
			},
		}
	);

	const { getUserMetricChange, data: customersMetric } =
		useGetUserMetricChange();

	useEffect(() => {
		getUserMetricChange('CONSUMER');
	}, []);

	const customersMetricData =
		customersMetric?.getUserMetricsPercentageChange?.data;

	useEffect(() => {
		getAllConsumers({
			variables: {
				limit: 10,
				find: {
					typeOfAccount: 'CONSUMER',
				},
			},
		});
	}, []);

	const onPressTab = (tab: string) => {
		if (tab === TABS[1]) {
			getAllConsumers({
				variables: {
					sort: {
						createdAt: -1,
					},
					limit: 10,
					find: {
						typeOfAccount: 'CONSUMER',
					},
				},
			});
		}
		if (tab === TABS[0]) {
			getAllConsumers({
				variables: {
					limit: 10,
					find: {
						typeOfAccount: 'CONSUMER',
					},
				},
			});
		}
		setSelectedTab(tab);
	};
	const unifiedLoading = useMemo(
		() => getSingleLoadingState(loadingStats, consumersLoading),
		[loadingStats, consumersLoading]
	);
	useQuery(GET_USER_GRAPH_DATA, {
		variables: {
			type: 'CONSUMER',
		},
		onCompleted: data => {
			if (
				data.getUserGraphDataBasedOnAccountType.success &&
				data.getUserGraphDataBasedOnAccountType.data
			) {
				let currMonth =
					data.getUserGraphDataBasedOnAccountType.data.currentMonth.map(
						(value: { dataPoint: number }) => {
							return value.dataPoint;
						}
					);
				let prevMonth =
					data.getUserGraphDataBasedOnAccountType.data.previousMonth.map(
						(value: { dataPoint: number }) => {
							return value.dataPoint;
						}
					);
				setPrevGraphData(prevMonth);
				setCurrGraphData(currMonth);
			}
		},
	});

	return (
		<DesktopTemplate>
			<PageHeader
				prevData={PrevGraphData}
				currData={CurrGraphData}
				pageHeader="All Guests"
				chartItemValues={[
					{
						title: 'All Guests',
						percentageDifference: Math.abs(
							customersMetricData?.allUsers || 0
						),
						percentageIncrease: customersMetricData?.allUsers >= 0,
						value: stats?.totalnum || 0,
					},
					{
						title: 'Active Guests',
						percentageDifference: Math.abs(
							customersMetricData?.activeUsers || 0
						),
						percentageIncrease:
							customersMetricData?.activeUsers >= 0,
						value: stats?.active || 0,
					},
					{
						title: 'Inactive Guests',
						percentageDifference: Math.abs(
							customersMetricData?.inactiveUsers || 0
						),
						percentageIncrease:
							customersMetricData?.inactiveUsers >= 0,
						value: stats?.inactive || 0,
					},
					{
						title: 'New Request',
						percentageDifference: Math.abs(
							customersMetricData?.newRequests || 0
						),
						percentageIncrease:
							customersMetricData?.newRequests >= 0,
						value: stats?.newRequest || 0,
					},
				]}
			/>
			<View
				style={{
					marginTop: getResponsiveStyle(35),
				}}
			>
				{allConsumersData && allConsumersData.length > 0 ? (
					<View>
						<TableComponent
							headerTabs={TABS}
							data={allConsumersData}
							onPressTab={onPressTab}
							selectedTab={selectedTab}
							headers={HEADERS}
							loading={loadingStats}
							type="profile"
							tableContentProps={{
								profileButton: true,
							}}
						/>
						<View
							style={{
								marginTop: getResponsiveStyle(67),
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<ViewAllText
								title="View All Guests"
								navigationDestination="AllCustomers"
							/>
						</View>
					</View>
				) : unifiedLoading ? (
					<Loader />
				) : (
					<Text
						style={{
							fontSize: getResponsiveStyle(14, 'font'),
							lineHeight: getResponsiveStyle(14, 'font'),
							color: palettes.grey[0],
						}}
					>
						No data Available!
					</Text>
				)}
			</View>
		</DesktopTemplate>
	);
}

export default GuestsScreen;
