import { gql } from '@apollo/client';

export const ADMIN_USER_FRAGMENT = gql`
	fragment AdminUserFragment on User {
		_id
		username
		email
		role
	}
`;
