import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { palettes } from '../../../config';
import { useNavigation } from '@react-navigation/native';
import { TUseNavigation } from '../../../types/types';

const ViewAllText = ({
	title,
	navigationDestination,
}: {
	title: string;
	navigationDestination: string;
}) => {
	const navigator = useNavigation<TUseNavigation>();
	return (
		<Text
			style={styles.hoverText}
			onPress={() => navigator.navigate(navigationDestination)}
		>
			{title}
		</Text>
	);
};

const styles = StyleSheet.create({
	hoverText: {
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[1],
		cursor: 'pointer',
	},
});

export default ViewAllText;
