import { Picker, PickerProps } from '@react-native-picker/picker';
import React from 'react';
import { View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Label, { LabelProps } from '../label/Label';

const Dropdown = ({
	pickerProps,
	labelProps,
}: {
	pickerProps?: PickerProps;
	labelProps?: LabelProps;
}) => {
	return (
		<View>
			{labelProps && labelProps?.label && <Label {...labelProps} />}
			<Picker
				
				style={[
					{
						width: '100%',
						borderWidth: 1,
						borderColor: palettes.grey[4],
						fontStyle: 'normal',
						fontWeight: 'normal',
						borderRadius: 6,
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowRadius: 4,
						shadowColor: 'rgba(180, 187, 198, 0.1)',
						shadowOpacity: 1,
						height: getResponsiveStyle(48, 'dimensions'),
						fontSize: getResponsiveStyle(16, 'font'),
						lineHeight: getResponsiveStyle(14, 'font'),
						paddingRight: getResponsiveStyle(24),
						paddingLeft: getResponsiveStyle(19),
					},
				]}
				{...pickerProps}
				itemStyle={{ paddingVertical: 20 }}
				dropdownIconColor={palettes.grey[1]}
				
			/>
		</View>
	);
};

export default Dropdown;
