import { gql } from '@apollo/client';

export const CREATE_NOTIFICATION = gql`
	mutation createNotification($args: CreateNotificationInputs!) {
		createNotification(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on Notification {
						id
						used
					}
				}
			}
		}
	}
`;

export const DELETE_NOTIFICATION = gql`
	mutation deleteNotification($args: DeleteNotificationArgs!) {
		deleteNotification(args: $args) {
			... on Response {
				success
				message
			}
		}
	}
`;

export const READ_NOTIFICATIONS = gql`
	mutation readAllNotifications {
		readAllNotifications {
			... on Response {
				success
			}
		}
	}
`;

export const DELETE_ALL_NOTIFICATIONS = gql`
	mutation deleteAllNotifications {
		deleteAllNotifications {
			... on Response {
				success
			}
		}
	}
`;