import { yupsRequiredString } from '../../utils/yupsRequiredString';
import * as yup from 'yup';

export const addBrandValidationSchema = yup.object({
	socialReason: yupsRequiredString(),
	commercialName: yupsRequiredString(),
	address1: yupsRequiredString(),
	address2: yup.string(),
	city: yupsRequiredString(),
	zipCode: yupsRequiredString(),
	country: yupsRequiredString(),
	countryCode: yupsRequiredString(),
	region: yup.string(),
	businessPhoneNumber: yupsRequiredString(),
	vat: yup.string(),
	vatId: yup.string(),
	companyId: yupsRequiredString(),
	email: yupsRequiredString().email(),
	numericSignature: yup.string(),
	interestTags: yup.array().of(yup.string()).required(),
	kybStatus: yup.string(),
	accountStatus: yup.string(),
	website: yup.string(),
	adminNote: yup.string(),
	description: yup.string(),
});

export const editBrandValidationSchema = yup.object({
	socialReason: yupsRequiredString(),
	commercialName: yupsRequiredString(),
	address1: yupsRequiredString(),
	address2: yup.string(),
	city: yupsRequiredString(),
	zipCode: yupsRequiredString(),
	country: yupsRequiredString(),
	countryCode: yup.string(),
	region: yup.string(),
	businessPhoneNumber: yup.string(),
	vat: yup.string(),
	vatId: yup.string(),
	companyId: yup.string(),
	email: yupsRequiredString().email(),
	numericSignature: yup.string(),
	interestTags: yup.array().of(yup.string()),
	kybStatus: yup.string(),
	accountStatus: yup.string(),
	website: yup.string(),
	adminNote: yup.string(),
	description: yup.string(),
});

export type TAddBrandInitalValues = yup.InferType<
	typeof addBrandValidationSchema
>;

export const addBrandInitalValues: TAddBrandInitalValues = {
	socialReason: '',
	commercialName: '',
	address1: '',
	address2: '',
	city: '',
	zipCode: '',
	country: '',
	countryCode: '',
	region: '',
	email: '',
	vat: '',
	businessPhoneNumber: '',
	vatId: '',
	companyId: '',
	numericSignature: '',
	interestTags: [],
	kybStatus: '',
	accountStatus: '',
	website: undefined,
	adminNote: '',
	description: '',
};
