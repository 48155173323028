import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	variant?: TErrorVariant;
	title: string;
	description?: string;
	id: number;
	onPressClose: (id: number) => void;
};

type TVariantStyles = {
	[K in TErrorVariant]: {
		['outerContainer']: TStyle;
		['innerContainer']: TStyle;
	};
};

const variantStyles: TVariantStyles = {
	normal: {
		outerContainer: {
			borderColor: 'transparent',
			backgroundColor: 'transparent',
		},
		innerContainer: {
			borderColor: palettes.yellow[0],
			backgroundColor: palettes.yellow[4],
		},
	},
	danger: {
		outerContainer: {
			borderColor: `${palettes.red[0]}20`,
		},
		innerContainer: {
			borderColor: palettes.red[0],
		},
	},
	warning: {
		outerContainer: {
			borderColor: `${palettes.yellow[0]}20`,
		},
		innerContainer: {
			borderColor: palettes.yellow[0],
		},
	},
};

const Close = styled.Text`
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	color: ${palettes.red[0]};
	font-weight: 600;
	padding-bottom: 2px;
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${palettes.red[0]};
	}
`;

export const Alert = ({
	variant = 'normal',
	title,
	description,
	id,
	onPressClose,
}: Props) => {
	return (
		<View
			style={[
				styleOuterContainer(),
				styles.outerContainer,
				variantStyles[variant].outerContainer,
			]}
		>
			<View
				style={[
					styles.innerContainer,
					styleInnerContainer(),
					variantStyles[variant].innerContainer,
				]}
			>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: `calc(100% - ${getResponsiveStyle(30)}px)`,
					}}
				>
					<Text style={[styleHeading(), styles.heading]}>
						{title}
					</Text>
					{description && (
						<Text style={[styleDescription(), styles.description]}>
							{description}
						</Text>
					)}
				</View>
				<Close onPress={() => onPressClose(id)}>Close</Close>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	description: {
		color: palettes.dark[4],
	},
	heading: {
		color: palettes.dark[0],
	},
	innerContainer: {
		borderWidth: 1,
		marginBottom: 0,
		flexDirection: 'row',
		alignItems: 'center',
		backgroundColor: 'white',
		borderRadius: 6,
		height: '100%',
		shadowOffset: {
			width: 0,
			height: 15,
		},
		shadowRadius: 25,
		shadowColor: 'rgba(124, 136, 155, 0.12)',
		shadowOpacity: 1,
	},
	outerContainer: {
		borderWidth: 3,
		borderRadius: 8,
	},
});

function styleDescription() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
}

function styleHeading() {
	return {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(24, 'font'),
		marginRight: getResponsiveStyle(36),
	};
}

function styleInnerContainer() {
	return {
		paddingLeft: getResponsiveStyle(40),
		paddingRight: getResponsiveStyle(30),
	};
}

function styleOuterContainer(): TStyle {
	return {
		width: `calc(100% - ${getResponsiveStyle(32 + 8)}px)`,
		height: getResponsiveStyle(64, 'dimensions'),
		marginHorizontal: getResponsiveStyle(16),
		marginTop: getResponsiveStyle(16),
	};
}
