import { useLazyQuery } from '@apollo/client';
import { Picker } from '@react-native-picker/picker';
import { useNavigation } from '@react-navigation/native';
import { FieldArray, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
    ActivityIndicator,
    ScrollView,
    StyleSheet,
    View,
    Text,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { Dropdown } from '../..';
import Dropdown2 from '../../common/dropdown2/dropdown2';
import { useGetAllBrands } from '../../../api/brands/useGetAllBrands';
import { useCreateMedia } from '../../../api/common/useCreateMedia';
import useCreateProduct from '../../../api/product/useCreateProduct';
import useEditProduct from '../../../api/product/useEditProduct';
import useGetAllProducts from '../../../api/product/useGetAllProducts';
import { PRODUCT_CATEGORIES } from '../../../constants/categories';
import { GET_PRODUCT } from '../../../graphql/product/queries';
import { setRefetchProduct } from '../../../redux/productsSlice';
import { TGetAllBrandsData } from '../../../types/brandTypes';
import {
    Media,
    TCreateProduct,
    TDropdownObject,
    TUseNavigation,
} from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Button from '../../common/button/Button';
import { HorizontalDivider } from '../../common/horizontal-divider';
import Input from '../../common/Input';
import Label from '../../common/label/Label';
import OptionButton from '../../common/option-button/OptionButton';
import Spacing from '../../common/spacing/spacing';
import Upload from '../../common/upload/upload';
import BooleanButton from './BooleanButton';
import {
    addSingleProductInitialValues,
    addSingleProductValidationSchema,
    emptyVariant,
    TStatus,
    emptyVariant as addVariantInitialValues,
    emptyValue,
    handleEditProductsOld,
    addSingleProductValidationSchemaOld,
} from './FormikConstants';
import { UploadedProductImage } from './UploadedProductImage';
import { Variant } from '../../../types/productTypes';
import { CategoryPill } from '../../common/filter-categories/CategoryPill';
import { TAddVariantFormValues } from './VariantFormConstants';
import useGetMedia from '../../../api/common/useGetMedia';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { palettes } from '../../../config';
import { useAppSelector } from '../../../redux/store';

type Props = {
    productId?: string;
};
const AddSingleProductFormOld = (props: Props) => {
    const [productStatus, setProductStatus] = useState('DRAFT');
    const { INTEREST_TAGS } = useAppSelector(state => state.tag);
    const { setAlert } = useSetAlert();
    const [currentVariant, setCurrentVariant] = useState(0);
    const [media, setMedia] = useState<Media[]>([]);
    const {
        _createMedia,
        loading: loading_uploadMedia,
        data: mediaData,
    } = useCreateMedia({});

    const [getSingleProduct, { data: product, loading: productLoading }] =
        useLazyQuery(GET_PRODUCT, {
            onCompleted: data => {
                setProductStatus(data?.getProduct?.data?.publishedStatus);
            },
        });
    useEffect(() => {
        if (props.productId)
            getSingleProduct({ variables: { _id: props.productId } });
    }, []);

    const productData = product?.getProduct?.data;

    const dispatch = useDispatch();

    const {
        executeProductMutation,
        data,
        loading: productCreateLoading,
    } = useCreateProduct();
    const {
        executeProductEditMutation,
        data: productEdit,
        loading: productEditLoading,
    } = useEditProduct();

    const { loggedInUserDetails } = useAppSelector(state => ({
        loggedInUserDetails: state.auth.loggedInUserDetails,
    }));

    const navigation = useNavigation<TUseNavigation>();
    const { getAllBrands, data: brandApiData } = useGetAllBrands();
    useEffect(() => {
        const media = mediaData?.createMedia?.data;

        if (media) {
            setMedia(prev => [...prev, media]);
        }
    }, [mediaData]);

    useEffect(() => {
        getAllBrands();
    }, []);

    const brandTestData = [
        {
            _id: '',
            companyName: 'Select Brand'
        }
    ]

    const brandData = () => {
        if (loggedInUserDetails?.affiliatedBrand) {
            return brandTestData?.concat(brandApiData?.filter((data: any) => data?._id === loggedInUserDetails?.affiliatedBrand))
        } else {
            return brandApiData
        }
    }
    //TODO for deleting variants
    // const handleRemove=()=>{

    // }

    useEffect(() => {
        if (productEdit) {
            navigation.navigate('Products');
            dispatch(setRefetchProduct(true));
        }
    }, [productEdit]);

    //6281faf7f51bfdb64b0ddc26 -> Media

    return (
        <Formik
            enableReinitialize
            initialValues={
                props.productId
                    ? handleEditProductsOld(productData)
                    : addSingleProductInitialValues
            }
            validationSchema={addSingleProductValidationSchemaOld}
            onSubmit={async (values, { resetForm }) => {
                const data = {
                    name: values.productName,
                    shortDescription: values.shortDescription,
                    description: values.description,
                    publishedStatus: productStatus,
                    category: values.productCategory,
                    brand: values.brand,
                    variants: values?.variants?.map(variant => {
                        return {
                            variator: variant.variantType,
                            value: variant.value,
                            GTIN: variant?.GTIN,
                            image: variant?.image?._id?.length
                                ? variant?.image?._id
                                : undefined,
                            _id:
                                variant?._id && variant?._id.length
                                    ? variant?._id
                                    : undefined,
                            vat: +variant?.vat,
                            weight: Number(variant?.weight),
                            weightUnit: variant.weightUnit,
                            option: {
                                name: variant?.options?.name,
                                values: variant?.options?.values?.map(
                                    (item, index) => {
                                        return {
                                            price: +item.price,
                                            compareAtPrice:
                                                Number(item.compareAtPrice) ??
                                                undefined,
                                            name: item.name,
                                        };
                                    }
                                ),

                                inventory: {
                                    isAvailable:
                                        variant?.options?.inventory.isAvailable,
                                    isTracked:
                                        variant?.options?.inventory?.isTracked,
                                    quantity: Number(
                                        variant?.options?.inventory.quantity
                                    ),
                                },
                            },
                        };
                    }),
                };

                console.log(data, 'form values');
                if (props.productId) {
                    executeProductEditMutation(
                        props.productId as string,
                        data as unknown as TCreateProduct
                    );
                    return;
                }
                const response = await executeProductMutation(
                    data as unknown as TCreateProduct
                );
                if (response.data?.createProduct?.success) {
                    resetForm();
                    setMedia([]);
                }
            }}
        >
            {({
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                dirty,
                isValid,
                touched,
                errors,
                values,
            }) => (
                <>
                    <Spacing
                        orientation="column"
                        spacing={20}
                        containerStyles={{
                            marginBottom: getResponsiveStyle(80),
                        }}
                    >
                        <Input
                            label="Product Name*"
                            onChangeText={handleChange('productName')}
                            onBlur={handleBlur('productName')}
                            value={values.productName}
                            error={
                                touched.productName && errors.productName
                                    ? errors.productName
                                    : ''
                            }
                        />

                        <Input
                            multiline
                            label={'Short Description*'}
                            style={[styles.shortDescription]}
                            onChangeText={handleChange('shortDescription')}
                            onBlur={handleBlur('shortDescription')}
                            value={values.shortDescription}
                            error={
                                touched.shortDescription &&
                                    errors.shortDescription
                                    ? errors.shortDescription
                                    : ''
                            }
                        />
                        <Input
                            multiline
                            label={'Description*'}
                            style={[styles.description]}
                            value={values.description}
                            onChangeText={handleChange('description')}
                            onBlur={handleBlur('description')}
                            error={
                                touched.description && errors.description
                                    ? errors.description
                                    : ''
                            }
                        />

                        <Dropdown
                            labelProps={{
                                label: 'Brand:',
                            }}
                            pickerProps={{
                                selectedValue: values.brand,
                                onValueChange: value => {
                                    setFieldValue('brand', value);
                                },
                                children: (
                                    <>
                                        {brandData()?.map((brand: any) => (
                                            <Picker.Item
                                                label={brand?.companyName}
                                                value={brand?._id}
                                            />
                                        ))}
                                    </>
                                ),
                            }}
                        />
                        <HorizontalDivider paddingY={getResponsiveStyle(24)} />
                        <Dropdown2
                            isSearchable
                            isMulti
                            key={'dropdown'}
                            options={INTEREST_TAGS}
                            inputProps={{
                                placeholder: 'Product Categories hint',
                            }}
                            labelProps={{
                                label: 'Product Categories*',
                            }}
                            values={INTEREST_TAGS.filter(item =>
                                values.productCategory?.includes(item.value)
                            )}
                            onChangeValue={(value: TDropdownObject) => {
                                setFieldValue('productCategory', [
                                    ...values?.productCategory,
                                    value?.value,
                                ]);
                            }}
                            onPressCrossOnBadge={(value: string) => {
                                const filteredProductCategory =
                                    values?.productCategory?.filter(
                                        item => item !== value
                                    );

                                setFieldValue(
                                    'productCategory',
                                    filteredProductCategory
                                );
                            }}
                        />
                        <Spacing spacing={14} withEqualWidth>
                            <OptionButton
                                onClick={() => {
                                    setProductStatus(TStatus.DRAFT);
                                    //setFieldValue('status', TStatus.DRAFT);
                                }}
                                value={TStatus.DRAFT}
                                active={productStatus === 'DRAFT'}
                                title={'Draft'}
                                titleStyles={{
                                    textAlign: 'center',
                                }}
                            />
                            <OptionButton
                                onClick={() => {
                                    setProductStatus(TStatus.ACITVE);
                                    //setFieldValue('status', TStatus.ACITVE);
                                }}
                                value={TStatus.ACITVE}
                                active={productStatus === 'ACTIVE'}
                                title={'Active'}
                                titleStyles={{
                                    textAlign: 'center',
                                }}
                            />
                        </Spacing>

                        <HorizontalDivider paddingY={getResponsiveStyle(24)} />
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <ScrollView
                                horizontal
                                showsHorizontalScrollIndicator={false}
                                style={{ marginRight: 20 }}
                            >
                                {values?.variants?.length ? (
                                    values?.variants?.map((item, index) => (
                                        <CategoryPill
                                            value={item.value}
                                            selected={index === currentVariant}
                                            text={`Variant ${values.variants[index]
                                                .variantType.length
                                                ? values.variants[index]
                                                    .variantType +
                                                ' ' +
                                                values.variants[index]
                                                    .value
                                                : index + 1
                                                }`}
                                            onClick={() => {
                                                setCurrentVariant(index);
                                                console.log(values.variants);
                                            }}
                                        />
                                    ))
                                ) : (
                                    <Text style={{ color: 'red' }}>
                                        At least one variant is required*
                                    </Text>
                                )}
                            </ScrollView>
                            <Button
                                title="Add Variant"
                                onPress={() => {
                                    setFieldValue('variants', [
                                        ...values.variants,
                                        emptyVariant,
                                    ]);
                                    setCurrentVariant(values.variants.length);
                                }}
                            />
                        </View>
                        <>
                            {values?.variants?.map((item, index) => (
                                <FieldArray
                                    key={index}
                                    name="variant"
                                    render={_arrayHelpers =>
                                        index === currentVariant && (
                                            <>
                                                <>
                                                    <Spacing
                                                        key={index}
                                                        orientation="column"
                                                        spacing={20}
                                                    >
                                                        <Input
                                                            label="Variant Type*"
                                                            onChangeText={text => {
                                                                setFieldValue(
                                                                    'variants.' +
                                                                    [
                                                                        index,
                                                                    ] +
                                                                    '.variantType',
                                                                    text
                                                                );
                                                            }}
                                                            onBlur={handleBlur(
                                                                'variants.' +
                                                                [index] +
                                                                '.variantType'
                                                            )}
                                                            value={
                                                                item.variantType
                                                            }
                                                            error={
                                                                touched.variants &&
                                                                    touched
                                                                        .variants[
                                                                    index
                                                                    ] &&
                                                                    errors.variants
                                                                    ? errors
                                                                        .variants[
                                                                        index
                                                                    ]
                                                                        ?.variantType
                                                                    : ''
                                                            }
                                                        />
                                                        <Input
                                                            label="Variant Value *"
                                                            onChangeText={text => {
                                                                setFieldValue(
                                                                    'variants.' +
                                                                    [
                                                                        index,
                                                                    ] +
                                                                    '.value',
                                                                    text
                                                                );
                                                            }}
                                                            onBlur={handleBlur(
                                                                'variants.' +
                                                                [index] +
                                                                '.value'
                                                            )}
                                                            value={item.value}
                                                            error={
                                                                touched.variants &&
                                                                    touched
                                                                        .variants[
                                                                    index
                                                                    ] &&
                                                                    errors.variants
                                                                    ? errors
                                                                        .variants[
                                                                        index
                                                                    ]?.value
                                                                    : ''
                                                            }
                                                        />
                                                        <Input
                                                            label="Product ID/GTIN *"
                                                            onChangeText={text => {
                                                                setFieldValue(
                                                                    'variants.' +
                                                                    [
                                                                        index,
                                                                    ] +
                                                                    '.GTIN',
                                                                    text
                                                                );
                                                            }}
                                                            onBlur={handleBlur(
                                                                'variants.' +
                                                                [index] +
                                                                '.GTIN'
                                                            )}
                                                            value={item.GTIN}
                                                            error={
                                                                touched.variants &&
                                                                    touched
                                                                        .variants[
                                                                    index
                                                                    ] &&
                                                                    errors.variants
                                                                    ? errors
                                                                        .variants[
                                                                        index
                                                                    ]?.GTIN
                                                                    : ''
                                                            }
                                                        />
                                                        <View>
                                                            <Upload
                                                                label="Product Image*"
                                                                hint="Drop files to upload"
                                                                onDrop={e =>
                                                                    _createMedia(
                                                                        {
                                                                            variables:
                                                                            {
                                                                                file: e[0] as File,
                                                                                category:
                                                                                    'PRODUCT',
                                                                                alt: 'media-files',
                                                                            },
                                                                            onCompleted:
                                                                                data => {
                                                                                    setAlert(
                                                                                        'Upload Successful',
                                                                                        'normal'
                                                                                    );
                                                                                    console.log(
                                                                                        data
                                                                                            .createMedia
                                                                                            .data
                                                                                    );
                                                                                    setFieldValue(
                                                                                        'variants.' +
                                                                                        [
                                                                                            index,
                                                                                        ] +
                                                                                        '.image',
                                                                                        {
                                                                                            _id: data
                                                                                                ?.createMedia
                                                                                                ?.data
                                                                                                ?._id,
                                                                                            src: data
                                                                                                ?.createMedia
                                                                                                ?.data
                                                                                                ?.src,
                                                                                        }
                                                                                    );
                                                                                },
                                                                        }
                                                                    )
                                                                }
                                                                dropzoneStyles={{
                                                                    height: getResponsiveStyle(
                                                                        240,
                                                                        'dimensions'
                                                                    ),
                                                                }}
                                                                containerStyles={{
                                                                    marginBottom:
                                                                        getResponsiveStyle(
                                                                            24
                                                                        ),
                                                                }}
                                                            />
                                                            {loading_uploadMedia ? (
                                                                <ActivityIndicator
                                                                    size="small"
                                                                    style={{
                                                                        margin: 20,
                                                                    }}
                                                                />
                                                            ) : (
                                                                !!values
                                                                    .variants[
                                                                    index
                                                                ].image?._id
                                                                    ?.length && (
                                                                    <Spacing>
                                                                        <UploadedProductImage
                                                                            image={
                                                                                values
                                                                                    .variants[
                                                                                    index
                                                                                ]
                                                                                    .image
                                                                                    .src
                                                                            }
                                                                        />
                                                                        <></>
                                                                    </Spacing>
                                                                )
                                                            )}
                                                        </View>
                                                        <Spacing
                                                            spacing={8}
                                                            withEqualWidth
                                                        >
                                                            <Input
                                                                label="VAT (%)*"
                                                                value={item.vat}
                                                                onChangeText={text => {
                                                                    setFieldValue(
                                                                        'variants.' +
                                                                        [
                                                                            index,
                                                                        ] +
                                                                        '.vat',
                                                                        text
                                                                    );
                                                                }}
                                                                onBlur={handleBlur(
                                                                    'variants.' +
                                                                    [
                                                                        index,
                                                                    ] +
                                                                    '.vat'
                                                                )}
                                                                error={
                                                                    touched.variants &&
                                                                        touched
                                                                            .variants[
                                                                        index
                                                                        ] &&
                                                                        errors.variants &&
                                                                        errors
                                                                            .variants[
                                                                        index
                                                                        ]
                                                                        ? errors
                                                                            ?.variants[
                                                                            index
                                                                        ].vat
                                                                        : ''
                                                                }
                                                            />
                                                            <View></View>
                                                        </Spacing>

                                                        <Input
                                                            label="Option Name"
                                                            onChangeText={text => {
                                                                setFieldValue(
                                                                    'variants.' +
                                                                    [
                                                                        index,
                                                                    ] +
                                                                    '.options.name',
                                                                    text
                                                                );
                                                            }}
                                                            onBlur={handleBlur(
                                                                'variants.' +
                                                                [index] +
                                                                '.options.name'
                                                            )}
                                                            value={
                                                                values.variants[
                                                                    index
                                                                ].options
                                                                    .name ?? ''
                                                            }
                                                            error={
                                                                touched.variants &&
                                                                    touched
                                                                        .variants[
                                                                    index
                                                                    ] &&
                                                                    errors.variants
                                                                    ? errors
                                                                        .variants[
                                                                        index
                                                                    ]?.options
                                                                        ?.name
                                                                    : ''
                                                            }
                                                        />
                                                    </Spacing>
                                                    <Text
                                                        style={{
                                                            color: 'red',
                                                            paddingVertical:
                                                                '20px',
                                                        }}
                                                    >
                                                        At least one Option is
                                                        required*
                                                    </Text>
                                                    {item?.options?.values?.map(
                                                        (item1, index1) => (
                                                            <Spacing
                                                                key={
                                                                    index1 +
                                                                    'nested'
                                                                }
                                                                spacing={8}
                                                                withEqualWidth
                                                            >
                                                                <Input
                                                                    label="Option Value"
                                                                    onChangeText={text => {
                                                                        setFieldValue(
                                                                            'variants.' +
                                                                            [
                                                                                index,
                                                                            ] +
                                                                            '.options.values.' +
                                                                            [
                                                                                index1,
                                                                            ] +
                                                                            '.name',
                                                                            text
                                                                        );
                                                                    }}
                                                                    onBlur={handleBlur(
                                                                        'variants.' +
                                                                        [
                                                                            index,
                                                                        ] +
                                                                        '.options.values.' +
                                                                        [
                                                                            index1,
                                                                        ] +
                                                                        '.name'
                                                                    )}
                                                                    value={
                                                                        item1?.name
                                                                    }
                                                                    error={
                                                                        touched.variants &&
                                                                            touched
                                                                                .variants[
                                                                            index
                                                                            ] &&
                                                                            errors.variants
                                                                            ? errors
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                ?.options
                                                                                ?.values[
                                                                                index1
                                                                            ]
                                                                                ?.name
                                                                            : ''
                                                                    }
                                                                />

                                                                <Input
                                                                    label="Listed Price"
                                                                    onChangeText={text => {
                                                                        setFieldValue(
                                                                            'variants.' +
                                                                            [
                                                                                index,
                                                                            ] +
                                                                            '.options.values.' +
                                                                            [
                                                                                index1,
                                                                            ] +
                                                                            '.price',
                                                                            text
                                                                        );
                                                                    }}
                                                                    onBlur={handleBlur(
                                                                        'variants.' +
                                                                        [
                                                                            index,
                                                                        ] +
                                                                        '.options.values.' +
                                                                        [
                                                                            index1,
                                                                        ] +
                                                                        '.price'
                                                                    )}
                                                                    value={
                                                                        (values.variants &&
                                                                            values
                                                                                .variants[
                                                                            index
                                                                            ] &&
                                                                            values
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                .options &&
                                                                            values
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                .options
                                                                                .values &&
                                                                            values
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                .options
                                                                                .values[
                                                                            index1
                                                                            ] &&
                                                                            values
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                .options
                                                                                .values[
                                                                                index1
                                                                            ]
                                                                                .price) ||
                                                                        ''
                                                                    }
                                                                    error={
                                                                        touched.variants &&
                                                                            touched
                                                                                .variants[
                                                                            index
                                                                            ] &&
                                                                            errors.variants
                                                                            ? errors
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                ?.options
                                                                                ?.values[
                                                                                index1
                                                                            ]
                                                                                ?.price
                                                                            : ''
                                                                    }
                                                                />

                                                                <Input
                                                                    label="Compare Price"
                                                                    onChangeText={text => {
                                                                        setFieldValue(
                                                                            'variants.' +
                                                                            [
                                                                                index,
                                                                            ] +
                                                                            '.options.values.' +
                                                                            [
                                                                                index1,
                                                                            ] +
                                                                            '.compareAtPrice',
                                                                            text
                                                                        );
                                                                    }}
                                                                    onBlur={handleBlur(
                                                                        'variants.' +
                                                                        [
                                                                            index,
                                                                        ] +
                                                                        '.options.values.' +
                                                                        [
                                                                            index1,
                                                                        ] +
                                                                        '.compareAtPrice'
                                                                    )}
                                                                    value={
                                                                        (values.variants &&
                                                                            values
                                                                                .variants[
                                                                            index
                                                                            ] &&
                                                                            values
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                .options &&
                                                                            values
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                .options
                                                                                .values &&
                                                                            values
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                .options
                                                                                .values[
                                                                            index1
                                                                            ] &&
                                                                            values
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                .options
                                                                                .values[
                                                                                index1
                                                                            ]
                                                                                .compareAtPrice) ||
                                                                        ''
                                                                    }
                                                                    error={
                                                                        touched.variants &&
                                                                            touched
                                                                                .variants[
                                                                            index
                                                                            ] &&
                                                                            errors.variants
                                                                            ? errors
                                                                                .variants[
                                                                                index
                                                                            ]
                                                                                ?.options
                                                                                ?.values[
                                                                                index1
                                                                            ]
                                                                                ?.compareAtPrice
                                                                            : ''
                                                                    }
                                                                />
                                                            </Spacing>
                                                        )
                                                    )}
                                                    <Button
                                                        title="Add Option Value"
                                                        onPress={() => {
                                                            setFieldValue(
                                                                'variants.' +
                                                                [index] +
                                                                '.options.values',
                                                                [
                                                                    ...values
                                                                        .variants[
                                                                        index
                                                                    ].options
                                                                        .values,
                                                                    emptyValue,
                                                                ]
                                                            );
                                                        }}
                                                    />
                                                    <HorizontalDivider
                                                        paddingY={getResponsiveStyle(
                                                            24
                                                        )}
                                                    />
                                                    <View
                                                        style={
                                                            styles.removeContainer
                                                        }
                                                    >
                                                        <Input
                                                            label="Stock quantity*"
                                                            onChangeText={text => {
                                                                setFieldValue(
                                                                    'variants.' +
                                                                    [
                                                                        index,
                                                                    ] +
                                                                    '.options.inventory.quantity',
                                                                    text
                                                                );
                                                            }}
                                                            onBlur={handleBlur(
                                                                'variants.' +
                                                                [index] +
                                                                '.options.inventory.quantity'
                                                            )}
                                                            value={
                                                                item.options
                                                                    .inventory
                                                                    .quantity
                                                            }
                                                            error={
                                                                touched?.variants &&
                                                                    touched
                                                                        .variants[
                                                                    index
                                                                    ] &&
                                                                    errors?.variants &&
                                                                    errors.variants[
                                                                    index
                                                                    ] &&
                                                                    errors
                                                                        ?.variants[
                                                                        index
                                                                    ]?.options &&
                                                                    errors.variants[
                                                                        index
                                                                    ]?.options
                                                                        .errors
                                                                        ?.variants[
                                                                        index
                                                                    ]?.options
                                                                        .inventory
                                                                    ? errors
                                                                        .variants[
                                                                        index
                                                                    ]?.options
                                                                        .errors
                                                                        ?.variants[
                                                                        index
                                                                    ]?.options
                                                                        .inventory
                                                                        ?.quantity
                                                                    : ''
                                                            }
                                                        />
                                                        <View>
                                                            <Label label="Is Stock Tracked?" />
                                                            <View
                                                                style={{
                                                                    flexDirection:
                                                                        'row',
                                                                    height: '100%',
                                                                }}
                                                            >
                                                                <BooleanButton
                                                                    type={
                                                                        'left'
                                                                    }
                                                                    label={
                                                                        'Yes'
                                                                    }
                                                                    selected={
                                                                        values
                                                                            .variants[
                                                                            index
                                                                        ]
                                                                            .options
                                                                            .inventory
                                                                            .isTracked ===
                                                                        true
                                                                    }
                                                                    onPress={() => {
                                                                        setFieldValue(
                                                                            'variants.' +
                                                                            [
                                                                                index,
                                                                            ] +
                                                                            '.options.inventory.isTracked',
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                                <BooleanButton
                                                                    type={
                                                                        'right'
                                                                    }
                                                                    label={'No'}
                                                                    selected={
                                                                        values
                                                                            .variants[
                                                                            index
                                                                        ]
                                                                            .options
                                                                            .inventory
                                                                            .isTracked ===
                                                                        false
                                                                    }
                                                                    onPress={() => {
                                                                        setFieldValue(
                                                                            'variants.' +
                                                                            [
                                                                                index,
                                                                            ] +
                                                                            '.options.inventory.isTracked',
                                                                            false
                                                                        );
                                                                    }}
                                                                />
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View
                                                        style={
                                                            styles.removeContainer
                                                        }
                                                    >
                                                        <Input
                                                            label="Weight (kgs):"
                                                            onChangeText={text => {
                                                                setFieldValue(
                                                                    'variants.' +
                                                                    [
                                                                        index,
                                                                    ] +
                                                                    '.weight',
                                                                    text
                                                                );
                                                            }}
                                                            onBlur={handleBlur(
                                                                'variants.' +
                                                                [index] +
                                                                '.weight'
                                                            )}
                                                            value={
                                                                item.weight ??
                                                                ''
                                                            }
                                                            error={
                                                                touched.variants &&
                                                                    touched
                                                                        .variants[
                                                                    index
                                                                    ] &&
                                                                    errors.variants &&
                                                                    errors.variants[
                                                                    index
                                                                    ]
                                                                    ? errors
                                                                        .variants[
                                                                        index
                                                                    ].weight
                                                                    : ''
                                                            }
                                                        />
                                                        <Button
                                                            title="Remove"
                                                            size="md"
                                                            outlineColor={
                                                                palettes.red[0]
                                                            }
                                                            onPress={() => {
                                                                setFieldValue(
                                                                    'variants',
                                                                    [
                                                                        ...values.variants.filter(
                                                                            (
                                                                                varVal,
                                                                                index
                                                                            ) =>
                                                                                index !==
                                                                                currentVariant
                                                                        ),
                                                                    ]
                                                                );
                                                            }}
                                                        />
                                                    </View>
                                                </>
                                            </>
                                        )
                                    }
                                />
                            ))}
                        </>

                        <></>
                    </Spacing>
                    <Button
                        title={`${props.productId ? 'Update' : 'Save'}`}
                        variant={isValid ? 'primary' : 'disabled'}
                        size="lg"
                        onPress={handleSubmit}
                        loading={productCreateLoading || productEditLoading}
                    />
                </>
            )}
        </Formik>
    );
};

export default AddSingleProductFormOld;

const styles = StyleSheet.create({
    description: {
        paddingVertical: getResponsiveStyle(20),
        height: getResponsiveStyle(160, 'dimensions'),
    },
    removeContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    shortDescription: {
        paddingVertical: getResponsiveStyle(20),
        height: getResponsiveStyle(90, 'dimensions'),
    },
});