import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ChevronDown } from '../..';
import { toggleMobileSidebar } from '../../../redux/mobileSidebarSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import InboxIcon from '../icons/InboxIcon';
import MagnifierGlassIcon from '../icons/MagnifierGlassIcon';
import MenuIcon from '../icons/MenuIcon';
import NotificationBellIcon from '../icons/NotificationBellIcon';
import ModalComponent from '../modal/ModalComponent';
import NotificationIconWithDropdown from '../notification-icon-with-dropdown/NotificationIconWithDropdown';
import ProfileIcon from '../ProfileIcon';
import MobileSidebar from '../sidebar/MobileSidebar';

type Props = {
	containerStyles?: TStyle;
	hasBackButton?: boolean;
};

const MobileNavigationBar = ({
	containerStyles,
	hasBackButton = false,
}: Props) => {
	const mobileSidebarState = useAppSelector(
		state => state.mobileSidebar.open
	);
	const loggedInUserDetails= useAppSelector(state => state.auth.loggedInUserDetails);
	const dispatch = useAppDispatch();
	return (
		<>
			<View style={[styles.container, containerStyles]}>
				<View style={styles.profileButtonContainer}>
					{hasBackButton && (
						<ChevronDown style={[styleChevron(), styles.chevron]} />
					)}
					<ProfileIcon 
					style={styles.profileIcon} 
					source={loggedInUserDetails?.personalInformation.profileImageLink}/>
				</View>
				<View style={styles.iconContainer}>
					{/* <MagnifierGlassIcon style={styles.magnifierIcon} /> */}
					<InboxIcon style={styles.inboxIcon} />
					<NotificationIconWithDropdown />
					<MenuIcon
						style={styles.menuIcon}
						onPress={() => dispatch(toggleMobileSidebar())}
					/>
				</View>
			</View>
			<ModalComponent
				header="Add New User"
				openState={mobileSidebarState}
				onClose={() => dispatch(toggleMobileSidebar())}
				type="sidebar"
			>
				<MobileSidebar />
			</ModalComponent>
		</>
	);
};

const styles = StyleSheet.create({
	notificationIcon: {
		height: 21,
		width: 20,
	},
	inboxIcon: {
		height: 21,
		width: 20,
	},
	magnifierIcon: {
		height: 20,
		width: 20,
	},
	profileIcon: {
		height: 36,
		width: 36,
	},
	menuIcon: {
		height: 21,
		width: 20,
	},
	profileButtonContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	chevron: {
		transform: [{ rotate: '90deg' }],
	},
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',

		paddingVertical: getResponsiveStyle(16),
	},
	iconContainer: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		width: '35%',
		maxWidth: 150,
		minWidth: 70,
	},
});

export default MobileNavigationBar;

function styleChevron() {
	return {
		width: getResponsiveStyle(7),
		height: getResponsiveStyle(12),
		marginRight: getResponsiveStyle(12),
		marginLeft: getResponsiveStyle(-5),
	};
}
