import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TInitialState = {
	open: boolean;
};

const initialState: TInitialState = {
	open: false,
};

const mobileSidebarSlice = createSlice({
	name: 'mobileSidebar',
	initialState,
	reducers: {
		toggleMobileSidebar(state) {
			state.open = !state.open;
		},
	},
});

export const { toggleMobileSidebar } = mobileSidebarSlice.actions;

export default mobileSidebarSlice.reducer;
