import { View, Text } from 'react-native';
import React, { useEffect } from 'react';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { GoBackButton, SectionHeading } from '../components';
import ProfileCards from '../components/common/profile-cards/ProfileCards';
import AllProfiles from '../components/common/all-profiles/AllProfiles';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { useGetAllUsersLazy } from '../api/auth/useGetAllUsersLazy';
import { TGetAllUsersFind } from '../types/types';
import { TCommonUserResponseData } from '../types/authTypes';
import Loader from '../components/common/loader/Loader';
import palettes from '../config/palettes';
import { GET_ALL_USERS } from '../graphql/auth/queries';
import { useMutation } from '@apollo/client';
import { ACCEPT_OR_REJECT } from '../graphql/user/mutation';

const SellersRequestsScreen = () => {
	const { getAllUsersLazy, data: usersData, loading: userLoading } = useGetAllUsersLazy();
	useEffect(() => {
		const find = {
			typeOfAccount: 'SELLER',
		} as TGetAllUsersFind;
		getAllUsersLazy(find);
	}, []);
	const users: TCommonUserResponseData[] =
		usersData?.getAllUsers?.data?.data?.filter(
			(user: TCommonUserResponseData) => user?.profileStatus === 'WAITING'
		);
	const [acceptOrRejectUserAccount, { loading: acceptOrRejectLoading }] = useMutation(ACCEPT_OR_REJECT, {

		awaitRefetchQueries: true,
		refetchQueries: [GET_ALL_USERS],

	})

	console.log({users})

	return (
		<DesktopTemplate>
			<GoBackButton history="Sellers" />
			<SectionHeading
				heading="New Sellers Requests"
				hasFilters
				containerStyles={{
					marginTop: getResponsiveStyle(28),
				}}
			/>
			{users?.length ? <AllProfiles newRequest={users} acceptOrRejectMutation={acceptOrRejectUserAccount} isRequest /> : userLoading ? (
				<Loader />
			) : (
				<Text
					style={{
						fontSize: getResponsiveStyle(14, 'font'),
						lineHeight: getResponsiveStyle(14, 'font'),
						color: palettes.grey[0],
					}}
				>
					No data Available!
				</Text>)}
		</DesktopTemplate>
	);
};
export default SellersRequestsScreen
