import React, { useEffect, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useIsSpecificViewportWidth } from '../../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { palettes } from '../../config';
import ModalComponent from '../common/modal/ModalComponent';
import Button from '../common/button/Button';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import { DELETE_BANK } from '../../graphql/stripe/mutations';
import { LIST_BANKS } from '../../graphql/stripe/queries';

type Props = {
	index?: number;
	bankName: string;
	iBan: string;
	country: string;
	// addresses?: TAddressBook[],
	handlePress: () => void;
	setDefaultLoading?: (arg: boolean) => void;
	bankId: string
	connectAccountId: string
};

const WithdrawCard = ({
	bankName,
	country,
	iBan,
	handlePress,
	bankId,
	connectAccountId
}: Props) => {
	const { t } = useTranslation();
	console.log({bankId})
	const [selected, setSelected] = useState(false);

	const [deleteBank, { loading: deletingBank, data: deleteBankData }] =
		useMutation(DELETE_BANK);
	const [deleteBankModalState, setDeleteBankModalState] =
		React.useState(false);
	const changeDeleteBankModalState = () => {
		setDeleteBankModalState(!deleteBankModalState);
	};

	const deleteBankFn = async () => {
		await deleteBank({
			variables: {
				args: {
					id: connectAccountId,
					bankId: bankId,
				},
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: LIST_BANKS,
					variables: {
						args: {
							id: connectAccountId,
						},
					},
				},
			],
			onCompleted: data => console.log(data),
		});
	};
	return (
		<View
			style={[
				styles.container,
				selected && { borderColor: palettes.primary[1] },
			]}
		>
			{/* {
				loadingedit ?
					<Loader /> : */}
			<Pressable
				onPress={() => {
					handlePress();
					setSelected(!selected);
				}}
				style={[
					styles.innerContainer,
					selected && styles.innerSelectedContainer,
					selected && { borderColor: palettes.primary[0] },
				]}
			>
				<View style={styles.headerContainer}>
					{/* <View style={styles.headerTextContainer}>
						<Text style={styles.textTitile}>{t('Name')}</Text>
						<Text style={styles.textSubtitle}>{name}</Text>
					</View> */}
					{/* {defaultAddress && (
						<View style={styles.defaultIndicator}>
							<Text style={styles.defaultIndicatorText}>
								{t('Default')}
							</Text>
						</View>
					)} */}
				</View>
				<View style={[styles.contentContainer, { width: '100%' }]}>
					<View style={styles.textContainer}>
						<Text style={styles.textTitile}>
							{t('Bank name') + ':'}
						</Text>
						<Text style={styles.textSubtitle}>
							{bankName}
						</Text>
					</View>
				</View>
				<View style={styles.contentContainer}>
					<View style={styles.textContainer}>
						<Text style={styles.textTitile}>{t('IBAN')}</Text>
						<Text style={styles.textSubtitle}>
							{/* {shortenName('shdudfdfdfdf')} */}
							{iBan}
						</Text>
					</View>
					<View style={styles.textContainer}>
						<Text style={styles.textTitile}>
							{t('Country') + ':'}
						</Text>
						<Text style={styles.textSubtitle}>
							{country}
							{/* {shortenName('hsddffgfgfg')} */}
						</Text>
					</View>
				</View>
				<View style={styles.contentContainer}>
					{' '}
					{/* <View style={styles.textContainer}>
						<Text style={styles.textTitile}>{t('Zip Code')}</Text>
						<Text style={styles.textSubtitle}>{'100001'}</Text>
					</View> */}
					<Text
						onPress={() => changeDeleteBankModalState()}
						style={styles.hoverText}
					>
						{t('Delete')}
					</Text>
				</View>
				{false && (
					<ModalComponent
						header={`${t('Delete Address Book')}`}
						openState={false}
						onClose={() => null}
					>
						{false ? (
							<Text>{t('Deleting Address Book...')}</Text>
						) : false ? (
							<Text>{t('Address Book Deleted')}</Text>
						) : (
							<View style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
								<Text>
									{t('Are you sure you want to delete?')}{' '}
								</Text>
								<Button title={t('Yes')} onPress={() => null} />
							</View>
						)}
					</ModalComponent>
				)}
			</Pressable>
			<ModalComponent
				header={`${t('Delete Bank Account')}`}
				openState={deleteBankModalState}
				onClose={changeDeleteBankModalState}
			>
				{deletingBank ? (
					<Text>{t('Deleting bank...')}</Text>
				) : deleteBankData ? (
					<Text>{t('Bank Deleted')}</Text>
				) : (
					<View style={styles.deleteContainer}>
						<Text>{t('Are you sure you want to delete? ')}</Text>
						<Button
							title={t('Yes')}
							onPress={() => deleteBankFn()}
						/>
					</View>
				)}
			</ModalComponent>
		</View>
	);
};

const styles = StyleSheet.create({
	selected: {
		borderColor: palettes.primary[1],
	},
	innerSelectedContainer: {
		// borderColor: palettes.primary[0],
		borderWidth: 1,
		borderRadius: 6,
	},
	deleteContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	innerContainer: {
		width: '100%',
		paddingTop: getResponsiveStyle(10),
		paddingLeft: getResponsiveStyle(20),
		paddingRight: getResponsiveStyle(20),
		paddingBottom: getResponsiveStyle(15),
	},
	container: {
		borderRadius: 6,
		borderColor: palettes.grey[5],
		borderWidth: 1,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpacity: 1,
		// maxWidth: getResponsiveStyle(440),
		width: '100%',
		padding: '1px',
	},
	headerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'flex-start',
	},
	headerTextContainer: {
		flexDirection: 'column',
		gap: 4,
	},
	textTitile: {
		color: palettes.grey[1],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	},
	textSubtitle: {
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(21, 'font'),
	},
	defaultIndicator: {
		paddingHorizontal: getResponsiveStyle(17),
		paddingTop: getResponsiveStyle(10),
		paddingBottom: getResponsiveStyle(8),
		backgroundColor: palettes.purple[0],
		borderRadius: 6,
	},
	defaultIndicatorText: {
		color: palettes.light[0],
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	},
	contentContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(10),
		width: '70%',
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 1,
		marginTop: getResponsiveStyle(15),
	},
	textContainer: {
		flexDirection: 'column',
		// marginRight: getResponsiveStyle(90, 'dimensions'),
		gap: 6,
	},
	hoverText: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[1],
		cursor: 'pointer',
	},
});

export default WithdrawCard;
