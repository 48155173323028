import { gql } from '@apollo/client';

export const GET_ALL_NOTIFICATIONS = gql`
	query getAllNotification {
		getAllNotifications(
			populate: [
				{ path: "sentBy", model: "User" }
				{ path: "event", model: "Event" }
				{ path: "eventId", model: "Event" }
			]
		) {
			success
			data {
				... on All {
					data {
						... on Notification {
							_id
							message
							notificationType
							eventId {
								_id
								name
								description
								typeOfEvent
								startingEventDateTime
								
							}
							createdAt
							read
							eventHostingId
							invitation
							event {
								_id
								name
								description
								typeOfEvent
								startingEventDateTime
								
							}
							eventType
							sentBy {
								_id
								username
								personalInformation {
									profileImageLink
									firstName
								}
							}
							user {
								_id
							}
						}
					}
				}
			}
		}
	}
`;
