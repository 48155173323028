import { View, Text, StyleSheet, Pressable } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';

type Props = {
	tabs: { label: string; value: string }[];
	onPress: (tab: string) => void;
	selectedTab: string;
};

const DescriptionTabs = ({ tabs, onPress, selectedTab }: Props) => {
	const { width } = useIsSpecificViewportWidth();
	const primaryColors = palettes.purple;
	return (
		<View style={styles.container}>
			{tabs.map(tab => (
				<Pressable key={tab.value} onPress={() => onPress(tab.value)}>
					<Text
						style={styleTab(
							selectedTab,
							tab.value,
							width,
							primaryColors
						)}
					>
						{tab.label}
					</Text>
				</Pressable>
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',

		borderBottomColor: palettes.grey[5],
		borderBottomWidth: 2,
	},
	tab: {
		textAlign: 'center',
		textTransform: 'capitalize',
	},
	tabNotSelected: {
		color: palettes.grey[0],

		fontWeight: '400',
	},
	tabSelected: {
		color: palettes.dark[0],
		paddingBottom: 9,
		paddingHorizontal: 3,
		fontWeight: 'bold',
		borderBottomWidth: 1,
	},
});

export default DescriptionTabs;

const styleTab = (
	selectedTab: string,
	tab: string,
	width: number,
	primary: string[]
) => {
	const tabSelected = {
		color: palettes.dark[0],
		paddingBottom: 9,
		paddingHorizontal: 3,
		fontWeight: 'bold',
		borderBottomWidth: 1,
	};
	const tabNotSelected = {
		color: palettes.grey[0],
		fontWeight: '400',
	};

	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(24),
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
		},
		selectedTab === tab
			? { ...tabSelected, borderBottomColor: primary[0] }
			: tabNotSelected,
		// {
		// 	marginBottom: getResponsiveStyle(48),
		// },
	];
};
