import { gql } from '@apollo/client';
import { PERSONAL_INFORMATION } from '../common/fragments';

const USER_FRAGMENT = gql`
	${PERSONAL_INFORMATION}
	fragment UserFragment on User {
		_id
		typeOfAccount
		username
		email
		profileStatus
		role
		isInvitedAdmin
		affiliatedBrand
		personalInformation {
			...PersonalInformation
		}
	}
`;

const USER_RESPONSE_FRAGMENT = gql`
	${USER_FRAGMENT}
	fragment UserResponseFragment on Response {
		statusCode
		success
		message
		data {
			... on User {
				...UserFragment
			}
		}
	}
`;
const USER_STAT_FRAGMENT = gql`
	${USER_FRAGMENT}
	fragment UserStatFragment on AllResponse {
		statusCode
		success
		message
		data {
			... on All {
				total
				totalActive
				totalInactive
				newRequest
				data {
					... on User {
						...UserFragment
					}
				}
			}
		}
	}
`;

const GET_ALL_USER_FRAGMENT = gql`
	${USER_FRAGMENT}
	fragment GetAllUsersFragment on AllResponse {
		statusCode
		success
		message
		data {
			... on All {
				total
				totalInactive
				totalActive
				newRequest
				data {
					...on User {
						...UserFragment
					}
				}
			}
		}
	}
`;

const GET_USER_FRAGMENT = gql`
	${USER_FRAGMENT}
	fragment GetAllUsersFragment on AllResponse {
		statusCode
		success
		message
		data {
			data {
				... on User {
					...UserFragment
				}
			}
		}
	}
`;

export {
	USER_RESPONSE_FRAGMENT,
	GET_ALL_USER_FRAGMENT,
	USER_FRAGMENT,
	USER_STAT_FRAGMENT,
	GET_USER_FRAGMENT,
};
