import { useLazyQuery } from "@apollo/client"
import { GET_APP_CONFIG } from "../../graphql/config/queries"

type TQueryArgs = {
    key: any

}
export const useGetAppConfg = () => {
    const [getAppConfig_, {data}] = useLazyQuery(GET_APP_CONFIG)

    const getAppConfig = ({ key }: TQueryArgs) => {
        getAppConfig_({
            variables: {
                key
            }
        })
    }

    return { getAppConfig, data }
}