import React from 'react';
import { useGetAllOrders } from '../api/order/useGetOrders';
import { GoBackButton } from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import OrderComponent from '../components/dashboard/OrderComponent';
import OrderSection from '../components/dashboard/OrderSection';

const AllOrdersScreen = () => {
	const {
		getAllOrders,
		data: AllOrders,
		loading: loadingOrders,
	} = useGetAllOrders({ find: { orderStatus: 'IN_PROGRESS' } });

	return (
		<DesktopTemplate>
			<GoBackButton history="Dashboard" />
			<OrderComponent
				hasViewAllButton={false}
				heading="Orders"
				data={AllOrders}
				loading={loadingOrders}
				action={getAllOrders}
			/>
			
		</DesktopTemplate>
	);
};

export default AllOrdersScreen;
