import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import { ChevronDown } from '../..';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { TUseNavigation } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	containerStyles?: TStyle;
	history?: any;
	onClick?: () => void;
};

const GoBackButton = ({ containerStyles, history, onClick }: Props) => {
	const navigation = useNavigation<TUseNavigation>();

	const onPressButton = () => {
		navigation.canGoBack()
			? navigation.goBack()
			: navigation.navigate(history && history);
	};

	return (
		<Pressable
			style={[
				styles.goBackContainer,
				styleGoBackContainer(),
				containerStyles,
			]}
			onPress={() => {
				onClick ? onClick() : onPressButton();
			}}
		>
			<ChevronDown style={styles.chevron} />{' '}
			<Text style={[styles.goBackText, styleGoBackText()]}>Back</Text>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	chevron: {
		transform: [
			{
				rotate: '90deg',
			},
		],
		maxWidth: 10,
		maxHeight: 15,
		marginLeft: -10,
	},
	goBackContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		maxWidth: 'max-content',
		borderRadius: 50,
		borderWidth: 1,
		borderColor: palettes.grey[3],
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 6,
		shadowColor: 'rgba(124, 136, 155, 0.06)',
		shadowOpacity: 1,
	},
	goBackText: {
		color: palettes.dark[0],
		userSelect: 'none',
	},
});

function styleGoBackText() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
}

function styleGoBackContainer() {
	return {
		paddingVertical: getResponsiveStyle(9, 'font'),
		paddingHorizontal: getResponsiveStyle(16, 'font'),
	};
}

export default GoBackButton;
