import { yupsRequiredString } from '../../../utils/yupsRequiredString';
import * as yup from 'yup';
import { useAppSelector } from '../../../redux/store';
import { TSignupInformation } from '../../../types/authTypes';

//validation for signupform
export const addSignupValidationSchema = yup.object({
    username: yupsRequiredString().matches(/^\S+$/, 'Username cannot have spaces'),
    email: yupsRequiredString().email(),
    password: yupsRequiredString().min(6, 'Min length: 6'),
    conf_password: yupsRequiredString().oneOf([yup.ref('password')], 'passwords do not match'),
    profImage: yup.string(),
    firstName: yupsRequiredString().matches(/^\S+$/, 'firstname cannot have spaces'),
    lastName: yupsRequiredString().matches(/^\S+$/, 'lastname cannot have spaces'),
    phoneNumber: yupsRequiredString(),
    gender: yupsRequiredString(),
    address1: yupsRequiredString(),
    address2: yup.string(),
    city: yupsRequiredString(),
    zipCode: yupsRequiredString(),
    country: yupsRequiredString(),
    countryCode: yupsRequiredString(),
    accountStatus: yup.string(),

});

type TAddSignupInitalValues = yup.InferType<typeof addSignupValidationSchema>;

export const initialValues = (initialState: TSignupInformation) => {
    const email = initialState.email
    const firstName = initialState.personalInformation.firstName
    const lastName = initialState.personalInformation.lastName

    //signup form initial value
    const addSignupInitalValues: TAddSignupInitalValues = {
        username: '',
        email,
        password: '',
        conf_password: '',
        profImage: '',
        firstName,
        lastName,
        gender: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        city: '',
        zipCode: '',
        country: '',
        countryCode: '',
        accountStatus: '',
    };
    return addSignupInitalValues
}
