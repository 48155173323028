import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const StarEmptyIcon = (props: SvgProps) => {
  return (
    <Svg width={15} height={14} fill="none" viewBox="0 0 15 14" {...props}>
      <Path
        fill="#fff"
        stroke="#FEBF3B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m7.525 1.13 1.826 3.7a.5.5 0 0 0 .376.273l4.084.597-2.955 2.878a.5.5 0 0 0-.143.443l.697 4.065-3.652-1.92a.5.5 0 0 0-.466 0l-3.652 1.92.697-4.066a.5.5 0 0 0-.143-.442L1.239 5.7l4.084-.597a.5.5 0 0 0 .376-.274l1.826-3.7z"
      />
    </Svg>
  );
};

export default StarEmptyIcon;
