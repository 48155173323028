import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { palettes } from '../../config';
import { useIsSpecificViewportWidth } from '../../hooks/useIsSpecificViewportWidth';
import { TStyleInputOrText } from '../../types/TStyle';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';

type TAuthType = 'login' | 'signup';

type Props = { title: string; text: string; authType: TAuthType };

const AuthHeading = ({ text, title, authType }: Props) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<View style={styleContainer()}>
			<Text
				style={[
					styles.title,
					styleTitle(),
					textAlignment(authType, isLessThanDesktopBase),
				]}
			>
				{title}
			</Text>
			<Text
				style={[
					styles.text,
					styleText(),
					textAlignment(authType, isLessThanDesktopBase),
				]}
			>
				{text}
			</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	title: {
		fontStyle: 'normal',
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		marginTop: 10,
		color: palettes.dark[4],
	},
});

export default AuthHeading;

const styleContainer = () => {
	return {
		marginTop: getResponsiveStyle(45),
	};
};

const styleText = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
	};
};

const styleTitle = () => {
	return {
		fontSize: getResponsiveStyle(24, 'font'),
		lineHeight: getResponsiveStyle(32, 'font'),
	};
};
function textAlignment(
	authType: TAuthType,
	isLessThanDesktopBase: boolean
): TStyleInputOrText {
	return {
		textAlign:
			authType === 'signup' || isLessThanDesktopBase ? 'center' : 'left',
	};
}
