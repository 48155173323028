import { useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { DraggableScrollView } from '../../../..';
import { TUseNavigation } from '../../../../../types/exportedTypes';
import { TStyle } from '../../../../../types/TStyle';
import { getResponsiveStyle } from '../../../../../utils/getResponsiveStyle';
import { TopNavigationItem } from './TopNavigationItem';

type Props = {
	scrollItems: TStepTemplateScrollItems[];
	changeSelectedIndex?: (value: number) => void;
	selectedIndex?: number;
	type?: string;
};

const TopNavigationScroll = ({
	scrollItems,
	changeSelectedIndex,
	selectedIndex = 0,
	type,
}: Props) => {
	const navigation = useNavigation<TUseNavigation>();
	const route = useRoute();
	return (
		<DraggableScrollView
			data={scrollItems}
			style={styleContainer()}
			showsHorizontalScrollIndicator={false}
			renderItem={(obj: any) => {
				const { item } = obj;
				return (
					<TopNavigationItem
						onPress={
							type === 'Profile'
								? () =>
										changeSelectedIndex &&
										changeSelectedIndex(item.id)
								: (screen: string) => {
										navigation.navigate(screen);
								  }
						}
						item={item}
						selected={
							type === 'Profile'
								? selectedIndex === item.id
								: route.name === item.screen
						}
					/>
				);
			}}
		/>
	);
};
export default TopNavigationScroll;

const styleContainer = () => {
	return {
		maxHeight: getResponsiveStyle(45),
		marginTop: getResponsiveStyle(22),
		marginLeft: 10,
		width: '100vw',
		maxWidth: getResponsiveStyle(464, 'dimensions'),
	} as TStyle;
};
