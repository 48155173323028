import { gql } from '@apollo/client';
const CREATE_NEW_BRAND = gql`
    mutation createBrand($args:BrandInputs!){
        createBrand(args:$args){
            ... on Response{
                statusCode
                success
                message
                data{
                    ... on Brand{
                        _id
                        connectAccountInfo {
                            connectAccountId
                            lastUrl
                        }
                    }   
                }
            }
        }
    }

`
const EDIT_BRAND = gql`
mutation editBrand($_id:ID!,$args:BrandInputs!){
    editBrand(_id:$_id,args:$args){
        ... on Response{
            statusCode
            success
            message
            data{
                ... on Brand{
                    _id
                    connectAccountInfo {
                        connectAccountId
                        lastUrl
                    }
                }   
            }
        }
    }
}

`

const DELETE_BANK_ACCOUNT = gql`
    mutation deleteBrandBank($args: DeleteBrandBankInputs){
        deleteBrandBank(args: $args){
            statusCode
            success
            data{
                __typename
            }
        }
    }
        
`

const CREATE_TRANSFER_FROM_PENDING_TRFS = gql`
	mutation createTransferFromPendingTransfer($sellerId: String) {
		createTransferFromPendingTransfer(sellerId: $sellerId) {
			... on AllResponse {
				statusCode
				success
				message
			}
		}
	}
`;

const DELETE_PENDING_TRANSFER = gql`
	mutation deletePendingTransfer($args: GetPendingTransfer!) {
		deletePendingTransfer(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;
export {
    CREATE_NEW_BRAND,
    EDIT_BRAND,
    DELETE_BANK_ACCOUNT,
    CREATE_TRANSFER_FROM_PENDING_TRFS,
    DELETE_PENDING_TRANSFER
}