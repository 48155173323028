import styled from "rn-css";

export const Action = styled.Text`
  cursor: pointer;
  font-weight: 600;
  text-align: right;
  padding-bottom: 2;
  border-bottom: 1px solid transparent;
`;

export default Action;
