import React, { useState } from 'react';
import { GET_ORDERS } from '../../graphql/order/queries';
import { TGetOrderResponse, TOrders } from '../../types/orderTypes';
import { useQueryTemplate } from '../template/useQueryTemplate';
export const useGetAllOrders = (params?: any) => {
	const [AllOrders, setAllOrders] = useState<any[]>();

	const onCompleted = (data: TGetOrderResponse) => {
		console.log(data);
		if (
			data &&
			data.getAllOrders &&
			data.getAllOrders.success &&
			data.getAllOrders.data &&
			data.getAllOrders.data.data
		) {
			setAllOrders(data.getAllOrders.data?.data);
		}
	};
	const {
		getAllData: getAllOrders,
		data,
		...states
	} = useQueryTemplate({
		query: GET_ORDERS,
		variables: params,

		onCompleted,
	});
	return { getAllOrders, data: AllOrders, ...states };
};
