import React from 'react';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { Linking } from 'react-native';
const Action = styled.Text`
	color: ${palettes.blue[0]};
	border-bottom: 1px solid transparent;
	cursor: pointer;
	&:hover {
		border-bottom: 1px solid ${palettes.blue[0]};
	}
`;

type Props = {
	actionText: string;
	actionLink?: string;
};

export const ActionMessage = ({ actionText, actionLink }: Props) => {
	return (
		<Action
			onPress={() => {
				actionLink && Linking.openURL(actionLink);
			}}
			style={[styleLabel()]}
		>
			{actionText}
		</Action>
	);
};

const styleLabel = () => {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
	} as TStyleInputOrText;
};
