import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_ALL_STARTERKITS } from '../../graphql/starterKits/queries';

const useGetAllStarterKits = () => {
	const [getAllStarterKits, { data, error, loading }] =
		useLazyQuery(GET_ALL_STARTERKITS);

	const executeAllStarterKitsQuery = () => {
		getAllStarterKits({});
	};
	return { executeAllStarterKitsQuery, data, error, loading };
};

export default useGetAllStarterKits;
