import React, { useEffect } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import useGetAllProducts from '../../../api/product/useGetAllProducts';
import { Product } from '../../../types/productTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ProductPriceInfo from './ProductPriceInfo';
import ProductDescription from './ProductDescription';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../../../redux/store';

const ProductDetail = (product: Product) => {
	const {
		executeAllProductQuery,
		data: productData,
		loading,
	} = useGetAllProducts();
	const {
		products: { productTableDetail },
	} = useAppSelector(states => states);

	return (
		<View style={styles.container}>
			<Image
				style={styles.image}
				source={{
					uri: productTableDetail?.variants[productTableDetail.defaultVariant]?.image?.src,
				}}
			/>
			<ProductPriceInfo {...productTableDetail} />
			<ProductDescription {...productTableDetail} />
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		padding: getResponsiveStyle(30, 'spacing'),
	},
	flex1: {
		flex: 1,
		marginBottom: 15,
	},
	image: {
		width: '100%',
		height: '50vh',
		borderRadius: 4,
		marginBottom: getResponsiveStyle(5),
	},
});

export default ProductDetail;
