import React, { useEffect, useState } from 'react';
import {
	ActivityIndicator,
	Image,
	Pressable,
	StyleSheet,
	View,
} from 'react-native';
import { DraggableScrollView } from '../..';
import { useGetSingleBrandLazy } from '../../../api/brands/useGetSingleBrand';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useAppSelector } from '../../../redux/store';
import { TGetAllBrandsData, TGetSingleBrandResponse } from '../../../types/brandTypes';
import { TGetAllProductsData } from '../../../types/productTypes';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Loader from '../../common/loader/Loader';
import ProductCard from './ProductCard';

const ProductCardSlider = () => {
	const { affiliatedBrands } = useAppSelector(state => state.getEvent.event);

	const [products, setProducts] = useState<TGetAllProductsData[]>([]);
	const [brandDetails, setBrandDetails] = useState<TGetAllBrandsData>();

	const onCompletedGetSingleBrandDetails = (
		data: TGetSingleBrandResponse
	) => {
		if (data.getBrand.success && data.getBrand.data) {
			setBrandDetails(data.getBrand.data);
			setProducts(data.getBrand.data.products.data);
		}
	};

	const { getSingleBrand, loading } = useGetSingleBrandLazy({
		onCompleted: onCompletedGetSingleBrandDetails,
	});

	useEffect(() => {
		getSingleBrand(affiliatedBrands[0]?._id);
	}, []);

	const renderItem = (item: any) => {
		return (
			<>
				{loading ? (
					<Loader
						size="small"
						containerStyles={{
							margin: getResponsiveStyle(20),
						}}
					/>
				) : (
					<ProductCard product={item.item} />
				)}
			</>
		);
	};
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<Pressable>
			<View style={[styles.container, styleContainer()]}>
				<DraggableScrollView
					horizontal
					showsHorizontalScrollIndicator={false}
					style={styleDraggableScroll()}
					data={products}
					renderItem={item => renderItem(item)}
				/>
				{!isLessThanDesktopBase && (
					<Image
						source={require('../../../assets/slider-gradient.png')}
						style={styles.scrollGradient}
					/>
				)}
			</View>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		maxWidth: '100%',
		zIndex: 1,
		position: 'relative',
	},
	scrollGradient: {
		width: '102px',
		height: '100%',
		position: 'absolute',
		right: '0px',
		zIndex: 2,
		cursor: 'auto',
	},
});

const styleDraggableScroll = (): TStyle => {
	return [
		styles.container,
		{
			maxWidth: '100%',
			paddingBottom: getResponsiveStyle(20),
		},
	];
};

export default ProductCardSlider;

const styleContainer = () => {
	return { marginBottom: getResponsiveStyle(30) };
};
