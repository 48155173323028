import { gql } from '@apollo/client';

const CREATE_NEW_EVENT = gql`
	mutation createNewEvent($args: CreateEventInputs!) {
		createEvent(args: $args) {
			statusCode
			success
			message
			data {
				... on Event {
					_id
					name
					description
					typeOfEvent
					startingEventDateTime
				}
			}
		}
	}
`;
const DELETE_EVENT = gql`
	mutation deleteEvent($_id: ID!) {
		deleteEvent(_id: $_id) {
			statusCode
			success
			message
		}
	}
`;

export const EDIT_EVENT_STATUS = gql`
	mutation approveOrRejectEvent(
		$_id: ID!
		$status: ApproveOrRejectEventType!
	) {
		approveOrRejectEvent(_id: $_id, option: $status) {
			statusCode
			success
			message
			data {
				... on Event {
					_id
					name
					description
					typeOfEvent
					startingEventDateTime
				}
			}
		}
	}
`;

export { CREATE_NEW_EVENT, DELETE_EVENT };
