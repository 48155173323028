import React from 'react';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import { Product } from '../../../types/productTypes';
import getSymbolFromCurrency from 'currency-symbol-map';

const ProductPriceInfo = (product: Product) => {
	console.log(product, 'detail data');

	const productData = product?.variants[product.defaultVariant]?.option?.values?.[0] || {}

	return (
		<View style={styles.container}>
			<View style={styles.price}>
				{
					productData?.compareAtPrice && <Text style={styles.startingPrice}>
						{getSymbolFromCurrency('eur')}{productData?.compareAtPrice}
					</Text>
				}
				{productData?.price > 0 && <Text style={styles.discountPrice}>
					{getSymbolFromCurrency('eur')}{productData?.price}
				</Text>}
			</View>
			<Pressable onPress={() => null}>
				<Text style={styles.labelLink}></Text>
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	price: {
		flexDirection: 'row',
	},
	startingPrice: {
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: 14,
		color: '#7C889B',
		marginRight: 10,
		textDecorationLine: 'line-through',
	},
	discountPrice: {
		fontStyle: 'normal',
		fontWeight: '600',
		lineHeight: 14,
		color: '#F96152',
	},
	labelLink: {
		fontStyle: 'normal',
		fontWeight: '600',
		fontSize: 14,
		lineHeight: 14,
		textDecorationLine: 'underline',
		color: '#6645F4',
	},
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 15,
		marginBottom: 20,
	},
});

export default ProductPriceInfo;
