import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_NEW_BRAND } from '../../graphql/brands/mutations';
import {
    TGetAllBrandsData,
    TGetAllBrandsResponse,

} from '../../types/brandTypes';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { useAppSelector } from '../../redux/store';
import { GET_ALL_BRANDS, GET_NEW_BRANDS } from '../../graphql/brands/queries';
const refetchQueries = [
    { query: GET_ALL_BRANDS },
    { query: GET_NEW_BRANDS }
]
const useCreateNewBrand = (
    onCompleted: (value: any) => void | undefined,
    _onError?: (error: ApolloError) => void | undefined
) => {
    // const brandState=useAppSelector(state=>state.)
    const { setAlert } = useSetAlert();
    const onError = (error: ApolloError) => {
        if (error) {
            setAlert(error.message, 'danger')
        }
    }
    const [_createBrand, { data, error, loading }] =
        useMutation(CREATE_NEW_BRAND, {
            awaitRefetchQueries: true,
            refetchQueries,
            onCompleted,
            onError: error => {
                _onError === undefined ? onError(error) : _onError(error);

            }
        })
    const createBrand = (args: any) => {
        _createBrand({
            variables: {
                args: args
            }
        })
    }
    return { createBrand, data, error, loading }

}
export default useCreateNewBrand