import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { createArray } from '../../../utils/createArray';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import SectionHeading from '../SectionHeading';
import { ProfileCard } from '../profile-cards/ProfileCard';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TNewRequest } from '../../../types/authTypes';
import { AcceptOrReject } from '../../../types/userTypes';

const AllProfiles = ({
	isBrand = false,
	isRequest = false,
	newRequest=[],
	acceptOrRejectMutation
}: {
	isBrand?: boolean;
	isRequest?: boolean;
	newRequest:TNewRequest[]
	acceptOrRejectMutation?: (variables?: AcceptOrReject) => void;

}) => {
	const ElementsToRender = newRequest;

	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	return (
		<View
			style={[
				styles.container,
				{
					justifyContent: isLessThanDesktopBase
						? 'center'
						: 'flex-start',
				},
			]}
		>
			{ElementsToRender.map((item) => (
				<ProfileCard acceptOrRejectMutation={acceptOrRejectMutation} isBrand={isBrand} isRequest={isRequest} newRequest={item} />
			))}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginTop: getResponsiveStyle(34),
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: getResponsiveStyle(10),
		width: '100%',
		// marginRight: -40,
		// flexGrow: 1,
	},
});
export default AllProfiles;
