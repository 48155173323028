import { useLazyQuery } from '@apollo/client';
import { GET_ADMIN_BALANCE, GET_BALANCE_ON_STRIPE } from '../graphql/auth/queries';
import { GET_TOTAL_PENDING_TRANSFER_AMOUNT } from '../graphql/stripe/queries';
import { setAdminBalance, setAvailableAdminBalanceOnStripe, setPendingBalanceInStripe, setTotalPendingTransfer } from '../redux/authSlice';
import { useAppDispatch } from '../redux/store';

type TObj = {
    amount: number
};
export const useGetAdminBalance = () => {
    const dispatch = useAppDispatch();
    const [getAdminBalance] = useLazyQuery(GET_ADMIN_BALANCE, {
        onCompleted:
            data => {
                dispatch(
                    setAdminBalance({
                        totalEarning: data?.getAdminBalance?.data?.totalAdminEarnings,
                        availableBalance: data?.getAdminBalance?.data?.adminBalance,
                        totalWithdrawals: data?.getAdminBalance?.data?.totalAdminWithdrawals,
                    })
                );
            }
    }
    );

    const [totalBalanceOnStripe] = useLazyQuery(GET_BALANCE_ON_STRIPE, {
        onCompleted:
            data => {
                dispatch(
                    setAvailableAdminBalanceOnStripe(
                        data?.getAdminBalanceOnStripe?.data?.available?.reduce((acc: number, obj: { amount: number }) => (acc + obj.amount), 0) / 100,
                    )
                );

                dispatch(
                    setPendingBalanceInStripe(
                        data?.getAdminBalanceOnStripe?.data?.pending?.reduce((acc: number, obj: { amount: number }) => (acc + obj.amount), 0) / 100,
                    )
                )
            }
    }
    );

    const [totalPendingTransfers] = useLazyQuery(GET_TOTAL_PENDING_TRANSFER_AMOUNT, {
        onCompleted:
            data => dispatch(
                setTotalPendingTransfer(data?.getTotalPendingTransferAmount?.data)
            )
    })

    return { getAdminBalance, totalBalanceOnStripe, totalPendingTransfers };
};
