import { useLazyQuery } from '@apollo/client';
import { GET_ALL_USERS } from '../../graphql/auth/queries';
import { TGetAllUsersFind } from '../../types/types';

export const useGetAllUsersLazy = () => {
	const [_getAllUsers, state] = useLazyQuery(GET_ALL_USERS);

	const getAllUsersLazy = (find: TGetAllUsersFind) => {
		_getAllUsers({
			variables: {
				find,
			},
		});
	};

	return {
		getAllUsersLazy,
		...state,
	};
};
