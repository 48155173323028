import React from 'react';
import {
	StyleSheet,
	View,
	Text,
	ViewStyle,
	StyleProp,
	// Image
} from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { hexToRGB } from '../../../utils/hexToRgba';
import { HeartIcon } from '../icons';
import { EventCardBottomProps } from './CardBottom';

const Image = styled.Image`
	backdrop-filter: blur(60px);
`;

type Props = {
	date?: string;
	image?: string;
	month?: string;
	title?: string;
};

export const CardTop = ({ date, month, title, image }: Props) => {
	return (
		<View style={styles.cardTop}>
			<Image
				source={{ uri: image || 'https://via.placeholder.com/500' }}
				style={styles.image}
			/>
			<Text style={[styles.eventMeta, styles.eventStatus]}>upcoming</Text>
			<Text style={[styles.eventMeta, styles.eventStartingTime]}>
				start at - {date&&formatDate(parseInt(date))}
			</Text>
			
		</View>
	);
};
const formatDate = (date: number) => {
	const originalHours: string = new Date(date)
		.getHours()
		.toLocaleString(undefined, {
			minimumIntegerDigits: 2,
			useGrouping: false,
		});

	const formattedHours: string =
		originalHours >= '12'
			? (Number(originalHours) - 12).toString()
			: originalHours;

	const minutes: string = new Date(Number(date))
		.getMinutes()
		.toLocaleString(undefined, {
			minimumIntegerDigits: 2,
			useGrouping: false,
		});

	const hourString: string = originalHours >= '12' ? 'PM' : 'AM';

	const result = `${formattedHours} : ${minutes} ${hourString}`;

	return originalHours ? result : '00:00 AM';
};

const styles = StyleSheet.create({
	cardTop: {
		position: 'relative',
		height: '50%',
		zIndex: 1,
	},
	eventMeta: {
		borderRadius: 6,
		paddingVertical: 5,
		paddingHorizontal: 10,
		textTransform: 'uppercase',
		fontWeight: '700',
		fontSize: 10,
		lineHeight: 14,
		textAlign: 'center',
		color: 'white',
		backgroundColor: hexToRGB(palettes.dark[0], 0.2),
	},
	eventStatus: {
		position: 'absolute',
		left: 10,
		top: 10,
	},
	eventStartingTime: {
		position: 'absolute',
		left: 10,
		bottom: 10,
	},
	eventHeartContainer: {
		position: 'absolute',
		right: 10,
		top: 10,
		backgroundColor: hexToRGB(palettes.dark[0], 0.2),
		borderRadius: 9999,
		padding: 7,
		height: 28,
		width: 28,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	image: {
		height: '100%',
		width: '100%',
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
	},
});
