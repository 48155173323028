import { useMutation } from "@apollo/client"
import { ADD_APP_CONFIG } from "../../graphql/config/mutation"


type ValueInput =  {
    value1: string
    value2: string
    value3?: string
  }
type TQueryArgs = {
    key: string
    description?: string
    value: ValueInput
}
type THookProps = {
    refetched?:any[]
    onCompleted: () => void
}
export const useAddAppConfg = ({ onCompleted,refetched=[] }: THookProps) => {
    const [addAppConfig_, { loading }] = useMutation(ADD_APP_CONFIG)

    const addAppConfig = ({ key, description, value }: TQueryArgs) => {
        addAppConfig_({
            variables: {
                key,
                value,
                description
            },
            awaitRefetchQueries:true,
            refetchQueries:refetched,

            onCompleted: () => onCompleted()
        })
    }

    return { addAppConfig, loading }
}