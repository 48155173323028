import React from 'react';
import { StyleSheet, View } from 'react-native';
import { SectionHeading } from '../..';
import { TAddress } from '../../../types/authTypes';
import { TStyle } from '../../../types/TStyle';
import { AcceptOrReject } from '../../../types/userTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { isLastArrElement } from '../../../utils/isLastArrElement';
import NotAvailableMessage from '../../common/not-available-message/NotAvailableMessage';
import AddHostPlaceholder from './AddHostOrFollowerPlaceholder';
import Profile from './Profile';

const NumberOfProfiles = [0, 1, 2, 3, 4, 6, 7, 8, 9, 10];

export type TProfileData = {
	email: string;
	description: string;
	address: TAddress;
	profileImage: string;
	id:string;
};

type Props = {
	heading: string;
	profileToRender?: number;
	isBrandProfile?: boolean;
	hasControls?: boolean;
	time?: string;
	navigationDestination?: string;
	data?: TProfileData[];
	hasAcceptorReject?:boolean
	acceptOrRejectMutation?: (variables?: AcceptOrReject) => void;
};

const ProfileList = ({
	heading,
	profileToRender = 5,
	isBrandProfile = false,
	hasControls = false,
	time,
	acceptOrRejectMutation,
	hasAcceptorReject,
	navigationDestination = 'Dashboard',
	data,
}: Props) => {
	return (
		<View style={styles.container}>
			<SectionHeading
				heading={heading}
				hasViewAllLabel
				navigationDestination={navigationDestination}
			/>
			{/* <AddHostPlaceholder /> */}
			{data !== undefined ? (
				data.length > 0 ? (
					data.map((profileItem, index) => (
						<Profile
							key={profileItem?.email + index}
							name={profileItem?.email}
							profileImage={profileItem?.profileImage}
							isBrandProfile={false}
							time={time}
							id={profileItem?.id}
							hasAcceptorReject={hasAcceptorReject}
							acceptOrRejectMutation={acceptOrRejectMutation}
							country={profileItem?.address?.countryLabel}
							city={profileItem?.address?.city}
							containerStyles={styleProfileContainer(
								index,
								profileToRender
							)}
						/>
					))
				) : (
					// @TODO: Create Placeholder
					<View><NotAvailableMessage message={`No ${heading}.`}/></View>
				)
			) : null}
		</View>
	);
};

const styleProfileContainer = (
	index: number,
	profileToRender: number
): TStyle => {
	return {
		marginBottom: isLastArrElement(
			NumberOfProfiles.slice(0, profileToRender),
			index
		)
			? 0
			: 16,
	};
};

const styles = StyleSheet.create({
	container: {
		// marginTop: getResponsiveStyle(54),
	},
});

export default ProfileList;
