import { Message } from '../types/types';

export const updateOneChat = (prevMessage: any, messagesInfo: Message) => {
	const currentChatExist = prevMessage.find(
		(message: Message) => message.sender === messagesInfo.sender
	);
	if (currentChatExist) {
		return [...prevMessage, messagesInfo];
	} else {
		return [...prevMessage];
	}
};
