import { gql } from '@apollo/client';

const CREATE_PRODUCT = gql`
	mutation createProduct($args: ProductInputs!) {
		createProduct(args: $args) {
			statusCode
			success
			message
			data {
				__typename
			}
		}
	}
`;

const DELETE_PRODUCT = gql`
	mutation deleteProduct($_id: ID!) {
		deleteProduct(_id: $_id) {
			statusCode
			success
			message
			data {
				__typename
			}
		}
	}
`;

const EDIT_PRODUCT = gql`
	mutation editProduct($_id: ID!, $args: ProductInputs!) {
		editProduct(_id: $_id, args: $args) {
			statusCode
			success
			message
			data {
				__typename
			}
		}
	}
`;

const CREATE_STARTERKIT = gql`
	mutation createStarterKit($args: StarterKitInputs!) {
		createStarterKit(args: $args) {
			statusCode
			success
			message
			data {
				__typename
			}
		}
	}
`;

export { CREATE_PRODUCT, CREATE_STARTERKIT, EDIT_PRODUCT, DELETE_PRODUCT };
