import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { CREATE_NEW_PASSWORD } from '../../graphql/auth/mutations';
import { TNewPasswordResponse } from '../../types/authTypes';
import { TUseNavigation } from '../../types/types';
const useCreateNewPassword = () => {
	const navigation = useNavigation<TUseNavigation>();
	const [createNewPassword] = useMutation<TNewPasswordResponse>(
		CREATE_NEW_PASSWORD,
		{
			onCompleted: data => {
				if (
					data.newPassword.success &&
					data.newPassword.statusCode === 200
				) {
					navigation.navigate('Login');
				}
			},
		}
	);

	const executeNewPasswordMutation = (password: string, token: string) => {
		createNewPassword({
			variables: {
				password,
				token,
			},
		});
	};
	return { executeNewPasswordMutation };
};

export default useCreateNewPassword;
