import { gql } from '@apollo/client';
import {
	USER_RESPONSE_FRAGMENT,
	GET_ALL_USER_FRAGMENT,
	USER_STAT_FRAGMENT,
	GET_USER_FRAGMENT,
} from './fragments';

export const GET_USER = gql`
	${USER_RESPONSE_FRAGMENT}

	query getUser {
		getUser {
			...UserResponseFragment
		}
	}
`;
export const CHECK_USERNAME_EMAIL_AVAILABILITY = gql`
	query checkUsernameEmailAvailability(
		$data: CheckUsernameEmailAvailability
	) {
		checkUsernameEmailAvailability(data: $data) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;
export const GET_USER_BY_ID = gql`
	${USER_RESPONSE_FRAGMENT}
	query getUserById($_id: ID!) {
		getUserById(_id: $_id) {
			...UserResponseFragment
		}
	}
`;
export const LOGOUT = gql`
	${USER_RESPONSE_FRAGMENT}
	query logout {
		logout {
			...UserResponseFragment
		}
	}
`;

export const GET_ALL_SELLERS = gql`
	${GET_ALL_USER_FRAGMENT}

	query getAllUsers(
		$find: UserQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllUsers(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			...GetAllUsersFragment
		}
	}
`;
export const GET_ALL_HOSTS = gql`
	${GET_ALL_USER_FRAGMENT}

	query getAllUsers(
		$find: UserQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllUsers(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			...GetAllUsersFragment
		}
	}
`;
export const GET_USER_STAT = gql`
	${USER_STAT_FRAGMENT}
	query getAllUsers(
		$find: UserQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllUsers(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			...UserStatFragment
		}
	}
`;

export const GET_ALL_CONSUMERS = gql`
	${GET_ALL_USER_FRAGMENT}

	query getAllUsers(
		$find: UserQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllUsers(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			...GetAllUsersFragment
		}
	}
`;
export const GET_ALL_USERS = gql`
	${GET_ALL_USER_FRAGMENT}

	query getAllUsers(
		$find: UserQueryParam
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
	) {
		getAllUsers(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			...GetAllUsersFragment
		}
	}
`;

export const GET_USER_METRICS_CHANGE = gql`
	query getUserMetricsPercentageChange($typeOfAccount: TypeOfAccount) {
		getUserMetricsPercentageChange(typeOfAccount: $typeOfAccount) {
			statusCode
			success
			message
			data {
				... on UserMetricsResponse {
					allUsers
					activeUsers
					inactiveUsers
					newRequests
				}
			}
		}
	}
`;
export const GET_USER_TABLE_STATS = gql`
	query getUserStats($id: [ID!]) {
		getUserStats(userIds: $id) {
			statusCode
			success
			message
			data {
				... on StatUserResponseData {
					total
					data {
						... on StatUserResponseEach {
							userId
							productsCount
							eventsCount
							followersCount
						}
					}
				}
			}
		}
	}
`;

export const GET_USER_STATS = gql`
	query getUserDashboardStats($_id: ID) {
		getUserDashboardStats(_id: $_id) {
			statusCode
			data {
				... on UserDashboardStats {
					eventsCount
					publishedEventsCount
					followersCount
					attendedEventsCount
					eventsInvitedCount
				}
			}
		}
	}
`;

export const GET_PENDING_SELLERS = gql`
	query getAllUsers($sort: JSON, $populate: JSON) {
		getAllUsers(
			current: 0
			limit: 4
			find: { profileStatus: WAITING, typeOfAccount: SELLER }
			sort: $sort
			populate: $populate
		) {
			statusCode
			success
			message
			data {
				... on All {
					total
					data {
						... on User {
							_id
							username
						}
					}
				}
			}
		}
	}
`;

export const GET_PENDING_HOSTS = gql`
	${GET_ALL_USER_FRAGMENT}
	query getAllUsers($sort: JSON, $populate: JSON) {
		getAllUsers(
			current: 0
			limit: 4
			find: { profileStatus: WAITING, typeOfAccount: HOST }
			sort: $sort
			populate: $populate
		) {
			...GetAllUsersFragment
		}
	}
`;

export const GET_ADMIN_BALANCE = gql`
	query getAdminBalance {
		getAdminBalance {
			statusCode
			success
			data {
				... on AdminBalances {
					totalAdminEarnings
					totalAdminWithdrawals
					adminBalance
				}
			}
		}
	}
`;

export const GET_BALANCE_ON_STRIPE = gql`
	query getAdminBalanceOnStripe {
		getAdminBalanceOnStripe {
			statusCode
			data {
				... on ConnectBalance {
					available {
						amount
						currency
						source_types {
							card
							bank_account
						}
					}
					pending {
						amount
						currency
						source_types {
							card
							bank_account
						}
					}
				}
			}
		}
	}
`;
