import { gql } from '@apollo/client';
export const GET_ORDERS = gql`
	query getAllOrders($find: JSON, $current: Int, $limit: Int, $sort: JSON) {
		getAllOrders(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: [
				"associatedUser"
				{
					path: "items"
					model: "Item"
					populate: {
						path: "variant"
						model: "Variant"
						populate: [
							{ path: "associatedProduct", model: "Product" }
						]
					}
				}
				{
					path: "items"
					model: "Item"
					populate: { path: "starterKit", model: "StarterKit" }
				}
				"brands"
			]
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						data {
							... on Order {
								_id
								paidWith
								purchasedTotalAmount
								orderStatus
								deliveryFee
								associatedUser {
									_id
									username
								}
								brands{
									_id
									name
								}
								orderId
								purchasedOn
								paymentStatus

								items {
									optionValue
									variant {
										_id
										variator
										value
										vat
										option {
											name
											values {
												name
												price
												compareAtPrice
											}
											inventory {
												quantity
											}
										}
										image {
											alt
											src
										}

										associatedProduct {
											_id
											name
											description
											category
											shortDescription
											publishedStatus
											brand {
												name
												_id
												connectAccountInfo {
													connectAccountId
													lastUrl
												}
											}
											images {
												name
												alt
												src
												type
											}
										}
									}
									starterKit {
										_id
										name

										description
										price
										compareAtPrice
									}
									itemType
									quantity
									affiliatedSellerId
									affiliatedHost
									price
									vat
								}
							}
						}
					}
				}
			}
		}
	}
`;
export const GET_USER_ORDERS = gql`
	query getAllUsersOrders(
		$id: ID
		$find: JSON
		$current: Int
		$limit: Int
		$sort: JSON
	) {
		getAllUserOrders(
			_id: $id
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: [
				"associatedUser"
				{
					path: "items"
					model: "Item"
					populate: {
						path: "variant"
						model: "Variant"
						populate: [
							{ path: "associatedProduct", model: "Product" }
						]
					}
				}
				{
					path: "items"
					model: "Item"
					populate: { path: "starterKit", model: "StarterKit" }
				}
				"brands"
			]
		) {
			statusCode
			success
			message
			data {
				... on All {
					total
					data {
						... on Order {
							_id
							paidWith
							purchasedTotalAmount
							orderStatus
							deliveryFee
							associatedUser {
								_id
								username
							}
							brands{
								_id
								name
							}
							orderId
							purchasedOn

							items {
								optionValue
								variant {
									_id
									variator
									value
									vat
									option {
										name
										values {
											name
											price
											compareAtPrice
										}
										inventory {
											quantity
										}
									}
									image {
										alt
										src
									}

									associatedProduct {
										_id
										name
										description
										category
										shortDescription
										publishedStatus
										brand {
											name
											_id
											connectAccountInfo {
												connectAccountId
												lastUrl
											}
										}
										images {
											name
											alt
											src
											type
										}
									}
								}
								starterKit {
									_id
									name

									description
									price
									compareAtPrice
								}
								itemType
								quantity
								affiliatedSellerId
								affiliatedHost
								price
								vat
							}
						}
					}
				}
			}
		}
	}
`;
