import {
	ParamListBase,
	RouteConfig,
	StackNavigationState
} from '@react-navigation/native';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { NativeStackNavigationEventMap } from '@react-navigation/native-stack/lib/typescript/src/types';
import AccountsScreen from '../screens/AccountsScreen';
import AddBrandScreen from '../screens/AddBrandScreen';
import AllBrandsScreen from '../screens/AllBrandsScreen';
import AllCustomersScreen from '../screens/AllCustomersScreen';
import AllEventsScreen from '../screens/AllEventsScreen';
import AllHostsScreen from '../screens/AllHostsScreen';
import AllOrdersScreen from '../screens/AllOrdersScreen';
import AllSellersScreen from '../screens/AllSellersScreen';
import BrandDetailsScreen from '../screens/BrandDetailsScreen';
import BrandRequestsScreen from '../screens/BrandsRequestsScreen';
import BrandsScreen from '../screens/BrandsScreen';
import GuestsScreen from '../screens/CustomersScreen';
import DashboardScreen from '../screens/DashboardScreen';
import EventDescriptionScreen from '../screens/eventDescription';
import EventsScreen from '../screens/EventsScreen';
import ForgotPassword from '../screens/ForgotPassword';
import HostScreen from '../screens/HostScreen';
import HostsRequestScreen from '../screens/HostsRequestScreen';
import LoginScreen from '../screens/LoginScreen';
import ProductsScreen from '../screens/ProductsScreen';
import ProductsSingleUploadScreen from '../screens/ProductsSingleUploadScreen';
import ProductsSingleUploadScreenOld from '../screens/ProductsSingleUploadScreenOld';
import ProductUploadCSVScreen from '../screens/ProductUploadCSVScreen';
import ProfileScreen from '../screens/ProfileScreen';
import ResetPassword from '../screens/ResetPassword';
import SellersRequestsScreen from '../screens/SellersRequestsScreen';
import SellersScreen from '../screens/SellersScreen';
import SignupScreen from '../screens/SignupScreen';
import SingleOrderScreen from '../screens/SingleOrderScreen';
import UpcomingEventsScreen from '../screens/UpcomingEventsScreen';
import DeliveryScreen from '../screens/DeliveryScreen';
import { TRootStackParamList } from '../types/types';
import TagScreen from '../screens/TagsScreen';
import TagForm from '../components/tags/tagsForm';

type TNavigationScreensConfig = RouteConfig<
	TRootStackParamList,
	keyof TRootStackParamList,
	StackNavigationState<ParamListBase>,
	NativeStackNavigationOptions,
	NativeStackNavigationEventMap
> & {
	protectedRoute?: boolean;
	isShowForBrandAdmin?: boolean;
};

export const ScreenConfig: TNavigationScreensConfig[] = [
	{
		name: 'Login',
		options: { headerShown: false },
		component: LoginScreen,
		protectedRoute: false,
	},
	{
		name: 'Signup',
		options: { headerShown: false },
		component: SignupScreen,
		protectedRoute: false
	},
	{
		name: 'ResetPassword',
		options: { headerShown: false },
		component: ResetPassword,
		protectedRoute: false,
	},
	{
		name: 'ForgotPassword',
		options: { headerShown: false },
		component: ForgotPassword,
		protectedRoute: false,
	},
	{
		name: 'Dashboard',
		component: DashboardScreen,
		options: { headerShown: false },
	},
	{
		name: 'Events',
		options: { headerShown: false },
		component: EventsScreen,
	},
	{
		name: 'Brands',
		options: { headerShown: false },
		component: BrandsScreen,
		isShowForBrandAdmin: true,
	},
	{
		name: 'BrandDetails',
		options: { headerShown: false },
		component: AddBrandScreen,
		isShowForBrandAdmin: true,
	},
	{
		name: 'AllEvents',
		options: { headerShown: false },
		component: AllEventsScreen,
	},
	{
		name: 'UpcomingEvents',
		options: { headerShown: false },
		component: UpcomingEventsScreen,
	},

	{
		name: 'AllBrands',
		options: { headerShown: false },
		component: AllBrandsScreen,
	},
	{
		name: 'BrandRequests',
		options: { headerShown: false },
		component: BrandRequestsScreen,
	},
	{
		name: "EventDescription",
		options: { headerShown: false },
		component: EventDescriptionScreen

	},
	{
		name: 'Host',
		options: { headerShown: false },
		component: HostScreen,
	},
	{
		name: 'AllHosts',
		options: { headerShown: false },
		component: AllHostsScreen,
	},
	{
		name: 'HostsRequests',
		options: { headerShown: false },
		component: HostsRequestScreen,
	},
	{
		name: 'Sellers',
		options: { headerShown: false },
		component: SellersScreen,
	},
	{
		name: 'AllSellers',
		options: { headerShown: false },
		component: AllSellersScreen,
	},
	{
		name: 'SellersRequests',
		options: { headerShown: false },
		component: SellersRequestsScreen,
	},
	{
		name: 'Guests',
		options: { headerShown: false },
		component: GuestsScreen,
	},
	{
		name: 'AllOrders',
		options: { headerShown: false },
		component: AllOrdersScreen

	},
	{
		name: 'AllCustomers',
		options: { headerShown: false },
		component: AllCustomersScreen,
	},
	{
		name: 'Products',
		options: { headerShown: false },
		component: ProductsScreen,
		isShowForBrandAdmin: true,
	},
	{
		name: 'ProductsUploadCSV',
		options: { headerShown: false },
		component: ProductUploadCSVScreen,
		isShowForBrandAdmin: true,
	},
	{
		name: 'ProductsSingleUpload',
		options: { headerShown: false },
		component: ProductsSingleUploadScreen,
		isShowForBrandAdmin: true,
	},
	{
		name: 'ProductsSingleUploadOld',
		options: { headerShown: false },
		component: ProductsSingleUploadScreenOld,
		isShowForBrandAdmin: true,
	},
	{
		name: 'Accounts',
		options: { headerShown: false },
		component: AccountsScreen,
	},
	{
		name: 'Delivery',
		options: { headerShown: false },
		component: DeliveryScreen,
	},
	{
		name: 'OrderDetails',
		options: { headerShown: false },
		component: SingleOrderScreen
	},
	{
		name: 'UserProfile',
		options: { headerShown: false },
		component: ProfileScreen
	},

	{
		name: 'BrandProfile',
		options: { headerShown: false },
		component: BrandDetailsScreen,
		isShowForBrandAdmin: true,
	},
	{
		name: "Tags",
		options: { headerShown: false },
		component: TagScreen
	},
	{
		name: "tagForm",
		options: { headerShown: false },
		component: TagForm
	},
];
