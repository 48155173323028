import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_DISCOUNTED_PRODUCTS } from '../../graphql/product/queries';
import { TGetAllProductFind } from '../../types/productTypes';

const useGetDiscountedProducts = () => {
	const [getDiscountedProducts, { data, error, loading }] = useLazyQuery(
		GET_DISCOUNTED_PRODUCTS
	);

	const executeDiscountProductQuery = () => {
		getDiscountedProducts({
			variables: {
				populate: ['images'],
			},
		});
	};
	return { executeDiscountProductQuery, data, error, loading };
};

export default useGetDiscountedProducts;
