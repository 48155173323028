import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { CREATE_PASSWORD_LINK } from '../../graphql/auth/mutations';
import { TCreatePasswordLinkResponse } from '../../types/authTypes';
import { TUseNavigation } from '../../types/types';

const useCreatePasswordLink = () => {
	const navigation = useNavigation<TUseNavigation>();

	const [createPasswordLink] = useMutation<TCreatePasswordLinkResponse>(
		CREATE_PASSWORD_LINK,
		{
			onCompleted: data => {
				if (
					data.createPasswordLink.statusCode === 200 &&
					data.createPasswordLink.success
				) {
					navigation.navigate('ResetPassword');
				}
			},
		}
	);

	const executeCreatePasswordMutation = (email: string) => {
		createPasswordLink({
			variables: {
				email,
			},
		});
	};
	return { executeCreatePasswordMutation };
};

export default useCreatePasswordLink;
