import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TInitialState = {
	open: boolean;
};

const initialState: TInitialState = {
	open: false,
};

const addUserSlice = createSlice({
	name: 'addUser',
	initialState,
	reducers: {
		toggleAddUserModal(state) {
			state.open = !state.open;
		},
	},
});

export const { toggleAddUserModal } = addUserSlice.actions;

export default addUserSlice.reducer;
