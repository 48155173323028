import { useMutation } from '@apollo/client';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { DELETE_APP_CONFIG } from '../../graphql/config/mutation';
import { GET_TAGS } from '../../graphql/config/queries';
import { useSetAlert } from '../../hooks/useSetAlerts';
import Button from '../common/button/Button';
import HeadingLarge from '../common/heading-large/HeadingLarge';
type Props = {
	keyValue: string;
	setModalState?: (value: boolean) => void;
};
const TagDeletion = ({ keyValue, setModalState }: Props) => {
	const { setAlert } = useSetAlert();
	const [deleteKey, { loading, data }] = useMutation(DELETE_APP_CONFIG);
	const onPressDelete = (key: string) => {
		deleteKey({
			variables: { key: key },
			awaitRefetchQueries: true,
			refetchQueries: [GET_TAGS],
			onCompleted: data => {
				if (
					data &&
					data.deleteAppConfig &&
					data.deleteAppConfig.success
				) {
					setAlert('Deletion Successful', 'normal');
					setModalState && setModalState(false);
				} else {
					setAlert('Error! Please try again later', 'normal');
					setModalState && setModalState(false);
				}
			},
			onError: err => {
				setAlert('Error! Please try again later', 'normal');
				setModalState && setModalState(false);
			},
		});
	};
	return (
		<>
			<HeadingLarge
				heading="Confirm Tag Deletion"
				text={'This cannot be undone!'}
			/>
			<View >
				<Button
					title={'Confirm'}
					onPress={() => onPressDelete(keyValue)}
					loading={loading}
				/>
				<Button
					title={'Cancel'}
					variant={loading ? 'disabled' : 'outline'}
					onPress={() => {
						setModalState && setModalState(false);
					}}
				/>
			</View>
		</>
	);
};
const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
});
export default TagDeletion;
