import { gql } from '@apollo/client';
export const ACCEPT_OR_REJECT = gql`
	mutation acceptOrRejectUser($id: ID!, $status: ProfileStatus!) {
		acceptOrRejectUser(id: $id, status: $status) {
			statusCode
			success
			message
		}
	}
`;
