import React from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import { RenderBadgeItemPropsInterface } from 'react-native-dropdown-picker';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { CrossIcon } from '../icons';

type Props = {
	onPress?: (value: string) => void;
	isLastItem?: boolean;
} & Partial<RenderBadgeItemPropsInterface>;
const primary = palettes.primary;
const Badge = ({ isLastItem = false, ...props }: Props) => {
	return (
		<Pressable
			style={[
				styleContainer(primary),
				styles.container,
				!isLastItem && {
					marginBottom: getResponsiveStyle(8),
					marginRight: getResponsiveStyle(8),
				},
			]}
			onPress={() => props.onPress && props.onPress(props.value ?? '')}
		>
			<Text numberOfLines={1} style={[styleText(), styles.text]}>
				{props.label}
			</Text>
			<CrossIcon style={styleCrossIcon()} viewBox="0 0 24 24" />
		</Pressable>
	);
};

export default Badge;

function styleCrossIcon() {
	return {
		height: getResponsiveStyle(20, 'dimensions'),
		width: getResponsiveStyle(20, 'dimensions'),
		right: getResponsiveStyle(5),
	};
}

function styleText() {
	return {
		fontSize: getResponsiveStyle(14),
		lineHeight: getResponsiveStyle(18),
		marginRight: getResponsiveStyle(6),
	};
}

function styleContainer(primary: string[]): TStyle {
	return {
		paddingLeft: getResponsiveStyle(11),
		paddingRight: getResponsiveStyle(8),
		paddingVertical: 8,
		backgroundColor: `${primary[0]}20`,
	};
}

const styles = StyleSheet.create({
	text: {
		color: palettes.dark[0],
	},
	container: {
		position: 'relative',
		borderRadius: 6,
		flexDirection: 'row',
		alignItems: 'center',
		maxWidth: 'max-content',
	},
});
