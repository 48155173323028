import { gql } from "@apollo/client";

export const CREATE_BRAND_CONNECT_ACCOUNT = gql`
    mutation createBrandConnectAccount($args: CreatCustomerInputs){
        createBrandConnectAccount(args:$args) {
            statusCode
            success
            data{
                ... on CreateConnect{
                    id
                    type
                }
            }
        }
    }
`

export const ONBOARD_ACCOUNT = gql`
	mutation accountOnBoarding($args: AccountOnBoardingInputs!) {
		accountOnBoarding(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on AccountLink {
						url
					}
				}
			}
		}
	}
`;


export const CREATE_BANK = gql`
	mutation createBank($args: CreateBankInputs!) {
		createBank(args: $args) {
			... on Response {
				statusCode
				success
				message
				data{
					... on CreateBank{
						id
						account
					}
				}
			}
		}
	}
`;

export const DELETE_BANK = gql`
	mutation deleteBank($args: DeleteBankInputs!) {
		deleteBank(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;

export const CREATE_BANK_TOKEN = gql`
	mutation createBankToken($args: CreateBankTokenInputs!) {
		createBankToken(args: $args) {
			... on Response {
				statusCode
				success
				message

				data {
					... on BankToken {
						id
						used
					}
				}
			}
		}
	}
`;

export const CREATE_PAYOUT = gql`
	mutation createPayout($args: CreatePayoutInputs!) {
		createPayout(args: $args) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;