import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Image, Pressable, StyleSheet, Text, View } from 'react-native';
import styled from 'rn-css';
import useLogout from '../../../api/auth/useLogout';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle, TStyleImage } from '../../../types/TStyle';
import { TUseNavigation } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Profile from '../../dashboard/profile-list/Profile';
import { HorizontalDivider } from '../horizontal-divider';
import ChevronDown from '../icons/ChevronDownIcon';
import { useAppSelector } from '../../../redux/store';
import ModalComponent from '../modal/ModalComponent';
import AdminWithdrawForm from '../../withdrawForm/AdminWithdrawForm';

const ProfileIconWithDropdown = () => {
	const isUserLoggedIn = useAppSelector(state => state.auth.isUserLoggedIn);
	const loggedInUserDetails = useAppSelector(
		state => state.auth.loggedInUserDetails
	);
	const [modalState, setModalState] = useState(false)

	const { executeLogoutQuery } = useLogout();
	const navigator = useNavigation<TUseNavigation>();
	const { isLessThanDesktopBase, isDesktop } = useIsSpecificViewportWidth();

	const [open, setOpen] = useState(false);

	const onClickProfile = () => {
		navigator.navigate('Login');
	};

	const onClickLogout = () => {
		executeLogoutQuery();
	};

	const profileImage =
		loggedInUserDetails?.personalInformation?.profileImageLink;

	return (
		<>
			<ClickAwayListener onClickAway={() => setOpen(false)}>
				<View
					style={[
						styles.container,
						styleContainer(isLessThanDesktopBase),
					]}
				>
					<Pressable
						style={{ flexDirection: 'row', alignItems: 'center' }}
						onPress={() => setOpen(prev => !prev)}
					>
						<Image
							source={
								profileImage
									? {
										uri: profileImage,
									}
									: require('../../../assets/defaultProfileImage.svg')
							}
							style={[styleProfileIcon(), { borderRadius: 9999 }]}
						/>
						{isDesktop && (
							<ChevronDown
								onPress={() => setOpen(prev => !prev)}
								style={styleChevronIcon()}
							/>
						)}
					</Pressable>
					{open && (
						<Pressable
							style={[
								styles.dropdownContainer,
								styleDropdownContainer(isLessThanDesktopBase),
							]}
							onPress={() => setOpen(false)}
						>
							<View style={[styleProfileContainer()]}>
								{isUserLoggedIn && loggedInUserDetails ? (
									<Profile
										name={`${loggedInUserDetails?.personalInformation?.firstName} ${loggedInUserDetails?.personalInformation?.lastName}`}
										profileImage={
											profileImage ??
											require('../../../assets/defaultProfileImage.svg')
										}
										hasAccountVerified={
											loggedInUserDetails?.isVerified
										}
										city={
											loggedInUserDetails?.personalInformation
												?.address?.city
										}
										country={
											loggedInUserDetails?.personalInformation
												?.address?.countryCode
										}
										withdrawable
										setModalState = {setModalState}
									/>
								) : (
									<Profile
										name={'Brooklyn Simmons'}
										profileImage={require('../../../assets/defaultProfileImage.svg')}
										hasAccountVerified
									/>
								)}
							</View>
							<HorizontalDivider
								paddingBottom={getResponsiveStyle(18)}
							/>
							<View style={[styleLinkContainer()]}>
								{/* <ProfileText onPress={onClickProfile}>
								{'Profile'}
							</ProfileText> */}
								<LogoutText onPress={onClickLogout}>
									{'Logout'}
								</LogoutText>
							</View>
						</Pressable>
					)}
				</View>
			</ClickAwayListener>

			<ModalComponent
				header="Admin withdrawal"
				isScrollable
				openState={modalState}
				onClose={() => {
					setModalState(false);
				}}
			>

				<AdminWithdrawForm
					onSubmit={() => { }}
					bankid={'_id'}
					setModalState={setModalState}
				// option={option}
				// setOption={setOption}
				// setAccounts = {setAccounts}
				// accounts = {accounts}
				/>
				{/* } */}

			</ModalComponent>
		</>
	);
};

const PressableLink = styled(Text)`
	cursor: pointer;
	font-weight: 600;
	font-size: ${getResponsiveStyle(16, 'font')}px;
	line-height: ${getResponsiveStyle(16, 'font')}px;
	padding-left: ${getResponsiveStyle(24)}px;
	padding-right: ${getResponsiveStyle(24)}px;
	height: ${getResponsiveStyle(40, 'dimensions')}px;
	display: flex;
	align-items: center;
	&:hover {
		background: ${palettes.grey[6]};
	}
`;

const ProfileText = styled(PressableLink)`
	color: ${palettes.dark[0]};
`;

const LogoutText = styled(PressableLink)`
	color: ${palettes.red[0]};
`;

function styleLinkContainer() {
	return {
		paddingBottom: getResponsiveStyle(16),
	};
}

function styleProfileContainer() {
	return {
		padding: getResponsiveStyle(24),
	};
}

function styleDropdownContainer(isLessThanDesktopBase: boolean): TStyle {
	return {
		minHeight: getResponsiveStyle(224, 'dimensions'),
		top: getResponsiveStyle(60, 'dimensions'),
		left: isLessThanDesktopBase ? 0 : 'auto',
		right: !isLessThanDesktopBase ? 0 : 'auto',
		width: getResponsiveStyle(300, 'dimensions'),
	};
}

function styleProfileIcon(): TStyleImage {
	return {
		height: getResponsiveStyle(48, 'dimensions'),
		width: getResponsiveStyle(48, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleContainer(isLessThanDesktopBase: boolean): TStyle {
	return {
		marginLeft: isLessThanDesktopBase ? 0 : getResponsiveStyle(24),
	};
}

function styleChevronIcon() {
	return {
		width: getResponsiveStyle(8, 'dimensions'),
		height: getResponsiveStyle(6, 'dimensions'),
	};
}

export default ProfileIconWithDropdown;

const styles = StyleSheet.create({
	container: {
		position: 'relative',
	},
	dropdownContainer: {
		height: 'fit-content',
		position: 'absolute',
		zIndex: 9999,
		backgroundColor: 'white',
		borderWidth: 1,
		borderColor: palettes.grey[5],
		shadowOffset: {
			width: 0,
			height: 30,
		},
		shadowRadius: 40,
		shadowColor: 'rgba(124, 136, 155, 0.18)',
		shadowOpacity: 1,
		borderRadius: 6,
	},
});
