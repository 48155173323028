import { View, Text } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { GoBackButton } from '../components';
import ScrollTabs from '../components/common/scroll-tabs/ScrollTabs';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import AllEventsSection from '../components/common/all-events-section/AllEventsSection';
import { TGetAllEventsData, TGetAllEventsResponse } from '../types/eventTypes';
import { Get_ALL_EVENTS } from '../graphql/events/queries';
import { useLazyQuery } from '@apollo/client';
import { dateFns } from '../utils/dateFns';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import Loader from '../components/common/loader/Loader';
const SECTION_TABS: string[] = [
	'All Events',
	// 'Best Selling Event',
	// 'Feature Events',
	'New Events',
	// 'Re-Scheduled Events',
];
const AllEventsScreen = () => {
	const [tabSelected, setTabSelected] = useState(SECTION_TABS[0]);

	const [tabEvents, setTabEvents] = useState<TGetAllEventsData[] | undefined>(
		[]
	);

	// all events
	const [getAllEvents, { loading: loadingGetAllEvents }] =
		useLazyQuery<TGetAllEventsResponse>(Get_ALL_EVENTS, {
			fetchPolicy: 'cache-and-network',
			onCompleted: (data: TGetAllEventsResponse) => {
				if (
					data.getAllEvents.data &&
					data.getAllEvents.success &&
					data.getAllEvents.data.data
				) {
					console.log(data.getAllEvents.data.data);
					setTabEvents(data.getAllEvents.data.data);
				} else {
					setTabEvents([]);
				}
			},
			onError: error => {
				setTabEvents([]);
			},
		});

	// all new events
	const [getAllNewEvents, { loading: loadingGetAllNewEvents }] =
		useLazyQuery<TGetAllEventsResponse>(Get_ALL_EVENTS, {
			fetchPolicy: 'cache-and-network',
			onCompleted: (data: TGetAllEventsResponse) => {
				if (
					data.getAllEvents.data &&
					data.getAllEvents.success &&
					data.getAllEvents.data.data
				) {
					setTabEvents(data.getAllEvents.data.data);
				} else {
					setTabEvents([]);
				}
			},
			onError: error => {
				error && setTabEvents([]);
			},
		});

	const onPressTab = (tab: string) => {
		setTabSelected(tab);
		if (tab === 'New Events') {
			getAllNewEvents({
				variables: {
					find: {
						startingEventDateTime: {
							$gte: new Date(),
							$lte: dateFns.addDays(7, new Date()),
						},
					},
				},
			});
		} else {
			getAllEvents({
				variables: {
					sort: { createdAt: -1 },
				}
			});
		}
	};

	useEffect(() => {
		getAllEvents({
			variables: {
				sort: { createdAt: -1 },
			}
		});
	}, []);

	const loadingUnifiedTabEvents = useMemo(
		() =>
			getSingleLoadingState(loadingGetAllEvents, loadingGetAllNewEvents),
		[loadingGetAllEvents, loadingGetAllNewEvents]
	);
	return (
		<DesktopTemplate>
			<GoBackButton history="Events" />
			<View
				style={{
					marginTop: getResponsiveStyle(32),
				}}
			>
				<ScrollTabs
					tabs={SECTION_TABS}
					onPress={onPressTab}
					selectedTab={tabSelected}
				/>
				<View
					style={{
						marginTop: getResponsiveStyle(24),
					}}
				>
					{loadingUnifiedTabEvents ? (
						<Loader />
					) : (
						tabEvents && <AllEventsSection data={tabEvents} />
					)}
				</View>
			</View>
		</DesktopTemplate>
	);
};

export default AllEventsScreen;
