import { useMutation } from "@apollo/client";
import { useState } from "react";
import { CREATE_BANK, CREATE_BANK_TOKEN, CREATE_PAYOUT } from "../../graphql/stripe/mutations";
import { LIST_BANKS } from "../../graphql/stripe/queries";
import { useSetAlert } from "../../hooks/useSetAlerts";

type TParams = {
    connectAccountId: string
    setCreateBankModalState: any
    amount: string
}

const useCreateBrandBank = ({ connectAccountId, setCreateBankModalState, amount }: TParams) => {
    const [tokenId, setTokenId] = useState('')
    const { setAlert } = useSetAlert()
    const [payout, { data, loading: payoutLoading }] = useMutation(CREATE_PAYOUT, {
		onCompleted: (data)=> {
			setCreateBankModalState(false)
			data?.createPayout?.success ? setAlert('Withdrawal successful', 'normal') :
			setAlert('Unable to complete withdrawal', 'danger')
		},
		onError: () => {
			setCreateBankModalState(false)
			setAlert('Unable to complete withdrawal', 'danger')
		}
	});
    const [createbanktoken, { loading: loadingBanktoken }] = useMutation(
        CREATE_BANK_TOKEN,
        {
            onCompleted: data => {
                if (data?.createBankToken?.data && data?.createBankToken?.success) {
                    setTokenId(data?.createBankToken?.data?.id);
                } else {
                    setCreateBankModalState(false)
                    setAlert('Unable to Create bank', 'normal');
                }
            },
            onError: () => {
                setCreateBankModalState(false)
                setAlert('Unable to Create bank', 'normal');
            },
        }
    );


    const [createBank, { loading: creatingBank, data: createBankData }] = useMutation(CREATE_BANK, {
        variables: {
            args: {
                id: connectAccountId,
                source: { external_account: tokenId },
            },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: LIST_BANKS,
                variables: {
                    args: {
                        id: connectAccountId,
                    },
                },
            },
        ],
        onCompleted: async data => {
            if (
                data?.createBank?.message === 'Bank Created' &&
                data?.createBank?.success
            ) {
                // setCreateBankModalState();
                // setAlert('Bank created successfully', 'normal');
                await payout({
                    variables: {
                        args: {
                            amount: parseFloat(amount) * 100,
                            currency: "eur",
                            destination: data?.createBank?.data?.id,
                            accountId: connectAccountId,
                        },
                    }
                })
            }
        },
        onError: () => {
            setCreateBankModalState();
            setAlert('Unable to Create Bank', 'danger');
        },
    });

    return { createbanktoken, createBank, creatingBank, createBankData, loadingBanktoken, payoutLoading }

}

export default useCreateBrandBank