import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { CREATE_STARTERKIT } from '../../graphql/product/mutation';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { TCreateStarterKit, TUseNavigation } from '../../types/types';
const useCreateStarterKit = () => {
	const { setAlert } = useSetAlert();
	const navigation = useNavigation<TUseNavigation>();
	const [createStarterKit, states] = useMutation(CREATE_STARTERKIT, {
		onCompleted: data => {
			if (data?.createStarterKit?.success) {
				setAlert('StarterKit Created Successfully', 'normal');
			} else {
				setAlert('Unable to create starterkit', 'danger');
			}
		},
		onError: () =>{
			setAlert('Unable to create starterkit', 'danger')
		}
	});

	const executeStarterKitMutation = (data: TCreateStarterKit) => {
		createStarterKit({
			variables: {
				args: data,
			},
		});
	};
	return { executeStarterKitMutation, ...states };
};

export default useCreateStarterKit;
