import { useWindowDimensions } from 'react-native';
import { breakpoints } from '../config';

export const useIsSpecificViewportWidth = () => {
	const { width, height } = useWindowDimensions();
	return {
		width,
		height,
		isTabletView:
			width >= breakpoints.tabletSm && width <= breakpoints.desktopBase,
		isMobileView: width <= breakpoints.mobile,
		isDesktop: width >= breakpoints.desktopBase,
		isLessThanDesktopBase: width < breakpoints.desktopBase,
		isLessThanDesktopMedium: width < breakpoints.desktopMd,
		isLessThanDesktopSmall: width < breakpoints.desktopSm,
	};
};
