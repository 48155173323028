import React from 'react';
import { Image } from 'react-native';

type Props = {
	countryCode?: string;
	size?: 20 | 40 | 80 | 160 | 320;
};

const Flag = ({ countryCode = 'uk', size = 20 }: Props) => {
	return (
		<Image
			source={{
				uri: `https://flagcdn.com/w${size}/${countryCode}.png`,
			}}
			style={{
				width: 16,
				height: 12,
				marginRight: 8,
			}}
		/>
	);
};

export default Flag;
