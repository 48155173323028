import { useLazyQuery } from '@apollo/client';
import { GET_SINGLE_BRAND } from '../../graphql/brands/queries';
import { TGetSingleBrandResponse } from '../../types/brandTypes';
import { TReturnUseApiHookLazyQuery } from '../../types/exportedTypes';
import { TOnCompleteApiCall } from '../../types/types';

type TGetSingleBrandArgs = {
	_id: string;
};

type Params =
	| {
			onCompleted?: TOnCompleteApiCall<TGetSingleBrandResponse>;
	  }
	| undefined;

type TReturn = {
	getSingleBrand: (_id: string) => void;
} & TReturnUseApiHookLazyQuery<TGetSingleBrandResponse, TGetSingleBrandArgs>;

export const useGetSingleBrandLazy: (params: Params) => TReturn = params => {
	const [executeQuery, states] = useLazyQuery<
		TGetSingleBrandResponse,
		TGetSingleBrandArgs
	>(GET_SINGLE_BRAND, {
		onCompleted: params?.onCompleted,
	});

	const getSingleBrand = (_id: string) => {
		executeQuery({
			variables: {
				_id,
			},
		});
	};

	return { getSingleBrand, ...states };
};
