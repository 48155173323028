import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { useIsSpecificViewportWidth } from "../../../hooks/useIsSpecificViewportWidth";
import { getResponsiveStyle } from "../../../utils/getResponsiveStyle";
import { CategoriesDropdown } from "./CategoriesDropdown";
import { CategoryPillList } from "./CategoryPillList";

type Props = {
  secondLabel: string;
  dropdownCategories: TDropdownObject[];
  pillCategories: TDropdownObject[];
};

const FilterCategories = ({
  secondLabel,
  dropdownCategories: _dropdownCategories,
  pillCategories: _pillCategories,
}: Props) => {
  const { width } = useIsSpecificViewportWidth();
  
  const [dropdownCategories, setDropdownCategories] =
    useState<TDropdownObject[]>(_dropdownCategories);

  const [pillCategories, setPillCategories] =
    useState<TDropdownObject[]>(_pillCategories);

  const [dropdownDimensions, setDropdownDimensions] = useState<{
    dropdownWidth: number;
    dropdownLeft: number;
  } | null>(null);

  const [containerDimensions, setContainerDimensions] = useState<null | number>(
    null,
  );
  const [putCategoriesInDropdown, setPutCategoriesInDropdown] = useState(false);

  useEffect(() => {
    if (containerDimensions && dropdownDimensions) {
      const { dropdownLeft, dropdownWidth } = dropdownDimensions;
      const sumOfDimensions = dropdownLeft + dropdownWidth;
      Math.abs(containerDimensions - sumOfDimensions) > 20 &&
        setPutCategoriesInDropdown(true);
    }
  }, [
    width,
    dropdownDimensions?.dropdownLeft,
    dropdownDimensions?.dropdownWidth,
    containerDimensions,
  ]);

  useEffect(() => {
    setCategoriesResponsively(
      width,
      _pillCategories,
      _dropdownCategories,
      setDropdownCategories,
      setPillCategories,
      putCategoriesInDropdown,
    );
  }, [width, putCategoriesInDropdown]);

  return (
    <View
      style={[styles.container, styleContainer(width)]}
      onLayout={(event) => {
        setContainerDimensions(event.nativeEvent.layout.width);
      }}
    >
      <CategoryPillList categories={pillCategories} label={secondLabel} />
      <View
        onLayout={(event) => {
          setDropdownDimensions({
            dropdownLeft: event.nativeEvent.layout.x,
            dropdownWidth: event.nativeEvent.layout.width,
          });
        }}
      >
        <CategoriesDropdown
          categories={dropdownCategories}
          label={secondLabel}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 2,
    // flexWrap: "wrap",
  },
});

const styleContainer = (width: number) => {
  return {
    marginBottom: getResponsiveStyle(24),
  };
};

const setCategoriesResponsively = (
  width: number,
  pillCategories: TDropdownObject[],
  dropdownCategories: TDropdownObject[],
  setDropdownCategories: React.Dispatch<
    React.SetStateAction<TDropdownObject[]>
  >,
  setPillCategories: React.Dispatch<React.SetStateAction<TDropdownObject[]>>,
  putCategoriesInDropdown: boolean,
) => {
  if (width < 1280 || putCategoriesInDropdown) {
    setDropdownCategories([...pillCategories.slice(2), ...dropdownCategories]);
    setPillCategories([pillCategories[0]]);
  } else {
    setDropdownCategories(dropdownCategories);
    setPillCategories(pillCategories);
  }
};

export default FilterCategories;
