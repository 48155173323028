import React from 'react';
import { Image, StyleSheet } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { useAppSelector } from '../../../redux/store';
import { TCommonResponseData } from '../../../types/authTypes';
import { TODO } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
// import ContactImageMessage from './ContactImageMessage';
// import ContactVideoMessage from './ContactVideoMessage';

const ChatMessageRapper = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;

const ChatMessageText = styled.Text`
	position: relative;
	font-size: ${getResponsiveStyle(15, 'font')}px;
	background-color: #eff1f4;
	color: ${palettes.dark[0]};
	line-height: 1.375rem;
	width: max-content;
	max-width: 80%;
	margin: 5px;
	padding-top: 7px;
	padding-right: 10px;
	padding-left: 10px;
	padding-bottom: 7px;
	border-top-left-radius: 0;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
	border-bottom-left-radius: 25px;
`;

const ContactMessage = ({
	message,
	usersTyping,
	user,
}: {
	message: TODO;
	usersTyping?: string[];
	user?: TCommonResponseData;
}) => {
	// const MessageTickContainer = styled.View`
	// 	display: inline-block;
	// 	margin-left: 2px;
	// 	position: absolute;
	// 	bottom: 1.5px;
	// 	right: 6px;
	// 	height: 16px;
	// 	width: 16px;
	// `;
	const chat = useAppSelector(state => state.chat.chat);

	return (
		<>
			{(message.messageType === 'TEXT' ||
				message.messageType === 'LINKS') && (
				<ChatMessageRapper>
					{user ? (
						<Image
							style={styles.image}
							source={{
								uri: user?.personalInformation
									?.profileImageLink,
							}}
						/>
					) : (
						<Image
							style={styles.image}
							source={{
								uri:
									chat?.image ||
									'http://via.placeholder.com/500',
							}}
						/>
					)}
					<ChatMessageText>{message.message}</ChatMessageText>
				</ChatMessageRapper>
			)}
			{/* {message.messageType === 'IMAGE' && (
				<ContactImageMessage message={message} />
			)}
			{message.messageType === 'VIDEO' && (
				<ContactVideoMessage message={message} />
			)} */}
		</>
	);
};

const styles = StyleSheet.create({
	flex1: {
		flex: 1,
		flexDirection: 'row',
		marginTop: 15,
	},
	textContainer: {
		width: 'max-content',
		height: 35,
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 10,
		paddingLeft: 10,
		paddingRight: 10,
		backgroundColor: '#EFF1F4',
		borderTopLeftRadius: 0,
		borderTopRightRadius: 50,
		borderBottomRightRadius: 50,
		borderBottomLeftRadius: 50,
	},
	text: {
		fontSize: 15,
		width: 'max-content',
		fontWeight: 'normal',
		color: palettes.dark[0],
	},
	image: {
		width: 30,
		height: 30,
		borderRadius: 25,
	},
	container: {
		// borderWidth: 2,
		// borderColor: palettes.grey[4],
		height: 250,
		width: '100%',
		overflow: 'scroll',
	},
	dot: {
		width: 5,
		height: 5,
		borderRadius: 50,
		marginRight: 0.5,
		backgroundColor: palettes.dark[0],
	},
});

export default ContactMessage;
