import React from 'react';
import { StyleSheet, View } from 'react-native';
import { StarEmptyIcon, StarFilledIcon } from '../../common/icons';

type Props = {
	ratings: number;
	justifyContent?:
		| 'center'
		| 'flex-start'
		| 'flex-end'
		| 'space-between'
		| 'space-around'
		| 'space-evenly'
		| undefined;
	containerStyles?: {};
	width?: number;
	marginRight?: number;
	paddingBottom?: number;
	height?: number;
};

const Ratings = ({
	ratings,
	justifyContent = 'center',
	paddingBottom = 18,
	height = 14,
	width = 14.7,
	marginRight = 5,
}: Props) => {
	const getStarsToRender = (ratings: number) => {
		const emptyStarsToRender = 5 - ratings;
		const filledStars = Array(ratings).fill(
			<StarFilledIcon
				style={{
					height,
					width,
					marginRight,
				}}
			/>
		);
		const emptyStars = Array(emptyStarsToRender).fill(
			<StarEmptyIcon
				style={{
					height,
					width,
					marginRight,
				}}
			/>
		);
		const allStarsToRender = [...filledStars, ...emptyStars];
		return allStarsToRender;
	};

	return (
		<View
			style={[
				styles.container,
				{
					paddingBottom,
					justifyContent,
				},
			]}
		>
			{getStarsToRender(ratings).map(star => star)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		// justifyContent: "center",
		// paddingBottom: 18,
	},
	star: {
		height: 14,
		width: 14.7,
		marginRight: 5,
	},
});

export default Ratings;
