import { Image, ImageProps, Pressable } from 'react-native';
import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { TStyle } from '../../../types/TStyle';

type Props = {
	onPress?: () => void;
	containerStyles?: TStyle;
} & SvgProps;

const ChevronDown = ({ onPress, containerStyles, ...props }: Props) => {
	return (
		<Pressable
			style={[{ minWidth: 15 }, containerStyles]}
			onPress={onPress}
			pointerEvents={'none'}
		>
			<Svg {...props}>
				<Path
					fill="#98A2B2"
					stroke="#fff"
					strokeWidth={0.3}
					d="M4.35 2.935 7.07.212l1.203 1.202-4.031 4.03-4.03-4.03L1.414.212l2.722 2.723.106.106.106-.106z"
				/>
			</Svg>
		</Pressable>
	);
};

export default ChevronDown;
