import { useRoute } from '@react-navigation/native';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { View } from 'react-native';
import { HeadingLarge, Input } from '..';
import { useAddAppConfg } from '../../api/config/useAddAppConfig';
import { useEditAppConfig } from '../../api/config/useEditAppConfig';
import { GET_TAGS } from '../../graphql/config/queries';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { TAppConfig } from '../../types/appConfig';
import { TUseRoute } from '../../types/types';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../../utils/getSingleLoadingState';
import { manipulateString } from '../../utils/manipulateString';
import { initialValues } from '../auth/signup/FormikConstant';
import Button from '../common/button/Button';
import GoBackButton from '../common/go-back-button/GoBackButton';
import DesktopTemplate from '../common/templates/DesktopTemplate';
import { addTagInitialValues, addTagValidation } from './formikConstants';

const TagForm = () => {
	const { setAlert } = useSetAlert();
	const routes = useRoute<TUseRoute<'tagForm'>>();
	const { tag } = routes.params;
	const onCompleted = () => {
		setAlert(
			`${convertToTitle(tag)} has been ${
				tag?.value?.value3 ? 'edited' : 'created'
			}`,
			'normal'
		);
	};
	const refetched = [GET_TAGS];
	const { addAppConfig, loading } = useAddAppConfg({
		onCompleted,
		refetched,
	});
	const { editAppConfig, editAppConfigLoading } = useEditAppConfig({
		onCompleted: data => {
			if (data && data?.editAppConfig && data?.editAppConfig?.success) {
				setAlert(
					`${convertToTitle(tag)} has been ${
						tag?.value?.value3 ? 'edited' : 'created'
					}`,
					'normal'
				);
			} else {
				setAlert('There was an error.Please try again later', 'danger');
			}
		},
		refetched,
		onError: () => {},
	});
	const convertToTitle = (tag: TAppConfig) => {
		console.log(tag);
		return manipulateString.capitalize(
			String(tag?.key)
				.toLowerCase()
				.replaceAll('_', ' ')
				.replaceAll('-', ' ')
		);
	};
	const getTagType = (key: string, value: string) => {
		const transformedValue = value
			.toUpperCase()
			.replaceAll('-', '_')
			.trim();
		switch (key) {
			case 'Interest Tags':
				return 'INTEREST_TAG_' + transformedValue;
			case 'Product Categories':
				return 'PRODUCT_CATEGORY_' + transformedValue;
			case 'Brand Categories':
				return 'BRAND_CATEGORY_' + transformedValue;
			default:
				return transformedValue;
		}
	};
	const unifiedLoading = useMemo(
		() => getSingleLoadingState(loading, editAppConfigLoading),
		[loading, editAppConfigLoading]
	);

	return (
		<>
			<DesktopTemplate>
				<GoBackButton />
				<HeadingLarge heading={convertToTitle(tag)} />
				<View style={{ alignItems: 'center' }}>
					<View
						style={{ width: getResponsiveStyle(465, 'dimensions') }}
					>
						<Formik
							enableReinitialize
							initialValues={
								tag?.value?.value3 ? tag : addTagInitialValues
							}
							onSubmit={values => {
								const tagVal = {
									key: getTagType(
										tag?.key,
										values?.value?.value3
									),
									description: values.key,
									value: values.value,
								};

								tag?.value?.value3
									? editAppConfig(tagVal)
									: addAppConfig(tagVal);
							}}
							validationSchema={addTagValidation}
						>
							{({
								handleChange,
								handleBlur,
								setFieldValue,
								handleSubmit,
								touched,
								errors,
								values,
								dirty,
								isValid,
							}) => (
								<>
									<Input
										label="English Label *"
										onChangeText={handleChange(
											'value.value1'
										)}
										onBlur={handleBlur('value.value1')}
										value={values.value.value1}
										error={
											touched?.value &&
											errors?.value &&
											errors?.value?.value1
												? errors.value.value1
												: ''
										}
									/>
									<Input
										label="French Label *"
										onChangeText={handleChange(
											'value.value2'
										)}
										onBlur={handleBlur('value.value2')}
										value={values.value.value2}
										error={
											touched?.value &&
											errors?.value &&
											errors?.value?.value2
												? errors.value.value2
												: ''
										}
									/>
									<Input
										label="Value *"
										onChangeText={handleChange(
											'value.value3'
										)}
										onBlur={handleBlur('value.value3')}
										editable={
											tag?.value?.value3 ? false : true
										}
										value={values.value.value3}
										error={
											touched?.value &&
											errors?.value &&
											errors?.value?.value3
												? errors.value.value3
												: ''
										}
									/>
									<Button
										title={
											tag?.value?.value3
												? 'Edit ' + convertToTitle(tag)
												: 'Save ' + convertToTitle(tag)
										}
										onPress={handleSubmit}
										size="lg"
										loading={unifiedLoading}
										variant={
											dirty && touched && isValid
												? 'primary'
												: 'disabled'
										}
									/>
								</>
							)}
						</Formik>
					</View>
				</View>
			</DesktopTemplate>
		</>
	);
};
export default TagForm;
