import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type TStat = { stat: number | string; label: string };

type Props = {
	stats: TStat[];
};

export const HostStatCard = ({ stats }: Props) => {
	return (
	<>
	</>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		gap: getResponsiveStyle(33),
		width: '100%',
		flexWrap: 'wrap',
	},
	innerContainer: { flex: 1, alignItems: 'center' },
	stat: {
		color: palettes.dark[0],
		fontWeight: '600',
		textAlign: 'center',
	},
	label: {
		color: palettes.grey[0],
		textTransform: 'uppercase',
		textAlign: 'center',
	},
});

const styleLabel = () => {
	return {
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
	};
};

const styleStat = () => {
	return {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginBottom: getResponsiveStyle(7),
	};
};

function styleContainer() {
	return {
		paddingTop: getResponsiveStyle(27),
		// marginBottom: getResponsiveStyle(16),
	};
}
