import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { palettes } from "../../../config";
import { TStyle, TStyleInputOrText } from "../../../types/TStyle";
import { getResponsiveStyle } from "../../../utils/getResponsiveStyle";

type Props = {
  stat: number;
  label: string;
  containerStyles?: TStyle;
};

const CompanyCardStats = ({ label, stat, containerStyles }: Props) => {
  return (
    <View style={[styles.container, styleContainer(), containerStyles]}>
      <Text style={[styles.text, styles.stat, styleStat()]}>{stat}</Text>
      <Text style={[styles.text, styles.label, styleLabel()]}>{label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		borderBottomColor: palettes.grey[5],
		borderBottomWidth: 1,
	},
	text: {
		fontWeight: '400',
		color: palettes.dark[0],
		textAlign: 'center',
	},
	label: {
		color: palettes.grey[0],
		textTransform: 'uppercase',
	},
	stat: {
		fontWeight: '600',
	},
});

function styleContainer() {
  return {
    paddingBottom: getResponsiveStyle(11),
  };
}

function styleLabel() {
  return {
    fontSize: getResponsiveStyle(10, "font"),
    lineHeight: getResponsiveStyle(12, "font"),
  };
}

function styleStat(): TStyleInputOrText {
  return {
    fontSize: getResponsiveStyle(24, "font"),
    lineHeight: getResponsiveStyle(18, "font"),
    marginBottom: 7,
  };
}

export default CompanyCardStats;
