import { useAppDispatch } from '../redux/store';
import { TErrorVariant } from '../types/types';
import { setAlerts as dispatchAlert } from '../redux/uiSlice';
import { manipulateString } from '../utils/manipulateString';

export const useSetAlert = () => {
	const dispatch = useAppDispatch();
	const setAlert = (title: string, type: TErrorVariant) => {
		dispatch(
			dispatchAlert([
				{
					title: manipulateString.capitalize(title),
					type,
				},
			])
		);
	};
	return { setAlert };
};
