import React, { useState } from 'react';
import { Pressable, Text } from 'react-native';
import { Tabs, UploadCSVForm } from '../components';
import TableComponent from '../components/common/table-component/TableComponent';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { TTableHeader } from '../types/types';

const TABS = ['All Products', 'Uploaded', 'Error', 'Pending'];
const Headers: TTableHeader[] = [
	{ width: 350, headerLabel: 'Products' },
	{ width: 200, headerLabel: 'Inventory On' },
	{ width: 200, headerLabel: 'QTY' },
	{ width: 200, headerLabel: 'Base Total' },
	{ width: 200, headerLabel: 'Purchased Total' },
	{ width: 200, headerLabel: 'Status' },
];

const ProductUploadCSVScreen = () => {
	const [selectedTab, setSelectedTab] = useState(TABS[0]);

	return (
		<DesktopTemplate
			navigationBarProps={{
				hasGoBackButton: true,
			}}
		>
			<UploadCSVForm />
			<Tabs
				showDropdowns
				tabs={TABS}
				selectedTab={selectedTab}
				containerStyle={{
					justifyContent: 'flex-start',
				}}
				onPress={tab => {
					setSelectedTab(tab);
				}}
			/>
			<TableComponent showHeader={false} headers={Headers} />
		</DesktopTemplate>
	);
};

export default ProductUploadCSVScreen;
