import { gql } from '@apollo/client';
import { ADMIN_USER_FRAGMENT } from './fragments';

export const EDIT_ADMIN_ROLE = gql`
	${ADMIN_USER_FRAGMENT}
	mutation editAdminRole($_id: ID!, $role: SuperAdminRole!) {
		editAdminRole(_id: $_id, role: $role) {
			statusCode
			success
			message
			data {
				...AdminUserFragment
			}
		}
	}
`;

export const INVITE_ADMIN = gql`
	mutation inviteAdmin(
		$email: String!
		$firstName: String!
		$lastName: String
		$role: SuperAdminRole!
		$affiliatedBrand: String
	) {
		inviteAdmin(
			email: $email
			firstName: $firstName
			lastName: $lastName
			role: $role
			affiliatedBrand: $affiliatedBrand
		) {
			... on Response {
				statusCode
				success
				message
			}
		}
	}
`;
