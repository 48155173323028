import { Formik } from 'formik'
import React, { useEffect } from 'react'
import DesktopTemplate from '../components/common/templates/DesktopTemplate'
import * as yup from 'yup'
import { yupsRequiredString } from '../utils/yupsRequiredString'
import { View } from 'react-native'
import { Button, HeadingLarge, Input, Spacing } from '../components'
import { getResponsiveStyle } from '../utils/getResponsiveStyle'
import { useAddAppConfg } from '../api/config/useAddAppConfig'
import { useSetAlert } from '../hooks/useSetAlerts'
import { useGetAppConfg } from '../api/config/useGetAppConfig'
import { useEditAppConfig } from '../api/config/useEditAppConfig'
import { useNavigation } from '@react-navigation/native'
import { TUseNavigation } from '../types/types'

type TInitialValues = {
    deliveryFee: string;
    maxOrderValue: string
}
const DeliveryScreen = () => {

    const validationSchema = yup.object({
        deliveryFee: yupsRequiredString(),
        maxOrderValue: yupsRequiredString()
    })

    const { data, getAppConfig } = useGetAppConfg()
    useEffect(() => {
        getAppConfig({ key: "MAXIMUM_ELIGIBLE_FREE_SHIPPING_AMOUNT" })
    }, [])
    const initialDeliveryValue = data?.getAppConfig?.data?.value
    const initialValues: TInitialValues = {
        deliveryFee: initialDeliveryValue ? initialDeliveryValue.value1 : '',
        maxOrderValue: initialDeliveryValue ? initialDeliveryValue.value2 : ''
    }

    const navigation = useNavigation<TUseNavigation>();
    const { setAlert } = useSetAlert()
    const onCompletedAddAppConfig = () => {
        setAlert('Delivery fee set successfully for orders below the maximum order value', 'normal')
        navigation.navigate('Dashboard')
    }
    const { addAppConfig, loading } = useAddAppConfg({ onCompleted: onCompletedAddAppConfig })
    const { editAppConfig, editAppConfigLoading } = useEditAppConfig({ onCompleted: onCompletedAddAppConfig })

    return (
        <DesktopTemplate>
            <View style={{ alignItems: 'center' }}>
                <View style={{ width: getResponsiveStyle(465, 'dimensions') }}>
                    <HeadingLarge
                        heading="Delivery"
                        text="Fill out all the mandatory fields"
                    />
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={(values) => {
                            !initialDeliveryValue ? addAppConfig({
                                key: "MAXIMUM_ELIGIBLE_FREE_SHIPPING_AMOUNT",
                                description: "Maximum Amount eligible for free shipping",
                                value: {
                                    value1: values.deliveryFee,
                                    value2: values.maxOrderValue
                                }
                            }) :
                                editAppConfig({
                                    key: "MAXIMUM_ELIGIBLE_FREE_SHIPPING_AMOUNT",
                                    description: "Maximum Amount eligible for free shipping",
                                    value: {
                                        value1: values.deliveryFee,
                                        value2: values.maxOrderValue
                                    }
                                })

                        }}
                        validationSchema={validationSchema}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            touched,
                            errors,
                            values,
                            dirty,
                            isValid
                        }) => (
                            <>
                                <Spacing orientation="column" spacing={33}>
                                    <Input
                                        label="Delivery Fee*"
                                        onChangeText={handleChange('deliveryFee')}
                                        onBlur={handleBlur('deliveryFee')}
                                        value={values.deliveryFee}
                                        error={
                                            touched.deliveryFee && errors.deliveryFee
                                                ? errors.deliveryFee
                                                : ''
                                        }
                                    />

                                    <Input
                                        label="Max. Order Value*"
                                        onChangeText={handleChange('maxOrderValue')}
                                        onBlur={handleBlur('maxOrderValue')}
                                        value={values.maxOrderValue}
                                        error={
                                            touched.maxOrderValue && errors.maxOrderValue
                                                ? errors.maxOrderValue
                                                : ''
                                        }
                                    />

                                    <Button
                                        title={initialDeliveryValue ? 'Edit' : 'Save'}
                                        variant={dirty && isValid ? 'primary' : 'disabled'}
                                        size="lg"
                                        onPress={handleSubmit}
                                        loading={loading || editAppConfigLoading}
                                    />
                                </Spacing>
                            </>
                        )}
                    </Formik>
                </View>
            </View>
        </DesktopTemplate>

    )
}

export default DeliveryScreen