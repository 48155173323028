import React, { useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Action, ActionLink, DropDownPickerSmall } from '..';
import { palettes } from '../../config';
import { TStyleInputOrText } from '../../types/TStyle';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import styled from 'rn-css';
import { useNavigation } from '@react-navigation/native';
import { TODO, TUseNavigation } from '../../types/types';

type TSize = 'small' | 'medium' | 'large';

type Props = {
	heading: string;
	createLinkLabel?: string;
	hasViewAllLabel?: boolean;
	hasFilters?: boolean;
	hasSeeAllLabel?: boolean;
	hasBorderBottom?: boolean;
	containerStyles?: TStyleInputOrText;
	size?: TSize;
	stylesObject?: {
		headingStyles?: TStyleInputOrText;
	};
	navigationDestination?: string;
};

const CreateLink = styled(Action)`
	color: ${palettes.primary[0]};
	&:hover {
		border-bottom: 1px solid ${palettes.primary[0]};
	}
`;

const SectionHeading = ({
	heading,
	createLinkLabel,
	hasViewAllLabel = false,
	hasFilters = false,
	size = 'medium',
	hasSeeAllLabel = false,
	hasBorderBottom = false,
	containerStyles,
	stylesObject,
	navigationDestination = 'Dashboard',
}: Props) => {
	const headingStyles = stylesObject?.headingStyles;
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [value, setValue] = useState<null | string | number | false | true>(
		null
	);
	const [items, setItems] = useState([
		{ label: 'Apple', value: 'apple' },
		{ label: 'Banana', value: 'banana' },
	]);

	const navigator = useNavigation<TODO>();

	return (
		<Text
			style={[
				styles.textContainer,
				styleTextContainer(size),
				containerStyles,
			]}
		>
			<Text style={[styleHeading(size, hasBorderBottom), headingStyles]}>
				{heading}
			</Text>
			{createLinkLabel && <CreateLink>+ {createLinkLabel}</CreateLink>}
			{hasViewAllLabel && !hasSeeAllLabel && (
				<ActionLink
					onPress={() => navigator.navigate(navigationDestination)}
				>
					View All
				</ActionLink>
			)}
			{hasSeeAllLabel && !hasViewAllLabel && (
				<ActionLink
					onPress={() => navigator.navigate(navigationDestination)}
				>
					See All
				</ActionLink>
			)}
			{hasFilters && (
				<View style={styles.filterContainer}>
					{/* <DropDownPickerSmall
						open={open}
						value={value}
						items={items}
						setOpen={value => {
							open2 && setOpen2(!value);
							setOpen(value);
						}}
						setValue={setValue}
						setItems={setItems}
						placeholder="Sort By"
						style={{ marginRight: 18 }}
					/> */}
					<DropDownPickerSmall
						open={open2}
						value={value}
						items={items}
						setOpen={value => {
							setOpen2(value);
							open && setOpen(!value);
						}}
						setValue={setValue}
						setItems={setItems}
						placeholder="All Filters"
					/>
				</View>
			)}
		</Text>
	);
};

const styles = StyleSheet.create({
	textContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	heading: {
		fontWeight: '700',
		color: palettes.dark[0],
	},
	action: {
		fontWeight: '600',
		textAlign: 'right',
		borderBottomWidth: 1,
		paddingBottom: 2,
	},
	createLink: {
		color: palettes.primary[0],
		borderBottomColor: palettes.primary[0],
	},
	viewAll: {
		color: palettes.grey[0],
		borderBottomColor: palettes.grey[0],
	},
	filterContainer: {
		flexDirection: 'row',
		zIndex: 999,
	},
});

const styleHeading = (
	size: TSize,
	hasBorderBottom: boolean
): TStyleInputOrText => {
	return [
		styles.heading,
		size === 'small'
			? styleHeadingSmall()
			: size === 'medium'
			? styleHeadingMedium()
			: size === 'large' && styleHeadingLarge(),
		hasBorderBottom && styleHeadingBorderBottom(),
	];
};

export default SectionHeading;

const styleHeadingBorderBottom = () => {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		paddingBottom: 9,
		borderBottomWidth: 1,
		borderBottomColor: palettes.primary[0],
		fontWeight: '600',
	};
};

const styleHeadingLarge = () => {
	return {
		fontSize: getResponsiveStyle(24, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
};

const styleHeadingMedium = () => {
	return {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
};

const styleHeadingSmall = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
	};
};

const styleTextContainer = (size: TSize) => {
	return { marginBottom: getResponsiveStyle(size === 'medium' ? 17 : 28) };
};
