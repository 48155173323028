import { View, Text } from 'react-native';
import { useLazyQuery } from '@apollo/client';
import { palettes } from '../config';
import Loader from '../components/common/loader/Loader';
import React, { useState, useEffect } from 'react';
import {
	GET_ALL_HOSTS,
	GET_ALL_USERS,
	GET_PENDING_HOSTS,
	GET_USER_STAT,
} from '../graphql/auth/queries';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { GoBackButton, SectionHeading } from '../components';
import ProfileCards from '../components/common/profile-cards/ProfileCards';
import AllProfiles from '../components/common/all-profiles/AllProfiles';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';

const HostsRequestScreen = () => {
	const [waiting, setwaiting] = useState<any[]>([]);
	// const [getStats,{loading}] = useLazyQuery(GET_USER_STAT, {
	// 	fetchPolicy: 'no-cache',
	// 	onCompleted: data => {
	// 		if (data.getAllHosts.data && data.getAllUsers.data.data) {
	// 			console.log('THIS IS IT ');
	// 			let x: any[] = data.getAllHosts.data.data;
	// 			console.log(data.getAllHosts.data);
	// 			console.log(x)

	// 			console.log(x.filter(t => t.profileStatus === 'WAITING'));
	// 			setwaiting(x.filter(t => t.profileStatus === 'WAITING'));

	// 		}

	// 	},
	// 	onError: error => {
	// 		console.log(error);
	// 	},
	// });
	const [getWaitingHosts, { loading }] = useLazyQuery(GET_ALL_USERS, {
		onCompleted: data => {
			if (data.getAllHosts.data && data.getAllUsers.data.data) {
				console.log('THIS IS IT ');
				let x: any[] = data.getAllHosts.data.data;
				console.log(data.getAllHosts.data);
				console.log(x);

				console.log(x.filter(t => t.profileStatus === 'WAITING'));
				setwaiting(x.filter(t => t.profileStatus === 'WAITING'));
			}
		},
	});
	useEffect(() => {
		getWaitingHosts({
			variables: {
				find: { profileStatus: 'WAITING', typeOfAccount: 'SELLER' },
			},
		});
	}, []);
	return (
		<DesktopTemplate>
			<GoBackButton history="Hosts" />
			<SectionHeading
				heading="New Hosts Requests"
				hasFilters
				containerStyles={{
					marginTop: getResponsiveStyle(28),
				}}
			/>

			{waiting.length ? (
				<AllProfiles isRequest newRequest={waiting} />
			) : loading ? (
				<Loader />
			) : (
				<Text
					style={{
						fontSize: getResponsiveStyle(14, 'font'),
						lineHeight: getResponsiveStyle(14, 'font'),
						color: palettes.grey[0],
					}}
				>
					No data Available!
				</Text>
			)}
		</DesktopTemplate>
	);
};

export default HostsRequestScreen;
