import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import styled from 'rn-css';
import { palettes } from '../../config';
import { DELETE_BANK_ACCOUNT } from '../../graphql/brands/mutations';
import { TBankAccount, TOptions } from '../../types/types';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import Button from '../common/button/Button';
import { GET_SINGLE_BRAND } from '../../graphql/brands/queries';
import WithdrawCard from './WithdrawCard';
type TProps = {
	setOption: (val: TOptions) => void;
	bankAccounts?: any[];
	_id: string;
	setAccounts: (val: TBankAccount[] | undefined) => void;
	connectAccountId: string
};

const StyledClearText = styled(Text)`
	font-size: ${getResponsiveStyle(11)}px;
	line-height: ${getResponsiveStyle(11)}px;
	color: ${palettes.red[0]};
	cursor: pointer;
	padding-bottom: 2;

	border-bottom: 1px solid ${palettes.red[0]};
`;
const ChooseBank = ({ setOption, bankAccounts, _id, setAccounts, connectAccountId }: TProps) => {
	const onPressBank = (bankAccount: any) => {
		setOption({
			accountName: bankAccount.accountName,
			bankName: bankAccount.bank_name,
			IBAN: bankAccount.account_number,
			bankId: bankAccount.bankId
		});
	};

	const [deleteBrandBank] = useMutation(DELETE_BANK_ACCOUNT);

	const removeBank = async (IBAN: string, _id: string) => {
		const accountsAfterRemoving = bankAccounts?.filter(
			account => account.IBAN !== IBAN
		);
		setAccounts(accountsAfterRemoving);
		await deleteBrandBank({
			variables: {
				args: {
					_id,
					IBAN,
				},
			},
			awaitRefetchQueries: true,
		});
	};
	return (
		<View style={styles.container}>
			<View>
				{bankAccounts?.map(bankAccount => (
					<View style={{ marginBottom: 20 }}>
						<WithdrawCard
							// bankAccountName={bankAccount.accountName}
							bankName={bankAccount.bank_name}
							iBan={bankAccount.account_number}
							handlePress={() => onPressBank(bankAccount)}
							country = {bankAccount.country}
							bankId = {bankAccount.bankId}
							connectAccountId = {connectAccountId}
						/>
						{/* <TouchableOpacity style={styles.accountContainer} onPress={() => onPressBank(bankAccount)}>
                                <View style={styles.accountInfo}>
                                    <Text style={styles.text}>Bank Account name:</Text>
                                    <Text>{bankAccount.accountName}</Text>
                                </View>
                                <View style={styles.accountInfo}>
                                    <Text style={styles.text}>IBAN:</Text>
                                    <Text>{bankAccount.IBAN}</Text>
                                </View>
                                <View style={styles.accountInfo}>
                                    <Text style={styles.text}>Bank Name:</Text>
                                    <Text>{bankAccount.accountName}</Text>
                                </View>

                            </TouchableOpacity> */}
						{/* <View style={styles.accountInfo}>
                                <Text ></Text>
                                <Button
                                    title={'Remove'}
                                    outlineColor={palettes.red[0]}
                                    onPress = {()=> removeBank(bankAccount.IBAN, _id)}
                                />
                            </View> */}
					</View>
				))}
			</View>
			<Button
				title={'Use a new Bank account'}
				onPress={() => setOption('NEW_BANK_ACCOUNT')}
			/>
		</View>
	);
};

export default ChooseBank;

const styles = StyleSheet.create({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		minHeight: getResponsiveStyle(500),
	},
	accountContainer: {
		width: getResponsiveStyle(400),
		height: getResponsiveStyle(100),
		display: 'flex',
		justifyContent: 'space-between',
		borderWidth: 1,
		borderColor: palettes.grey[3],
		borderRadius: 6,
		shadowOffset: {
			width: 0,
			height: 15,
		},
		shadowRadius: 25,
		shadowColor: 'rgba(124, 136, 155, 0.12)',
		shadowOpacity: 1,
		padding: 10,
	},
	accountInfo: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	text: {
		fontWeight: '600',
	},
	textRemove: {
		color: palettes.red[0],
	},
});
