import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import AddUserForm from '../components/account/AddUserForm';
import Loader from '../components/common/loader/Loader';
import ModalComponent from '../components/common/modal/ModalComponent';
import TableComponent from '../components/common/table-component/TableComponent';
import { TTableOnChangeDropdown } from '../components/common/table/Table';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { palettes } from '../config';
import { EDIT_ADMIN_ROLE } from '../graphql/accounts/mutations';
import { GET_ALL_ADMINS } from '../graphql/accounts/queries';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../hooks/useSetAlerts';
import { toggleAddUserModal } from '../redux/addUserSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import {
	TAdmin,
	TAdminAPIInputs,
	TAdminAPIResponse,
	TAdminRole,
	TAdminWithTypename,
	TEditAdminAPIResponse,
	TEditAdminTypeAPIInputs
} from '../types/adminTypes';
import { TTableHeader } from '../types/types';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';

// const SORT_BY_FILTER = [
// 	{
// 		label: 'Revenue',
// 		value: 'revenue',
// 	},
// 	{
// 		label: 'Orders',
// 		value: 'orders',
// 	},
// 	{
// 		label: 'Value',
// 		value: 'value',
// 	},
// 	{
// 		label: 'Refund',
// 		value: 'refund',
// 	},
// ];
// const DATE_FILTER = [
// 	{
// 		label: 'Last week',
// 		value: 'last_year',
// 	},
// 	{
// 		label: 'Last month',
// 		value: 'last_month',
// 	},
// 	{
// 		label: 'Last 3 months',
// 		value: 'last_3_months',
// 	},
// 	{
// 		label: 'Last year',
// 		value: 'last_year',
// 	},
// ];

const TABS = [
	'All Users',
	'Admin',
	'Host',
	'Sellers',
	'Brand',
	'Events',
] as const;

type TAccountTabs = typeof TABS[number];

const Headers: TTableHeader[] = [
	{
		headerLabel: 'Username',
		width: '40%',
	},
	{
		headerLabel: 'Email',
		width: '40%',
	},
	{
		headerLabel: 'Role',
		width: '30%',
	},
];

const LIMIT = 10;

const filterAllAdminData = (data: TAdminWithTypename[]): TAdmin[] => {
	return data.map(({ __typename, ...d }) => ({
		...d,
	}));
};

function AccountsScreen() {
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();
	const { width } = useIsSpecificViewportWidth();

	const addUserModalState = useAppSelector(state => state.addUser.open);

	const [tabSelected, setTabSelected] = useState<TAccountTabs>(TABS[0]);
	const [current, setCurrent] = useState(0);
	const [tableData, setTableData] = useState<TAdmin[]>([]);
	const [queryCalledOnInitialLoad, setQueryCalledOnInitialLoad] =
		useState(false);
	const [totalData, setTotalData] = useState(0);

	const [getAllAdmins, { loading }] = useLazyQuery<
		TAdminAPIResponse,
		TAdminAPIInputs
	>(GET_ALL_ADMINS, {
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			if (
				data &&
				data.getAllAdmins &&
				data.getAllAdmins.success &&
				data.getAllAdmins.data &&
				data.getAllAdmins.data.data
			) {
				const filteredData = filterAllAdminData(
					data.getAllAdmins.data.data
				);
				setTotalData(data.getAllAdmins.data.total);
				setTableData([...tableData, ...filteredData]);
				setQueryCalledOnInitialLoad(true);
			}
		},
		onError: error => {
			if (!!error) {
				setAlert('Unable to fetch profiles!', 'danger');
			}
		},
	});

	//edit admin role
	const [editAdminRole] = useMutation<
		TEditAdminAPIResponse,
		TEditAdminTypeAPIInputs
	>(EDIT_ADMIN_ROLE, {
		refetchQueries: [
			{
				query: GET_ALL_ADMINS,
				variables: {
					current,
					limit: LIMIT,
					...(!!tabSelected &&
						tabSelected !== 'All Users' && {
							find: { tabSelected },
						}),
				} as TAdminAPIInputs,
			},
		],
		onCompleted: data => {
			if (
				data &&
				data.editAdminRole &&
				data.editAdminRole.success &&
				data.editAdminRole.data &&
				data.editAdminRole
			) {
			}
		},
		onError: error => {
			if (!!error) {
			}
		},
	});

	useEffect(() => {
		tabSelected === 'All Users' && getAllAdminsWithCommonVars({});
	}, [current]);

	const getAllAdminsWithCommonVars = (find:any ) => {
		getAllAdmins({
			variables: {
				find,
				current,
				limit: LIMIT,
				
			},
		});
	};

	const resetValuesOnTabChange = () => {
		setCurrent(0);
		setTotalData(0);
		setTableData([]);
		setQueryCalledOnInitialLoad(false);
	};

	const onPressTab = (tab: TAccountTabs) => {
		resetValuesOnTabChange();
		switch (tab) {
			case 'All Users':
				getAllAdminsWithCommonVars({});
				break;
			case 'Admin':
				getAllAdminsWithCommonVars({ role: 'ADMIN'});
				break;
			case 'Host':
				getAllAdminsWithCommonVars({role: 'HOST'});
				break;
			case 'Sellers':
				getAllAdminsWithCommonVars({role:'SELLER'});
				break;
			case 'Brand':
				getAllAdminsWithCommonVars({role:'BRAND'});
				break;
			case 'Events':
				getAllAdminsWithCommonVars({role: 'EVENT'});
				break;
			default:
				getAllAdmins();
				break;
		}
		setTabSelected(tab);
	};

	const handlePressLogin = () => {};

	const onPressLoadMore = () => {
		setCurrent(prev => prev + 1);
	};

	const onChangeRoleDropdown: TTableOnChangeDropdown = async (
		value,
		_,
		rowIndex
	) => {
		const indexOfAdminToChangeRole = tableData.findIndex(
			d => d._id === rowIndex
		);
		try {
			const { data } = await editAdminRole({
				variables: {
					_id: tableData[indexOfAdminToChangeRole]._id,
					role: value as TAdminRole,
				},
			});
			if (!!data && !!data.editAdminRole && !!data.editAdminRole.data) {
				const { __typename, ...responseData } = data.editAdminRole.data;
				const _tableData = insertDataAtIndex(
					tableData,
					indexOfAdminToChangeRole,
					responseData
				);
				setTableData(_tableData);
				setAlert('Role changed successfully!', 'normal');
			}
		} catch (error) {
			setAlert('Unable to change role!', 'danger');
		}
	};

	return (
		<DesktopTemplate navigationBarProps={{ hasAddNewUser: true }}>
			<View style={styles.headerContainer}>
				<ScrollView
					horizontal={true}
					showsHorizontalScrollIndicator={false}
					style={styles.tabContainer}
				>
					{TABS.map(tab => (
						<Pressable key={tab} onPress={() => onPressTab(tab)}>
							<Text
								style={styleTab(tabSelected ?? '', tab, width)}
							>
								{tab}
							</Text>
						</Pressable>
					))}
				</ScrollView>
				{/* {!isLessThanDesktopBase && (
					<View style={styles.filterContainer}>
						<CategoriesDropdown
							categories={SORT_BY_FILTER}
							label=""
							placeholder="Sort by"
						/>
						<CategoriesDropdown
							categories={DATE_FILTER}
							label=""
							placeholder="Date"
						/>
					</View>
				)} */}
			</View>

			{loading && !queryCalledOnInitialLoad ? (
				<Loader />
			) : tableData && tableData.length === 0 ? (
				<Text
					style={{
						fontSize: getResponsiveStyle(14, 'font'),
						lineHeight: getResponsiveStyle(14, 'font'),
						color: palettes.grey[0],
					}}
				>
					No data Available!
				</Text>
			) : (
				tableData && (
					<TableComponent
						type="Admin"
						headerTabs={TABS as unknown as string[]}
						data={tableData}
						headers={Headers}
						showHeader={false}
						tableContentProps={{
							messageButton: false,
							profileButton: false,
							followButton: false,
							adminButton: true,
							onChangeDropdown: onChangeRoleDropdown,
						}}
						loadMoreObject={{
							hasLoadMore: tableData.length < totalData,
							loading: loading && queryCalledOnInitialLoad,
							onPress: onPressLoadMore,
						}}
					/>
				)
			)}

			<ModalComponent
				header="Add New User"
				openState={addUserModalState}
				onClose={() => dispatch(toggleAddUserModal())}
			>
				<AddUserForm onSubmit={handlePressLogin} />
			</ModalComponent>
		</DesktopTemplate>
	);
}

export default AccountsScreen;

const styles = StyleSheet.create({
	tabContainer: {
		flexDirection: 'row',
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: getResponsiveStyle(24),
	},

	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
	},
	tab: {
		textAlign: 'center',
		textTransform: 'capitalize',
	},
	tabNotSelected: {
		color: palettes.grey[0],
		fontSize: getResponsiveStyle(14, 'font'),

		fontWeight: '400',
	},
	tabSelected: {
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: 'bold',
	},
});

const styleTab = (selectedTab: string, tab: string, width: number) => {
	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(24),
			lineHeight: getResponsiveStyle(18, 'font'),
		},
		selectedTab === tab ? styles.tabSelected : styles.tabNotSelected,
	];
};
const insertDataAtIndex = (
	tableData: TAdmin[],
	indexOfAdminToChangeRole: number,
	responseData: Omit<TAdminWithTypename, '__typename'>
) => {
	return tableData.map((d, i) => {
		if (i === indexOfAdminToChangeRole) {
			return responseData;
		} else {
			return d;
		}
	});
};
