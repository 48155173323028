import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { EDIT_STARTERKIT } from '../../graphql/starterKits/mutations';
import { GET_ALL_STARTERKITS } from '../../graphql/starterKits/queries';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { TEditStarterKitResponse } from '../../types/productTypes';
import { TCreateStarterKit, TUseNavigation } from '../../types/types';

type Params = {
	onErrorEditStarterKit: () => void;
	onCompletedEditStarterKit: (data: TEditStarterKitResponse) => void
}
const useEditStarterKit = (params?: Params) => {
	const [editStarterKit, { data, loading, error }] = useMutation(EDIT_STARTERKIT, {
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_ALL_STARTERKITS }],
		onCompleted: data => {
			params?.onCompletedEditStarterKit &&
				params.onCompletedEditStarterKit(data)
		},
		onError: () => {
			params?.onErrorEditStarterKit &&
				params.onErrorEditStarterKit()
		}
	});

	const executeStarterKitEditMutation = (_id: string, data: TCreateStarterKit) => {
		editStarterKit({
			variables: {
				_id,
				args: data,
			},
		});
	};
	return { executeStarterKitEditMutation, data, loading, error };
};

export default useEditStarterKit;
