import { useLazyQuery } from "@apollo/client"
import { TStarterKitsFormValues } from "../../components/products/create-starter-kit-modal-content/FormikStarterKitsConstants";
import { GET_STARTERKIT_DETAILS } from "../../graphql/starterKits/queries"
import { TGetStarterKitDetailsResponse } from "../../types/productTypes";

type Params = {
    onCompletedGetStarterKitDetails?: (data: TGetStarterKitDetailsResponse) => void;
}
const useGetStarterKitDetails = (params?: Params) => {
    const [getStarterKitDetails, { data, error, loading }] = useLazyQuery<any>(GET_STARTERKIT_DETAILS)

    const executeGetStarterKitDetails = (id: string) => {
        getStarterKitDetails({
            variables: {
                _id: id
            },
            onCompleted: (data) => {

                params?.onCompletedGetStarterKitDetails &&
                    params.onCompletedGetStarterKitDetails(data)
            }
        })
    }

    return { executeGetStarterKitDetails, data, loading, error };

}

export default useGetStarterKitDetails