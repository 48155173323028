import { View, Text } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { GoBackButton } from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import TableComponent from '../components/common/table-component/TableComponent';
import {
	TGetAllConsumersResponse,
	TGetAllUsersResponse,
	TStatsforTable,
	TUserDataForTable,
} from '../types/userTypes';
import { TFind, TGetAllUsersFind, TPaginatedOptions } from '../types/types';
import { GET_ALL_CONSUMERS, GET_USER_TABLE_STATS } from '../graphql/auth/queries';
import { useLazyQuery } from '@apollo/client';
import Loader from '../components/common/loader/Loader';
import { palettes } from '../config';
import { manipulateString } from '../utils/manipulateString';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';

const TABS = ['All Guests', 'New Guests'];
const HEADERS = [
	{
		headerLabel: 'Name',
		width: getResponsiveStyle(180),
	},
	{
		headerLabel: 'Status',
		width: getResponsiveStyle(80),
	},
	{
		headerLabel: 'Location',
		width: getResponsiveStyle(150),
	},
	{
		headerLabel: 'Followers',
		width: getResponsiveStyle(100),
	},
	{
		headerLabel: 'Events Attended',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Purchase Amount',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Email',
		width: getResponsiveStyle(150),
	},
];

const AllCustomersScreen = () => {
	const [selectedTab, setSelectedTab] = useState(TABS[0]);
	const [allConsumersData, setAllConsumersData] =
		useState<TUserDataForTable[]>();
	const [stats, setStats] = useState<TStatsforTable>();

	const [
		getAllConsumers,
		{ loading: consumersLoading, data: consumersData },
	] = useLazyQuery<TGetAllUsersResponse, TPaginatedOptions & TFind>(
		GET_ALL_CONSUMERS,
		{
			fetchPolicy: 'cache-and-network',
			variables:{
			find:{
				typeOfAccount: 'CONSUMER'
			}as TGetAllUsersFind
		},
			onCompleted: data => {
				if (
					data.getAllUsers.data &&
					data.getAllUsers.data.data
				) {
					setStats({
						totalnum: data.getAllUsers.data.total,
						active: data.getAllUsers.data.totalActive,
						inactive: data.getAllUsers.data.totalInactive,
						newRequest: data.getAllUsers.data.newRequest,
					});
					getUserStats({
						variables: {
							id: data.getAllUsers.data.data.map(o => o._id),
						},
					});
				}
			},
		}
	);
	const [getUserStats, { loading: loadingStats }] = useLazyQuery(
		GET_USER_TABLE_STATS,
		{
			onCompleted: resp => {
				console.log(resp);

				let arrTotal = consumersData?.getAllUsers?.data?.data?.map(
					consumer => ({
						image:
							consumer?.personalInformation?.profileImageLink ??
							null,
						name: consumer?.username,
						status: manipulateString.capitalize(
							consumer.profileStatus.toLowerCase()
						),
						location:
							consumer?.personalInformation?.address?.city +
							', ' +
							consumer?.personalInformation?.address
								?.countryLabel,
						followers: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === consumer?._id
						).followersCount,
						eventsAttended: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === consumer?._id
						).eventsCount,
						purchaseAmount: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === consumer?._id
						)?.productsCount,
						email: consumer?.email,
						_id: consumer?._id,
					})
				);
				setAllConsumersData(arrTotal);
			},
		}
	);

	useEffect(() => {
		getAllConsumers();
	}, []);

	const onPressTab = (tab: string) => {
		if (tab === TABS[1]) {
			getAllConsumers({
				variables: {
					sort: {
						createdAt: -1,
					},
				},
			});
		}
		if (tab === TABS[0]) {
			getAllConsumers();
		}
		setSelectedTab(tab);
	};
	const unifiedLoading = useMemo(
		() => getSingleLoadingState(loadingStats, consumersLoading),
		[loadingStats, consumersLoading]
	);
	return (
		<DesktopTemplate>
			<GoBackButton history="Customers" />
			<View style={{ marginTop: getResponsiveStyle(32) }}>
				{allConsumersData && allConsumersData.length > 0 ? (
					<View>
						<TableComponent
							headerTabs={TABS}
							data={allConsumersData}
							onPressTab={onPressTab}
							selectedTab={selectedTab}
							headers={HEADERS}
							type="profile"
							loading={unifiedLoading}
							tableContentProps={{
								profileButton: true,
							}}
						/>
					</View>
				) : unifiedLoading ? (
					<Loader />
				) : (
					<Text
						style={{
							fontSize: getResponsiveStyle(14, 'font'),
							lineHeight: getResponsiveStyle(14, 'font'),
							color: palettes.grey[0],
						}}
					>
						No data Available!
					</Text>
				)}
			</View>
		</DesktopTemplate>
	);
};

export default AllCustomersScreen;
