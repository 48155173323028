import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import styled from 'rn-css';
import { Button, Checkbox } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
type TActionButtonProps = {
	onPress: () => void;
	label: string;
};
type TAllCheckboxProps = {
	isChecked: boolean;
	onChange: () => void;
};

type Props = {
	tabs: string[];
	onPress: (tab: string) => void;
	selectedTab: string;
	containerStyle?: TStyle;
	showDropdowns?: boolean;
	actionButtonProps?: TActionButtonProps;
	checkboxProps?: TAllCheckboxProps;
};

const Tabs = ({
	tabs,
	onPress,
	selectedTab,
	containerStyle,
	showDropdowns = false,
	actionButtonProps = undefined,
	checkboxProps = undefined,
}: Props) => {
	const ActionText = styled.Text`
		&:hover {
			padding-bottom: 7px;
			border-bottom: 1px solid ${palettes.primary[0]};
		}
	`;
	const { width } = useIsSpecificViewportWidth();
	return (
		<View style={[styles.outerContainer, styleContainer()]}>
			<View style={[styles.innerContainer, containerStyle]}>
				{checkboxProps !== undefined && (
					<View
						style={{
							marginRight: getResponsiveStyle(24),
							width: 25,
							height: 25,
						}}
					>
						<Checkbox
							isChecked={checkboxProps.isChecked}
							onChange={() => {
								checkboxProps?.onChange &&
									checkboxProps.onChange();
							}}
						/>
					</View>
				)}
				{tabs.map(tab => (
					<Pressable key={tab} onPress={() => onPress(tab)}>
						<Text style={styleTab(selectedTab, tab, width)}>
							{tab}
						</Text>
					</Pressable>
				))}
			</View>
			{showDropdowns && (
				<View style={styles.filterContainer}>
					{!!actionButtonProps && (
						<ActionText
							onPress={actionButtonProps.onPress}
							style={styles.createTag}
						>
							{'+ ' + actionButtonProps.label}
						</ActionText>
					)}
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	outerContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		zIndex: 5000,
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
	},
	innerContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		flexWrap: 'wrap',
		alignItems: 'center',
		rowGap: getResponsiveStyle(20),
	},
	tab: {
		textAlign: 'center',
		userSelect: 'none',
		paddingBottom: 12,
	},
	tabNotSelected: {
		color: palettes.grey[0],
		fontWeight: '400',
	},
	tabSelected: {
		color: palettes.dark[0],
		fontWeight: '700',
		paddingBottom: 12,
		borderBottomColor: palettes.primary[0],
		borderBottomWidth: 1,
	},
	createTag: {
		color: palettes.primary[0],
		fontWeight: '400',
		paddingBottom: 12,
		fontSize: getResponsiveStyle(14, 'font'),
	},
});

export default Tabs;

const styleTab = (selectedTab: string, tab: string, width: number) => {
	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(24),
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
		},
		selectedTab === tab ? styles.tabSelected : styles.tabNotSelected,
		,
	];
};
function styleContainer() {
	return {
		marginBottom: getResponsiveStyle(48),
	};
}
