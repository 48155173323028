import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import styled from 'rn-css';
import { GoBackButton } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { toggleAddUserModal } from '../../../redux/addUserSlice';
import { setAddProductModalState } from '../../../redux/productsSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { TDesktopNavigationBar, TUseNavigation } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { EmptyHeartIcon } from '../icons';
import InboxIcon from '../icons/InboxIcon';
import MagnifierGlassIcon from '../icons/MagnifierGlassIcon';
import NotificationBellIcon from '../icons/NotificationBellIcon';
import Input from '../Input';
import NotificationIconWithDropdown from '../notification-icon-with-dropdown/NotificationIconWithDropdown';
import { ProfileIconWithDropdown } from '../profile-icon-with-dropdown';
import { useSelector } from 'react-redux'

const CreateEventLink = styled.Text`
	cursor: pointer;
	border-bottom: 1px solid transparent;
	margin-right: ${getResponsiveStyle(27)}px;
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	&:hover {
		padding-bottom: 7px;
		border-bottom: 1px solid ${palettes.primary[0]};
	}
`;

const DesktopNavigationBar = ({
	containerStyles,
	hasAddNewUser = false,
	hasAddNewProduct = false,
	hasGoBackButton = false,
	hasAddNewBrand = false,
	goBackClick,
}: TDesktopNavigationBar) => {
	const navigation = useNavigation<TUseNavigation>();
	const { isUserLoggedIn, loggedInUserDetails } = useAppSelector(state => state.auth)


	const dispatch = useAppDispatch();
	return (
		<View style={styles.container}>
			{hasGoBackButton && <GoBackButton onClick={goBackClick} />}

			{!hasGoBackButton && (
				<View style={styles.innerContainer}>
					{/* <Input
						decoration={
							<MagnifierGlassIcon style={styleSearchIcon()} />
						}
						placeholder="Search"
						style={styleSearchInput()}
					/> */}

					<View style={styles.profileIconContainer}>
						{hasAddNewUser && (
							<CreateEventLink
								onPress={() => dispatch(toggleAddUserModal())}
								style={styles.createEvent}
							>
								+ Add New User
							</CreateEventLink>
						)}
						{hasAddNewProduct && (
							<CreateEventLink
								onPress={() =>
									dispatch(setAddProductModalState(true))
								}
								style={styles.createEvent}
							>
								+ Add New Products
							</CreateEventLink>
						)}
						{hasAddNewBrand && loggedInUserDetails?.role !== "BRAND_ADMIN" && (
							<CreateEventLink
								onPress={() => navigation.navigate('BrandDetails')}
								style={styles.createEvent}
							>
								+ Add New Brand
							</CreateEventLink>
						)}
						{isUserLoggedIn && (
							<View style={styles.iconContainer}>
								{/* <EmptyHeartIcon style={styleEmptyHearIcon()} />
								<InboxIcon style={styleInboxIcon()} /> */}
								<View
									style={[
										styles.notificationIconContainer,

									]}
								>
									<NotificationIconWithDropdown
									// style={styleNotificationBellIcon()}
									/>
								</View>

								<ProfileIconWithDropdown />
							</View>
						)}
					</View>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		paddingTop: getResponsiveStyle(24),
		paddingBottom: getResponsiveStyle(34),
		zIndex: 5000,
	},
	innerContainer: {
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		position: 'relative',

		width: '100%',
		zIndex: 5000,
	},

	link: {
		color: palettes.grey[0],
		paddingBottom: 7,
	},
	linkBold: {
		fontWeight: '700',
		color: palettes.dark[0],
		borderBottomWidth: 1,
		borderBottomColor: palettes.primary[0],
	},
	iconContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	notificationIconContainer: {
		// borderRightWidth: 1,
		// borderRightColor: palettes.grey[7],
	},
	profileIconContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	createEvent: {
		alignItems: 'center',
		color: palettes.primary[0],
		paddingBottom: 1,
	},
	logo: { minHeight: 38 },
	centerIconContainer: {
		position: 'absolute',
		left: `calc(50% - ${getResponsiveStyle(16)}px)`,
	},
	alreadyHaveAnAccountText: {
		color: palettes.dark[4],
	},
	signInContainer: { flexDirection: 'row', alignItems: 'center' },
});

export default DesktopNavigationBar;

const styleSearchInput = () => {
	return { minWidth: getResponsiveStyle(320, 'dimensions') };
};

function styleSearchIcon() {
	return {
		height: getResponsiveStyle(20, 'dimensions'),
		width: getResponsiveStyle(20, 'dimensions'),
	};
}

function styleChevronIcon() {
	return {
		width: getResponsiveStyle(8, 'dimensions'),
		height: getResponsiveStyle(6, 'dimensions'),
	};
}

function styleProfileIcon() {
	return {
		marginLeft: getResponsiveStyle(24),
		height: getResponsiveStyle(48, 'dimensions'),
		width: getResponsiveStyle(48, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleNotificationBellIcon() {
	return {
		width: getResponsiveStyle(20, 'dimensions'),
		height: getResponsiveStyle(21, 'dimensions'),
	};
}

function styleNotificationIconContainer() {
	return { paddingRight: getResponsiveStyle(21) };
}

function styleInboxIcon() {
	return {
		width: getResponsiveStyle(20, 'dimensions'),
		height: getResponsiveStyle(18, 'dimensions'),
		marginLeft: getResponsiveStyle(21),
		marginRight: getResponsiveStyle(24),
	};
}

function styleEmptyHearIcon() {
	return {
		width: getResponsiveStyle(20, 'dimensions'),
		height: getResponsiveStyle(18, 'dimensions'),
	};
}
