import React from 'react';
import { Image, Pressable, View } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { useNavigation } from '@react-navigation/native';
import { TStyle } from '../../types/TStyle';
import { TUseNavigation } from '../../types/types';

type Props = { tint: string; containerStyles?: TStyle } & SvgProps;

const Logo = ({ tint, containerStyles, height, width, ...props }: Props) => {
	const navigator = useNavigation<TUseNavigation>();

	const handleClick = () => {
		navigator.navigate('Dashboard');
	};
	return (
		<Pressable
			style={[{ height, width }, containerStyles]}
			onPress={handleClick}
		>
			<Svg {...props} height={height} width={width}>
				<Path
					fill={tint}
					d="M18.816 0A17.099 17.099 0 0 0 8.539 3.426L7.013 1.393a17.172 17.172 0 1 0 20.594 27.483l1.523 2.034A17.172 17.172 0 0 0 18.816 0zm-9.79 11.463a9.058 9.058 0 0 1 2.844-3.57l1.529 2.043a8.976 8.976 0 0 1 5.417-1.824 9.176 9.176 0 0 1 4.003.912 9.059 9.059 0 0 1 1.442 15.375l-1.517-2.022a8.96 8.96 0 0 1-5.417 1.824 9.174 9.174 0 0 1-4.006-.926 9.058 9.058 0 0 1-4.295-11.811zm6.455 3.115h5.18a1.037 1.037 0 0 1 1.037 1.037v1.074a1.037 1.037 0 0 1-1.037 1.037h-5.18a1.038 1.038 0 0 1-1.038-1.037v-1.074a1.037 1.037 0 0 1 1.038-1.037z"
				/>
			</Svg>
		</Pressable>
	);
};

export default Logo;
