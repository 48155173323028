import { View, Text } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { GoBackButton } from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import TableComponent from '../components/common/table-component/TableComponent';
import { TBrandsTableData, TGetAllBrandsData, TGetAllBrandsResponse } from '../types/brandTypes';
import { TProfileData } from '../components/dashboard/profile-list/ProfileList';
import { useGetAllBrands } from '../api/brands/useGetAllBrands';
import { useGetAllNewBrands } from '../api/brands/useGetAllNewBrands';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import Loader from '../components/common/loader/Loader';
import { palettes } from '../config';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_BRAND_STATS, GET_ALL_BRANDS, GET_NEW_BRANDS, GET_BRANDS_REVENUE } from '../graphql/brands/queries';
import { TPaginatedOptions, TFind, TODO } from '../types/types';
import { useSetAlert } from '../hooks/useSetAlerts';
import { TStatsforTable } from '../types/userTypes';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useAppSelector } from '../redux/store';

const TABS = ['All Brands', 'New Brands'];
const HEADERS = [
	{
		headerLabel: 'Company',
		width: getResponsiveStyle(250),
	},
	{
		headerLabel: 'Website',
		width: getResponsiveStyle(200),
	},

	{
		headerLabel: 'Location',
		width: getResponsiveStyle(192),
	},
	{
		headerLabel: 'Followers',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Products',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Events',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: `Earnings(${getSymbolFromCurrency('eur')})`,
		width: getResponsiveStyle(140),
	},


];
const tableBrandDataSelector = (data?: TBrandsTableData[]) => {
	if (!checkIfBrandsDataExists(data)) {
		console.log('error');
		return;
	}
	console.log(data);
	return data?.map(val => ({
		profileImage: val.profileImage,
		companyName: val.companyName,
		website: val.website,
		location: val.location,
		followers: val.followers,
		products: val.products,
		events: val.events,
		earnings: val.earnings,
		_id: val._id
	})) as TBrandsTableData[];
};

const checkIfBrandsDataExists = (data?: TBrandsTableData[]) => {
	return data ? true : false;
};

const AllBrandsScreen = () => {
	const [selectedTab, setSelectedTab] = useState(TABS[0]);
	const { setAlert } = useSetAlert();
	const [allBrandsData, setAllBrandsData] = useState<TBrandsTableData[]>();
	const [brandStats, setBrandStats] = useState<TStatsforTable>();
	const [newBrands, setnewBrands] = useState<TBrandsTableData[]>();
	const [SelectedBrandData, setselected] = useState<TBrandsTableData[]>();
	const [PrevGraphData, setPrevGraphData] = useState<number[]>();
	const [CurrGraphData, setCurrGraphData] = useState<number[]>();
	const [stats, setStats] = useState<any[]>([]);
	const [statIds, setStatIds] = useState<string[]>([]);
	const [brandIds, setbrandIds] = useState<any[]>([]);
	const [earned, setEarned] = useState<any[]>([]);

	const { loggedInUserDetails } = useAppSelector(state => state.auth)
	const tableBrandDataSelector = (data?: TBrandsTableData[]) => {
		if (!checkIfBrandsDataExists(data)) {
			console.log('error');
			return;
		}
		console.log(data);
		return data as TBrandsTableData[];
	};

	const checkIfBrandsDataExists = (data?: TBrandsTableData[]) => {
		return data ? true : false;
	};
	const [getStats, { loading: loading }] = useLazyQuery(GET_BRAND_STATS, {
		onCompleted: data => {
			if (
				data.getBrandStats &&
				data.getBrandStats.success &&
				data.getBrandStats.data
			) {
				setStats([...stats, ...data.getBrandStats.data.data]);
			} else {
				setAlert('Unable to fetch brand stats', 'danger');
			}
		},
		onError: () => {
			console.log('ther was an error');
		},
	});
	useEffect(() => {
		getStats({
			variables: {
				_ids: statIds,
			},
		});
	}, [statIds]);
	const [getBrandsRevenue] = useLazyQuery(GET_BRANDS_REVENUE, {
		onCompleted: data => {
			setEarned([
				...earned,
				...data?.getBrandTrxDetails?.data?.data,
			]);
		},
	});
	useEffect(() => {
		getBrandsRevenue({
			variables: {
				ids: brandIds,
			},
		});
	}, [brandIds]);

	const [
		getAllBrands,
		{ data: dataGetAllBrands, loading: loadingGetAllBrands },
	] = useLazyQuery<TGetAllBrandsResponse, TPaginatedOptions & TFind>(
		GET_ALL_BRANDS,
		{


			onCompleted: data => {
				if (
					data.getAllBrands.data &&
					data.getAllBrands.success &&
					data.getAllBrands.data.data
				) {
					let ids = data.getAllBrands.data.data.map(
						brand => brand?._id
					);
					setbrandIds([...brandIds, ...ids]);

					setStatIds([
						...statIds,
						...data.getAllBrands.data.data.map(o => o._id),
					]);
					let arrTotal: any = data?.getAllBrands?.data?.data?.map(
						brand => ({
							connectId:
								brand.connectAccountInfo?.connectAccountId,
							profileImage: brand.profileImage,
							companyName: brand.name,
							website: brand.website ?? 'N/A',
							location: brand.address.countryLabel,
							_id: brand._id,
						})
					);
					setAllBrandsData(arrTotal);
					setBrandStats({
						totalnum: data.getAllBrands.data.total,
						active: data.getAllBrands.data.totalActive,
						inactive: data.getAllBrands.data.totalInactive,
						newRequest: data.getAllBrands.data.newRequest,
					});
				} else {
					setAlert('Unable to get all brands', 'danger');
					setAllBrandsData([]);
				}
			},
			onError: () => {
				setAlert('Unable to get all brands', 'danger');
				setAllBrandsData([]);
			},
		}
	);

	const [
		getNewBrands,
		{ data: dataGetAllNewBrands, loading: loadingGetAllNewBrands },
	] = useLazyQuery(GET_NEW_BRANDS, {

		onCompleted: data => {
			console.log(data);
			if (
				!(
					data.getNewBrands.data &&
					data.getNewBrands.success &&
					data.getNewBrands.data.data
				)
			) {
				setAlert('Unable to get new brands', 'danger');
				setAllBrandsData([]);
			} else {
				let ids = data.getNewBrands.data.data.map(
					brand => brand?._id
				);
				setbrandIds([...brandIds, ...ids]);
				setStatIds([
					...statIds,
					...data.getNewBrands.data.data.map(o => o._id),
				]);
				let arrTotal = data?.getNewBrands?.data?.data?.map(brand => ({
					connectId: brand.connectAccountInfo?.connectAccountId,
					profileImage: brand.profileImage,
					companyName: brand.name,
					website: brand.website ?? 'N/A',
					location: brand.address.countryLabel,
					_id: brand._id,
				}));

				setnewBrands(arrTotal);
			}
		},
		onError: () => {
			setAlert('Unable to get new brands', 'danger');
			setAllBrandsData([]);
		},
	});
	useEffect(() => {
		getAllBrands();
		getNewBrands();
	}, []);
	const onPressTab = (tab: string) => {
		setSelectedTab(tab);

		if (tab === 'New Brands') {
			setselected(tableBrandDataSelector(newBrands));
		}
		if (tab === 'All Brands') {
			setselected(tableBrandDataSelector(allBrandsData));
		}
	};

	useEffect(() => {
		setselected(tableBrandDataSelector(allBrandsData));
	}, [allBrandsData]);

	const unifiedLoading = useMemo(
		() =>
			getSingleLoadingState(
				loadingGetAllBrands,
				loadingGetAllNewBrands,
				loading
			),
		[loadingGetAllBrands, loadingGetAllNewBrands, loading]
	);

	const brandTab = loggedInUserDetails?.role === "BRAND_ADMIN" ? TABS?.slice(0, 1) : TABS


	return (
		<DesktopTemplate>
			<GoBackButton history="Brands" />
			<View style={{ marginTop: getResponsiveStyle(32) }}>
				<View>
					{unifiedLoading ? (
						<Loader />
					) : !!SelectedBrandData && SelectedBrandData.length > 0 ? (
						!!selectedTab && (
							<TableComponent
								type="brand"
								headerTabs={brandTab}
								onPressTab={onPressTab}
								selectedTab={selectedTab}
								data={SelectedBrandData?.map(val => ({
									profileImage: val.profileImage,
									companyName: val.companyName,
									website: val.website,
									location: val.location,
									followers:
										stats?.find(
											(value: { brandId: string }) =>
												value.brandId === val._id
										)?.followersCount ?? '...',
									products:
										stats?.find(
											(value: { brandId: string }) =>
												value.brandId === val._id
										)?.productsCount ?? '...',
									events:
										stats?.find(
											(value: { brandId: string }) =>
												value.brandId === val._id
										)?.eventsCount ?? '...',
									earnings:
										earned.find(
											earning =>
												earning.brandId === val?._id
										)?.grossEarnings?.toFixed(2) ?? 'N/A',
									_id: val._id,
								}))}
								headers={HEADERS}
								loading={unifiedLoading}
							/>
						)
					) : (
						<Text
							style={{
								fontSize: getResponsiveStyle(14, 'font'),
								lineHeight: getResponsiveStyle(14, 'font'),
								color: palettes.grey[0],
							}}
						>
							No data Available!
						</Text>
					)}
				</View>
			</View>
		</DesktopTemplate>
	);
};

export default AllBrandsScreen;
