import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { GoBackButton, SectionHeading } from '../components';
import AllEventsSection from '../components/common/all-events-section/AllEventsSection';
import Loader from '../components/common/loader/Loader';
import ScrollEventSection from '../components/common/scroll-events-section/ScrollEventSection';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { EDIT_EVENT_STATUS } from '../graphql/events/mutations';
import { Get_ALL_EVENTS } from '../graphql/events/queries';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { TGetAllEventsData, TGetAllEventsResponse } from '../types/eventTypes';
import { dateFns } from '../utils/dateFns';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';

function UpcomingEventsScreen() {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const [allUpcomingEvents, setAllUpcomingEvents] = useState<
		TGetAllEventsData[] | undefined
	>([]);
	const [allUpcomingPendingEvents, setAllUpcomingPendingEvents] = useState<
		TGetAllEventsData[] | undefined
	>([]);

	const [recentUpcomingEvents, setRecentUpcomingEvents] = useState<
		TGetAllEventsData[] | undefined
	>([]);

	// upcoming events
	const [getAllUpcomingEvents, { loading: loadingGetAllUpcomingEvents }] =
		useLazyQuery<TGetAllEventsResponse>(Get_ALL_EVENTS, {
			onCompleted: (data: TGetAllEventsResponse) => {
				if (
					data.getAllEvents.data &&
					data.getAllEvents.success &&
					data.getAllEvents.data.data
				) {
					setAllUpcomingEvents(data.getAllEvents.data.data);
					setAllUpcomingPendingEvents(
						// data.getAllEvents.data.data.filter(
						// 	item => item.statusOfEvent === 'PENDING'
						// )
						[]
					);
				} else {
					setAllUpcomingEvents([]);
				}
			},
			onError: error => {
				setAllUpcomingEvents([]);
			},
		});

	const [
		getRecentUpcomingEvents,
		{ loading: loadingGetRecentUpcomingEvents },
	] = useLazyQuery<TGetAllEventsResponse>(Get_ALL_EVENTS, {
		onCompleted: (data: TGetAllEventsResponse) => {
			if (
				data.getAllEvents.data &&
				data.getAllEvents.success &&
				data.getAllEvents.data.data
			) {
				setRecentUpcomingEvents(data.getAllEvents.data.data);
			} else {
				setRecentUpcomingEvents([]);
			}
		},
		onError: error => {
			setRecentUpcomingEvents([]);
		},
	});

	useEffect(() => {
		getAllUpcomingEvents({
			variables: {
				find: {
					startingEventDateTime: {
						$gte: new Date(),
					},
				},
			},
		});
		getRecentUpcomingEvents({
			variables: {
				find: {
					startingEventDateTime: {
						$gte: new Date(),
					},
				},
			},
		});
	}, []);
	const [acceptOrRejectEvent, { loading: acceptOrRejectEventLoading }] =
		useMutation(EDIT_EVENT_STATUS, {
			awaitRefetchQueries: true,
			refetchQueries: [Get_ALL_EVENTS],
		});

	const loadingUnifiedTabEvents = useMemo(
		() =>
			getSingleLoadingState(
				loadingGetRecentUpcomingEvents,
				loadingGetAllUpcomingEvents
			),
		[loadingGetRecentUpcomingEvents, loadingGetAllUpcomingEvents]
	);

	return (
		<DesktopTemplate>
			<GoBackButton history="Events" />
			{loadingGetAllUpcomingEvents ? (
				<Loader />
			) : (
				<>
					{allUpcomingPendingEvents &&
						allUpcomingPendingEvents.length > 0 && (
							<ScrollEventSection
								heading={'New Request'}
								hasFilters={!isLessThanDesktopBase}
								containerStyle={{
									marginTop: getResponsiveStyle(28),
									width: '100%',
								}}
								cardProps={{
									type: 'Request',
								}}
								acceptOrRejectMutation={acceptOrRejectEvent}
								data={allUpcomingPendingEvents}
							/>
						)}
					{allUpcomingEvents && (
						<ScrollEventSection
							heading={'Recent Upcoming Event'}
							hasFilters={!isLessThanDesktopBase}
							containerStyle={{
								marginTop: getResponsiveStyle(28),
								width: '100%',
							}}
							cardProps={{
								type: 'Request',
							}}
							hasRequest={false}
							data={allUpcomingEvents}
						/>
					)}
				</>
			)}
			<View
				style={{
					marginTop: getResponsiveStyle(46),
				}}
			>
				<View style={styles.headerContainer}>
					<SectionHeading
						heading={'All Upcoming Events'}
						hasFilters
						stylesObject={{
							headingStyles: {
								fontWeight: '700',
							},
						}}
						containerStyles={{
							width: '100%',
						}}
					/>
				</View>
				{loadingGetRecentUpcomingEvents ? (
					<Loader />
				) : (
					<>
						
						{recentUpcomingEvents && (
							<AllEventsSection data={recentUpcomingEvents} />
						)}
					</>
				)}
			</View>
		</DesktopTemplate>
	);
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'column',
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: getResponsiveStyle(21),
	},
});

export default UpcomingEventsScreen;
