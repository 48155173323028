import React, { ComponentProps, CSSProperties } from 'react';
import { View, StyleSheet } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

export type TDateTimeType = 'datetime-local' | 'date' | 'time';

export type TDateTimeTypeProps = {
	dateTimeProps?: Omit<ComponentProps<'input'>, 'type' | 'style'>;
	inputStyles?: CSSProperties;
	type?: TDateTimeType;
};

export const WebDateTimePicker = ({
	dateTimeProps,
	inputStyles,
	type,
}: TDateTimeTypeProps) => {
	const primary = palettes.primary;
	return (
		<View style={reactNativeStyles.container}>
			<input
				type={type}
				style={{ ...reactStyles, ...inputStyles, outline: primary[0] }}
				{...dateTimeProps}
			/>
		</View>
	);
};

const reactNativeStyles = StyleSheet.create({
	container: {
		flex: 1,
		width: '100%',
	},
});

const reactStyles: CSSProperties = {
	borderRadius: 6,
	border: `1px solid ${palettes.grey[4]}`,
	height: getResponsiveStyle(48, 'dimensions'),
	fontSize: getResponsiveStyle(16, 'font'),
	lineHeight: getResponsiveStyle(14, 'font'),
	paddingLeft: getResponsiveStyle(24),
	paddingRight: getResponsiveStyle(24),
};
