export const linking = {
	prefixes: ['https://admin.ocpus-mobile.com', 'admin.ocpus-client://'],
	config: {
		screens: {
			Login: '/login',
			Signup: '/signup',
			Dashboard: '/dashboard',
			Events: '/events',
			AllEvents: '/events/all',
			UpcomingEvents: '/events/upcoming',
			Brands: '/brands',
			AllBrands: '/brands/all',
			BrandRequests: '/brands/requests',
			AddBrand: '/brand/add',
			Host: '/host',
			AllHosts: '/hosts/all',
			HostsRequests: '/hosts/requests',
			Sellers: '/sellers',
			AllSellers: '/sellers/all',
			SellersRequests: '/sellers/requests',
			Guests: '/customers',
			EventDescription: '/event/description',
			AllCustomers: '/customers/all',
			AllOrders: '/orders/all',
			Products: '/products',
			ProductsUploadCSV: '/products/upload',
			ProductsSingleUpload: 'products/add',
			Accounts: '/accounts',
			Delivery: '/delivery',
			Tags:"/tags"
		},
	},
};
