import { View, Text } from 'react-native';
import React from 'react';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { GoBackButton, SectionHeading } from '../components';
import ProfileCards from '../components/common/profile-cards/ProfileCards';
import AllProfiles from '../components/common/all-profiles/AllProfiles';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';

const BrandRequestsScreen = () => {
	return (
		<DesktopTemplate>
			<GoBackButton history="Brands" />
			<SectionHeading
				heading="New Brands Requests"
				hasFilters
				containerStyles={{
					marginTop: getResponsiveStyle(28),
				}}
			/>
			<AllProfiles isBrand isRequest />
		</DesktopTemplate>
	);
};

export default BrandRequestsScreen;
