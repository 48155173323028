import { View, Text, ScrollView, Pressable, StyleSheet } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';

type Props = {
	tabs: string[];
	onPress?: (tab: string) => void;
	selectedTab?: string
};

const ScrollTabs = ({ tabs = [], onPress, selectedTab }: Props) => {
	const { width } = useIsSpecificViewportWidth();

	const getTabs = () => {
		return tabs;
	};

	return (
		<ScrollView
			horizontal={true}
			showsHorizontalScrollIndicator={false}
			style={styles.tabContainer}
		>
			{tabs.map(tab => (
				<Pressable key={tab} onPress={() => onPress && onPress(tab)}>
					<Text style={styleTab(selectedTab ?? "", tab, width)}>{tab}</Text>
				</Pressable>
			))}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	tabContainer: {
		flexDirection: 'row',
	},

	tab: {
		textAlign: 'center',
		textTransform: 'capitalize',
	},
	tabNotSelected: {
		color: palettes.grey[0],
		fontSize: getResponsiveStyle(14, 'font'),

		fontWeight: '400',
	},
	tabSelected: {
		color: palettes.dark[0],
		// paddingBottom: 9,
		fontSize: getResponsiveStyle(14, 'font'),
		// paddingHorizontal: 3,

		fontWeight: 'bold',
		// borderBottomColor: palettes.primary[0],
		// borderBottomWidth: 1,
	},
});

export default ScrollTabs;

const styleTab = (selectedTab: string, tab: string, width: number) => {
	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(24),
			lineHeight: getResponsiveStyle(18, 'font'),
		},
		selectedTab === tab ? styles.tabSelected : styles.tabNotSelected,
		// {
		// 	marginBottom: getResponsiveStyle(48),
		// },
	];
};
