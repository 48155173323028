import React from 'react';
import {
	View,
	StyleSheet,
	ScrollView,
	useWindowDimensions,
	KeyboardAvoidingView,
	Platform,
	Text,
} from 'react-native';
import { breakpoints } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../../types/TStyle';
import { TDesktopNavigationBar } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import DesktopNavigationBar from '../desktop-navigation-bar/DesktopNavigationBar';
import MobileNavigationBar from '../mobile-navigation-bar/MobileNavigationBar';
import Sidebar from '../sidebar/Sidebar';

type Props = {
	hasPaddingRight?: boolean;
	navigationBarProps?: Omit<TDesktopNavigationBar, 'containerStyles'>;
};

const DesktopTemplate: React.FC<Props> = ({
	children,
	navigationBarProps,
	hasPaddingRight = true,
}) => {
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return (
		<View style={styles.container}>
			<KeyboardAvoidingView
				behavior={Platform.OS === 'ios' ? 'position' : 'padding'}
				style={styles.keyboardAvoidingView}
			>
				{/* Sidebar */}
				{!isLessThanDesktopBase && <Sidebar />}
				<ScrollView
					style={[
						styles.pageContainer,
						styleContentContainer(width),
						{
							left: isLessThanDesktopBase
								? 0
								: getResponsiveStyle(240),
						},
					]}
				>
					{!isLessThanDesktopBase && (
						<DesktopNavigationBar {...navigationBarProps} />
					)}
					{isLessThanDesktopBase && (
						<MobileNavigationBar
							containerStyles={styleNavMobile(hasPaddingRight)}
						/>
					)}
					<View
						style={{
							height: '100%',
							paddingBottom: getResponsiveStyle(50),
						}}
					>
						{children}
					</View>
				</ScrollView>
			</KeyboardAvoidingView>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		overflow: 'hidden',
		minWidth: '100vw',
		minHeight: '100vh',
		zIndex: 5000,
	},
	keyboardAvoidingView: {
		flexDirection: 'row',
		height: '100%',
	},
	pageContainer: {
		flexDirection: 'column',
		flex: 1,
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 5000,
		// left: getResponsiveStyle(240),
	},
});

export default DesktopTemplate;

const styleContentContainer = (width: number) => {
	return {
		paddingHorizontal: width >= breakpoints.desktopBase ? 32 : 16,
		minHeight: '100vh',
	} as TStyle;
};

const styleNavMobile = (hasPaddingRight: boolean): TStyle => {
	return {
		paddingRight: hasPaddingRight ? 0 : 16,
	};
};
