import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useGetAllUsersLazy } from '../api/auth/useGetAllUsersLazy';
import { useGetUserMetricChange } from '../api/auth/useGetUserMetricChange';
import Loader from '../components/common/loader/Loader';
import PageHeader from '../components/common/page-header/PageHeader';
import PeopleScrollSection from '../components/common/people-scroll-section/PeopleScrollSection';
import TableComponent from '../components/common/table-component/TableComponent';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import ViewAllText from '../components/common/view-all-text/ViewAllText';
import { palettes } from '../config';
import {
	GET_ALL_HOSTS,
	GET_ALL_SELLERS,
	GET_ALL_USERS,
	GET_PENDING_SELLERS,
	GET_USER_TABLE_STATS,
} from '../graphql/auth/queries';
import { GET_USER_GRAPH_DATA } from '../graphql/common/queries';
import { ACCEPT_OR_REJECT } from '../graphql/user/mutation';
import { TCommonUserResponseData } from '../types/authTypes';
import { TFind, TGetAllUsersFind, TPaginatedOptions } from '../types/types';
import {
	TGetAllSellersResponse,
	TGetAllUsersResponse,
	TStatsforTable,
	TUserDataForTable,
} from '../types/userTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import { manipulateString } from '../utils/manipulateString';

const TABS = ['All Sellers', 'New Sellers'];
const HEADERS = [
	{
		headerLabel: 'Name',
		width: getResponsiveStyle(180),
	},
	{
		headerLabel: 'Status',
		width: getResponsiveStyle(80),
	},
	{
		headerLabel: 'Location',
		width: getResponsiveStyle(150),
	},
	{
		headerLabel: 'Followers',
		width: getResponsiveStyle(100),
	},
	{
		headerLabel: 'Events Attended',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Purchase Amount',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Email',
		width: getResponsiveStyle(150),
	},
];

function SellersScreen() {
	const [selectedTab, setSelectedTab] = useState(TABS[0]);
	const [allSellersData, setAllSellersData] = useState<TUserDataForTable[]>();
	const [stats, setStats] = useState<TStatsforTable>();
	const [waiting, setwaiting] = useState<any[]>([]);
	const [PrevGraphData, setPrevGraphData] = useState<number[]>();
	const [CurrGraphData, setCurrGraphData] = useState<number[]>();

	const [getAllSellers, { loading: sellersLoading, data: sellersData }] =
		useLazyQuery<TGetAllUsersResponse, TPaginatedOptions & TFind>(
			GET_ALL_USERS,
			{
				fetchPolicy: 'cache-and-network',
				variables: {
					find: {
						typeOfAccount: 'SELLER',
					} as TGetAllUsersFind,
				},
				onCompleted: data => {
					console.log(data);
					if (data.getAllUsers.data && data.getAllUsers.data.data) {
						setStats({
							totalnum: data.getAllUsers.data.total,
							active: data.getAllUsers.data.totalActive,
							inactive: data.getAllUsers.data.totalInactive,
							newRequest: data.getAllUsers.data.newRequest,
						});
						getUserStats({
							variables: {
								id: data.getAllUsers.data.data.map(o => o._id),
							},
						});
					}
				},
			}
		);
	// const { loading: loadingwait, data: sellersDatawait } = useQuery<
	// 	TGetAllSellersResponse,
	// 	TPaginatedOptions & TFind
	// >(GET_ALL_SELLERS, {
	// 	fetchPolicy: 'no-cache',
	// 	variables: {
	// 		find: {
	// 			profileStatus: 'WAITING',
	// 		},
	// 		limit: 10,
	// 	},
	// 	onCompleted: data => {
	// 		if (data.getAllSellers.data && data.getAllSellers.data.data) {
	// 			setwaiting(data.getAllSellers.data.data);
	// 		}
	// 	},
	// });

	const [getWaitingSellers, { loading: waitingSellersLoading }] =
		useLazyQuery(GET_ALL_USERS, {
			onCompleted: data => {
				if (data?.getAllUsers?.data?.data)
					setwaiting(data.getAllUsers.data.data);
			},
		});

	const [getUserStats, { loading: loadingStats }] = useLazyQuery(
		GET_USER_TABLE_STATS,
		{
			onCompleted: resp => {
				let arrTotal = sellersData?.getAllUsers?.data?.data?.map(
					seller => ({
						image:
							seller?.personalInformation?.profileImageLink ??
							null,
						name: seller?.username,
						status: manipulateString.capitalize(
							seller.profileStatus.toLowerCase()
						),
						location:
							seller?.personalInformation?.address?.city +
							', ' +
							seller?.personalInformation?.address?.countryLabel,
						followers: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === seller?._id
						).followersCount,
						eventsAttended: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === seller?._id
						).eventsCount,
						purchaseAmount: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === seller?._id
						)?.productsCount,
						email: seller?.email,
						_id: seller?._id,
					})
				);
				console.log(arrTotal);

				setAllSellersData(arrTotal);
			},
		}
	);

	useEffect(() => {
		getAllSellers({
			variables: {
				limit: 10,
				find: { typeOfAccount: 'SELLER' },
			},
		});

		getWaitingSellers({
			variables: {
				current: 0,
				limit: 4,
				find: { typeOfAccount: 'SELLER', profileStatus: 'WAITING' },
			},
		});
	}, []);
	const onPressTab = (tab: string) => {
		if (tab === TABS[1]) {
			getAllSellers({
				variables: {
					sort: {
						createdAt: -1,
					},
					limit: 10,
					find: { typeOfAccount: 'SELLER' },
				},
			});
		}
		if (tab === TABS[0]) {
			getAllSellers({
				variables: {
					limit: 10,
					find: { typeOfAccount: 'SELLER' },
				},
			});
		}
		setSelectedTab(tab);
	};

	const { getUserMetricChange, data: sellersMetricData } =
		useGetUserMetricChange();

	useEffect(() => {
		getUserMetricChange('SELLER');
	}, []);
	const unifiedLoading = useMemo(
		() =>
			getSingleLoadingState(
				loadingStats,
				sellersLoading,
				waitingSellersLoading
			),
		[loadingStats, sellersLoading]
	);

	const sellersMetrics =
		sellersMetricData?.getUserMetricsPercentageChange?.data;
	useQuery(GET_USER_GRAPH_DATA, {
		variables: {
			type: 'SELLER',
		},
		onCompleted: data => {
			if (
				data.getUserGraphDataBasedOnAccountType.success &&
				data.getUserGraphDataBasedOnAccountType.data
			) {
				let currMonth =
					data.getUserGraphDataBasedOnAccountType.data.currentMonth.map(
						(value: { dataPoint: number }) => {
							return value.dataPoint;
						}
					);
				let prevMonth =
					data.getUserGraphDataBasedOnAccountType.data.previousMonth.map(
						(value: { dataPoint: number }) => {
							return value.dataPoint;
						}
					);
				setPrevGraphData(prevMonth);
				setCurrGraphData(currMonth);
			}
		},
	});
	const [acceptOrRejectUserAccount, { loading: acceptOrRejectLoading }] =
		useMutation(ACCEPT_OR_REJECT, {
			awaitRefetchQueries: true,
			refetchQueries: [GET_ALL_USERS],
		});

	return (
		<DesktopTemplate>
			<PageHeader
				prevData={PrevGraphData}
				currData={CurrGraphData}
				pageHeader="All Sellers"
				chartItemValues={[
					{
						title: 'All Sellers',
						percentageDifference: Math.abs(
							sellersMetrics?.allUsers || 0
						),
						percentageIncrease: sellersMetrics?.allUsers >= 0,
						value: stats?.totalnum || 0,
					},
					{
						title: 'Active Sellers',
						percentageDifference: Math.abs(
							sellersMetrics?.activeUsers || 0
						),
						percentageIncrease: sellersMetrics?.activeUsers >= 0,
						value: stats?.active || 0,
					},
					{
						title: 'Inactive Sellers',
						percentageDifference: Math.abs(
							sellersMetrics?.inactiveUsers || 0
						),
						percentageIncrease: sellersMetrics?.inactiveUsers >= 0,
						value: stats?.inactive || 0,
					},
					{
						title: 'New Requets',
						percentageDifference: Math.abs(
							sellersMetrics?.newRequests || 0
						),
						percentageIncrease: sellersMetrics?.newRequests >= 0,
						value: stats?.newRequest || 0,
					},
				]}
			/>
			{unifiedLoading ? (
				<Loader />
			) : (
				<>
					<PeopleScrollSection
						header="New Seller Requests"
						newRequest={waiting}
						cardProps={{
							isRequest: true,
						}}
						acceptOrRejectMutation={acceptOrRejectUserAccount}
						navigationDestination="SellersRequests"
					/>

					<View
						style={{
							marginTop: getResponsiveStyle(35),
						}}
					>
						{allSellersData && allSellersData.length > 0 ? (
							<>
								<View>
									<TableComponent
										headerTabs={TABS}
										type="profile"
										data={allSellersData}
										onPressTab={onPressTab}
										selectedTab={selectedTab}
										headers={HEADERS}
										loading={loadingStats}
										tableContentProps={{
											profileButton: true,
											messageButton: true,
										}}
									/>
								</View>

								<View
									style={{
										marginTop: getResponsiveStyle(67),
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<ViewAllText
										title="View All Sellers"
										navigationDestination="AllSellers"
									/>
								</View>
							</>
						) : (
							<Text
								style={{
									fontSize: getResponsiveStyle(14, 'font'),
									lineHeight: getResponsiveStyle(14, 'font'),
									color: palettes.grey[0],
								}}
							>
								No data Available!
							</Text>
						)}
					</View>
				</>
			)}

			{/* <PeopleScrollSection
				header="New Seller Requests"
				newRequest={waiting}
				cardProps={{
					isRequest: true,
				}}
				acceptOrRejectMutation={acceptOrRejectUserAccount}
				navigationDestination="SellersRequests"
			/> */}
			{/* <View
				style={{
					marginTop: getResponsiveStyle(35),
				}}
			>
				{allSellersData && allSellersData.length > 0 ? (
					<>
						<View>
							<TableComponent
								headerTabs={TABS}
								type="profile"
								data={allSellersData}
								onPressTab={onPressTab}
								selectedTab={selectedTab}
								headers={HEADERS}
								loading={loadingStats}
								tableContentProps={{
									profileButton: true,
									messageButton: true,
								}}
							/>
						</View>

						<View
							style={{
								marginTop: getResponsiveStyle(67),
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<ViewAllText
								title="View All Sellers"
								navigationDestination="AllSellers"
							/>
						</View>
					</>
				) : unifiedLoading ? (
					<Loader />
				) : (
					<Text
						style={{
							fontSize: getResponsiveStyle(14, 'font'),
							lineHeight: getResponsiveStyle(14, 'font'),
							color: palettes.grey[0],
						}}
					>
						No data Available!
					</Text>
				)}
			</View> */}
		</DesktopTemplate>
	);
}

export default SellersScreen;
