import React from 'react';
import { Platform, StyleSheet, Text, TextInputProps, View } from 'react-native';
import { palettes } from '../../../config';
import { TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { ActionMessage } from './ActionMessage';
import { ErrorMessage } from './ErrorMessage';

export type LabelProps = {
	label: string;
	labelStyle?: TStyleInputOrText;
	labelProps?: TextInputProps;
	actionText?: string;
	error?: string;
	actionLink?: string;
	labelHint?: string;
};

const Label = ({
	label,
	labelStyle,
	labelProps,
	actionText,
	error = '',
	actionLink,
	labelHint = '',
}: LabelProps) => {
	return (
		<View style={[styles.container, styleContainer()]}>
			<Text
				{...labelProps}
				style={[
					styles.label,
					styleLabel(),
					!!error && styles.labelError,
					labelStyle,
				]}
			>
				{label}
			</Text>
			<Text>
				{(actionText || error) && Platform.OS === 'web' && (
					<View>
						{error !== '' && <ErrorMessage error={error} />}
						{!!actionText && error === '' && (
							<ActionMessage
								actionText={actionText}
								actionLink={actionLink}
							/>
						)}
					</View>
				)}
			</Text>
			<Text>
				{!!labelHint && !actionText && !error && (
					<Text style={styles.labelHint}>{labelHint}</Text>
				)}
			</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	labelHint: {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		color: palettes.grey[1],
	},
	label: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		color: palettes.grey[0],
	},
	labelError: {
		color: palettes.red[0],
	},

	container: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 7,
	},
});

const styleLabel = () => {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
	} as TStyleInputOrText;
};

function styleContainer() {
	return { minHeight: getResponsiveStyle(17) };
}

export default Label;
