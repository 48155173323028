import React from 'react';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Button, Input } from '../..';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { TStyle } from '../../../types/TStyle';
import useCreatePasswordLink from '../../../api/auth/useCreatePasswordLink';

const validationSchema = yup.object({
	email: yup.string().email('Invalid email').required('Required'),
});

type TInitialValue = yup.InferType<typeof validationSchema>;

const initialValues: TInitialValue = {
	email: '',
};

const ForgotPasswordForm = () => {
	const { executeCreatePasswordMutation } = useCreatePasswordLink();
	return (
		<View>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={values => {
					console.log({ values });
					executeCreatePasswordMutation(values.email);
				}}
			>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					dirty,
					isValid,
					touched,
					errors,
				}) => (
					<>
						<Input
							label={'Email'}
							containerStyles={styleInput()}
							onChangeText={handleChange('email')}
							onBlur={handleBlur('email')}
							style={[styles.input]}
							error={
								touched.email && errors.email
									? errors.email
									: ''
							}
						/>
						<Button
							title={'Send Email'}
							variant={dirty && isValid ? 'primary' : 'disabled'}
							outerContainerProps={{
								style: styleLoginButtonOuterContainer(),
							}}
							size="lg"
							onPress={handleSubmit}
						/>
					</>
				)}
			</Formik>
		</View>
	);
};

export default ForgotPasswordForm;

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
});

const styleInput = () => {
	return { marginTop: getResponsiveStyle(40) };
};

function styleLoginButtonOuterContainer(): TStyle {
	return {
		marginTop: getResponsiveStyle(40),
		marginBottom: getResponsiveStyle(40),
	};
}
