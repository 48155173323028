import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Button, OptionButton } from '../..';
import { setAddProductModalState } from '../../../redux/productsSlice';
import { useAppDispatch } from '../../../redux/store';
import { TUseNavigation } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

export type ProductModelContentOptions = 'CSV' | 'Create';

const AddProductModelContent = () => {
	const navigation = useNavigation<TUseNavigation>();
	const dispatch = useAppDispatch();
	const [selectedOption, setSelectedOption] = useState<
		undefined | ProductModelContentOptions
	>();
	return (
		<View>
			<View style={{ marginBottom: getResponsiveStyle(16) }}>
				<OptionButton
					title="Upload CSV"
					text="Upload CSV of Products"
					value="CSV"
					outerContainerStyles={{
						marginBottom: getResponsiveStyle(8),
					}}
					onClick={() => {
						setSelectedOption('CSV');
					}}
				/>
				<OptionButton
					title="Add Product"
					text="Add a new Product with its variants?"
					value="Create"
					onClick={() => {
						setSelectedOption('Create');
					}}
				/>
			</View>
			<View>
				<Button
					title="Create"
					variant="primary"
					size="lg"
					onPress={() => {
						dispatch(setAddProductModalState(false));
						if (selectedOption === 'CSV') {
							navigation.navigate('ProductsUploadCSV');
						} else {
							navigation.navigate('ProductsSingleUpload');
						}
					}}
				/>
			</View>
		</View>
	);
};

export default AddProductModelContent;
