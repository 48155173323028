import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Button, Input } from '../..';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useLogin from '../../../api/auth/useLogin';
const schema = Yup.object({
	email: Yup.string().email('Invalid email').required('Required').trim(),
	password: Yup.string().required('Required'),
});

type TInitialValues = Yup.InferType<typeof schema>;

const LoginForm = (props: {
	onSubmit: (email: string, password: string) => void;
	handlePressForgetPassword: () => void;
}) => {
	const [email, onChangeEmail] = React.useState('');
	const [password, onChangePassword] = React.useState('');

	const { executeLoginMutation, loading } = useLogin();

	const handlePressForgetPassword = () => {
		props.handlePressForgetPassword();
	};

	const signIn = (email: string, password: string) => {
		if (!!email && !!password)
			executeLoginMutation({
				email,
				password,
			});
	};

	const handleSubmit = () => {
		props.onSubmit(email, password);
	};

	return (
		<>
			<View>
				<Input
					value={email}
					style={[styles.input]}
					containerStyles={styleInput('email')}
					onChangeText={onChangeEmail}
					label="Username / Email"
					onSubmitEditing={() => signIn(email, password)}
				/>
				<Input
					style={[styles.input]}
					containerStyles={styleInput('password')}
					value={password}
					onChangeText={onChangePassword}
					label="Password"
					onSubmitEditing={() => signIn(email, password)}
					secureTextEntry
				/>
			</View>

			<Button
				title="Login"
				variant="primary"
				outerContainerProps={{
					style: styleLoginButtonOuterContainer(),
				}}
				size="lg"
				loading={loading}
				onPress={() => signIn(email, password)}
			/>

			<TouchableOpacity
				onPress={handlePressForgetPassword}
				activeOpacity={0.75}
			>
				<Text
					style={[styles.textButtonForget, styleTextButtonForgot()]}
				>
					Did you forget your password?
				</Text>
			</TouchableOpacity>
		</>
	);
};

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
	textButtonForget: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		textDecorationLine: 'underline',
		color: palettes.grey[0],
		textAlign: 'center',
	},
});

export default LoginForm;

const styleInput = (type: 'email' | 'password') => {
	return { marginBottom: getResponsiveStyle(type === 'email' ? 20 : 40) };
};
function styleTextButtonForgot() {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
	};
}

function styleLoginButtonOuterContainer() {
	return {
		marginBottom: getResponsiveStyle(20),
	};
}
