import { useMutation, useQuery } from '@apollo/client';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useGetAppConfg } from '../api/config/useGetAppConfig';
import { useGetAllUpcomingEvents } from '../api/events/useGetAllUpcomingEvents';
import Loader from '../components/common/loader/Loader';
import PageHeader from '../components/common/page-header/PageHeader';
import ScrollEventSection from '../components/common/scroll-events-section/ScrollEventSection';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import OrderSection from '../components/dashboard/OrderSection';
import ProfileList, {
	TProfileData,
} from '../components/dashboard/profile-list/ProfileList';
import { palettes } from '../config';
import {
	GET_ALL_USERS
} from '../graphql/auth/queries';
import { GET_ALL_BRANDS } from '../graphql/brands/queries';
import { EDIT_EVENT_STATUS } from '../graphql/events/mutations';
import { Get_ALL_EVENTS } from '../graphql/events/queries';
import { GET_ALL_NOTIFICATIONS } from '../graphql/notification/queries';
import { NOTIFICATION_SUBSCRIPTION } from '../graphql/notification/subscriptions';
import { GET_SALES_GRAPH_DATA } from '../graphql/sales/queries';
import {
	GET_TODAYS_REVENUE,
	GET_YESTERDAYS_REVENUE,
} from '../graphql/stripe/queries';
import { ACCEPT_OR_REJECT } from '../graphql/user/mutation';
import { useGetAdminBalance } from '../hooks/useGetAdminBalance';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../hooks/useSetAlerts';
import { setNoOfNotifications } from '../redux/notificationSlice';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { TAdminRole } from '../types/adminTypes';
import { TCommonUserResponseData } from '../types/authTypes';
import { TGetAllBrandsResponse } from '../types/brandTypes';
import { TGetAllEventsResponse } from '../types/eventTypes';
import {
	NotificationType,
	Revenue,
	RevenueAndTaxInfo,
	TFind,
	TODO,
	TPaginatedOptions,
} from '../types/types';
import {
	TGetAllConsumersResponse,
	TGetAllHostsResponse,
	TGetAllSellersResponse,
	TGetAllUsersResponse,
} from '../types/userTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';

const userDataSelector = (
	queryFunctionName: string,
	data: any
): TProfileData[] => {
	return data[queryFunctionName].data?.data.map(
		(guests: TCommonUserResponseData) => ({
			address: guests?.personalInformation?.address,
			description: '',
			email: guests?.email,
			profileImage: guests?.personalInformation?.profileImageLink,
			id: guests._id,
		})
	);
};

function DashboardScreen() {
	const { setAlert } = useSetAlert();

	const [dataGetAllSellers, setDataGetAllSellers] = useState<
		TProfileData[] | undefined
	>();
	const [dataGetAllHosts, setDataGetAllHosts] = useState<
		TProfileData[] | undefined
	>([]);

	const [dataGetAllGuests, setDataGetAllGuests] = useState<
		TProfileData[] | undefined
	>([]);

	const {getAdminBalance, totalBalanceOnStripe, totalPendingTransfers} = useGetAdminBalance()

	useEffect(()=>{
		getAdminBalance();
		totalBalanceOnStripe()
		totalPendingTransfers()
	},[])

	const [dataGetAllBrands, setDataGetAllBrands] = useState<
		TProfileData[] | undefined
	>([]);
	const [PrevGraphData, setPrevGraphData] = useState<number[]>();
	const [CurrGraphData, setCurrGraphData] = useState<number[]>();

	const [daysOrders, setDaysOrders] = useState<number>(0);
	const [daysRevenue, setDaysRevenue] = useState(0);
	const [daysTax, setDaysTax] = useState(0);

	const [yesterdayOrders, setYesterdayOrders] = useState<number>(0);
	const [yesterdayRevenue, setYesterdayRevenue] = useState(0);
	const [yesterdaysTax, setYesterdaysTax] = useState(0)

	const { isLessThanDesktopBase, isMobileView } =
		useIsSpecificViewportWidth();

	const { data: dataGetAllEvents, loading: loadingEvents } =
		useGetAllUpcomingEvents('PENDING_APPROVAL');

	const { subscribeToMore: subscribeToNotification, data: notificationData } =
		useQuery(GET_ALL_NOTIFICATIONS);

	let noOfUnread = 0;

	// const { data: subData, loading } = useSubscription(
	// 	NOTIFICATION_SUBSCRIPTION,
	// 	{ variables: { userId: loggedInUserDetails?._id } }
	// );

	// console.log({ subData });
	const dispatch = useAppDispatch();

	const { loggedInUserDetails, role } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
		role: state.auth.loggedInUserDetails?.role,
	}));

	useEffect(() => {
		if (notificationData !== undefined) {
			notificationData?.getAllNotifications?.data?.data?.map(
				(data: NotificationType) => {
					if (data.read === false) {
						noOfUnread += 1;
					}
				}
			);
		}
		dispatch(setNoOfNotifications(noOfUnread));
	}, [notificationData]);

	console.log({ notificationData });
	subscribeToNotification({
		document: NOTIFICATION_SUBSCRIPTION,
		variables: { userId: loggedInUserDetails?._id },
		updateQuery: (prev, { subscriptionData }) => {
			if (!subscriptionData.data) return prev;
			const newNotification =
				subscriptionData.data.notificationSubscription;
			let oldObj = {};
			console.log({ subscriptionData });
			if (prev !== undefined) {
				if (prev.getAllNotifications !== undefined) {
					if (prev.getAllNotifications.data !== undefined) {
						if (prev.getAllNotifications.data.data !== undefined) {
							oldObj = { ...prev.getAllNotifications.data.data };
						}
					}
				}
			}
			return {
				getAllNotifications: {
					data: {
						data: [newNotification, oldObj],
					},
				},
			};
		},
	});

	//Consumers
	useQuery<TGetAllUsersResponse, TPaginatedOptions & TFind>(
		GET_ALL_USERS,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				limit: 6,
				find: {
					typeOfAccount: "CONSUMER"
				}
			},
			onCompleted: data => {
				if (
					data.getAllUsers?.success &&
					data.getAllUsers?.data &&
					data.getAllUsers?.data?.data
				) {
					setDataGetAllGuests(() =>
						userDataSelector('getAllUsers', data)
					);
				} else {
					setAlert('Unable to fetch guests', 'danger');
					setDataGetAllGuests([]);
				}
			},
			onError: error => {
				setAlert('Unable to fetch guests', 'danger');
				setDataGetAllGuests([]);
			},
		}
	);

	// Hosts
	const { loading: loadingGetAllHosts } = useQuery<
		TGetAllUsersResponse,
		TPaginatedOptions & TFind
	>(GET_ALL_USERS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			limit: 6,
			find: {
				profileStatus: 'WAITING',
				typeOfAccount: "HOST"
			},
		},
		onCompleted: data => {
			if (
				data.getAllUsers?.success &&
				data.getAllUsers?.data &&
				data.getAllUsers?.data?.data
			) {
				setDataGetAllHosts(() => userDataSelector('getAllUsers', data));
			} else {
				setAlert('Unable to fetch hosts', 'danger');
				setDataGetAllHosts([]);
			}
		},
		onError: error => {
			setAlert('Unable to fetch hosts', 'danger');
			setDataGetAllHosts([]);
		},
	});

	// BRANDS
	const { loading: loadingGetAllBrands } = useQuery<
		TGetAllBrandsResponse,
		TPaginatedOptions & TFind
	>(GET_ALL_BRANDS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			limit: 6,
		},
		onCompleted: data => {
			if (
				data.getAllBrands?.success &&
				data.getAllBrands?.data &&
				data.getAllBrands?.data.data
			) {
				setDataGetAllBrands(() =>
					data.getAllBrands?.data?.data.map(brand => ({
						email: brand.email,
						description: brand.description,
						address: brand.address,
						profileImage: brand.profileImage,
						id: brand._id,
					}))
				);
			} else {
				setAlert('Unable to fetch brands', 'danger');
				setDataGetAllBrands([]);
			}
		},
		onError: () => {
			setAlert('Unable to fetch brands', 'danger');
			setDataGetAllBrands([]);
		},
	});

	// SELLERS
	const { loading: loadingGetAllSellers } = useQuery<
		TGetAllUsersResponse,
		TPaginatedOptions & TFind
	>(GET_ALL_USERS, {
		fetchPolicy: 'cache-and-network',
		variables: {
			limit: 6,
			find: {
				profileStatus: 'WAITING',
				typeOfAccount: "SELLER"
			},
		},
		onCompleted: data => {
			if (
				data.getAllUsers?.success &&
				data.getAllUsers?.data &&
				data.getAllUsers?.data?.data
			) {
				setDataGetAllSellers(() =>
					userDataSelector('getAllUsers', data)
				);
			} else {
				setAlert('Unable to fetch sellers', 'danger');
				setDataGetAllSellers([]);
			}
		},
		onError: error => {
			setAlert('Unable to fetch sellers', 'danger');
			setDataGetAllSellers([]);
		},
	});

	useQuery(GET_SALES_GRAPH_DATA, {
		onCompleted: data => {
			if (data.getSalesGraphData?.success && data.getSalesGraphData?.data) {
				let currMonth = data.getSalesGraphData?.data.currentMonth.map(
					(value: { dataPoint: number }) => {
						return value.dataPoint;
					}
				);
				let prevMonth = data.getSalesGraphData?.data.previousMonth.map(
					(value: { dataPoint: number }) => {
						return value.dataPoint;
					}
				);
				setPrevGraphData(prevMonth);
				setCurrGraphData(currMonth);
			}
		},
	});
	const { data: salesGraphData, loading: salesGraphLoading } = useQuery(
		GET_SALES_GRAPH_DATA,

		{
			onCompleted: data => {
				if (
					data.getSalesGraphData?.success &&
					data.getSalesGraphData?.data
				) {
					let currMonth =
						data.getSalesGraphData?.data.currentMonth.map(
							(value: { dataPoint: number }) => {
								return value.dataPoint;
							}
						);
					let prevMonth =
						data.getSalesGraphData?.data.previousMonth.map(
							(value: { dataPoint: number }) => {
								return value.dataPoint;
							}
						);
					setPrevGraphData(prevMonth);
					setCurrGraphData(currMonth);
				}
			},
		}
	);
	const [acceptOrRejectUserAccount, { loading: acceptOrRejectLoading }] =
		useMutation(ACCEPT_OR_REJECT, {
			awaitRefetchQueries: true,
			refetchQueries: [GET_ALL_USERS],
		});
	const [acceptOrRejectEvent, { loading: acceptOrRejectEventLoading }] =
		useMutation(EDIT_EVENT_STATUS, {
			awaitRefetchQueries: true,
			refetchQueries: [Get_ALL_EVENTS],
		});

	useQuery(GET_TODAYS_REVENUE, {
		onCompleted: data => {
			setDaysOrders(data?.getOrdersAdminPerDay?.data?.total);

			let todaysNetRevenue = 0
			let todaysTax = 0
			data?.getOrdersAdminPerDay?.data?.data?.map((obj: RevenueAndTaxInfo) => {
				todaysNetRevenue += obj.netRevenue;
				todaysTax += obj.tax
			})
			setDaysRevenue(todaysNetRevenue);
			setDaysTax(todaysTax)
		},
	});

	useQuery(GET_YESTERDAYS_REVENUE, {
		onCompleted: data => {
			setYesterdayOrders(data?.getOrdersAdminYesterDay?.data?.total);

			let yesterdaysNetRevenue = 0
			let yesterdaysTax = 0
			data?.getOrdersAdminYesterDay?.data?.data?.map((obj: RevenueAndTaxInfo) => {
				yesterdaysNetRevenue += obj.netRevenue;
				yesterdaysTax += obj.tax
			})

			setYesterdayRevenue(yesterdaysNetRevenue);
			setYesterdaysTax(yesterdaysTax)
		},
	});

	const globalAccountsLoading = useMemo(
		() => getSingleLoadingState(loadingGetAllBrands, loadingEvents),
		[loadingGetAllBrands, loadingEvents]
	);

	const revenuepercent: TODO =
		yesterdayRevenue > 0
			? Math.ceil(((daysRevenue - yesterdayRevenue) * 100) / yesterdayRevenue)
			: 'nil';

	const ordersPercent: TODO =
		yesterdayOrders > 0
			? Math.ceil(
				((daysOrders - yesterdayOrders) * 100) / yesterdayOrders
			)
			: 'nil';

	const averagePercent_: number = Math.ceil(
		((daysRevenue / daysOrders - yesterdayRevenue / yesterdayOrders) * 100) /
		(yesterdayRevenue / yesterdayOrders)
	);
	const averagePercent: TODO =
		averagePercent_ >= 0 || averagePercent_ < 0 ? averagePercent_ : 'nil';

	const showOnBasisOfRole = (_role: TAdminRole) => {
		return _role === role || role === 'ADMIN' || 'SUPER_ADMIN';
	};

	return (
		<DesktopTemplate>
			<PageHeader
				pageHeader="Sales"
				prevData={PrevGraphData}
				currData={CurrGraphData}
				chartItemValues={[
					{
						title: 'Today’s revenue',
						percentageDifference: revenuepercent,
						percentageIncrease:
							daysRevenue >= yesterdayRevenue ? true : false,
						value: `${getSymbolFromCurrency('eur')}${(daysRevenue).toFixed(2)}`,
						showPercentage: revenuepercent === 'nil' ? false : true,
					},
					{
						title: 'Today’s orders',
						percentageDifference: Math.ceil(ordersPercent),
						percentageIncrease:
							daysOrders >= yesterdayOrders ? true : false,
						value: daysOrders,
						showPercentage: ordersPercent === 'nil' ? false : true,
					},
					{
						title: 'Avg. value',
						percentageDifference: Math.ceil(averagePercent),
						percentageIncrease:
							daysRevenue / daysOrders >=
								yesterdayRevenue / yesterdayOrders
								? true
								: false,
						value:
							daysOrders > 0 && daysRevenue / daysOrders
								? `${getSymbolFromCurrency('eur')}${((daysRevenue) / daysOrders).toFixed(
									2
								)}`
								: `${getSymbolFromCurrency('eur')}0.00`,
						showPercentage: averagePercent === 'nil' ? false : true,
					},
					{
						title: 'Today’s tax',
						percentageDifference: 0,
						percentageIncrease: false,
						value: `${getSymbolFromCurrency('eur')} ${daysTax}`,
						showPercentage: false,
					},
					{
						title: 'Today’s refund',
						percentageDifference: 0,
						percentageIncrease: false,
						value: '0',
						showPercentage: false,
					},

				]}
			/>
			{globalAccountsLoading ? (
				<View style={{ marginTop: getResponsiveStyle(70) }}>
					<Loader />
				</View>
			) : (
				<View style={{ marginBottom: 50 }}>
					{dataGetAllEvents &&
						dataGetAllEvents?.getAllEvents &&
						dataGetAllEvents.getAllEvents.data &&
						showOnBasisOfRole('EVENT') && (
							<>
								<ScrollEventSection
									heading={'New Requests'}
									hasViewAllLabel
									cardProps={{
										type: 'Request',
									}}
									containerStyle={{
										marginTop: getResponsiveStyle(54),
										paddingBottom: getResponsiveStyle(54),
									}}
									clickable={true}
									acceptOrRejectMutation={acceptOrRejectEvent}
									navigationDestination="UpcomingEvents"
									data={dataGetAllEvents?.getAllEvents.data?.data.filter(
										x => x.isRejected === false
									)}
								/>
							</>
						)}

					<View
						style={[
							styles.profileListContainer,
							{ marginTop: getResponsiveStyle(40) },
						]}
					>
						{!isMobileView && showOnBasisOfRole('BRAND') && (
							<View
								style={{
									flexBasis: isLessThanDesktopBase
										? 'auto'
										: '45%',
									flex: 1,
									paddingBottom: getResponsiveStyle(48),
								}}
							>
								<ProfileList
									heading="Brands"
									hasControls
									isBrandProfile
									navigationDestination="AllBrands"
									data={dataGetAllBrands}
								/>
							</View>
						)}
						{(!isMobileView && role === 'ADMIN') ||
							('SUPER_ADMIN' && (
								<View style={styles.sectionDivider} />
							))}

						{showOnBasisOfRole('SELLER') && (
							<View
								style={{
									flexBasis: isLessThanDesktopBase
										? 'auto'
										: '45%',
									flex: 1,
									paddingBottom: getResponsiveStyle(48),
								}}
							>
								<ProfileList
									heading="New Sellers"
									hasControls
									hasAcceptorReject
									acceptOrRejectMutation={
										acceptOrRejectUserAccount
									}
									navigationDestination="AllSellers"
									data={dataGetAllSellers}
								/>
							</View>
						)}
					</View>

					<View style={[styles.profileListContainer]}>
						{showOnBasisOfRole('HOST') && (
							<View
								style={{
									flexBasis: isLessThanDesktopBase
										? 'auto'
										: '45%',
									flex: 1,
									paddingTop: getResponsiveStyle(48),
								}}
							>
								<ProfileList
									heading="New Hosts"
									hasControls
									hasAcceptorReject
									acceptOrRejectMutation={
										acceptOrRejectUserAccount
									}
									navigationDestination="AllHosts"
									data={dataGetAllHosts}
								/>
							</View>
						)}
						{(!isMobileView && role === 'ADMIN') ||
							('SUPER_ADMIN' && (
								<View style={styles.sectionDivider} />
							))}

						<View
							style={{
								flexBasis: isLessThanDesktopBase
									? 'auto'
									: '45%',
								flex: 1,
								paddingTop: getResponsiveStyle(48),
							}}
						>
							<ProfileList
								heading="Guests"
								hasControls
								navigationDestination="AllCustomers"
								data={dataGetAllGuests}
							/>
						</View>
					</View>
				</View>
			)}

			<OrderSection heading="Orders" hasViewAllButton={true} />

			
		</DesktopTemplate>
	);
}
const styles = StyleSheet.create({
	sectionDivider: {
		width: 1,
		height: '100%',
		backgroundColor: palettes.grey[5],
	},
	profileListContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		maxWidth: '100%',
		justifyContent: 'space-between',
		gap: getResponsiveStyle(31),
	},
});
export default DashboardScreen;
