import React from 'react';
import NotificationWrapper from './NotificationWrapper';
import { StandardNotification } from './StandardNotification';
import { NotificationType } from '../../../types/types';

interface Props {
	notification: NotificationType;
}
const NotificationLayouts = ({ notification }: Props) => {

	return (
		<>
			<NotificationWrapper>
				<StandardNotification notification={notification} />
			</NotificationWrapper>
		</>
	);

};

export default NotificationLayouts;

