const breakpoints = {
	tabletSm: 640,
	tabletLg: 768,
	mobile: 800,
	desktopBase: 1200,
	desktopSm: 1280,
	desktopMd: 1440,
	desktopLg: 1920,
};

export default breakpoints;
