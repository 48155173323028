import React, { useEffect } from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import { Button, Ratings } from '../..';
import { palettes } from '../../../config';
import { TCommonUserResponseData } from '../../../types/authTypes';
import { AcceptOrReject } from '../../../types/userTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { DotIcon } from '../../common/icons';
import ProfileIcon from '../../common/ProfileIcon';
import Profile from '../../dashboard/profile-list/Profile';
import { placeholderImage } from '../table/TableContent';
import { ActionButtons } from './ActionButtons';
import { HostStatCard } from './HostStatCard';
import { ProfileImageWithGender } from './ProfileImageWithGender';
const STATS = [
	{
		label: 'Followers',
		stat: 447,
	},
	{
		label: 'Products',
		stat: '180+',
	},
	{
		label: 'Events',
		stat: 12,
	},
];

type Props = {
	isBrand?: boolean;
	isRequest?: boolean;
	showGender?: boolean;
	hasMargin?: boolean;
	newRequest: any;
	acceptOrRejectMutation?: (variables?: AcceptOrReject) => void;
};

export const ProfileCard = ({
	isBrand = false,
	isRequest = false,
	showGender = false,
	hasMargin = false,
	acceptOrRejectMutation,
	newRequest,
}: Props) => {
	const firstName =
		newRequest?.item?.personalInformation?.firstName ||
		newRequest?.personalInformation?.firstName;
	const lastName =
		newRequest?.item?.personalInformation?.lastName ||
		newRequest?.personalInformation?.lastName;

	const userName = newRequest?.item?.username || newRequest?.username;

	return (
		<View style={[styles.container, styleContainer(hasMargin)]}>
			{!isBrand ? (
				<>
					<ProfileImageWithGender
						gender={newRequest.gender}
						image={
							newRequest.item
								? newRequest.item.personalInformation
										?.profileImageLink
								: newRequest.personalInformation
										?.profileImageLink
						}
					/>
					<Text style={[styles.name, styleName()]}>
						{firstName} {lastName}
					</Text>
					<Text style={[styles.profileHandle, styleProfileHandle()]}>
						@{userName}
					</Text>
				</>
			) : (
				<View>
					<Profile
						// key={number}
						name="Unilever"
						id={newRequest.item._id}
						// location="www.nike.com"
						profileImage={placeholderImage}
						isBrandProfile={true}
						// hasControls
						// controlType="view"
						// time={time}
						// containerStyles={{

						// }}
						// imageMargin={getResponsiveStyle(9)}
					/>
				</View>
			)}

			{isBrand && <HostStatCard stats={STATS} />}
			<ActionButtons
				acceptOrRejectMutation={acceptOrRejectMutation}
				id={newRequest?._id ?? newRequest?.item?._id}
				isRequest={isRequest}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		borderRadius: 6,
		backgroundColor: 'white',
		width: 'max-content',

		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpacity: 1,
	},
	name: {
		fontWeight: '600',

		textAlign: 'center',
		color: palettes.dark[0],
		marginBottom: 1,
	},
	profileHandle: {
		fontWeight: '400',
		color: palettes.grey[0],
		textAlign: 'center',
		marginBottom: 1,
	},
	dotIcon: {},
	location: {
		color: palettes.dark[4],
		paddingBottom: 11,
		textAlign: 'center',
	},
	eventsAttended: {
		textAlign: 'center',
		color: palettes.grey[0],
		borderTopColor: palettes.grey[5],
		borderTopWidth: 1,
	},
	eventsAttendedStat: {
		color: palettes.dark[0],
		fontWeight: '600',
	},
});

const styleProfileHandle = () => {
	return {
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
};

const styleName = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginTop: getResponsiveStyle(13),
	};
};

const styleContainer = (hasMargin: boolean) => {
	return {
		marginRight: hasMargin ? getResponsiveStyle(16) : 0,
		paddingHorizontal: getResponsiveStyle(24),
		paddingVertical: getResponsiveStyle(24),
		minWidth: getResponsiveStyle(264, 'dimensions'),
	};
};
