import React, { ComponentProps } from "react";
import { FlatList, ScrollView } from "react-native";
import { useDraggableScroll } from "../../hooks/use-draggable-scroll";

const DraggableScrollView = React.forwardRef<
  FlatList,
  ComponentProps<typeof FlatList>
>(function DraggableScrollView(props, ref) {
  const { refs } = useDraggableScroll<FlatList>({
    outerRef: ref,
    cursor: "grab", // optional, default
  });

  return <FlatList ref={refs} horizontal {...props} />;
});

export default DraggableScrollView;
