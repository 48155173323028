import React from 'react';
import { View, Pressable, Text, StyleSheet } from 'react-native';
import { Table } from '..';
import { palettes, user } from '../../config';
import { useIsSpecificViewportWidth } from '../../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import { CategoriesDropdown } from '../common/filter-categories/CategoriesDropdown';
import Loader from '../common/loader/Loader';
import ViewAllText from '../common/view-all-text/ViewAllText';
const HEADERS = [
	{
		headerLabel: 'ORDER#',
		width: getResponsiveStyle(120),
	},
	{
		headerLabel: 'PURCHASED ON',
		width: getResponsiveStyle(150),
	},
	{
		headerLabel: 'BRANDS',
		width: getResponsiveStyle(120),
	},

	{
		headerLabel: 'PURCHASED TOTAL',
		width: getResponsiveStyle(180),
	},
	{
		headerLabel: 'CUSTOMERS',
		width: getResponsiveStyle(180),
	},
	{
		headerLabel: 'QUANTITY',
		width: getResponsiveStyle(90),
	},
	{
		headerLabel: 'PAYMENT STATUS',
		width: getResponsiveStyle(180),
	},
];

const SORT_BY_FILTER = [
	{
		label: 'Revenue',
		value: 'revenue',
	},
	{
		label: 'Orders',
		value: 'orders',
	},
	{
		label: 'Value',
		value: 'value',
	},
	{
		label: 'Refund',
		value: 'refund',
	},
];
const DATE_FILTER = [
	{
		label: 'Last week',
		value: 'last_year',
	},
	{
		label: 'Last month',
		value: 'last_month',
	},
	{
		label: 'Last 3 months',
		value: 'last_3_months',
	},
	{
		label: 'Last year',
		value: 'last_year',
	},
];

const SECTION_TABS = ['Active orders', 'Completed', 'Cancelled'];

type SECTION_TYPE = 'Active orders' | 'Completed' | 'Cancelled';
const SECTION: SECTION_TYPE = 'Active orders';

const SECTIONSTATUS = {
	'Active orders': 'IN_PROGRESS',
	Completed: 'DELIVERED',
	Cancelled: 'CANCELLED',
};
const PAYMENTSTATUS = {
	'Active orders': 'SUCCESS',
	Completed: 'SUCCESS',
	Cancelled: 'FAILED',
};

const getTabs = (section: SECTION_TYPE) => {
	return SECTION_TABS;
};
type Props = {
	heading?: string;
	data?: any;
	loading?: boolean;
	limit?: number;
	hasViewAllButton?: boolean;
	userId?: string;
	action: (variables?: any) => void;
};
const OrderComponent = ({
	heading,
	data,
	limit,
	loading,
	userId,
	hasViewAllButton,

	action,
}: Props) => {
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();
	const [selectedTab, setSelectedTab] = React.useState<string>(
		getTabs(SECTION)[0]
	);
	const onPress = (tab: SECTION_TYPE) => {
		setSelectedTab(tab);
		let x = tab;
		let id = {};

		{
			limit && (limit = 10);
		}

		{
			userId && (id = { brands: { $in: [userId] } });
		}
		action({
			find: {
				...id,
				orderStatus: SECTIONSTATUS[tab],
				paymentStatus: PAYMENTSTATUS[tab],
				limit
			},
		});
		// TODO: Set wondows scroll here
	};
	return (
		<View style={styles.container}>
			<View style={styles.headerContainer}>
				{heading ? (
					<Text style={styles.header}>{heading}</Text>
				) : (
					<View></View>
				)}
				{!isLessThanDesktopBase && (
					<View style={styles.tabContainer}>
						{SECTION_TABS.map(tab => (
							<Pressable key={tab} onPress={() => onPress(tab)}>
								<Text style={styleTab(selectedTab, tab, width)}>
									{tab}
								</Text>
							</Pressable>
						))}
					</View>
				)}
				<View style={styles.filterContainer}>
					{/* <CategoriesDropdown
						categories={SORT_BY_FILTER}
						label=""
						placeholder="Sort by"
					/>
					<CategoriesDropdown
						categories={DATE_FILTER}
						label=""
						placeholder="Date"
					/> */}
				</View>
			</View>
			{isLessThanDesktopBase && (
				<View
					style={[
						styles.tabContainer,
						{
							justifyContent: 'center',
							marginTop: getResponsiveStyle(25),
						},
					]}
				>
					{SECTION_TABS.map(tab => (
						<Pressable key={tab} onPress={() => onPress(tab)}>
							<Text style={styleTab(selectedTab, tab, width)}>
								{tab}
							</Text>
						</Pressable>
					))}
				</View>
			)}
			<View style={styles.tableContainer}>
				{loading ? (
					<Loader />
				) : data && data.length > 0 ? (
					<>
						<Table
							content={data}
							headers={HEADERS}
							type="order"
							tableContentProps={{ viewButton: true }}
						/>
						<View
							style={{
								marginTop: getResponsiveStyle(33),
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{hasViewAllButton && (
								<ViewAllText
									title="View All Orders"
									navigationDestination="AllOrders"
								/>
							)}
						</View>
					</>
				) : (
					<Text>No Available Orders</Text>
				)}
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	container: {
		marginTop: getResponsiveStyle(20),
	},
	tabContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	header: {
		fontSize: getResponsiveStyle(24),
		color: palettes.dark[0],
		fontWeight: 'bold',
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
	},
	tab: {
		textAlign: 'center',
		textTransform: 'capitalize',
	},
	tabNotSelected: {
		color: palettes.grey[0],

		fontWeight: '400',
	},
	tabSelected: {
		color: palettes.dark[0],
		paddingBottom: 9,
		paddingHorizontal: 3,

		fontWeight: 'bold',
		borderBottomColor: palettes.primary[0],
		borderBottomWidth: 1,
	},
	tableContainer: {
		marginTop: getResponsiveStyle(50),
	},
});

const styleTab = (selectedTab: string, tab: string, width: number) => {
	return [
		styles.tab,
		{
			marginRight: getResponsiveStyle(24),
			fontSize: getResponsiveStyle(14, 'font'),
			lineHeight: getResponsiveStyle(14, 'font'),
		},
		selectedTab === tab ? styles.tabSelected : styles.tabNotSelected,
		// {
		// 	marginBottom: getResponsiveStyle(48),
		// },
	];
};

export default OrderComponent;
