import React from 'react';
import { View } from 'react-native';
import { HeadingLarge } from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import AddSingleProductFormOld from '../components/products/add-single-product-form/AddSingleProductFormOld';

const ProductsSingleUploadScreenOld = (props: any) => {
    const productId = props.route?.params?.productId;

    return (
        <DesktopTemplate
            navigationBarProps={{
                hasGoBackButton: true,
            }}
        >
            <View style={{ alignItems: 'center' }}>
                <View style={{ width: getResponsiveStyle(465, 'dimensions') }}>
                    <HeadingLarge
                        heading="Add New Product"
                        text="Fill out all the mandatory fields"
                    />
                    <AddSingleProductFormOld productId={productId} />
                </View>
            </View>
        </DesktopTemplate>
    );
};

export default ProductsSingleUploadScreenOld;
