import { ItemValue } from '@react-native-picker/picker/typings/Picker';
import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { PickerValue, TTableHeader } from '../../../types/types';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Loader from '../loader/Loader';
import { TableContent } from './TableContent';
import { TableHeader } from './TableHeader';

export type TTableOnChangeDropdown = (value: ItemValue, indexOfItem: number, id?: string | number) => void

export type TableContentProps = {
	type?:
		| 'brand'
		| 'profile'
		| 'order'
		| 'Admin'
		| 'Events'
		| 'tag'
		| 'singleOrder'
		| 'product';
	messageButton?: boolean;
	profileButton?: boolean;
	followButton?: boolean;
	adminButton?: boolean;
	viewButton?: boolean;
	hasCheckbox?: boolean;
	onChangeCheckbox?: (id: number) => void;
	onChangePickerVal?: (val: PickerValue, id: number) => void;
	onChangeDropdown?: TTableOnChangeDropdown;
};

export type TTableLoadMoreProps = {
	hasLoadMore: boolean;
	onPress: () => void;
	loading: boolean;
};
interface Props<T> {
	headers: TTableHeader[];
	content: T[];
	dataDetail?: any;
	type?:
		| 'brand'
		| 'profile'
		| 'order'
		| 'Admin'
		| 'Events'
		| 'singleOrder'
		| 'tag'
		| 'product';
	tableContentProps?: TableContentProps;
	showScrollBar?: boolean;
	loadMoreObject?: TTableLoadMoreProps;
}

export const Table = <T extends unknown>({
	headers,
	content,
	dataDetail,
	type,
	tableContentProps,
	showScrollBar,
	loadMoreObject = {
		hasLoadMore: false,
		onPress: () => {},
		loading: false,
	},
}: Props<T>) => {
	return (
		<View style={styles.container}>
			<ScrollView
				horizontal
				style={styles.scrollViewContainer}
				contentContainerStyle={styles.contentContainerStyle}
				showsHorizontalScrollIndicator={showScrollBar}
			>
				<TableHeader
					headers={headers}
					type={type}
					hasCheckbox={tableContentProps?.hasCheckbox}
				/>
				{content.map((user: T, index) => (
					<TableContent
						key={index}
						user={user}
						dataDetail={dataDetail}
						headers={headers}
						id={index}
						type={type}
						{...tableContentProps}
					/>
				))}
				{loadMoreObject.hasLoadMore && (
					<View style={styles.loadMoreContainer}>
						{loadMoreObject.loading ? (
							<Loader size={'small'} />
						) : (
							<StyledLoadMore onPress={loadMoreObject.onPress}>
								Load more
							</StyledLoadMore>
						)}
					</View>
				)}
			</ScrollView>
		</View>
	);
};

const StyledLoadMore = styled.Text`
	color: ${palettes.primary[0]};
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
	&:hover {
		border-bottom: 1px solid ${palettes.primary[0]};
	}
`;

const styles = StyleSheet.create({
	loadMoreContainer: {
		width: '100%',
		alignItems: 'center',
		marginTop: getResponsiveStyle(16),
	},
	container: {
		marginLeft: -24,
	},
	scrollViewContainer: {
		flexDirection: 'column',
		width: '100%',
	},
	contentContainerStyle: {
		flexDirection: 'column',
		minWidth: '100%',
		overflow: 'visible',
	},
});

export default Table;
