import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCreateProduct } from '../types/types';

const initialState: TCreateProduct = {
	name: '',
	shortDescription: '',
	description: '',
	category: '',
	images: [''],
	brand: '',
	options: [''],
	variants: [],
	publishedStatus: '',
};

const createProductSlice = createSlice({
	name: 'creatProduct',
	initialState,
	reducers: {
		setProductDetails(state, action: PayloadAction<TCreateProduct>) {
			state = action.payload;
		},
		// setaffiliatedBrands(state, action: PayloadAction<string>) {
		// 	state.affiliatedBrands = [
		// 		...state.affiliatedBrands,
		// 		action.payload,
		// 	];
		// },
	},
});

export default createProductSlice.reducer;
export const { setProductDetails } = createProductSlice.actions;
