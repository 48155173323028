import { useMutation } from '@apollo/client';
import React, { useRef } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { DELETE_NOTIFICATION } from '../../../graphql/notification/mutations';
import { GET_ALL_NOTIFICATIONS } from '../../../graphql/notification/queries';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { InboxIcon } from '../icons';
import Loader from '../loader/Loader';
import { NotificationType } from '../../../types/types';
import Button from '../button/Button';
import { ACCEPT_OR_REJECT } from '../../../graphql/user/mutation';
// import ProfileIcon from '../ProfileIcon';

const StyledClearText = styled(Text)`
	font-size: ${getResponsiveStyle(11)}px;
	line-height: ${getResponsiveStyle(11)}px;
	color: ${palettes.red[0]};
	cursor: pointer;
	padding-bottom: 2;

	border-bottom: 1px solid ${palettes.red[0]};
`;

const getSecondsFromDate = 1000;
const getMinuteFromDate = 60000;
const getHourFromDate = 3600000;
const getDayFromDate = 86400000;
const aDay = 24;
const aMinute = 60;

export const StandardNotification = ({
	notification,
	hovered,
	type = 'standard',
}: {
	notification: NotificationType;
	hovered?: boolean;
	type?: 'standard' | 'password';
}) => {
	console.log(type);

	const ref = useRef(null);
	const isHovered = useHover(ref);
	const { setAlert } = useSetAlert();
	const [deleteNotification, { loading }] = useMutation(DELETE_NOTIFICATION, {
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_ALL_NOTIFICATIONS }],
		onCompleted: data => {
			if (data && data.deleteNotification.success) {
				setAlert('Notification successfully deleted', 'normal');
			} else {
				setAlert('Could not delete notification', 'danger');
			}
		},
		onError: () => {
			setAlert('Could not delete notification', 'danger');
		},
	});

	const [deleteNotification_] = useMutation(DELETE_NOTIFICATION, {
		awaitRefetchQueries: true,
		refetchQueries: [{ query: GET_ALL_NOTIFICATIONS }]
	})
	console.log(notification.user._id)
	const [acceptUser, { loading: loadingAccept }] = useMutation(ACCEPT_OR_REJECT, {
		variables: {
			id: notification.sentBy?._id,
			status: 'ACTIVE'

		},
		onCompleted: data => {
			deleteNotification_({
				variables: {
					args: {
						id: notification._id
					}
				}
			});
			if (data && data.acceptOrRejectUser.success) {
				setAlert('Approved user successfullly', 'normal');
			} else {
				setAlert('Could not approve user', 'danger');
			}
		},
		onError: () => {
			setAlert('Could not approve user', 'danger')
		},

	})

	const [rejectUser, { loading: loadingReject }] = useMutation(ACCEPT_OR_REJECT, {
		variables: {
			id: notification.sentBy?._id,
			status: 'BLACK_LISTED'

		},
		onCompleted: data => {
			deleteNotification_({
				variables: {
					args: {
						id: notification._id
					}
				}
			});
			if (data && data.acceptOrRejectUser.success) {
				setAlert('Rejected user successfullly', 'normal');
			} else {
				setAlert('Could not reject user', 'danger');
			}
		},
		onError: () => {
			setAlert('Could not reject notification', 'danger');
		},

	})

	if (loading) {
		return <Loader size={'small'} />;
	}

	return (
		<View style={[styles.container, styleContainer()]} ref={ref}>
			<View style={styles.notificationIconContainer}>
				{type === 'standard' && (
					<InboxIcon
						style={[styles.notificationIcon]}
					// isPressable={false}

					/>
				)}

			</View>
			<View style={[detailsContainer()]}>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',

						alignItems: 'flex-start',
					}}
				>
					{/* <Text style={[styleEventName(), styles.eventName]}>
						{notification.notificationType !==
							'EVENT_ABOUT_TO_START_NOTIFICATION' &&
							notification.notificationType !==
							'EVENT_ABOUT_TO_END_NOTIFICATION' &&
							notification.notificationType !==
							'ADMIN_NOTIFICATION' &&
							notification.sentBy.username}
					</Text> */}
					{isHovered && (
						<TouchableOpacity
							onPress={() => {
								console.log('wellccommm');
								deleteNotification({
									variables: {
										args: {
											id: notification._id,
										},
									},
								});
							}}
						>
							<StyledClearText>Clear</StyledClearText>
						</TouchableOpacity>
					)}
					{/* {isHovered && <StyledClearText>Clear</StyledClearText>} */}
				</View>

				<Text
					style={[styleEventDescription(), styles.eventDescription]}
				>
					{/* {notification.notificationType === 'ADMIN_NOTIFICATION' && */}
						{'A new user with username' + ' ' + notification.message + ' ' + 'has just registered'}
				</Text>
				<Text style={[styleEventDate(), styles.eventDate]}>
					{Math.floor(
						(Date.now() - Number(notification?.createdAt)) /
						getSecondsFromDate
					) < aMinute ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
								getSecondsFromDate
							)}
							{''}s ago
						</Text>
					) : Math.floor(
						(Date.now() - Number(notification?.createdAt)) /
						getMinuteFromDate
					) < aMinute ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
								getMinuteFromDate
							)}
							{''}m ago
						</Text>
					) : Math.floor(
						(Date.now() - Number(notification?.createdAt)) /
						getHourFromDate
					) < aDay ? (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
								getHourFromDate
							)}
							{''}h ago
						</Text>
					) : (
						<Text style={[styles.eventDate]}>
							{Math.floor(
								(Date.now() - Number(notification?.createdAt)) /
								getDayFromDate
							)}{' '}
							days ago
						</Text>
					)}
				</Text>
				<div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'row' }}>
					<Button
						title={loadingAccept ? <Loader size={"small"} /> : "Accept"}
						onPress={() => {
							acceptUser()

						}}
						variant="grey"
						outerContainerProps={{
							style: {
								flexBasis: getResponsiveStyle(112),
							},
						}}
					/>
					<Button
						title={loadingReject ? <Loader size={"small"} /> : "Reject"}
						onPress={() => {
							rejectUser()
						}}
						outlineColor={palettes.red[0]}
						outerContainerProps={{
							style: {
								width: getResponsiveStyle(73),
							},
						}}
					/>
				</div>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	eventDescription: {
		color: palettes.dark[4],
	},
	eventDate: {
		color: palettes.grey[2],
		marginTop: 6,
	},
	eventName: {
		fontWeight: '600',
		color: palettes.dark[0],
	},
	notificationIcon: {
		width: 28,
		height: 28,
	},
	notificationIconContainer: {
		width: getResponsiveStyle(56),
		height: getResponsiveStyle(56),
		justifyContent: 'center',
		alignItems: 'center',
		borderColor: palettes.grey[4],
		borderWidth: 1,
		borderRadius: 9999,
	},
	container: {
		flexDirection: 'row',
		borderTopWidth: 1,
		borderTopColor: palettes.grey[5],
		alignItems: 'center',
		gap: getResponsiveStyle(16),
	},
});

function styleEventDescription() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}

function styleEventName() {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		marginBottom: getResponsiveStyle(5),
	};
}
function styleEventDate() {
	return {
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}
function detailsContainer() {
	return {
		width: `calc(100% - ${getResponsiveStyle(56 + 16 + 24)}px)`,
	};
}

function styleNotificationIconContainer(primary: string[]) {
	return {
		right: getResponsiveStyle(10, 'font'),
		bottom: getResponsiveStyle(-6, 'font'),
		backgroundColor: primary[0],
	};
}

function styleProfileIcon() {
	return {
		width: getResponsiveStyle(56, 'dimensions'),
		height: getResponsiveStyle(56, 'dimensions'),
		marginRight: getResponsiveStyle(16),
	};
}

function styleContainer() {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		paddingVertical: getResponsiveStyle(15),
	};
}
