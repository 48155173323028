import styled from "rn-css";
import { palettes } from "../../../config";
import { getResponsiveStyle } from "../../../utils/getResponsiveStyle";

export const Action = styled.Text`
  cursor: pointer;
  font-weight: 600;
  text-align: right;
  padding-bottom: 2;
  border-bottom: 1px solid transparent;
  font-size: ${getResponsiveStyle(12, "font")}px;
  line-height: ${getResponsiveStyle(14, "font")}px;
`;

const ActionLink = styled(Action)`
  color: ${palettes.grey[1]};
  &:hover {
    border-bottom: 1px solid ${palettes.grey[1]};
  }
`;

export default ActionLink;
