import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import styled from 'rn-css';
import { palettes } from '../../../config';
import { StarterKit } from '../../../types/productTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

export const CollectionDetails = (props: StarterKit) => {
	return (
		<View style={styles.container}>
			<Text numberOfLines={2} style={styles.collectionName}>
				{props.name}
			</Text>
			<Text numberOfLines={2} style={styles.collectionText}>
				{props.description}
			</Text>
			{/* <ViewAll>View All</ViewAll> */}
		</View>
	);
};

const ViewAll = styled.Text`
	font-size: ${getResponsiveStyle(14, 'font')}px;
	line-height: ${getResponsiveStyle(14, 'font')}px;
	font-weight: 600;
	padding-bottom: 2px;
	color: ${palettes.grey[1]};
	cursor: pointer;
	width: max-content;
	&:hover {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
`;

const styles = StyleSheet.create({
	collectionText: {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(20, 'font'),
		marginBottom: getResponsiveStyle(38),
		maxWidth: getResponsiveStyle(222, 'dimensions'),
		color: palettes.grey[0],
	},
	collectionName: {
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(18),
		marginBottom: getResponsiveStyle(8),
		maxWidth: getResponsiveStyle(222, 'dimensions'),
		fontWeight: '600',
		color: palettes.dark[0],
	},
	container: {
		minWidth: getResponsiveStyle(296, 'dimensions'),
		marginRight: getResponsiveStyle(8),
	},
});
