import { gql } from '@apollo/client';
import { ADMIN_USER_FRAGMENT } from './fragments';

export const GET_ALL_ADMINS = gql`
	${ADMIN_USER_FRAGMENT}
	query getAllAdmins(
		$current: Int
		$limit: Int
		$sort: JSON
		$populate: JSON
		$find: AdminQueryParam
	) {
		getAllAdmins(
			find: $find
			current: $current
			limit: $limit
			sort: $sort
			populate: $populate
		) {
			statusCode
			success
			message
			data {
				... on All {
					total
					data {
						...AdminUserFragment
					}
				}
			}
		}
	}
`;
