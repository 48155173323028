import React from 'react';
import DropDownPicker, {
	DropDownPickerProps,
} from 'react-native-dropdown-picker';
import { ChevronDown } from '../..';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {} & DropDownPickerProps;

const DropdownSmall = ({
	containerStyle,
	textStyle,
	style,
	dropDownContainerStyle,
	listItemContainerStyle,
	open,
	setOpen,
	...props
}: Props) => {
	return (
		<DropDownPicker
			{...props}
			zIndex={2000}
			zIndexInverse={2000}
			listMode="SCROLLVIEW"
			showTickIcon={false}
			disableBorderRadius={false}
			open={open}
			setOpen={setOpen}
			scrollViewProps={{
				showsVerticalScrollIndicator: false,
				showsHorizontalScrollIndicator: false,
			}}
			style={[
				{
					flexDirection: 'row',
				},
				style,
			]}
			textStyle={[
				{
					fontSize: getResponsiveStyle(14, 'font'),
					lineHeight: getResponsiveStyle(14, 'font'),
					color: palettes.grey[0],
					marginRight: 4,
				},
				textStyle,
			]}
			containerStyle={[
				{
					width: 'max-content',
				},
				containerStyle,
			]}
			dropDownContainerStyle={[
				{
					paddingTop: getResponsiveStyle(6),
					borderRadius: 6,
					marginTop: getResponsiveStyle(8),
					borderColor: palettes.grey[3],
					borderTopLeftRadius: 6,
					borderTopRightRadius: 6,
					shadowOffset: {
						width: 0,
						height: 15,
					},
					shadowRadius: 25,
					shadowColor: 'rgba(124, 136, 155, 0.12)',
					shadowOpacity: 1,
					zIndex: 999,
				},
				dropDownContainerStyle,
			]}
			listItemContainerStyle={[
				{
					marginBottom: getResponsiveStyle(6),
					height: getResponsiveStyle(25, 'dimensions'),
					paddingHorizontal: getResponsiveStyle(8),
					borderLeftWidth: 2,
					borderLeftColor: 'transparent',
				},
				listItemContainerStyle,
			]}
			listItemLabelStyle={{
				display: 'flex',
				alignItems: 'center',
			}}
			selectedItemContainerStyle={{
				borderLeftWidth: 2,
				borderLeftColor: palettes.primary[0],
			}}
			selectedItemLabelStyle={{
				color: palettes.dark[0],
			}}
			ArrowDownIconComponent={() => (
				<ChevronDown
					style={{
						width: getResponsiveStyle(8, 'dimensions'),
						height: getResponsiveStyle(6, 'dimensions'),
						marginTop: getResponsiveStyle(5),
					}}
					onPress={() => setOpen(!open)}
				/>
			)}
			ArrowUpIconComponent={() => (
				<ChevronDown
					style={{
						width: getResponsiveStyle(8, 'dimensions'),
						height: getResponsiveStyle(6, 'dimensions'),
						marginTop: getResponsiveStyle(5),
						transform: [{ rotate: '180deg' }],
					}}
					onPress={() => setOpen(!open)}
				/>
			)}
		/>
	);
};

export default DropdownSmall;
