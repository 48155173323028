import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { Hoverable } from 'react-native-web-hover';
import { palettes } from '../../../config';

const NotificationWrapper = ({
	children,
}: {
	children: React.ReactElement;
}) => {

	return (
		<Hoverable
			children={({ hovered }) => (
				<View
					style={{
						borderLeftColor: hovered ? palettes.blue[0] : '#fff',
						borderLeftWidth: 2,
					}}
				>
					{React.cloneElement(children, { hovered })}
					
				</View>
			)}
		/>
	);
};

const styles = StyleSheet.create({
	gradientBox: {
		width: '100%',
		height: 70,
		position: 'absolute',
		bottom: 0,
	},
});

export default NotificationWrapper;
