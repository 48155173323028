import React from 'react';
import { DraggableScrollView, ProductCard } from '../..';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { Product, StarterKit, Variant } from '../../../types/productTypes';
import { TStyle } from '../../../types/TStyle';
import { createArray } from '../../../utils/createArray';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const array = createArray(5);

const ProductCards = ({ variants }: { variants: Variant[] }) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return (
		<DraggableScrollView
			horizontal
			data={variants}
			renderItem={({ item, index }: any) => {
				return (
					<ProductCard
						product={item}
						isLastItem={array.length - 1 === index}
					/>
				);
			}}
			showsHorizontalScrollIndicator={false}
			style={[styleDraggableScroll(isLessThanDesktopBase)]}
		/>
	);
};

const styleDraggableScroll = (isLessThanDesktopBase: boolean): TStyle => {
	return {
		width: `calc(100% + ${
			isLessThanDesktopBase
				? getResponsiveStyle(16)
				: getResponsiveStyle(32)
		}px)`,
		paddingBottom: getResponsiveStyle(20),
		paddingLeft: getResponsiveStyle(8),
	};
};

export default ProductCards;
