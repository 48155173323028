import React, { useState, useMemo } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { ProfileStack } from '../profile-stack/ProfileStack';
import { ActionLink, Button } from '../..';
import { useNavigation } from '@react-navigation/native';
import { TODO, TUseNavigation } from '../../../types/types';
import { AcceptOrRejectEvent } from '../../../types/eventTypes';
import { TGetAllEventsData } from '../../../types/eventTypes';
import CardBottomAction from './CardBottomAction';
import { useMutation } from '@apollo/client';
import { DELETE_EVENT } from '../../../graphql/events/mutations';
import { useSetAlert } from '../../../hooks/useSetAlerts';

export type EventCardBottomProps = {
	hasRequestButton: boolean;
	month: string;
	date: string;
	title: string;
	description: string;
	item: TGetAllEventsData;
	id: string;
	acceptOrRejectMutation?: (variables?: AcceptOrRejectEvent) => Promise<TODO>;
	clickable?: boolean;
};

export const CardBottom = ({
	date,
	description,
	hasRequestButton,
	acceptOrRejectMutation,
	clickable = false,
	month,
	id,
	title,
	item,
}: EventCardBottomProps) => {
	const { setAlert } = useSetAlert();
	const navigation = useNavigation<TUseNavigation>();
	const [deleteEvent, { data, error, loading }] = useMutation(DELETE_EVENT, {
		onCompleted: (data) => {
			if (data?.deleteEvent?.success) {
				setAlert(data?.deleteEvent?.message, "normal")
				navigation.navigate('Dashboard');
			} else {
				setAlert(data?.deleteEvent?.message, "danger")
			}
		}
	});
	const handleNavigation = () => {
		navigation.navigate('Events');
	};

	const handleEventDelete = () => {
		deleteEvent({
			variables: {
				_id: item?._id,
			},
		});
	}
	const handleNavigationOnclick = () => {
		navigation.navigate('EventDescription', { eventId: id });
	};

	const invitedPeople = useMemo(() => {
		return item?.invitedPeople?.map(
			i => i?.personalInformation?.profileImageLink
		);
	}, [item?.invitedPeople]);

	return (
		<View style={[styles.cardBottom, styleCardBottom()]}>
			<View style={[styles.dateContainer, styleDateContainer()]}>
				<Text
					style={[
						styles.dateContainerMonth,
						styleDateContainerMonth(),
					]}
				>
					{month}
				</Text>
				<Text
					style={[styles.dateContainerDate, styleDateContainerDate()]}
				>
					{date}
				</Text>
			</View>
			<View style={styles.detailsContainer}>
				<View style={{ flexDirection: 'column' }}>
					{clickable ? (
						<Pressable onPress={handleNavigationOnclick} >
							<Text
								style={[
									styles.detailsHeading,
									styleDetailsHeading(),
								]}
								numberOfLines={1}
							>
								{title}
							</Text>
							<Text
								style={[styles.detailsText, styleDetailsText()]}
								numberOfLines={2}
							>
								{description}
							</Text>
						</Pressable>
					) : (
						<>
							<Text
								style={[
									styles.detailsHeading,
									styleDetailsHeading(),
								]}
								numberOfLines={1}
							>
								{title}
							</Text>
							<Text
								style={[styles.detailsText, styleDetailsText()]}
								numberOfLines={2}
							>
								{description}
							</Text>
						</>
					)}
				</View>
				{hasRequestButton ? (
					<View
						style={
							clickable ? { position: 'absolute', bottom: 0 } : {}
						}
					>
						<CardBottomAction
							id={id}
							acceptOrRejectMutation={acceptOrRejectMutation}
						/>
					</View>
				) : (
					<View style={{ flexDirection: 'column' }}>
						<Text
							style={[
								styles.peopleAreGoing,
								stylePeopleAreGoing(),
							]}
						>
							People are going
						</Text>
						<View style={styles.profileStackRow}>
							<ProfileStack
								profileLink={
									invitedPeople || [
										'https://via.placeholder.com/500',
									]
								}
							/>
							<Button
								title="Delete"
								variant="primary"
								onPress={handleEventDelete}
								loading={loading}
								innerContainerProps={{
									style: {
										paddingLeft: '2rem',
										paddingRight: '2rem',
									},
								}}
							/>
						</View>
					</View>
				)}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	cardBottom: {
		display: 'flex',
		flexDirection: 'row',
		backgroundColor: 'white',
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
		height: '50%',
		position: 'relative',
	},
	dateContainer: {
		minWidth: 'max-content',
		textTransform: 'uppercase',
		textAlign: 'center',
	},
	dateContainerMonth: {
		fontWeight: '600',
		color: palettes.primary[0],
	},
	dateContainerDate: {
		fontWeight: '400',
		color: palettes.dark[0],
	},
	detailsContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		flexShrink: 1,
		justifyContent: 'space-between',
	},
	detailsHeading: {
		fontWeight: '700',
		color: palettes.dark[0],
		marginBottom: 3,
	},
	detailsText: {
		fontWeight: '400',
		color: palettes.dark[4],
		marginBottom: 13,
	},
	peopleAreGoing: {
		fontWeight: '600',
	},
	profileStackRow: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		alignItems: 'center',
	},
	viewDetailsText: {
		fontWeight: '600',
		color: palettes.grey[1],
		textDecorationLine: 'underline',
		fontSize: 14,
		lineHeight: 14,
	},
});

const stylePeopleAreGoing = () => {
	return {
		fontSize: getResponsiveStyle(10, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		marginBottom: getResponsiveStyle(5),
	};
};

const styleDetailsText = () => {
	return {
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
	};
};

const styleDetailsHeading = () => {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(24, 'font'),
	};
};

const styleDateContainerDate = () => {
	return {
		fontSize: getResponsiveStyle(24, 'font'),
		lineHeight: getResponsiveStyle(24, 'font'),
	};
};

const styleDateContainerMonth = () => {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
		marginBottom: getResponsiveStyle(4),
	};
};

const styleDateContainer = () => {
	return { paddingRight: getResponsiveStyle(15) };
};

const styleCardBottom = () => {
	return {
		paddingVertical: getResponsiveStyle(19),
		paddingLeft: getResponsiveStyle(17),
		paddingRight: getResponsiveStyle(17),
	};
};
