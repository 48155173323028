import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ImageBackground, Text } from 'react-native';
import { Button, DumbComponentForFlex, GoBackButton, Tabs } from '../..';
import { useCreateConnectToken } from '../../../api/brands/useCreateConnectToken';
import useGetBrandConnectBalance from '../../../api/brands/useGetBrandConnectBalance';
import { useOnBoardingFlow } from '../../../api/brands/useOnBoardingFlow';
import { breakpoints, palettes } from '../../../config';
import { GET_BRAND_CONNECT_ACCOUNT, LIST_BANKS } from '../../../graphql/stripe/queries';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { TStyle } from '../../../types/TStyle';
import { TBankAccount, TStat, TOptions } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ChooseBank from '../../withdrawForm/ChooseBank';
import WithdrawForm from '../../withdrawForm/withdrawForm';
import Loader from '../loader/Loader';
import ModalComponent from '../modal/ModalComponent';
import Tab2 from '../tabs/Tab2';
import { CompanyProfileNameRating } from './PageProfileNameRating';
import { CompanyStats } from './PageStats';

type Props = {
	name: string;
	city: string;
	countryCode: string;
	countryLabel: string
	profileImage?: string;
	rating?: number;
	blockUserButton?: boolean
	stats: TStat[];
	tabs: string[];
	onPressTab: (selectedTab: string) => void;
	outlineColor: string;
	selectedTab: string;
	isProfile?: boolean;
	verified?: boolean;
	showTabs?: boolean;
	isUser?: boolean;
	isUserFollowed?: boolean;
	followLoading?: boolean;
	followUser?: (followedId: string) => void;
	_id: string;
	type?: string
	bankAccounts?: TBankAccount[]
	email?: string
	line1?: string
};

const BrandDetailsHero = ({
	city,
	countryCode,
	countryLabel,
	name,
	onPressTab,
	rating,
	selectedTab,
	stats,
	tabs,
	email = '',
	line1 = '',
	blockUserButton = false,
	isProfile = false,
	outlineColor = palettes.grey[0],
	verified = false,
	showTabs = true,
	isUserFollowed,
	followLoading,
	followUser,
	profileImage,
	_id,
	type,
	bankAccounts
}: Props) => {
	const { width, isLessThanDesktopBase } = useIsSpecificViewportWidth();

	const [option, setOption] = useState<TOptions>('NOT_SELECTED')
	const [modalState, setModalState] = useState(false)
	const [accounts, setAccounts] = useState(bankAccounts)
	const [token, setToken] = useState('')
	const [onboardingFinish, setOnboardingFinish] = useState<boolean | string>('');
	const [connectAccount, setConnectAccount] = useState('');
	const [getBrandConnect, { data: getConnetData }] = useLazyQuery(GET_BRAND_CONNECT_ACCOUNT);
	const [brandAccounts, setBrandAccounts] = useState([])
	const [listBanks, { loading: banksLoading, data: banksListed }] = useLazyQuery(LIST_BANKS);
	const {setAlert} = useSetAlert()
	const createConnectToken = useCreateConnectToken({
		city,
		email,
		first_name: name,
		line1,
	})

	const getToken = async () => {
		const { account } = await createConnectToken()
		setToken(account?.token?.id)
	}
	const { availableBalance, getConnectBalance, pendingBalance } = useGetBrandConnectBalance({
		connectAccountId: getConnetData?.getBrandConnectAccount?.data?.connectAccountId
	})
	useEffect(() => {
		getBrandConnect({
			variables: {
				brandId: _id
			}
		})
		getToken()

	}, [])

	useEffect(() => {
		if (getConnetData?.getBrandConnectAccount?.data?.connectAccountId) {
			const connectId = getConnetData?.getBrandConnectAccount?.data?.connectAccountId
			listBanks({
				variables: {
					args: {
						id: connectId,
						// id: connectAccountId_,
					},
				},
				onCompleted: (data) => {
					setBrandAccounts(data?.listBanks?.data?.data)
				}
			});
			getConnectBalance()
		}
	}, [getConnetData])

	const { onBoard, connectError } = useOnBoardingFlow({
		brandId: _id,
		connectAccount,
		countryLabel,
		getConnetData,
		onboardingFinish,
		setConnectAccount,
		setOnboardingFinish,
		setModalState,
		token
	})

	useEffect(() => {
		if (connectError) {
			setAlert(connectError.message, 'normal');
			setModalState(false)
		}
	}, [connectError]);
	return (
		<>
			<ImageBackground
				style={[styles.container, styleContainer(width)]}
				source={require('../../../assets//images/page-hero-background.png')}
			>
				<View style={stylePaddings(width)}>
					{isProfile && (
						<View style={styles.brandHero}>
							<GoBackButton containerStyles={styleGoBackButton()} />
							<View>
								{type === 'BRAND' &&
									(<Button
										title="Withdraw"
										variant="primary"
										onPress={() => {
											onBoard();
											setModalState(true)
										}
										}
										size='sm'
									/>)
								}
							</View>
						</View>
					)}
					<View style={[styleCompanyDetailsContainer()]}>


						<CompanyProfileNameRating
							companyName={name}
							city={city}
							countryCode={countryCode}
							rating={rating || 3}
							isProfile={isProfile}
							verified={verified}
							profileImage={profileImage}
						/>

						<DumbComponentForFlex flex={0.81} />
						{!isLessThanDesktopBase && <CompanyStats stats={stats} />}
						<DumbComponentForFlex flex={1} />
						{/* {blockUserButton&& (
						<Button title="Block User" outlineColor={outlineColor} size="sm" />
					) } */}
						<DumbComponentForFlex flex={0.9} />


					</View>
					{isLessThanDesktopBase && (
						<CompanyStats
							stats={stats}
							containerStyles={companyStatsContainer()}
						/>
					)}
				</View>


			</ImageBackground>
			<Tab2
				tabs={tabs}
				onPress={onPressTab}
				selectedTab={selectedTab}
				containerStyle={[styles.tabContainer, styleTabContainer()]}
			/>



			{/* <ModalComponent
				header="Create Withdraw request"
				isScrollable
				openState={modalState}
				onClose={() => {
					setModalState(false);
					setOption('NOT_SELECTED')
				}}
			>
				{
					// bankAccounts?.length && option === 'NOT_SELECTED' ?
					// 	<ChooseBank setOption={setOption} bankAccounts={accounts} setAccounts={setAccounts} _id={_id} /> :
						<WithdrawForm
							onSubmit={() => { }}
							brandName={name}
							_id={_id}
							setModalState={setModalState}
							option={option}
							setOption={setOption}
							setAccounts={setAccounts}
							accounts={accounts}
						/>
				}

			</ModalComponent> */}


			<ModalComponent
				// type="sidebar"
				isScrollable
				header="Withdraw"
				openState={modalState}
				onClose={() => setModalState(false)}
			>
				{connectAccount === 'No Connect account' ? (
					<View>
						<View>
							{
								'To add bank accounts for receiving payouts,your account has to be enabled, please wait...'
							}
						</View>
						<Loader />
					</View>
				) : !onboardingFinish ? (
					<View>
						<View>
							{
								'Further verification is need, Onboarding in progress...'
							}
						</View>
						<Loader />
					</View>
				) : getConnetData?.getBrandConnectAccount?.data?.currently_due
					?.length > 1 ||
					(getConnetData?.getBrandConnectAccount?.data?.currently_due
						?.length === 1 &&
						!getConnetData?.getBrandConnectAccount?.data?.currently_due?.includes(
							'external_account'
						)) ? (
					<View>
						<a
							href={
								getConnetData &&
								getConnetData.getBrandConnectAccount?.data?.lastUrl
							}
						>
							{'Click on this to provide verification details'}
						</a>
					</View>
				) : (
					brandAccounts?.length && option === 'NOT_SELECTED' ?
						<ChooseBank
							setOption={setOption}
							bankAccounts={brandAccounts}
							setAccounts={setBrandAccounts}
							_id={_id}
							connectAccountId={getConnetData?.getBrandConnectAccount?.data?.connectAccountId}
						/> :
						<WithdrawForm
							onSubmit={() => { }}
							brandName={name}
							_id={_id}
							setModalState={setModalState}
							option={option}
							setOption={setOption}
							setAccounts={setAccounts}
							accounts={brandAccounts}
							country={countryLabel}
							connectAccountId={getConnetData?.getBrandConnectAccount?.data?.connectAccountId}
							availableBalance={availableBalance / 100}
							pendingBalance = {pendingBalance/ 100}
						/>
				)}
			</ModalComponent>
		</>
	);
};

export default BrandDetailsHero;

const styles = StyleSheet.create({
	tabContainer: {
		marginBottom: 0,
		paddingTop: getResponsiveStyle(40),
		width: '100vw',
	},
	container: {
		// backgroundColor: '#FAFBFC',
		width: '100vw',
		borderBottomWidth: 1,
		borderBottomColor: palettes.grey[5],

	},
	walletButtons: {
		flex: 1,
	},
	brandHero: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		gap: '67vw'
		// justifyContent: 'space-between',
		// alignItems: 'center'
	}
});
function styleTabContainer() {
	return {

		paddingRight: getResponsiveStyle(40),
	};
}

function companyStatsContainer(): TStyle {
	return { marginBottom: getResponsiveStyle(37) };
}

function styleCompanyDetailsContainer(): TStyle {
	return {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		paddingBottom: getResponsiveStyle(22),
	};
}

function styleGoBackButton(): TStyle {
	return { marginBottom: getResponsiveStyle(32) };
}

function styleContainer(width: number): TStyle {
	return {
		paddingTop: getResponsiveStyle(32),
		marginLeft: width >= breakpoints.desktopBase ? -40 : -16,
	};
}

function stylePaddings(width: number) {
	return {
		paddingLeft: width >= breakpoints.desktopBase ? 40 : 16,
		paddingRight: width >= breakpoints.desktopBase ? 40 : 16,

	};
}
