import { gql } from '@apollo/client';

const CREATE_MEDIA = gql`
	mutation createMedia(
		$file: Upload!
		$category: MediaCategory!
		$alt: String
	) {
		createMedia(file: $file, category: $category, alt: $alt) {
			... on Response {
				success
				statusCode
				message
				data {
					... on Media {
						name
						_id
						alt
						src
					}
				}
			}
		}
	}
`;
export { CREATE_MEDIA };
