import * as yup from 'yup';
import { Variant } from '../../../types/productTypes';
import { yupsRequiredString } from '../../../utils/yupsRequiredString';
export const addOptionValueSchema = yup.object({
	name: yup.string(),
	price: yupsRequiredString(),
	compareAtPrice: yup.string().nullable(),
});
export const addVariantFormValSchema = yup.object({
	variantType: yupsRequiredString(),
	// value: yupsRequiredString(),
	// GTIN: yupsRequiredString(),
	// _id: yup.string(),
	// image: yup.object({
	// 	_id: yup.string(),
	// 	src: yup.string(),
	// }),
	// options: yup.object({
	// 	name: yup.string(),
		
	// 	inventory: yup.object({
	// 		quantity: yup.number(),
	// 		isAvailable: yup.boolean(),
	// 		isTracked: yup.boolean(),
	// 	}).required(),
	// 	values: yup
	// 		.array()
	// 		.of(addOptionValueSchema)
	// 		.compact()
	// 		.required()
	// 		.min(1),
	// }),
	// vat: yupsRequiredString(),
	// weight: yup.string().nullable(),
	// weightUnit: yup.string().nullable(),
});

export const addVariantFormValSchemaOld = yup.object({
	variantType: yupsRequiredString(),
	value: yupsRequiredString(),
	GTIN: yupsRequiredString(),
	_id: yup.string(),
	image: yup.object({
		_id: yup.string(),
		src: yup.string(),
	}),
	options: yup.object({
		name: yup.string(),
		
		inventory: yup.object({
			quantity: yup.number(),
			isAvailable: yup.boolean(),
			isTracked: yup.boolean(),
		}).required(),
		values: yup
			.array()
			.of(addOptionValueSchema)
			.compact()
			.required()
			.min(1),
	}),
	vat: yupsRequiredString(),
	weight: yup.string().nullable(),
	weightUnit: yup.string().nullable(),
});

export type TAddValuesFormValue = yup.InferType<typeof addOptionValueSchema>;
export type TAddVariantFormValues = yup.InferType<
	typeof addVariantFormValSchema
>;
