import React from 'react';
import { StyleSheet, View } from 'react-native';
import { EventCard } from '../..';
import { TGetAllEventsData } from '../../../types/eventTypes';
import { dateFns } from '../../../utils/dateFns';

type Props = {
	events: TGetAllEventsData[];
};

export const AllEventsDesktop = ({ events }: Props) => {
	return (
		<View style={styles.allEventsContainer}>
			{events &&
				events.map((item, index) => {
					const isLastElement = events.length - 1 === index;
					return (
						<EventCard
							media={item.eventMedia}
							key={index}
							size="large"
							containerStyles={styleSingleEventWeb(isLastElement)}
							date={dateFns.date(+item.startingEventDateTime)}
							time={item.startingEventDateTime}
							month={dateFns.month(+item.startingEventDateTime)}
							description={item.description}
							title={item.name}
							hasRequestButton={false}
							item={item}
						/>
					);
				})}
		</View>
	);
};

const styles = StyleSheet.create({
	allEventsContainer: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		marginRight: -40,
		flexGrow: 1,
	},
});

const styleSingleEventWeb = (isLastElement: boolean) => {
	return {
		marginRight: isLastElement ? 0 : 16,
		width: '32%',
		minWidth: '32%',
		maxWidth: '32%',
		flex: 1,
	};
};
