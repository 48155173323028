import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatContactProps, UnreadMessages } from '../types/types';

type TInitialState = {
	chat: ChatContactProps;
	unReadMessages: UnreadMessages[];
};

const initialState: TInitialState = {
	chat: {} as ChatContactProps,
	unReadMessages: [] as UnreadMessages[],
};

const chatSlice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		setCurrentChat(state, action: PayloadAction<ChatContactProps>) {
			state.chat = action.payload;
		},
		setUnReadMessages(state, action: PayloadAction<UnreadMessages[]>) {
			state.unReadMessages = action.payload;
		},
	},
});

export const { setCurrentChat, setUnReadMessages } = chatSlice.actions;

export default chatSlice.reducer;
