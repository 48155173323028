import { gql } from "@apollo/client";

export const GET_TODAYS_REVENUE = gql`
    query getOrdersAdminPerDay{
        getOrdersAdminPerDay{
            statusCode
            success
            message
            data{
                total
                data{
                    ... on AdminRevAndTax{
                        netRevenue
                        tax
                        createdAt
                    }
                }
            }
        }
    }
`

export const GET_YESTERDAYS_REVENUE = gql`
    query getOrdersAdminYesterDay{
        getOrdersAdminYesterDay{
            statusCode
            success
            message
            data{
                total
                data{
                    ... on AdminRevAndTax{
                        netRevenue
                        tax
                        createdAt
                    }
                }
            }
        }
    }
`

export const GET_BRAND_CONNECT_ACCOUNT = gql`
    query getBrandConnectAccount($brandId: String){
        getBrandConnectAccount(brandId: $brandId){
            statusCode
            success
            data{
                ... on ConnectAccount {
                    userId
                    connectAccountId
                    flag
                    lastUrl
                    type
                    currently_due
				}
            }
        }
    }
`

export const LIST_BANKS = gql`
	query listBanks($args: ListCardsInput!) {
		listBanks(args: $args) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						data {
							... on BanksList {
								bankId
								account_name
								account_number
								country
								bank_name
								default
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_BALANCE = gql`
	query getBalance($args: GetBalanceInputs) {
		getBalance(args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on ConnectBalance {
						available {
							amount
							currency
						}
						pending {
							amount
							currency
						}
					}
				}
			}
		}
	}
`;

export const GET_TOTAL_PENDING_TRANSFER_AMOUNT = gql`
	query getTotalPendingTransferAmount{
		getTotalPendingTransferAmount{
			statusCode
			success
			data{
				... on TotalPendingTransfers{
					totalPendingTransfers
					totalPendingTransfersBrands
					totalPendingTransfersSellers
                    totalPendingTransfersNowAvailableOnStripe
                    totalPendingTransfersPendingOnStripe
				}
			}
		}
	}
`