import React, { useCallback } from 'react';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { Pressable, StyleSheet, View } from 'react-native';
import styled from 'rn-css';
import { Label } from '../..';
import { palettes } from '../../../config';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useAppDispatch } from '../../../redux/store';
import { setAlerts } from '../../../redux/uiSlice';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { UploadDocumentsIcon } from '../icons';
import { LabelProps } from '../label/Label';
import Loader from '../loader/Loader';

type Props = {
	hint?: string;
	uploadIcon?: JSX.Element;
	containerStyles?: TStyle;
	dropzoneStyles?: TStyle;
	onDrop?: DropzoneProps['onDrop'];
	accept?: string | string[];
	loading?: boolean;
};

const Hint = styled.Text`
	color: ${palettes.grey[1]};
	border-bottom: 1px solid transparent;
	&:hover {
		border-bottom: 1px solid ${palettes.grey[1]};
	}
`;

const Upload = ({
	label = '',
	labelHint,
	labelProps,
	labelStyle,
	actionText,
	actionLink,
	error = '',
	hint,
	uploadIcon: UploadIcon = <UploadDocumentsIcon />,
	containerStyles,
	dropzoneStyles,
	onDrop: _onDrop,
	accept,
	loading = false,
}: Props & Partial<LabelProps>) => {
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();
	const onDrop: DropzoneProps['onDrop'] = useCallback(
		(acceptedFiles, fileRejected, event) => {
			_onDrop && _onDrop(acceptedFiles, fileRejected, event);
		},
		[]
	);

	const onDropRejected: DropzoneProps['onDropRejected'] = (
		selectedFiles,
		e
	) => {
		const errorMessages = selectedFiles.map(
			files => files.errors[0].message
		);
		dispatch(
			setAlerts(
				errorMessages.map(errorMessage => ({
					title: errorMessage,
					type: 'danger',
				}))
			)
		);
	};
	const onDropAccepted: DropzoneProps['onDropAccepted'] = (
		selectedFiles,
		e
	) => {
		setAlert('File successfully added', 'normal');
	};
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept,
		onDropAccepted,
		onDropRejected,
	});
	return (
		<View style={containerStyles}>
			{!!label && (
				<Label
					label={label}
					actionText={actionText}
					labelProps={labelProps}
					labelStyle={labelStyle}
					error={error}
					actionLink={actionLink}
					labelHint={labelHint}
				/>
			)}
			<Pressable style={styles.outerContainer}>
				<View
					style={[
						styles.innerContainer,
						styleInnerContainer(),
						dropzoneStyles,
					]}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
						{...getRootProps()}
					>
						<input
							type={'file'}
							style={{
								display: 'none',
							}}
							id="file-uploader-input"
							{...getInputProps()}
						/>

						{loading ? (
							<Loader />
						) : (
							<>
								{UploadIcon}
								<Hint style={styleHint()}>{hint}</Hint>
							</>
						)}
					</div>
				</View>
			</Pressable>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {},
	outerContainer: { borderWidth: 3, borderColor: 'transparent' },
	innerContainer: {
		borderWidth: 1,
		borderColor: palettes.grey[4],
		width: '100%',
		borderRadius: 6,
		borderStyle: 'dashed',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

export default Upload;

const styleInnerContainer = () => {
	return { height: getResponsiveStyle(136, 'dimensions') };
};

const styleHint = (): any => {
	return {
		marginTop: getResponsiveStyle(11),
		fontSize: getResponsiveStyle(12),
		lineHeight: getResponsiveStyle(12),
	};
};
