import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';
import { stripe_key } from '../../utils/stripeConstants';

type TParams = {
    city: string;
    line1: string;
    first_name: string;
    email: string;
}
export const useCreateConnectToken = ({
    city, 
    email,
    first_name, 
    line1,
    }: TParams) => {

    const [token, setToken] = useState('')
    const createConnectToken = async () => {
        const stripe = await loadStripe(stripe_key);

        const account = await stripe?.createToken('account', {
            business_type: 'individual',
            individual: {
                address: {
                    city,
                    line1,
                },
                first_name,
                email,
            },
            tos_shown_and_accepted: true,
        });
        return {account};
    };

    return createConnectToken


}
