import { useMutation } from "@apollo/client";
import { DELETE_ADMIN_INVITE } from "../../graphql/auth/mutations";
import { TDeleteAdminInvite } from "../../types/authTypes";


export const useDeleteAdminInvite =(
    onCompleted:(data:TDeleteAdminInvite)=> void | undefined
)=>{
    const onError = error =>{
        console.log(error)
    }
    const [deleteAdminInvite, states] = useMutation<TDeleteAdminInvite>(
        DELETE_ADMIN_INVITE,
        {
            onCompleted,
            onError,
        }
    );

    const executeDeleteMutation = ({
        email
    }:{email:string})=>{
        deleteAdminInvite({
            variables:{
                invitedAdminEmail:email
            }
        })
    }

    return {executeDeleteMutation, ...states}
}