import {yupsRequiredString} from '../../utils/yupsRequiredString';
import * as yup from 'yup';
export const addTagValidation=yup.object({
    key:yup.string(),
    description:yup.string().nullable(),
    value:yup.object({
        value1:yupsRequiredString(),
        value2:yupsRequiredString(),
        value3:yupsRequiredString()
    })
})
export type TAddTagValidation=yup.InferType<typeof addTagValidation>
export const addTagInitialValues:TAddTagValidation={
    key:"",
    description:"",
    value:{
        value1:"",
        value2:"",
        value3:""

    }
}