import { View, Text, Pressable, StyleSheet } from 'react-native';
import React from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { toggleMobileSidebar } from '../../../redux/mobileSidebarSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import useLogout from '../../../api/auth/useLogout';

const SidebarRoutes = ({
	type = 'desktop',
}: {
	type: 'desktop' | 'mobile';
}) => {
	const SIDEBAR_ROUTES = useAppSelector(state => state.ui.sidebarArray);
	const handleNavigation = (value: string) => {
		dispatch(toggleMobileSidebar());

		navigator.replace(value);
	};
	const route = useRoute();
	const navigator = useNavigation<any>();
	const dispatch = useAppDispatch();
	const confirmActiveRoute = (value: string) => {
		return route.name === value || route?.name?.includes(value);
	};
	//logout functionality
	const { executeLogoutQuery } = useLogout();
	const onClickLogout = () => {
		executeLogoutQuery();
	};
	return (
		<>
			{SIDEBAR_ROUTES.map(({ screen, id }) => (
				<Pressable key={id} onPress={() => handleNavigation(screen)}>
					<View style={styles.navLinkContainer}>
						{confirmActiveRoute(screen) && (
							<View
								style={[
									styles.navLinkActiveBorder,
									{
										left:
											type === 'desktop'
												? getResponsiveStyle(-40)
												: getResponsiveStyle(-35),
									},
								]}
							/>
						)}
						<Text
							style={
								confirmActiveRoute(screen)
									? styles.activeNavLink
									: styles.navLink
							}
						>
							{screen}
						</Text>
					</View>
				</Pressable>
			))}
			<View style={styles.optionNavContainer}>
				{/* <Pressable onPress={() => handleNavigation('Settings')}>
					<View style={styles.navLinkContainer}>
						{confirmActiveRoute('Settings') && (
							<View style={styles.navLinkActiveBorder} />
						)}
						<Text
							style={
								confirmActiveRoute('Settings')
									? styles.activeNavLink
									: styles.navLink
							}
						>
							Settings
						</Text>
					</View>
				</Pressable> */}
				<Pressable onPress={() => handleNavigation('Logout')}>
					<View style={styles.navLinkContainer} >
						{confirmActiveRoute('Logout') && (
							<View style={styles.navLinkActiveBorder} />
						)}
						<Text
							style={
								confirmActiveRoute('Logout')
									? styles.activeNavLink
									: styles.navLink
							}
							onPress={onClickLogout}
						>
							Logout
						</Text>
					</View>
				</Pressable>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'column',
		backgroundColor: palettes.grey[6],
		width: '100%',
		height: '100vh',
		paddingVertical: getResponsiveStyle(32),
		paddingHorizontal: getResponsiveStyle(40),
	},
	logoContainer: {
		marginBottom: getResponsiveStyle(70.59),
	},
	navLinkContainer: {
		alignItems: 'center',
		flexDirection: 'row',
		position: 'relative',
	},
	activeNavLink: {
		color: palettes.dark[0],
		fontWeight: 'bold',
		fontSize: getResponsiveStyle(16),
		lineHeight: getResponsiveStyle(44),
	},
	navLink: {
		color: palettes.grey[0],
		fontWeight: '400',
		fontSize: getResponsiveStyle(16),
		lineHeight: getResponsiveStyle(44),
	},
	navLinkActiveBorder: {
		backgroundColor: palettes.purple[0],
		position: 'absolute',
		width: 2,
		height: '100%',
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
		overflow: 'hidden',
		top: 0,
	},
	optionNavContainer: {
		marginTop: 'auto',
		marginBottom: getResponsiveStyle(54),
	},
});
export default SidebarRoutes;
