import { gql } from '@apollo/client';

const GET_CONVERSATION = gql`
	query getConversation($conversationId: String!) {
		getOneConversationByUserId(conversationId: $conversationId) {
			statusCode
			data {
				messages {
					message
					messageType
					sender
					recipients
					recipientsReadReceipt
				}
			}
		}
	}
`;

const GET_CONVERSATION_BY_PARTICIPANT = gql`
	query getConversation($participants: [String]!) {
		getConversationByParticipants(participants: $participants) {
			statusCode
			data {
				id
			}
		}
	}
`;

export { GET_CONVERSATION, GET_CONVERSATION_BY_PARTICIPANT };
