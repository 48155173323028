import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { useAppSelector } from '../../redux/store';
import { TODO } from '../../types/types';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import ChatInput from './message/ChatInput';
import ContactMessage from './message/ContactMessage';
import UserMessage from './message/UserMessage';
// import ChatInput from '../../live-event/product-section/event-chat/ChatInput';
// import ContactMessage from '../../live-event/product-section/event-chat/ContactMessage';
type Props = {
	scrollViewRef: any;
	setMessage: (e: string) => void;
	handleMessageSend: (e: any) => any;
	messages: any[];
};
const ChatChild = ({
	scrollViewRef,
	setMessage,
	handleMessageSend,
	messages,
}: Props) => {
	const { loggedInUserDetails } = useAppSelector(state => ({
		loggedInUserDetails: state.auth.loggedInUserDetails,
	}));

	return (
		<>
			<ScrollView
				// style={styles.chatBox}
				style={{
					paddingLeft: getResponsiveStyle(15),
					paddingRight: getResponsiveStyle(15),
				}}
				showsHorizontalScrollIndicator={false}
				ref={scrollViewRef}
				onContentSizeChange={() =>
					scrollViewRef.current.scrollToEnd({
						animated: false,
					})
				}
			>
				{messages && messages?.length ? (
					messages.map((message: TODO) => (
						<>
							{message?.sender === loggedInUserDetails?._id ? (
								<UserMessage message={message} />
							) : (
								<ContactMessage message={message} />
							)}
						</>
					))
				) : (
					<></>
				)}
			</ScrollView>

			<ChatInput
				handleChange={message => setMessage(message)}
				onMessageSend={handleMessageSend}
				showUpload={false}
				handleTypingFocusIn={() => null}
				handleTypingFocusOut={() => null}
			/>
		</>
	);
};
export default ChatChild;
