import { Picker } from '@react-native-picker/picker';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Formik, validateYupSchema } from 'formik'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import Input from '../common/Input';
import * as Yup from 'yup'
import Button from '../common/button/Button';
import { useLazyQuery, useMutation } from '@apollo/client';
import { palettes } from '../../config';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { useAppSelector } from '../../redux/store';
import { CREATE_ADMIN_WITHDRAWAL } from '../../graphql/auth/mutations';
import { GET_BALANCE_ON_STRIPE } from '../../graphql/auth/queries';

const AdminWithdrawForm = (props: {
    setModalState: (val: boolean) => void;
}) => {
    const schema = Yup.object({
        bankId: Yup.string().required('Required'),
        amount: Yup.string().required('Required'),
        adminName: Yup.string().required('Required'),

    });
    type TInitialValues = Yup.InferType<typeof schema>;
    const { setAlert } = useSetAlert()
    const initialValues: TInitialValues = {
        amount: '',
        bankId: '',
        adminName: 'Ocpus.net',
    };
    const availableBalanceOnStripe = useAppSelector(state => state.auth.availableAdminBalanceOnStripe)
	const totalPendingTransfersNowAvailableOnStripe = useAppSelector(state => state.auth.totalPendingTransfers.totalPendingTransfersNowAvailableOnStripe) / 100
	const ocpusAvailableBalance = availableBalanceOnStripe - totalPendingTransfersNowAvailableOnStripe

    const [
		availableBalanceBeforeReduction,
		setAvailableBalanceBeforeReduction,
	] = useState(ocpusAvailableBalance);
	useEffect(()=> {
		setAvailableBalanceBeforeReduction(ocpusAvailableBalance)
	},[ocpusAvailableBalance])

    const updateAvailableBalance = (amount: number) => {
		console.log({amount})
		if (amount > 0 && amount <= ocpusAvailableBalance) {
			setAvailableBalanceBeforeReduction(ocpusAvailableBalance - amount);
		} else if (amount === 0) {
			setAvailableBalanceBeforeReduction(ocpusAvailableBalance);
		}
	};

    const validate = (values: TInitialValues) => {
        const errors = { amount: '' };
        if (parseInt(values.amount) > ocpusAvailableBalance) {
            errors.amount = `Insufficient Balance. Current available balance is ${getSymbolFromCurrency('eur')}${ocpusAvailableBalance}`;
            return errors;
        } 
         else if (parseInt(values.amount) < 1) {
            errors.amount = `Amount must be greater than 1`;
            return errors;
        } else {
            return;
        }
    };

    const [adminWithdraw, { loading }] = useMutation(CREATE_ADMIN_WITHDRAWAL, {
        awaitRefetchQueries: true,
        refetchQueries: [{query: GET_BALANCE_ON_STRIPE}],
        onCompleted: (data) => {
            props.setModalState(false);
            data?.createAdminWithdrawal?.success ?
                setAlert('Withdrawal successful', 'normal') :
                setAlert('Something went wrong while withdrawing', 'danger')
            
        }
    })

    // const bankId = 'ba_1KljBaGPsYminZ9Gk8KDNaPN'
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            validate={validate}
            onSubmit={values => {
                adminWithdraw({
                    variables: {
                        bankId: values.bankId,
                        amount: parseFloat(values.amount)
                    }
                });
            }}
        >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                dirty,
                isValid,
                errors,
                touched,
                setFieldValue,
            }) => (
                <>
                    <View>

                        <View style={{}}>
                            <View style={styles.available}>
                                {`Available balance:     ${getSymbolFromCurrency('eur')}${availableBalanceBeforeReduction.toFixed(2)}`}
                            </View>
                        </View>

                        <Input
                            value={values.adminName}
                            style={[styles.input]}
                            containerStyles={{
                                marginBottom: getResponsiveStyle(35),
                            }}
                            onChangeText={handleChange('brandName')}
                            onBlur={handleBlur('brandName')}
                            label={'Name'}
                            error={touched.adminName ? errors.adminName : ''}
                            editable={false}
                        // placeholder="Enter account number"
                        />
                        <Input
                            value={values.amount}
                            style={[styles.input]}
                            containerStyles={{
                                marginBottom: getResponsiveStyle(35),
                            }}
                            onChangeText={(text) => {
                                updateAvailableBalance(+text)
                                // handleChange('amount')
                                setFieldValue('amount', text);
                            }}
                            onBlur={handleBlur('amount')}
                            label={`Enter Amount ${getSymbolFromCurrency('eur')}`}
                            error={touched.amount ? errors.amount : ''}

                        // placeholder="Enter account number"
                        />

                        <Input
                            value={values.bankId}
                            style={[styles.input]}
                            containerStyles={{
                                marginBottom: getResponsiveStyle(35),
                            }}
                            onChangeText={handleChange('bankId')}
                            onBlur={handleBlur('bankId')}
                            label={'Destination Bank (ID of a bank acount in your stripe dashboard)'}
                            error={touched.bankId ? errors.bankId : ''}

                        // placeholder="Enter account number"
                        />

                    </View>
                    <Button
                        title={'Withdraw'}
                        variant="primary"
                        size="sm"
                        onPress={handleSubmit}
                        loading={loading}
                    />
                </>
            )}

        </Formik>
    );
}

export default AdminWithdrawForm

const styles = StyleSheet.create({
    input: {
        width: '100%',
    },
    available: {
        fontWeight: '700',
        color: palettes.primary[0],
        // marginBottom: 30,
        marginTop: 20
    }
})