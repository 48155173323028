import { View, Text } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { GoBackButton } from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import TableComponent from '../components/common/table-component/TableComponent';
import { TGetAllHostsResponse, TGetAllUsersResponse, TStatsforTable, TUserDataForTable } from '../types/userTypes';
import { TFind, TGetAllUsersFind, TPaginatedOptions } from '../types/types';
import { GET_ALL_HOSTS, GET_USER_TABLE_STATS } from '../graphql/auth/queries';
import { useLazyQuery } from '@apollo/client';
import Loader from '../components/common/loader/Loader';
import { palettes } from '../config';
import { manipulateString } from '../utils/manipulateString';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';

const TABS = ['All Hosts', 'New Hosts'];
const HEADERS = [
	{
		headerLabel: 'Name',
		width: getResponsiveStyle(180),
	},
	{
		headerLabel: 'Status',
		width: getResponsiveStyle(80),
	},
	{
		headerLabel: 'Location',
		width: getResponsiveStyle(150),
	},
	{
		headerLabel: 'Followers',
		width: getResponsiveStyle(100),
	},
	{
		headerLabel: 'Events Attended',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Purchase Amount',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Email',
		width: getResponsiveStyle(150),
	},
];

const AllHostsScreen = () => {
	const [selectedTab, setSelectedTab] = useState(TABS[0]);
	const [allHostsData, setAllHostsData] = useState<TUserDataForTable[]>();
	const [stats, setStats] = useState<TStatsforTable>();
	const [waiting, setwaiting] = useState<any[]>([]);
	const [getUserStats, { loading: loadingStats }] = useLazyQuery(
		GET_USER_TABLE_STATS,
		{
			onCompleted: resp => {
				console.log(resp);

				let arrTotal = hostsData?.getAllUsers?.data?.data?.map(
					hosts => ({
						image:
							hosts?.personalInformation?.profileImageLink ??
							null,
						name: hosts?.username,
						status: manipulateString.capitalize(
							hosts.profileStatus.toLowerCase()
						),
						location:
							hosts?.personalInformation?.address?.city +
							', ' +
							hosts?.personalInformation?.address?.countryLabel,
						followers: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === hosts?._id
						).followersCount,
						eventsAttended: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === hosts?._id
						).eventsCount,
						purchaseAmount: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === hosts?._id
						)?.productsCount,
						email: hosts?.email,
						_id: hosts._id,
					})
				);
				console.log(arrTotal);

				setAllHostsData(arrTotal);
			},
			onError: () => {
				console.log('ther was an error');
			},
		}
	);
	const [getAllHosts, { loading:hostLoading,data:hostsData }] = useLazyQuery<
		TGetAllUsersResponse,
		TPaginatedOptions & TFind
	>(GET_ALL_HOSTS, {
		fetchPolicy: 'no-cache',
		variables:{
			find:{
				typeOfAccount: 'HOST'
			}as TGetAllUsersFind
		},
		
		onCompleted: data => {
			if (data.getAllUsers.data && data.getAllUsers.data.data) {
				getUserStats({
					variables: {
						id: data.getAllUsers.data.data.map(o => o._id),
					},
				});
			}
		}
	});

	useEffect(() => {
		getAllHosts();
	}, []);

	const onPressTab = (tab: string) => {
		if (tab === TABS[1]) {
			getAllHosts({
				variables: {
					sort: {
						createdAt: -1,
					},
				},
			});
		}
		if (tab === TABS[0]) {
			getAllHosts();
		}
		setSelectedTab(tab);
	};
	const unifiedLoading = useMemo(
		() => getSingleLoadingState(loadingStats, hostLoading),
		[loadingStats, hostLoading]
	);

	return (
		<DesktopTemplate>
			<GoBackButton history="Hosts" />
			<View style={{ marginTop: getResponsiveStyle(32) }}>
				{allHostsData && allHostsData.length > 0 ? (
					<View>
						<TableComponent
							headerTabs={TABS}
							data={allHostsData}
							type="profile"
							onPressTab={onPressTab}
							selectedTab={selectedTab}
							headers={HEADERS}
							loading={unifiedLoading}
							showScrollBar={false}
							tableContentProps={{
								profileButton: true,
								messageButton: true,
							}}
						/>
					</View>
				) : unifiedLoading ? (
					<Loader />
				) : (
					<Text
						style={{
							fontSize: getResponsiveStyle(14, 'font'),
							lineHeight: getResponsiveStyle(14, 'font'),
							color: palettes.grey[0],
						}}
					>
						No data Available!
					</Text>
				)}
			</View>
		</DesktopTemplate>
	);
};

export default AllHostsScreen;
