import { useMutation } from '@apollo/client';
import { Picker } from '@react-native-picker/picker';
import { Formik, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import * as Yup from 'yup';
import { Button, Input, Label } from '..';
import { palettes } from '../../config';
import { INVITE_ADMIN } from '../../graphql/accounts/mutations';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { toggleAddUserModal } from '../../redux/addUserSlice';
import { useAppDispatch } from '../../redux/store';
import {
	TAdminRole,
	TInviteAdminInputs,
	TInviteAdminResponse,
} from '../../types/adminTypes';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import { useGetAllBrands } from '../../api/brands/useGetAllBrands';

type TRoleDropdownValues = {
	label: string;
	value: TAdminRole;
};

const RoleDropdownValue: TRoleDropdownValues[] = [
	{
		value: 'ADMIN',
		label: 'Admin',
	},
	{
		value: 'BRAND_ADMIN',
		label: 'Brand Admin',
	},
	{
		value: 'BRAND',
		label: 'Brand',
	},
	{
		value: 'EVENT',
		label: 'Event',
	},
	{
		value: 'HOST',
		label: 'Host',
	},
	{
		value: 'SELLER',
		label: 'Seller',
	},
];

const schema = Yup.object({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email').required('Required').trim(),
	role: Yup.mixed<TAdminRole>().required('Required'),
	affiliatedBrand: Yup.string()
});

type TInitialValues = Yup.InferType<typeof schema>;

const initialValues: TInitialValues = {
	email: '',
	role: 'ADMIN',
	firstName: '',
	lastName: '',
	affiliatedBrand: "",
};

const AddUserForm = (props: {
	onSubmit: (email: string, rule: string) => void;
}) => {
	const { setAlert } = useSetAlert();
	const dispatch = useAppDispatch();

	const handleSubmit = (values: TInitialValues) => {
		inviteAdmin({
			variables: values,
		});
	};

	const { getAllBrands, data: brandData } = useGetAllBrands();

	useEffect(() => {
		getAllBrands()
	}, [])


	const [inviteAdmin, { loading }] = useMutation<
		TInviteAdminResponse,
		TInviteAdminInputs
	>(INVITE_ADMIN, {
		onCompleted: data => {
			if (data && data.inviteAdmin) {
				if (data.inviteAdmin.success) {
					setAlert('Admin invited successfully', 'normal');
				} else {
					setAlert(data.inviteAdmin.message, 'danger');
				}
			} else {
				setAlert('An error occurred during invitation', 'danger');
			}
			dispatch(toggleAddUserModal());
		},
		onError: () => {
			setAlert('An error occurred during invitation', 'danger');
			dispatch(toggleAddUserModal());
		},
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={schema}
			onSubmit={async values => {
				handleSubmit(values);
			}}
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				values,
				dirty,
				isValid,
				errors,
				touched,
				setFieldValue,
			}) => (
				<>
					<View>
						<Input
							value={values.email}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(20),
							}}
							onChangeText={handleChange('email')}
							onBlur={handleBlur('email')}
							label="Email"
							error={touched.email ? errors.email : ''}
							placeholder="Enter user email address"
						/>
						<Input
							value={values.firstName}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(20),
							}}
							onChangeText={handleChange('firstName')}
							onBlur={handleBlur('firstName')}
							label="First name"
							error={touched.firstName ? errors.firstName : ''}
							placeholder="Enter FirstName"
						/>
						<Input
							value={values.lastName}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(20),
							}}
							onChangeText={handleChange('lastName')}
							onBlur={handleBlur('lastName')}
							label="Last name"
							error={touched.lastName ? errors.lastName : ''}
							placeholder="Enter Last name"
						/>
						<Label
							error={touched.role ? errors.role : ''}
							label="Role"
						/>
						<Picker
							selectedValue={values.role}
							onValueChange={itemValue =>
								setFieldValue('role', itemValue)
							}
							style={[
								styles.dropdownContainer,
								Platform.OS === 'web' &&
									!touched.role &&
									!errors.role
									? styleFocusOutline()
									: styleErrorOutline(),

								// styleInput(decoration),
								!!errors.role &&
								touched.role &&
								styles.inputError,
							]}
							itemStyle={styles.dropdownItem}
							dropdownIconColor={palettes.grey[1]}
							onBlur={handleBlur('role')}
						>
							{RoleDropdownValue.map(
								({ label, value }, index) => (
									<Picker.Item
										key={index}
										label={label}
										value={value}
									/>
								)
							)}
						</Picker>
						{values?.role === "BRAND_ADMIN" &&
							<View>
								<Label
									error={touched.affiliatedBrand ? errors.affiliatedBrand : ''}
									label="Brand"
								/>
								<Picker
									selectedValue={values.affiliatedBrand}
									onValueChange={itemValue =>
										setFieldValue('affiliatedBrand', itemValue)
									}
									style={[
										styles.dropdownContainer,
										Platform.OS === 'web' &&
											!touched.affiliatedBrand &&
											!errors.affiliatedBrand
											? styleFocusOutline()
											: styleErrorOutline(),

										// styleInput(decoration),
										!!errors.affiliatedBrand &&
										touched.affiliatedBrand &&
										styles.inputError,
									]}
									itemStyle={styles.dropdownItem}
									dropdownIconColor={palettes.grey[1]}
									onBlur={handleBlur('affiliatedBrand')}
								>
									{brandData?.map((data: any) => {
										return {
											label: data?.companyName,
											value: data?._id
										}
									})?.map(
										({ label, value }: any, index: any) => (
											<Picker.Item
												key={index}
												label={label}
												value={value}
											/>
										)
									)}
								</Picker>
							</View>
						}
					</View>

					<Button
						title="Send"
						variant="primary"
						outerContainerProps={{
							style: styleLoginButtonOuterContainer(),
						}}
						size="lg"
						onPress={handleSubmit}
						loading={loading}
					/>
				</>
			)}
		</Formik>
	);
};

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	dropdownContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		fontStyle: 'normal',
		fontWeight: 'normal',
		borderRadius: 6,
		// box shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: getResponsiveStyle(48, 'dimensions'),
		paddingRight: getResponsiveStyle(24),
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		paddingLeft: getResponsiveStyle(19),
	},
	dropdownItem: {
		paddingVertical: 20,
	},
	inputError: {
		borderColor: palettes.red[0],
	},
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 12,
		lineHeight: 12,
		color: palettes.grey[0],
	},
});

export default AddUserForm;

function styleLoginButtonOuterContainer() {
	return {
		marginTop: getResponsiveStyle(46),
	};
}

function styleErrorOutline() {
	return {
		outlineColor: palettes.red[0],
		outerWidth: 1,
	} as any;
}

function styleFocusOutline() {
	return {
		outlineColor: palettes.primary[0],
		outerWidth: 1,
	} as any;
}
