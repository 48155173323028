import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { palettes } from '../../../config';
import { TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const TermsAndConditions = () => {
	return (
		<Text style={[styles.termsAndConditions, styleTermsAndConditions()]}>
			By continuing, you agree to our Privacy Policy, Terms & Conditions,
			and FCRA Disclaimer. Please see our Fraud Disclaimer.
		</Text>
	);
};

export default TermsAndConditions;
function styleTermsAndConditions(): TStyleInputOrText {
	return {
		fontSize: getResponsiveStyle(11, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
		marginBottom: getResponsiveStyle(20),
	};
}

const styles = StyleSheet.create({
	termsAndConditions: {
		textAlign: 'center',
		letterSpacing: 0.18,
		color: palettes.grey[0],
	},
});
