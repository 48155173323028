import { Picker } from '@react-native-picker/picker';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Formik, validateYupSchema } from 'formik';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { getResponsiveStyle } from '../../utils/getResponsiveStyle';
import Input from '../common/Input';
import * as Yup from 'yup';
import Button from '../common/button/Button';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_BRANDS_REVENUE } from '../../graphql/brands/queries';
import { palettes } from '../../config';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { TBankAccount, TOptions } from '../../types/types';
import useCreateBrandBank from '../../api/brands/useCreateBrandBank';
import { getCountryValueFromLabel } from '../../utils/getCountryValueFromLabel';
import Currencies from '../../utils/currencies';
import { CREATE_PAYOUT } from '../../graphql/stripe/mutations';

const WithdrawForm = (props: {
	onSubmit: (
		amount: string,
		iban: string,
		brandName: string,
		// accountName: string
	) => void;
	brandName: string;
	_id: string;
	setModalState: (val: boolean) => void;
	option: TOptions;
	setOption: (val: TOptions) => void;
	setAccounts: (val: TBankAccount[]) => void;
	accounts: TBankAccount[];
	country: string
	connectAccountId: string
	pendingBalance: number
	availableBalance: number
}) => {
	// const [availableBalance, setAvailableBalance] = useState(0);
	const [amount, setAmount] = useState('')
	const [
		availableBalanceBeforeReduction,
		setAvailableBalanceBeforeReduction,
	] = useState(props.availableBalance);
	useEffect(()=> {
		setAvailableBalanceBeforeReduction(props.availableBalance)
	},[props.availableBalance])
	const handleSubmit = (values: {
		amount: string;
		iban: string;
		brandName: string;
		// accountName: string;
		// bankName: string;
	}) => {
		props.onSubmit(
			values.amount,
			values.iban,
			values.brandName,
			// values.accountName
		);
	};

	const schema = Yup.object({
		iban: Yup.string().required('Required'),
		amount: Yup.string().required('Required'),
		brandName: Yup.string().required('Required'),
		// accountName: Yup.string().required('Required'),
		// bankName: Yup.string().required('Required'),
	});

	type TInitialValues = Yup.InferType<typeof schema>;

	const { setAlert } = useSetAlert();

	const initialValues: TInitialValues = {
		amount: '',
		iban: props.option !== 'NEW_BANK_ACCOUNT' ? props?.option?.IBAN : '',
		brandName: props?.brandName,
		// accountName:
		// 	props?.option !== 'NEW_BANK_ACCOUNT' ? props?.option?.accountName : '',
		// bankName:
		// 	props?.option !== 'NEW_BANK_ACCOUNT' ? props?.option?.bankName : '',
	};

	// const [getBrandRevenue] = useLazyQuery(GET_BRANDS_REVENUE, {
	// 	onCompleted: data => {
	// 		// setAvailableBalance(
	// 		// 	data?.getBrandTrxDetails?.data?.data[0]?.availableEarning?.toFixed(
	// 		// 		2
	// 		// 	)
	// 		// );
	// 		// setAvailableBalanceBeforeReduction(
	// 		// 	data?.getBrandTrxDetails?.data?.data[0]?.availableEarning?.toFixed(
	// 		// 		2
	// 		// 	)
	// 		// );
	// 	},
	// });

	// const [brandWithdrawal, { loading: brandWithdrawLoading }] = useMutation(
	// 	BRAND_WITHDRAW,
	// 	{
	// 		onCompleted: () => {
	// 			props.setModalState(false);
	// 			props.setOption('NOT_SELECTED');
	// 			setAlert(
	// 				'Withdrawal request sent, payment will be processed soon',
	// 				'normal'
	// 			);
	// 		},
	// 	}
	// );

	// useEffect(() => {
	// 	getBrandRevenue({
	// 		variables: {
	// 			ids: [props._id],
	// 		},
	// 	});
	// }, [props._id]);

	const validate = (values: TInitialValues) => {
		const errors = { amount: '' };
		if (parseInt(values.amount) > (props.availableBalance)) {
			errors.amount = `Insufficient Balance. Current balance is ${getSymbolFromCurrency(
				'eur'
			)}${props.availableBalance ?? 0}`;
			return errors;
		} else if (parseInt(values.amount) < 1) {
			errors.amount = `Amount must be greater than 1`;
			return errors;
		} else {
			return;
		}
	};

	const settingValues = (values: any) => {
		setAmount(values.amount)
	}

	const updateAvailableBalance = (amount: number) => {
		if (amount > 0 && amount <= props.availableBalance) {
			setAvailableBalanceBeforeReduction(props.availableBalance - amount);
		} else if (amount === 0) {
			setAvailableBalanceBeforeReduction(props.availableBalance);
		}
	};

	const { createBank, createbanktoken, creatingBank, loadingBanktoken, createBankData } = useCreateBrandBank({
		connectAccountId: props.connectAccountId,
		setCreateBankModalState: props.setModalState,
		amount
	})
	const [payout, { data, loading: payoutLoading }] = useMutation(CREATE_PAYOUT, {
		onCompleted: (data) => {
			props.setModalState(false)
			data?.createPayout?.success ? setAlert('Withdrawal successful', 'normal') :
				setAlert('Unable to complete withdrawal', 'danger')
		},
		onError: () => {
			props.setModalState(false)
			setAlert('Unable to complete withdrawal', 'danger')
		}
	});
	const oneStepWithdrawal = async (values: any) => {
		if (!props.option.IBAN) {
			await createbanktoken({
				variables: {
					args: {
						country:
							getCountryValueFromLabel(props.country)?.toUpperCase(),
						currency: "eur",
						account_number: values.iban,
					},
				},
			})
			await createBank()

		}
		else {
			await payout({
				variables: {
					args: {
						amount: parseFloat(values.amount) * 100,
						currency: "eur",
						destination: props.option.bankId,
						accountId: props.connectAccountId,
					},
				}
			})
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={schema}
			validate={validate}
			onSubmit={values => {
				handleSubmit(values);
				settingValues(values);
				oneStepWithdrawal(values)
				// brandWithdrawal({
				// 	variables: {
				// 		amount: parseInt(values.amount),
				// 		brandId: props._id,
				// 		withdrawalAccountNumber: values.iban,
				// 	},
				// });
				// props.setAccounts([
				// 	...props.accounts,
				// 	{
				// 		accountName: values.accountName,
				// 		bankName: values.bankName,
				// 		IBAN: values.iban,
				// 	},
				// ]);
			}}
		>
			{({
				handleChange,
				handleBlur,
				handleSubmit,
				values,
				dirty,
				isValid,
				errors,
				touched,
				setFieldValue,
			}) => (
				<>
					<View>
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<View>
								<View style={styles.available}>
									{`Available balance:     ${getSymbolFromCurrency(
										'eur'
									)}${availableBalanceBeforeReduction?.toFixed(2) || 0
										}`}
								</View>
								<View style={styles.pending}>
									{`Pending balance:     ${getSymbolFromCurrency(
										'eur'
									)}${props.pendingBalance?.toFixed(2) || 0
										}`}
								</View>
							</View>

							{
								props.accounts.length > 0 &&

								<Button
									title={'Back'}
									onPress={() => props.setOption('NOT_SELECTED')}
								/>
							}
						</View>

						<Input
							value={values.brandName}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(35),
							}}
							onChangeText={handleChange('brandName')}
							onBlur={handleBlur('brandName')}
							label={'Brand name'}
							error={touched.brandName ? errors.brandName : ''}
							editable={false}
						// placeholder="Enter account number"
						/>
						<Input
							value={values.amount}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(35),
							}}
							onChangeText={text => {
								// handleChange('amount');
								updateAvailableBalance(text);
								setFieldValue('amount', text);
							}}
							onBlur={handleBlur('amount')}
							label={`Enter Amount ${getSymbolFromCurrency(
								'eur'
							)}`}
							error={touched.amount ? errors.amount : ''}

						// placeholder="Enter account number"
						/>

						{/* <Input
							value={values.bankName}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(35),
							}}
							onChangeText={handleChange('bankName')}
							onBlur={handleBlur('bankName')}
							label={'Bank Name'}
							error={touched.bankName ? errors.bankName : ''}

							// placeholder="Enter account number"
						/> */}
						<Input
							value={values.iban}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(35),
							}}
							onChangeText={handleChange('iban')}
							onBlur={handleBlur('iban')}
							label={'IBAN'}
							error={touched.iban ? errors.iban : ''}

						// placeholder="Enter account number"
						/>
						{/* <Input
							value={values.accountName}
							style={[styles.input]}
							containerStyles={{
								marginBottom: getResponsiveStyle(35),
							}}
							onChangeText={handleChange('accountName')}
							onBlur={handleBlur('accountName')}
							label={'Account name'}
							error={
								touched.accountName ? errors.accountName : ''
							}

							// placeholder="Enter account number"
						/> */}
					</View>
					<Button
						title={'Withdraw'}
						variant="primary"
						size="sm"
						onPress={handleSubmit}
						loading={creatingBank || loadingBanktoken || payoutLoading}
					/>
				</>
			)}
		</Formik>
	);
};

export default WithdrawForm;

const styles = StyleSheet.create({
	input: {
		width: '100%',
	},
	available: {
		fontWeight: '700',
		color: palettes.primary[0],
		marginTop: 20,
	},
	pending: {
		fontWeight: '700',
		color: palettes.red[0],
		marginBottom: 30,
		marginTop: 10,
	},
});
