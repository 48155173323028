import { FetchResult } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import {
	StyleSheet,
	View,
	useWindowDimensions,
	StyleProp,
	ViewStyle,
	Pressable,
	TouchableOpacity,
} from 'react-native';
import useGetMedia from '../../../api/common/useGetMedia';
import { breakpoints } from '../../../config';
import { AcceptOrRejectEvent } from '../../../types/eventTypes';
import { TODO, TUseNavigation } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { CardBottom, EventCardBottomProps } from './CardBottom';
import { CardTop } from './CardTop';
import EventCardBody from './EventBody';

type Props = {
	size?: 'large' | 'small';
	media: string[];
	id: string;
	time: string;
	acceptOrRejectMutation?: (variables?: AcceptOrRejectEvent) => Promise<TODO>;

	containerStyles?: StyleProp<ViewStyle>;
} & EventCardBottomProps;
type EventCardBodyProps = {
	clickable: boolean;
};
const Event = ({
	size = 'small',
	containerStyles,
	date,
	acceptOrRejectMutation,
	description,
	id,
	time,
	hasRequestButton,
	month,
	title,
	media,
	item,
	clickable,
}: Props) => {
	const width = useWindowDimensions().width;
	return (
		<View style={styleContainer(width, size, containerStyles)}>
			<EventCardBody
				id={id}
				media={media}
				time={time}
				hasRequestButton={hasRequestButton}
				acceptOrRejectMutation={acceptOrRejectMutation}
				month={month}
				date={date}
				title={title}
				description={description}
				item={item}
				clickable={clickable}
			/>
		</View>
	);
	// hasRequestButton ? (
	// 	<TouchableOpacity
	// 		onPress={() => handleNavigation()}
	// 		style={styleContainer(width, size, containerStyles)}
	// 	>
	// 		<EventCardBody
	// 			id={id}
	// 			media={media}
	// 			time={time}
	// 			hasRequestButton={hasRequestButton}
	// 			acceptOrRejectMutation={acceptOrRejectMutation}
	// 			month={month}
	// 			date={date}
	// 			title={title}
	// 			description={description}
	// 			item={item}
	// 			clickable={clickable}
	// 		/>
	// 	</TouchableOpacity>
	// ) : (

	// );
};

const getResponsiveStyles = (width: number) => {
	return width >= breakpoints.desktopBase
		? styleDesktop()
		: width >= breakpoints.tabletSm
		? styleTablet()
		: styleMobile();
};

const styles = StyleSheet.create({
	container: {
		width: '100%',
		borderRadius: 6,
		// cursor: 'pointer',
		// box-shadow
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 7,
		shadowColor: 'rgba(0, 0, 0, 0.03)',
		shadowOpacity: 1,
	},
});

export default Event;

const styleContainer = (
	width: number,
	size: string,
	containerStyles?: StyleProp<ViewStyle>
): StyleProp<ViewStyle> => {
	return [
		styles.container,
		getResponsiveStyles(width),
		size === 'small' && styleSmall(),
		containerStyles,
	];
};

const styleSmall = () => {
	return {
		marginBottom: getResponsiveStyle(24),
		height: getResponsiveStyle(320, 'dimensions'),
		maxWidth: '32%',
	};
};

const styleMobile = () => {
	return {
		marginBottom: getResponsiveStyle(10),
		height: getResponsiveStyle(320, 'dimensions'),
		maxWidth: '100%',
	};
};

const styleTablet = () => {
	return {
		marginBottom: getResponsiveStyle(10),
		height: getResponsiveStyle(320, 'dimensions'),
		maxWidth: '49.5%',
	};
};

const styleDesktop = () => {
	return {
		marginBottom: getResponsiveStyle(24),
		height: getResponsiveStyle(448, 'dimensions'),
		maxWidth: '49%',
	};
};
