import { Image, ImageProps } from "react-native";
import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const MagnifierGlassIcon = ({ ...props }: SvgProps) => {
  return (
    <Svg {...props}>
      <Path
        fill="#98A2B2"
        stroke="#fff"
        strokeWidth={0.3}
        d="M9 .15c4.885 0 8.85 3.965 8.85 8.85S13.885 17.85 9 17.85.15 13.885.15 9 4.115.15 9 .15zm0 16c3.95 0 7.15-3.2 7.15-7.15S12.95 1.85 9 1.85 1.85 5.05 1.85 9s3.2 7.15 7.15 7.15zm7.283 1.335 1.202-1.202 2.617 2.616-1.203 1.203-2.616-2.617z"
      />
    </Svg>
  );
};

export default MagnifierGlassIcon;
