import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { palettes } from '../../../config';
import { toggleMobileSidebar } from '../../../redux/mobileSidebarSlice';
import { useAppDispatch } from '../../../redux/store';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Logo from '../Logo';
import SidebarRoutes from './SidebarRoutes';

const MobileSidebar = () => {
	const navigator = useNavigation<any>();
	const dispatch = useAppDispatch();
	const handleNavigation = (value: string) => {
		dispatch(toggleMobileSidebar());
		navigator.navigate(value);
	};

	return (
		<View style={styles.container}>
			<View style={styles.logoContainer}>
				<Logo
					tint={palettes.purple[0]}
					// style={styles.logo}
					width={38}
					height={38}
					scale={1.3}
					viewBox="0 0 38 38"
					onPress={() => handleNavigation('Dashboard')}
				/>
			</View>

			<SidebarRoutes type="mobile" />
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'column',
		backgroundColor: palettes.grey[6],
		width: '100%',
		height: '100vh',
		paddingVertical: getResponsiveStyle(32),
		paddingHorizontal: getResponsiveStyle(40),
	},
	logoContainer: {
		marginBottom: getResponsiveStyle(70.59),
	},
	navLinkContainer: {
		alignItems: 'center',
		flexDirection: 'row',
		position: 'relative',
	},
	activeNavLink: {
		color: palettes.dark[0],
		fontWeight: 'bold',
		fontSize: getResponsiveStyle(16),
		lineHeight: getResponsiveStyle(44),
	},
	navLink: {
		color: palettes.grey[0],
		fontWeight: '400',
		fontSize: getResponsiveStyle(16),
		lineHeight: getResponsiveStyle(44),
	},
	navLinkActiveBorder: {
		backgroundColor: palettes.purple[0],
		position: 'absolute',
		width: 2,
		height: '100%',
		borderTopRightRadius: 10,
		borderBottomRightRadius: 10,
		overflow: 'hidden',
		top: 0,
		left: getResponsiveStyle(-35),
	},
	optionNavContainer: {
		marginTop: 'auto',
		marginBottom: getResponsiveStyle(54),
	},
});

export default MobileSidebar;
