import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-web-hover';
import { useGetTags } from '../api/config/useGetTags';
import { Tabs } from '../components';
import TableComponent from '../components/common/table-component/TableComponent';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { TAppConfig } from '../types/appConfig';
import { TTableHeader, TUseNavigation } from '../types/types';
const TagScreen = () => {
	const navigation = useNavigation<TUseNavigation>();
	const tabs = ['Interest Tags', 'Product Categories', 'Brand Categories'];
	const [selected, setSelected] = useState(tabs[0]);
	const [currentTags, setCurrentTags] = useState<TAppConfig[]>([]);
	const { data, getAppTags, loading } = useGetTags();
	useEffect(() => {
		getAppTags({ key: getKey(selected) });
	}, [selected]);
	const getKey = (selected: string) => {
		if (selected === tabs[0]) return 'INTEREST_TAG';
		if (selected === tabs[1]) return 'PRODUCT_CATEGORY';
		if (selected === tabs[2]) return 'BRAND_CATEGORY';
		return 0;
	};
	useEffect(() => {
		data &&
			data?.getTags &&
			data?.getTags?.data &&
			data?.getTags?.data?.data &&
			setCurrentTags(data?.getTags?.data?.data);
	}, [data]);

	const Headers: TTableHeader[] = [
		{ width: 150, headerLabel: 'English Label' },
		{ width: 150, headerLabel: 'French Label' },
		{ width: 250, headerLabel: 'Value' },
		{ width: 200, headerLabel: 'Created By' },
		{ width: 200, headerLabel: 'Last Modified' },
	];
	return (
		<>
			<DesktopTemplate>
				<Tabs
					tabs={tabs}
					selectedTab={selected}
					onPress={tab => {
						setSelected(tab);
					}}
					showDropdowns
					actionButtonProps={{
						label: 'Add ' + selected,
						onPress: () =>
							navigation.navigate('tagForm', {
								tag: {
									key: selected,
									description: null,
									value: {
										value1: '',
										value2: '',
										value3: '',
									},
								},
							}),
					}}
				/>

				<TableComponent
					type={'tag'}
					headers={Headers}
					data={currentTags?.map(item => {
						return { ...item, selected: selected };
					})}
					selectedTab={selected}
					onPressTab={tab => setSelected(tab)}
					loading={loading}
				/>
			</DesktopTemplate>
		</>
	);
};
export default TagScreen;
