import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import Modal from 'react-native-modal';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

export type TModalProps = {
	header: string;
	onClose: () => void;
	openState: boolean;
	children: JSX.Element;
	type?: 'modal' | 'sidebar';
	slideInRight?: boolean;
	isScrollable?: boolean;
	flexChild?: boolean;
	customWidth?: number;
};

const ModalComponent = ({ type = 'modal', ...props }: TModalProps) => {
	return (
		<View
			style={{
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: 22,
			}}
		>
			<Modal
				isVisible={props.openState}
				onBackdropPress={props.onClose}
				avoidKeyboard={true}
				backdropOpacity={0.3}
				onBackButtonPress={props.onClose}
				style={
					type === 'modal'
						? styles.modalContainer
						: props.slideInRight
						? styles.sidebarContainerRight
						: styles.sidebarContainer
				}
				animationIn={
					type === 'modal'
						? 'slideInUp'
						: props.slideInRight
						? 'slideInRight'
						: 'slideInLeft'
				}
				animationOut={
					type === 'modal'
						? 'slideOutDown'
						: props.slideInRight
						? 'slideOutRight'
						: 'slideOutLeft'
				}
			>
				<View
					style={
						type === 'modal'
							? [
									styles.modalContentContainer,
									props.customWidth && {
										width: getResponsiveStyle(
											props.customWidth
										),
									},
							  ]
							: [
									styles.sidebarContentContainer,
									props.slideInRight && {
										width: getResponsiveStyle(470),
									},
							  ]
					}
				>
					{(type === 'modal' || props.slideInRight) && (
						<View
							style={[
								styles.headerContainer,
								props.slideInRight &&
									styles.isSlideRightHeadSpacing,
							]}
						>
							<Text style={styles.header}>{props.header}</Text>
							<Text
								style={styles.closeButton}
								onPress={props.onClose}
							>
								Close
							</Text>
						</View>
					)}
					{props.isScrollable ? (
						<ScrollView
							showsVerticalScrollIndicator={true}
							showsHorizontalScrollIndicator={false}
							contentContainerStyle={{
								marginBottom: 'auto',
								marginTop:
									type === 'modal'
										? getResponsiveStyle(7)
										: 0,
							}}
						>
							{props.children}
						</ScrollView>
					) : (
						<View
							style={[
								{
									marginTop:
										type === 'modal'
											? getResponsiveStyle(38)
											: 0,
								},
								props.flexChild && { flex: 1 },
							]}
						>
							{props.children}
						</View>
					)}
				</View>
			</Modal>
		</View>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		justifyContent: 'center',
		margin: 0,
		alignItems: 'center',
	},
	sidebarContainer: {
		justifyContent: 'flex-start',
		margin: 0,
		alignItems: 'flex-start',
	},
	sidebarContainerRight: {
		justifyContent: 'flex-start',
		margin: 0,
		alignItems: 'flex-end',
	},
	modalContentContainer: {
		backgroundColor: palettes.light[0],
		shadowColor: palettes.grey[0],
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.18,
		shadowRadius: 4,
		elevation: 5,
		borderRadius: 6,
		borderColor: palettes.grey[5],
		borderWidth: 1,
		padding: getResponsiveStyle(35),
		width: getResponsiveStyle(488),
		maxWidth: '95vw',
		maxHeight: '100vh',
		// minHeight: getResponsiveStyle(500),
		// max
	},
	sidebarContentContainer: {
		backgroundColor: palettes.light[0],
		shadowColor: palettes.grey[0],
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.18,
		shadowRadius: 4,
		elevation: 5,
		borderColor: palettes.grey[5],
		borderWidth: 1,
		width: getResponsiveStyle(300),
		maxWidth: '70vw',
		// padding: getResponsiveStyle(20),
		maxHeight: '100vh',
		minHeight: '100vh',
		// overflowY: 'scroll',

		// max
	},
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	isSlideRightHeadSpacing: {
		paddingTop: getResponsiveStyle(30, 'spacing'),
		paddingRight: getResponsiveStyle(30, 'spacing'),
		paddingLeft: getResponsiveStyle(30, 'spacing'),
	},
	header: {
		fontSize: getResponsiveStyle(24, 'font'),
		color: palettes.dark[0],
		fontWeight: 'bold',
		lineHeight: getResponsiveStyle(18),
	},
	closeButton: {
		fontSize: getResponsiveStyle(14, 'font'),
		color: palettes.red[0],
		fontWeight: '600',
		lineHeight: getResponsiveStyle(14),
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		cursor: 'pointer',
	},
	contentContainer: {
		marginTop: getResponsiveStyle(38),
	},
});

export default ModalComponent;
