import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { CREATE_PRODUCT } from '../../graphql/product/mutation';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { TCreateProduct, TUseNavigation } from '../../types/types';
const useCreateProduct = () => {
	const { setAlert } = useSetAlert();
	const navigation = useNavigation<TUseNavigation>();
	const [createProduct, states] = useMutation(CREATE_PRODUCT, {
		onCompleted: data => {
			if (data?.createProduct?.success) {
				setAlert('Product Created Successfully', 'normal');
			} else {
				setAlert(data.createProduct.message, 'danger');
			}
		},
		onError: errors => {
			setAlert(errors?.message, 'danger');
		},
	});

	const executeProductMutation = async (data: TCreateProduct) => {
		return await createProduct({
			variables: {
				args: data,
			},
		});
	};
	return { executeProductMutation, ...states };
};

export default useCreateProduct;
