import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import useGetMedia from '../../../api/common/useGetMedia';
import { AcceptOrRejectEvent } from '../../../types/eventTypes';
import { TODO, TUseNavigation } from '../../../types/types';
import { EventCardBottomProps, CardBottom } from './CardBottom';
import { CardTop } from './CardTop';
type Props = {
	size?: 'large' | 'small';
	media: string[];
	id: string;
	time: string;
	acceptOrRejectMutation?: (variables?: AcceptOrRejectEvent) => Promise<TODO>;

	containerStyles?: StyleProp<ViewStyle>;
} & EventCardBottomProps;
type EventCardBodyProps = {
	clickable: boolean;
};
const EventCardBody = ({
	size = 'small',
	containerStyles,
	date,
	acceptOrRejectMutation,
	description,
	id,
	time,
	hasRequestButton,
	month,
	title,
	media,
	item,
	clickable,
}: Props) => {
	const { executeMediaQuery, data } = useGetMedia();

	useEffect(() => {
		if (media?.[0]) {
			executeMediaQuery({
				_id: media[0],
			});
		}
	}, []);

	return (
		<>
			<CardTop
				image={data?.getMedia?.data?.src}
				date={time}
				month={month}
			/>
			<CardBottom
				id={id}
				hasRequestButton={hasRequestButton}
				acceptOrRejectMutation={acceptOrRejectMutation}
				month={month}
				date={date}
				title={title}
				description={description}
				item={item}
				clickable={clickable}
			/>
		</>
	);
};
export default EventCardBody;
