import { gql } from '@apollo/client';

export const GET_MEDIA = gql`
	query getMedia($_id: ID!) {
		getMedia(_id: $_id) {
			statusCode
			success
			message
			data {
				... on Media {
					_id
					src
					alt
					name
				}
			}
		}
	}
`;
export const GET_USER_GRAPH_DATA = gql`
	query getUserGraphDataBasedOnAccountType($type:TypeOfAccount) {
		getUserGraphDataBasedOnAccountType(typeOfAccount:$type) {
			statusCode
			success
			message
			data {
				... on GraphDataResponse {
					previousMonth {
						dataPoint
						week
					}
					currentMonth {
						dataPoint
						week
					}
				}
			}
		}
	}
`;
