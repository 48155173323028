export const dateFns = {
	month(date: number) {
		const fn = new Intl.DateTimeFormat('en-US', { month: 'short' }).format;
		return fn(date);
	},
	date(date: number) {
		const fn = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format;
		return fn(date);
	},
	addDays(daysToAdd: number, date: Date) {
		date.setDate(date.getDate() + daysToAdd);
		return date;
	},
	formatAMPM(date: Date) {
		let hours = date.getHours();
		let minutes: string | number = date.getMinutes();
		let seconds = date.getSeconds();
		let ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;
		var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
		return strTime;
	},
	mdy(dateObj: Date) {
		const months = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		];
		const month = dateObj.getMonth(); //months from 1-12
		let day: string | number = dateObj.getDate();
		day = day < 10 ? '0' + day : day;
		const year = dateObj.getUTCFullYear();

		return `${months[month]} ${day}, ${year}`;
	},
};
