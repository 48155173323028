import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import { TSignupInformation } from '../types/authTypes'

const initialState = {
    newUser:{
        username:'',
        email: '',
        typeOfAccount: '',
        password: '',
        affiliatedBrand: '',
        gender: '',
        role:'',
        personalInformation:{
            firstName: '',
            lastName: '',
            phoneNumber: '',
            profileImageLink: '',
            address:{
                address1: '',
                address2: '',
                city: '',
                zipCode: '',
                countryCode:'',
                countryLabel: ''
            }
        }
    }
}

const createUserSlice = createSlice({
    name:'createUser',
    initialState,
    reducers:{
        setCreateUserDetails(state, action: PayloadAction<TSignupInformation>){
            state.newUser = { ...state.newUser, ...action.payload}
        }
    }
})

export const {setCreateUserDetails} = createUserSlice.actions

export default createUserSlice.reducer