import { View, Text } from 'react-native';
import React, { useEffect, useMemo, useState } from 'react';
import { GoBackButton } from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import TableComponent from '../components/common/table-component/TableComponent';
import { TGetAllSellersResponse, TGetAllUsersResponse, TStatsforTable, TUserDataForTable } from '../types/userTypes';
import { useLazyQuery } from '@apollo/client';
import { TFind, TGetAllUsersFind, TPaginatedOptions } from '../types/types';
import { GET_ALL_SELLERS, GET_ALL_USERS, GET_USER_TABLE_STATS } from '../graphql/auth/queries';
import Loader from '../components/common/loader/Loader';
import { palettes } from '../config';
import { manipulateString } from '../utils/manipulateString';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';

const TABS = ['All Sellers', 'New Sellers'];

const HEADERS = [
	{
		headerLabel: 'Name',
		width: getResponsiveStyle(180),
	},
	{
		headerLabel: 'Status',
		width: getResponsiveStyle(80),
	},
	{
		headerLabel: 'Location',
		width: getResponsiveStyle(150),
	},
	{
		headerLabel: 'Followers',
		width: getResponsiveStyle(100),
	},
	{
		headerLabel: 'Events Attended',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Purchase Amount',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Email',
		width: getResponsiveStyle(150),
	},
];

const AllSellersScreen = () => {
	const [selectedTab, setSelectedTab] = useState(TABS[0]);
	const [allSellersData, setAllSellersData] = useState<TUserDataForTable[]>();
	const [stats, setStats] = useState<TStatsforTable>();
	const [waiting, setwaiting] = useState<any[]>([]);


	const [getAllSellers, { loading:sellersLoading,data:sellersData }] = useLazyQuery<
	TGetAllUsersResponse,TPaginatedOptions & TFind
	>
	(GET_ALL_USERS, {
		fetchPolicy: 'cache-and-network',
		variables:{
			find:{
				typeOfAccount: 'SELLER'
			}as TGetAllUsersFind
		},
		onCompleted: data => {
			if (data?.getAllUsers?.data && data?.getAllUsers?.data.data) {
				setwaiting(data.getAllUsers.data.data.filter(t => t.profileStatus === 'WAITING'));
				setStats({
					totalnum: data.getAllUsers.data.total,
					active: data.getAllUsers.data.totalActive,
					inactive: data.getAllUsers.data.totalInactive,
					newRequest: data.getAllUsers.data.newRequest,
				});
				getUserStats({
					variables: {
						id: data.getAllUsers.data.data.map(o => o._id),
					},
				});
			}
			}

	});
	
const [getUserStats, { loading: loadingStats }] = useLazyQuery(
		GET_USER_TABLE_STATS,
		{
			onCompleted: resp => {
				console.log(resp);

				let arrTotal = sellersData?.getAllUsers?.data?.data?.map(
					seller => ({
						image:
							seller?.personalInformation?.profileImageLink ??
							null,
						name: seller?.username,
						status: manipulateString.capitalize(seller.profileStatus.toLowerCase()) ,
						location:
							seller?.personalInformation?.address?.city +
							', ' +
							seller?.personalInformation?.address?.countryLabel,
						followers: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === seller?._id
						).followersCount,
						eventsAttended:
							resp?.getUserStats?.data?.data?.find(
	(val: { userId: string }) =>
									val.userId === seller?._id
							).eventsCount,
						purchaseAmount:
							resp?.getUserStats?.data?.data?.find(
								(val: { userId: string }) =>
									val.userId === seller?._id
							)?.productsCount,
						email: seller?.email,
						_id:seller?._id
					})
				);
				console.log(arrTotal);

				setAllSellersData(arrTotal);
			},

				})


	useEffect(() => {
		getAllSellers();
	}, []);
	const unifiedLoading=useMemo(()=>
	getSingleLoadingState(loadingStats,sellersLoading),[loadingStats,sellersLoading])
	const onPressTab = (tab: string) => {
		if (tab === TABS[1]) {
			getAllSellers({
				variables: {
					sort: {
						createdAt: -1,
					},
				},
			});
		}
		if (tab === TABS[0]) {
			getAllSellers();
		}
		setSelectedTab(tab);
	};
	return (
		<DesktopTemplate>
			<GoBackButton history="Sellers" />
			<View style={{ marginTop: getResponsiveStyle(32) }}>
				{ allSellersData && allSellersData.length > 0 ? (
					<View>
						<TableComponent
							headerTabs={TABS}
							type="profile"
							data={allSellersData}
							onPressTab={onPressTab}
							selectedTab={selectedTab}
							headers={HEADERS}
							loading={unifiedLoading}
							tableContentProps={
								{
									profileButton:true,
									messageButton:true,
								}
							}
						/>
					</View>
				) :unifiedLoading ? (
					<Loader />
				) : (
					<Text
						style={{
							fontSize: getResponsiveStyle(14, 'font'),
							lineHeight: getResponsiveStyle(14, 'font'),
							color: palettes.grey[0],
						}}
					>
						No data Available!
					</Text>
				)}
			</View>
		</DesktopTemplate>
	);
};

export default AllSellersScreen;
