import React from 'react';
import { View } from 'react-native';
import { AddBrandForm } from '../components';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';

const AddBrandScreen = () => {
	return (
		<DesktopTemplate
			navigationBarProps={{
				hasGoBackButton: true,
			}}
		>
			<View
				style={{
					alignItems: 'center',
				}}
			>
				<View
					style={{ minWidth: getResponsiveStyle(464, 'dimensions') }}
				>
					<AddBrandForm />
				</View>
			</View>
		</DesktopTemplate>
	);
};

export default AddBrandScreen;
