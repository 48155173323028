import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ProductCardSlider from './ProductCardSlider';
import { useTranslation } from 'react-i18next';

const ProductScrollSection = () => {
	const { t } = useTranslation();
	return (
		<View style={[Styles.container, styleContainer()]}>
			<View style={Styles.headerContainer}>
				<Text style={Styles.title}>{t('Products')}</Text>
				{/* <Text style={Styles.hoverText}>{t('View All')}</Text> */}
			</View>

			<View style={Styles.sliderContainer}>
				<ProductCardSlider />
			</View>
		</View>
	);
};

const Styles = StyleSheet.create({
	container: {
		// width: '100%',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	title: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	sliderContainer: {
		width: '100%',
		marginTop: getResponsiveStyle(29),
	},
	hoverText: {
		// marginTop: 20,
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[2],
		cursor: 'pointer',
	},
});
export default ProductScrollSection;

function styleContainer() {
	return {
		marginTop: getResponsiveStyle(51),
		// marginBottom: getResponsiveStyle(41),
	};
}
