import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Button } from '../..';
import { palettes } from '../../../config';

import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

function DescriptionContent({ content }: { content: String }) {

	return (
		<View style={[Styles.container]}>
			<View >
				<Text style={Styles.title}>Description</Text>
			</View>
			<View style={Styles.contentContainer}>
				<Text style={Styles.subtitle}>{content || ''}</Text>
			</View>
			<View style={Styles.buttonContainer}>
				{content.length > 500 && (
					<Text style={Styles.option}>{'Learn More'}</Text>
				)}
			</View>
		</View>
	);
}

const Styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	buttonContainer: {
		marginTop: getResponsiveStyle(18),
	},
	contentContainer: {
		marginTop: getResponsiveStyle(29),
	},
	option: {
		fontSize: getResponsiveStyle(14, 'font'),
		fontWeight: '600',
		textDecorationLine: 'underline',
		textUnderlineOffset: 2,
		color: palettes.grey[2],
		cursor: 'pointer',
	},
	title: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	subtitle: {
		fontSize: getResponsiveStyle(18, 'font'),
		fontWeight: 'normal',
		color: palettes.dark[2],
	},
});
export default DescriptionContent;

