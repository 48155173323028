import { useLazyQuery } from '@apollo/client';
import { GET_MEDIA } from '../../graphql/common/queries';

const useGetMedia = () => {
	const [getMedia, { data, error, loading }] = useLazyQuery(GET_MEDIA, {});

	const executeMediaQuery = ({
		_id,
		onCompleted,
	}: {
		_id: string;
		onCompleted?: (data: TMediaResponse) => void;
	}) => {
		getMedia({
			variables: {
				_id,
			},
			onCompleted,
		});
	};
	return { executeMediaQuery, data, error, loading };
};

export default useGetMedia;
