import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_MEDIA } from '../../graphql/common/mutations';
import { useSetAlert } from '../../hooks/useSetAlerts';

type TParams = {
	onCompleted?: (data: TMediaResponse) => void;
};

export const useCreateMedia = ({ onCompleted }: TParams) => {
	const { setAlert } = useSetAlert();
	const onError = (error: ApolloError) => {
		if (error.message) {
			setAlert(error.message[0], 'danger');
		}
	};
	const [_createMedia, states] = useMutation<TMediaResponse>(CREATE_MEDIA, {
		onCompleted,
		onError,
	});

	const uploadMedia = async (
		file: File,
		category: TUploadMediaCategory,
		alt: string
	) => {
		_createMedia({
			variables: {
				file,
				category,
				alt,
			},
		});
	};
	return { uploadMedia, _createMedia, ...states };
};
