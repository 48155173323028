import { useMutation } from '@apollo/client';
import { ADD_APP_CONFIG, EDIT_APP_CONFIG } from '../../graphql/config/mutation';

type ValueInput = {
	value1: string;
	value2: string;
	value3?: string;
};
type TQueryArgs = {
	key: string;
	description?: string;
	value: ValueInput;
};
type THookProps = {
	refetched?: any[];
	onError: () => void;
	onCompleted: (data?: any) => void;
};
export const useEditAppConfig = ({
	onCompleted,
	refetched = [],
	onError,
}: THookProps) => {
	const [editAppConfig_, { loading: editAppConfigLoading }] =
		useMutation(EDIT_APP_CONFIG);

	const editAppConfig = ({ key, description, value }: TQueryArgs) => {
		editAppConfig_({
			variables: {
				key,
				value,
				description,
			},
			awaitRefetchQueries: true,
			refetchQueries: refetched,
			onError: () => onError(),
			onCompleted,
		});
	};

	return { editAppConfig, editAppConfigLoading };
};
