import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TGetAllEventCommon } from '../types/eventTypes';

type TInitialState = {
	event: TGetAllEventCommon;
	isEditEvent: boolean;
	eventIds: string[];
	showLocks: boolean;
	disableLivestreamFromIVS: boolean;
	disableDailyRoomNStream: boolean;
};

const initialState: TInitialState = {
	event: {
		_id: '',
		affiliatedBrands: [],
		affiliatedHosts: [],
		affiliatedSeller: {
			_id: '',
			personalInformation: {
				profileImageLink: '',
			},
			username: '',
		},
		createdAt: '',
		description: '',
		eventMedia: [],
		invitedPeople: [],
		isHostApplicationAccepted: true,
		isSellerApplicationOpen: true,
		name: '',
		startingEventDateTime: '',
		isApproved: false,
		isHostRequired: false,
		typeOfEvent: 'VIRTUAL',
		eventLocation: {
			address1: '',
			city: '',
			countryCode: '',
			countryLabel: '',
		},
		isStreamGenerated: false,
		eventStreamTimeline: null,
	},
	isEditEvent: false,
	eventIds: [],
	showLocks: false,
	disableLivestreamFromIVS: false,
	disableDailyRoomNStream: false,
};

const eventSlice = createSlice({
	name: 'getEvent',
	initialState,
	reducers: {
		setCurrentEvent(state, action: PayloadAction<TGetAllEventCommon>) {
			state.event = action.payload;
		},
		
	},
});

export const {
	setCurrentEvent,
	
} = eventSlice.actions;

export default eventSlice.reducer;
