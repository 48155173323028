import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TError } from '../types/types';
import { getWidth } from '../utils/getWidth';

type TInitialState = {
	noOfNotifications: number
};

const initialState: TInitialState = {
	noOfNotifications: 0

};

const notficationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		setNoOfNotifications(state, action: PayloadAction<number>) {
			state.noOfNotifications = action.payload;
		},
		
	},
});

export const {
	setNoOfNotifications
} = notficationSlice.actions;

export default notficationSlice.reducer;
