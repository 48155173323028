import React from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";
import { palettes } from "../../../config";
import { TStyleInputOrText } from "../../../types/TStyle";
import { getResponsiveStyle } from "../../../utils/getResponsiveStyle";
import { hexToRGB } from "../../../utils/hexToRgba";

type Props = {
  text: string;
  selected?: boolean;
  onClick: (tesxt: string) => void;
  isFirstItem?: boolean;
  value: string;
};

export const CategoryPill = ({
  text,
  selected = false,
  onClick,
  isFirstItem,
  value,
}: Props) => {
  return (
    <Pressable
      onPress={() => onClick(value)}
      style={[
        style.container,
        styleContainer(),
        isFirstItem && { marginLeft: -19 },
        selected && style.selectedContainer,
      ]}
    >
      <Text
        style={[selected ? styleSelectedCategory() : styleUnselectedCategory()]}
      >
        {text}
      </Text>
    </Pressable>
  );
};

const style = StyleSheet.create({
  container: {
    borderRadius: 20,
    userSelect: "none",
  },
  selectedContainer: {
    backgroundColor: hexToRGB(palettes.primary[1], 0.5),
  },
});
function styleContainer() {
  return {
    paddingHorizontal: getResponsiveStyle(19),
    paddingVertical: getResponsiveStyle(7),
  };
}

function styleUnselectedCategory() {
  return {
    lineHeight: getResponsiveStyle(14, "font"),
    fontSize: getResponsiveStyle(16, "font"),
    fontWeight: "400",
    color: palettes.grey[0],
  } as TStyleInputOrText;
}

function styleSelectedCategory() {
  return {
    fontSize: getResponsiveStyle(16, "font"),
    lineHeight: getResponsiveStyle(18, "font"),
    fontWeight: "600",
    color: palettes.primary[0],
  } as TStyleInputOrText;
}
