import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { useGetUserMetricChange } from '../api/auth/useGetUserMetricChange';
import Chart from '../components/common/chart-components/Chart';
import Loader from '../components/common/loader/Loader';
import PageHeader from '../components/common/page-header/PageHeader';
import PeopleScrollSection from '../components/common/people-scroll-section/PeopleScrollSection';
import TableComponent from '../components/common/table-component/TableComponent';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import ViewAllText from '../components/common/view-all-text/ViewAllText';
import { palettes } from '../config';
import {
	GET_ALL_HOSTS,
	GET_PENDING_HOSTS,
	GET_ALL_USERS,
	GET_USER_TABLE_STATS,
} from '../graphql/auth/queries';
import { GET_USER_GRAPH_DATA } from '../graphql/common/queries';
import { ACCEPT_OR_REJECT } from '../graphql/user/mutation';
import { TFind, TPaginatedOptions } from '../types/types';
import {
	TGetAllHostsResponse,
	TGetAllUsersResponse,
	TStatsforTable,
	TUserDataForTable,
} from '../types/userTypes';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';
import { manipulateString } from '../utils/manipulateString';

const TABS = ['All Hosts', 'New Hosts'];

const HEADERS = [
	{
		headerLabel: 'Name',
		width: getResponsiveStyle(180),
	},
	{
		headerLabel: 'Status',
		width: getResponsiveStyle(80),
	},
	{
		headerLabel: 'Location',
		width: getResponsiveStyle(150),
	},
	{
		headerLabel: 'Followers',
		width: getResponsiveStyle(100),
	},
	{
		headerLabel: 'Events Attended',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Purchase Amount',
		width: getResponsiveStyle(140),
	},
	{
		headerLabel: 'Email',
		width: getResponsiveStyle(140),
	},
];

function HostScreen() {
	const [selectedTab, setSelectedTab] = useState(TABS[0]);
	const [allHostsData, setAllHostsData] = useState<TUserDataForTable[]>();
	const [stats, setStats] = useState<TStatsforTable>();
	const [waiting, setwaiting] = useState<any[]>([]);
	const [PrevGraphData, setPrevGraphData] = useState<number[]>();
	const [CurrGraphData, setCurrGraphData] = useState<number[]>();
	const [getUserStats, { loading: loadingStats }] = useLazyQuery(
		GET_USER_TABLE_STATS,
		{
			onCompleted: resp => {
				console.log(resp);

				let arrTotal = hostsData?.getAllUsers?.data?.data?.map(
					hosts => ({
						image:
							hosts?.personalInformation?.profileImageLink ??
							null,
						name: hosts?.username,
						status: manipulateString.capitalize(
							hosts.profileStatus.toLowerCase()
						),
						location:
							hosts?.personalInformation?.address?.city +
							', ' +
							hosts?.personalInformation?.address?.countryLabel,
						followers: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === hosts?._id
						).followersCount,
						eventsAttended: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === hosts?._id
						).eventsCount,
						purchaseAmount: resp?.getUserStats?.data?.data?.find(
							(val: { userId: string }) =>
								val.userId === hosts?._id
						)?.productsCount,
						email: hosts?.email,
						_id: hosts._id,
					})
				);
				console.log(arrTotal);

				setAllHostsData(arrTotal);
			},
			onError: () => {
				console.log('ther was an error');
			},
		}
	);

	const [getSomeHosts, { loading: loading, data: hostsData }] = useLazyQuery<
		TGetAllUsersResponse,
		TPaginatedOptions & TFind
	>(GET_ALL_USERS, {
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			if (data?.getAllUsers?.data && data?.getAllUsers?.data?.data) {
				let x: any[] = data.getAllUsers.data.data;
				setStats({
					totalnum: data.getAllUsers.data.total,
					active: data.getAllUsers.data.totalActive,
					inactive: data.getAllUsers.data.totalInactive,
					newRequest: data.getAllUsers.data.newRequest,
				});
				getUserStats({
					variables: {
						id: data.getAllUsers.data.data.map(o => o._id),
					},
				});
			}
		},
	});
	// const { loading: loadingwait, data: hostsDatawait } = useQuery<
	// 	TGetAllHostsResponse,
	// 	TPaginatedOptions & TFind
	// >(GET_ALL_HOSTS, {
	// 	fetchPolicy: 'no-cache',
	// 	variables: {
	// 		find: {
	// 			profileStatus: 'WAITING',
	// 		},
	// 		limit: 10,
	// 	},
	// 	onCompleted: data => {
	// 		if (data?.getAllHosts?.data && data?.getAllHosts?.data?.data) {
	// 			setwaiting(data.getAllHosts.data.data);
	// 		}
	// 	},
	// });

	const [getWaitingHosts, { loading: waitingHostsLoading }] = useLazyQuery(
		GET_PENDING_HOSTS,
		{
			onCompleted: data => {
				if (data?.getAllUsers?.data?.data)
					setwaiting(data.getAllUsers.data.data);
			},
		}
	);

	const { getUserMetricChange, data: hostsMetric } = useGetUserMetricChange();

	useEffect(() => {
		getUserMetricChange('HOST');
	}, []);

	const hostsMetrics = hostsMetric?.getUserMetricsPercentageChange?.data;

	useEffect(() => {
		getSomeHosts({
			variables: { limit: 10, find: { typeOfAccount: 'HOST' } },
		});

		getWaitingHosts();
	}, []);

	const onPressTab = (tab: string) => {
		if (tab === TABS[1]) {
			getSomeHosts({
				variables: {
					sort: {
						createdAt: -1,
					},
					limit: 10,
					find: { typeOfAccount: 'HOST' },
				},
			});
		}
		if (tab === TABS[0]) {
			getSomeHosts({
				variables: {
					limit: 10,
					find: { typeOfAccount: 'HOST' },
				},
			});
		}
		setSelectedTab(tab);
	};

	useQuery(GET_USER_GRAPH_DATA, {
		variables: {
			type: 'HOST',
		},
		onCompleted: data => {
			if (
				data.getUserGraphDataBasedOnAccountType.success &&
				data.getUserGraphDataBasedOnAccountType.data
			) {
				let currMonth =
					data.getUserGraphDataBasedOnAccountType.data.currentMonth.map(
						(value: { dataPoint: number }) => {
							return value.dataPoint;
						}
					);
				let prevMonth =
					data.getUserGraphDataBasedOnAccountType.data.previousMonth.map(
						(value: { dataPoint: number }) => {
							return value.dataPoint;
						}
					);
				setPrevGraphData(prevMonth);
				setCurrGraphData(currMonth);
			}
		},
	});
	const [acceptOrRejectUserAccount, { loading: acceptOrRejectLoading }] =
		useMutation(ACCEPT_OR_REJECT, {
			awaitRefetchQueries: true,
			refetchQueries: [GET_ALL_USERS],
		});

	return (
		<DesktopTemplate>
			<PageHeader
				prevData={PrevGraphData}
				currData={CurrGraphData}
				pageHeader="All Hosts"
				chartItemValues={[
					{
						title: 'All Hosts',
						percentageDifference: Math.abs(
							hostsMetrics?.allUsers || 0
						),
						percentageIncrease: hostsMetrics?.allUsers >= 0,
						value: stats?.totalnum || 0,
					},
					{
						title: 'Active Hosts',
						percentageDifference: Math.abs(
							hostsMetrics?.activeUsers || 0
						),
						percentageIncrease: hostsMetrics?.activeUsers >= 0,
						value: stats?.active || 0,
					},
					{
						title: 'Inactive Hosts',
						percentageDifference: Math.abs(
							hostsMetrics?.inactiveUsers || 0
						),
						percentageIncrease: hostsMetrics?.inactiveUsers >= 0,
						value: stats?.inactive || 0,
					},
					{
						title: 'New Requests',
						percentageDifference: Math.abs(
							hostsMetrics?.newRequests || 0
						),
						percentageIncrease: hostsMetrics?.newRequests >= 0,
						value: stats?.newRequest || 0,
					},
				]}
			/>

			{loading || loadingStats || waitingHostsLoading ? (
				<Loader />
			) : (
				<>
					<PeopleScrollSection
						header="New Host Requests"
						newRequest={waiting}
						cardProps={{ isRequest: true }}
						navigationDestination="HostsRequests"
						acceptOrRejectMutation={acceptOrRejectUserAccount}
					/>

					{allHostsData && allHostsData.length > 0 ? (
						<>
							<View
								style={{
									marginTop: getResponsiveStyle(35),
								}}
							>
								<TableComponent
									headerTabs={TABS}
									type="profile"
									data={allHostsData}
									onPressTab={onPressTab}
									selectedTab={selectedTab}
									headers={HEADERS}
									loading={loading}
									tableContentProps={{
										profileButton: true,
										messageButton: true,
									}}
								/>
							</View>
							<View
								style={{
									marginTop: getResponsiveStyle(67),
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<ViewAllText
									title="View All Hosts"
									navigationDestination="AllHosts"
								/>
							</View>
						</>
					) : (
						<Text
							style={{
								fontSize: getResponsiveStyle(14, 'font'),
								lineHeight: getResponsiveStyle(14, 'font'),
								color: palettes.grey[0],
							}}
						>
							No data Available!
						</Text>
					)}
				</>
			)}

			{/* <PeopleScrollSection
					header="New Host Requests"
					newRequest={waiting}
					cardProps={{ isRequest: true }}
					navigationDestination="HostsRequests"
					acceptOrRejectMutation={acceptOrRejectUserAccount}
				/> */}

			{/* {allHostsData && allHostsData.length > 0 ? (
				<>
					<View
						style={{
							marginTop: getResponsiveStyle(35),
						}}
					>
						<TableComponent
							headerTabs={TABS}
							type="profile"
							data={allHostsData}
							onPressTab={onPressTab}
							selectedTab={selectedTab}
							headers={HEADERS}
							loading={loading}
							tableContentProps={{
								profileButton: true,
								messageButton: true,
							}}
						/>
					</View>
					<View
						style={{
							marginTop: getResponsiveStyle(67),
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<ViewAllText
							title="View All Hosts"
							navigationDestination="AllHosts"
						/>
					</View>
				</>
			) : loading || loadingStats ? (
				<Loader />
			) : (
				<Text
					style={{
						fontSize: getResponsiveStyle(14, 'font'),
						lineHeight: getResponsiveStyle(14, 'font'),
						color: palettes.grey[0],
					}}
				>
					No data Available!
				</Text>
			)} */}
		</DesktopTemplate>
	);
}

export default HostScreen;
