import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useGetAllOrders } from '../../../api/order/useGetOrders';
import { useGetAllUsersOrders } from '../../../api/order/useGetUsersOrders';
import OrderComponent from '../../dashboard/OrderComponent';
import OrderSection from '../../dashboard/OrderSection';

type Props = {
	id: string;
};
const ProfileOrders = ({ id }: Props) => {
	const {
		getAllOrders,
		data: AllOrders,
		loading: loadingOrders,
	} = useGetAllOrders();

	useEffect(() => {
		getAllOrders({
			find: { orderStatus: 'IN_PROGRESS', paymentStatus: "SUCCESS", brands: { $in: [id] } }
		})
	}, [])

	return (
		<>
			<OrderComponent
				hasViewAllButton={false}
				data={AllOrders}
				loading={loadingOrders}
				action={getAllOrders}
				userId={id}
			/>
		</>
	);
};
export default ProfileOrders;
