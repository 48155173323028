import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { StarterKit } from '../../../types/productTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Button from '../../common/button/Button';
import ModalComponent from '../../common/modal/ModalComponent';
import { CollectionDetails } from '../collection-details/CollectionDetails';
import ProductCards from '../product-cards/ProductCards';
import CreateStarterKitModalContent from '../create-starter-kit-modal-content/CreateStarterKitModalContent'


const StartedKitsList = (props: StarterKit) => {
	const [modalState, setModalState] = useState(false)
	return (
		<View style={styles.container}>
			<CollectionDetails {...props} />
			<ProductCards variants={props.variants} />

			<Button title='Edit' onPress={() => setModalState(true)} />


			<ModalComponent
				header="Edit Starter Kit"
				isScrollable
				customWidth={581}
				openState={modalState}
				onClose={() => {
					setModalState(false);
				}}
			>
				<CreateStarterKitModalContent
					editStarterKit={true}
					starterKit={props}
					closeModal = {()=> setModalState(false)}
					onSubmit={()=>{}}
					starterKitCreateLoading={false}
				/>
			</ModalComponent>
		</View>
	);
};

export default StartedKitsList;

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		marginBottom: getResponsiveStyle(38),
	},
});
