import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { CategoryPill } from "./CategoryPill";

type Props = {
  label: string;
  categories: TDropdownObject[];
};

export const CategoryPillList = ({ label, categories }: Props) => {
  const [selectedCategory, setselectedCategory] = useState("");
  const onClickCategory = (category: string) => {
    setselectedCategory(category);
  };

  useEffect(() => {
    setselectedCategory(categories[1]?.value);
  }, [categories]);

  return (
    <View style={styles.categoryPillContainer}>
      {categories.map((category, index) => {
        return (
          <CategoryPill
            key={category.label}
            text={index === 1 ? `${label}${category.label}` : category.label}
            selected={selectedCategory === category.value}
            onClick={onClickCategory}
            isFirstItem={index === 0}
            value={category.value}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  categoryPillContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
});
