import { Text, StyleSheet } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	message: string;
};

const NotAvailableMessage = ({ message }: Props) => {
	return <Text style={styles.text}>{message}</Text>;
};

export default NotAvailableMessage;

const styles = StyleSheet.create({
	text: {
		fontWeight: '400',
		fontSize: getResponsiveStyle(18, 'font'),
		lineHeight: getResponsiveStyle(25, 'font'),
		color: palettes.grey[0],
	},
});
