import * as React from "react";
import { Pressable } from "react-native";
import Svg, { SvgProps, Path } from "react-native-svg";

const UploadDocumentsIcon = (props: SvgProps) => (
  <Pressable>
    <Svg width={24} height={28} viewBox="0 0 24 28" fill="none" {...props}>
      <Path
        d="M17.333.667 24 7.333v18.678a1.324 1.324 0 0 1-1.324 1.322H1.324A1.333 1.333 0 0 1 0 26.011V1.989C0 1.26.593.667 1.324.667h16.01Zm-4 13.333h4L12 8.667 6.667 14h4v5.333h2.666V14Z"
        fill="#C3CAD4"
      />
    </Svg>
  </Pressable>
);

export default UploadDocumentsIcon;
