import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Button, Upload } from '../..';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import axios from 'axios';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { serverLink } from '../../../../App';

const UploadCSVForm = () => {
	const { setAlert } = useSetAlert();
	const [file, setFile] = useState<File[]>([]);
	const [loading, setLoading] = useState(false);
	const uploadFile = async (file: File[]) => {
		setLoading(true);
		const formData = new FormData();
		formData.append('files', file[0]);
		const result = await axios.post(
			serverLink + '/product/bulk-upload',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		);
		console.log(result);
		setAlert('Upload in Progress', 'normal');
		setFile([]);
		setLoading(false);
	};
	const onDrop = (vart: any) => {
		setAlert('Uploading File', 'normal');
		setFile(vart);
	};
	return (
		<View style={styles.outerContainer}>
			<View style={styles.innerContainer}>
				<Text style={styles.heading}>Upload CSV</Text>
				<Text style={styles.text}>
					Fill out all the mandatory fields
				</Text>
				<Upload
					label={'Upload Excel File'}
					hint={
						file && file?.length
							? file[0].name
							: 'Drag and drop or browse your files for a .xls file'
					}
					containerStyles={{
						marginBottom: getResponsiveStyle(80),
					}}
					dropzoneStyles={{
						height: getResponsiveStyle(264, 'dimensions'),
					}}
					accept={['.xls', '.xlsx']}
					onDrop={item => onDrop(item)}
				/>
				<Button
					title={'Upload'}
					variant={file && file?.length ? 'primary' : 'disabled'}
					onPress={() => uploadFile(file)}
					loading={loading}
				/>
			</View>
		</View>
	);
};

export default UploadCSVForm;

const styles = StyleSheet.create({
	outerContainer: {
		alignItems: 'center',
	},
	innerContainer: {
		marginBottom: getResponsiveStyle(36),
		minWidth: getResponsiveStyle(465, 'dimensions'),
	},
	text: {
		fontWeight: '400',
		color: palettes.dark[4],
		textAlign: 'center',
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
		marginBottom: getResponsiveStyle(36),
	},
	heading: {
		fontWeight: '700',
		color: palettes.dark[0],
		textAlign: 'center',
		fontSize: getResponsiveStyle(34, 'font'),
		lineHeight: getResponsiveStyle(32, 'font'),
		marginBottom: getResponsiveStyle(13),
	},
});
