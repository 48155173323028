import {ApolloError,useMutation} from '@apollo/client';
import { EDIT_BRAND} from '../../graphql/brands/mutations';
import {
	TGetAllBrandsData,
    TGetAllBrandsResponse,
    TGetEditedSingleBrandResponse,
    
} from '../../types/brandTypes';
import {useSetAlert} from '../../hooks/useSetAlerts';
import {useAppSelector} from '../../redux/store';
import { GET_ALL_BRANDS } from '../../graphql/brands/queries';
const refetchQueries=[
    {
        query:GET_ALL_BRANDS
    }
]
const useEditBrand=(
    onCompleted:(value:TGetEditedSingleBrandResponse)=>void|undefined,
    _onError?:(error:ApolloError)=>void|undefined
    )=>{
        // const brandState=useAppSelector(state=>state.)
        const {setAlert}=useSetAlert();
        const onError=(error:ApolloError)=>{
            if(error){
                setAlert(error.message,'danger')
            }
        }
        const [_editBrand,{data,error,loading}]=
        useMutation(EDIT_BRAND,{
            awaitRefetchQueries:true,
            refetchQueries,
            onCompleted,
            onError:error=>{
                _onError===undefined?onError(error):_onError(error);
                
            }
        })
        const editBrand=(_id:string,args:any)=>{
            _editBrand({
                variables:{
                    _id:_id,
                    args:args
                }
            })
        }
        return {editBrand,data,error,loading}

    }
export default useEditBrand