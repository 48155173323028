import { useMutation } from "@apollo/client";
import { CREATE_NEW_USER } from "../../graphql/auth/mutations";
import { TCreateNewUser, TSignupInformation } from "../../types/authTypes";
import { useNavigation } from "@react-navigation/native";
import { TUseNavigation } from "../../types/types";
import { useSetAlert } from "../../hooks/useSetAlerts";
import { useAppSelector } from "../../redux/store";


export const useCreateUser=(
    onCompleted: (data:TCreateNewUser)=> void | undefined
)=>{
    const formData = useAppSelector(state=>state.createNewUser.newUser)
    const [createUser, states] = useMutation<TCreateNewUser>(CREATE_NEW_USER,{
        onCompleted,
    })
    const setCreateUserVariables =()=>{
        createUser({
            variables:{
                args:formData
            }
        })
    }

    return {setCreateUserVariables, ...states}
}