import { View, Text, StyleSheet, Image } from 'react-native';
import React from 'react';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
type Props = {
	product: AffiliatedProduct;
};
const ProductCard = ({ product }: Props) => {
	return (
		<View style={[Styles.container, styleContainer()]}>
			<Image
				source={{
					uri: product.images ? product?.images[0]?.src : '',
				}}
				style={Styles.image}
			/>
			<Text style={Styles.name}>
				{product.shortDescription.substring(0, 100)}
			</Text>
			<Text style={Styles.price}>
				${product.variants && product.variants[0].price}
			</Text>
		</View>
	);
};

const Styles = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		// width: '208px',
	},
	image: {
		width: '100%',
		height: '200px',
		resizeMode: 'cover',
		borderRadius: 6,
	},
	name: {
		marginTop: 14,
		fontSize: 12,
		fontWeight: '400',
		color: palettes.dark[2],
		textAlign: 'center',
	},
	price: {
		marginTop: 6,
		fontSize: 14,
		fontWeight: '700',
		color: palettes.dark[0],
	},
});

export default ProductCard;

const styleContainer = () => {
	return {
		marginRight: getResponsiveStyle(17),
		// paddingHorizontal: getResponsiveStyle(24),
		// paddingVertical: getResponsiveStyle(24),
		width: getResponsiveStyle(208, 'dimensions'),
	};
};
