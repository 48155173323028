import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '../types/productTypes';
import { TError } from '../types/types';
import { getWidth } from '../utils/getWidth';

type TInitialState = {
	addProductModalState: boolean;
	showUploadCSV: boolean;
	productTableDetail: Product;
	refetchProduct: boolean;
};

const initialState: TInitialState = {
	addProductModalState: false,
	showUploadCSV: false,
	productTableDetail: {} as Product,
	refetchProduct: false,
};

const productsSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setAddProductModalState(state, action: PayloadAction<boolean>) {
			state.addProductModalState = action.payload;
		},
		setShowUploadCSV(state, action: PayloadAction<boolean>) {
			state.showUploadCSV = action.payload;
			state.addProductModalState = false;
		},
		setProductTableDetail(state, action: PayloadAction<Product>) {
			state.productTableDetail = action.payload;
		},
		setRefetchProduct(state, action: PayloadAction<boolean>) {
			state.refetchProduct = action.payload;
		},
	},
});

export const {
	setAddProductModalState,
	setShowUploadCSV,
	setProductTableDetail,
	setRefetchProduct,
} = productsSlice.actions;

export default productsSlice.reducer;
