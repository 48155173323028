import React from 'react';
import { View, Image } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

export const UploadedProductImage = ({ image }: { image?: string }) => {
	return (
		<View
			style={{
				borderRadius: 8,
				width: getResponsiveStyle(96, 'dimensions'),
				height: getResponsiveStyle(96, 'dimensions'),
				borderWidth: 1,
				borderColor: palettes.grey[4],
				overflow: 'hidden',
			}}
		>
			{!!image && (
				<Image
					source={{ uri: image || 'https://via.placeholder.com/500' }}
					style={{ width: '100%', height: '100%', borderRadius: 6 }}
				/>
			)}
		</View>
	);
};
