import { FetchResult } from '@apollo/client';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Button } from '../..';
import { palettes } from '../../../config';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import {
	AcceptOrRejectEvent,
	TAcceptOrRejectEvent,
	TStatusOfEvent,
} from '../../../types/eventTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Loader from '../loader/Loader';
type Props = {
	id: string;
	acceptOrRejectMutation?: (
		variables?: AcceptOrRejectEvent
	) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
};
const CardBottomAction = ({ id, acceptOrRejectMutation }: Props) => {
	const { setAlert } = useSetAlert();
	const [status, setStatusLoading] = useState<boolean>(false);
	const [rejectStatus, setRejectStatusLoading] = useState<boolean>(false);
	const handleAcceptOrReject = async (status: TAcceptOrRejectEvent) => {
		if (!acceptOrRejectMutation) return;

		if (status === 'APPROVE') {
			setStatusLoading(true);
		} else {
			setRejectStatusLoading(true);
		}

		const result = await acceptOrRejectMutation({
			variables: {
				_id: id,
				status,
			},
		});
		
		const data = result?.data?.approveOrRejectEvent;
		if (data?.success) {
			if (status === 'APPROVE') {
				setStatusLoading(false);
			} else {
				setRejectStatusLoading(false);
			}
			setAlert(data?.message, 'normal');
		}
	};
	return (
		<>
			<View style={{ flexDirection: 'row' }}>
				<View
					style={{
						flexDirection: 'row',
						width: '100%',
						// marginTop: getResponsiveStyle(34),
					}}
				>
					<Button
						title={status?<Loader size="small"/>:"Accept"}
						variant={rejectStatus ? 'disabled' : 'grey'}
						
						onPress={() => handleAcceptOrReject('APPROVE')}
						outerContainerProps={{
							style: {
								flex: 1,
							},
						}}
					/>
					<Button
						title={rejectStatus?<Loader size="small"/>:"Reject"}
						outlineColor={palettes.red[0]}
						
						variant={status ? 'disabled' : 'outline'}
						onPress={() => handleAcceptOrReject('REJECT')}
						outerContainerProps={{
							style: {
								flex: 1,
							},
						}}
					/>
				</View>
			</View>
		</>
	);
};
export default CardBottomAction;
