import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Pressable } from 'react-native-web-hover';
import { palettes } from '../../../config';
import { TStyle, TStyleInputOrText } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
type Props = {
	title: string;
	text?: string;
	outerContainerStyles?: TStyle;
	onClick: (type: string) => void;
	value: string;
	active?: boolean;
	titleStyles?: TStyleInputOrText;
};

const OptionButton = ({
	text,
	title,
	outerContainerStyles,
	onClick,
	value,
	active = false,
	titleStyles,
}: Props) => {
	const primary = palettes.primary;
	return (
		<Pressable
			style={({ focused }) => [
				styles.button,
				styleButtonOuterContainer(),
				styles.buttonOuterContainer,
				outerContainerStyles,
				focused && {
					borderColor: primary[1],
				},
			]}
		>
			{({ hovered, focused, pressed }) => (
				<View
					style={[
						styles.buttonInnerContainer,
						styleButtonInnerContainer(),

						focused && {
							borderColor: primary[0],
						},
						pressed && {
							borderColor: primary[0],
						},
						active && {
							borderColor: primary[0],
						},
					]}
				>
					<View
						style={{
							marginLeft: getResponsiveStyle(24),
							marginRight: getResponsiveStyle(24),
						}}
					>
						<Text
							style={[
								styles.title,
								styleTitle(),
								!!text && {
									marginBottom: getResponsiveStyle(6),
								},
								hovered && styles.titleHovered,
								focused && styles.titleFocused,
								pressed && styles.titlePressed,
								titleStyles,
							]}
						>
							{pressed && onClick(value)}
							{title}
						</Text>

						{!!text && (
							<Text style={[styles.text, styleText()]}>
								{text}
							</Text>
						)}
					</View>
				</View>
			)}
		</Pressable>
	);
};

export default OptionButton;

const styles = StyleSheet.create({
	buttonOuterContainer: {
		borderWidth: 3,
		borderColor: 'transparent',
		borderRadius: 8,
	},
	button: {
		backgroundColor: '#fff',
	},
	buttonInnerContainer: {
		borderWidth: 1,
		borderColor: palettes.grey[3],
		borderRadius: 6,
	},
	buttonLabel: { fontWeight: 'bold' },
	text: {
		fontStyle: 'normal',
		fontWeight: 'normal',

		color: palettes.grey[0],
	},
	title: {
		fontStyle: 'normal',
		fontWeight: 'normal',

		color: palettes.dark[4],
	},
	titleFocused: {
		color: palettes.dark[0],
	},
	titleHovered: {
		color: palettes.dark[0],
	},
	titlePressed: {
		color: palettes.dark[0],
	},
});

const styleButtonInnerContainer = () => {
	return { paddingVertical: getResponsiveStyle(18) };
};

function styleText() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(12, 'font'),
	};
}

function styleTitle() {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
}

function styleButtonOuterContainer() {
	return {
		marginBottom: getResponsiveStyle(12),
	};
}
