import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button } from '../..';
import { palettes } from '../../../config';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { TUseNavigation } from '../../../types/types';
import { AcceptOrReject } from '../../../types/userTypes';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Loader from '../loader/Loader';

type Props = {
	id: string;
	isRequest?: boolean;
	acceptOrRejectMutation?: (variables?: AcceptOrReject)=>any;
};

export const ActionButtons = ({
	id,
	isRequest = false,
	acceptOrRejectMutation,
}: Props) => {
	const navigation = useNavigation<TUseNavigation>();
	const [statusLoading, setStatusLoading] = useState<boolean>(false);
	const {setAlert}=useSetAlert()
	return (
		<View style={[styles.buttonContainer, styleButtonContainer(isRequest)]}>
			{!isRequest ? (
				<View
					style={{
						flexDirection: 'row',
						width: '100%',
						// marginTop: getResponsiveStyle(34),
					}}
				>
			
					<Button
						title={'View Profile'}
						variant="grey"
						outerContainerProps={{
							style: styles.button,
						}}
						onPress={() =>
							navigation.navigate('UserProfile', {
								userId: id,
							})
						}
					/>
				</View>
			) : (
				<View
					style={{
						flexDirection: 'row',
						width: '100%',
						// marginTop: getResponsiveStyle(16),
					}}
				>
					{statusLoading ? (
						<Loader size="small" />
					) : (
						<>
							<Button
								title={'Accept'}
								outerContainerProps={{
									style: styles.button,
								}}
								onPress={async() => {
									setStatusLoading(true);
									let result=acceptOrRejectMutation &&
										await acceptOrRejectMutation({
											variables: {
												id: id,
												status: 'ACTIVE',
											},
										});
									const data=result?.data?.acceptOrRejectUser
									if(data){
										setStatusLoading(false)
										data.success?setAlert('User Status Updated Successfully','normal'):setAlert('An error occured, try again shortly','danger')

									}
								}}
								variant="grey"
							/>
							
							<Button
								title={'Reject'}
							onPress={async() => {
									setStatusLoading(true);
									let result=acceptOrRejectMutation &&
										await acceptOrRejectMutation({
											variables: {
												id: id,
												status: 'DISABLED',
											},
										});
									const data=result?.data?.acceptOrRejectUser
									if(data){
										setStatusLoading(false)
										data.success?setAlert('User Status Updated Successfully','normal'):setAlert('An error occured, try again shortly','danger')

									}
								}}
								outerContainerProps={{
									style: {
										flexBasis: getResponsiveStyle(80),
									},
								}}
								outlineColor={palettes.red[0]}
							/>
						</>
					)}
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	button: {
		flex: 1,
	},
	buttonContainer: {
		flexDirection: 'row',
	},
});
function styleButtonContainer(isRequest: boolean) {
	return {
		marginTop: isRequest ? getResponsiveStyle(16) : 34,
	};
}
