import { gql } from '@apollo/client';
export const GET_SALES_GRAPH_DATA = gql`
	query getSalesGraphData {
		getSalesGraphData {
			statusCode
			success
			message
			data {
				... on GraphDataResponse {
					previousMonth {
						dataPoint
						week
					}
					currentMonth {
						dataPoint
						week
					}
				}
			}
		}
	}
`;
