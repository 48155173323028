import React from "react";
import {
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { palettes } from "../../config";

const Support = (props: { onSubmit: () => void }) => {
  const onPressHelp = () => {
    props.onSubmit();
  };

  return (
    <>
      <Text style={styles.container}>
        <TouchableOpacity disabled>
          <Text style={styles.text}>Need help? </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={onPressHelp} activeOpacity={0.75}>
          <Text style={styles.clickHere}>Click here</Text>
        </TouchableOpacity>{" "}
        <TouchableOpacity disabled>
          <Text style={styles.text}>or email us at: </Text>
        </TouchableOpacity>
        <TouchableOpacity disabled>
          <Text style={styles.email}>support@ocpus.com</Text>
        </TouchableOpacity>
      </Text>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    textAlign: "center",
  },
  clickHere: {
    // fontFamily: "Maison Neue",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 24,
    textDecorationLine: "underline",
    // textAlign: "center",
    /* or 157% */
    color: palettes.primary[0],
  },
  email: {
    // fontFamily: "Maison Neue",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 24,
    /* or 157% */
    color: palettes.primary[0],
  },
  text: {
    // fontFamily: "Maison Neue",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 24,
    // textAlign: "center",
    /* or 157% */
    color: palettes.dark[0],
  },
});

export default Support;
