enum UserType {
  BRAND = 0,
  CONSUMER,
  HOST,
  SELLER,
  SUPER_ADMIN,
}

const user = UserType.SELLER;

export default user;
