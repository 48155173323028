import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
	AuthTemplate,
	LoginFooter,
	ResetPasswordForm,
	TermsAndConditions,
} from '../components';
import LoginHeroImage from '../components/auth/LoginHeroImage';
import { useIsSpecificViewportWidth } from '../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../types/TStyle';
import { getResponsiveStyle } from '../utils/getResponsiveStyle';

const ResetPassword = () => {
	const { isDesktop } = useIsSpecificViewportWidth();
	return (
		<View style={isDesktop && styles.container}>
			<LoginHeroImage />
			<AuthTemplate
				heading={'Reset Password'}
				subText={'Enter new password for your account.'}
				contentStyles={styleMainContent()}
				authType={'login'}
				scrollItems={[]}
			>
				<ResetPasswordForm />
				{isDesktop && <LoginFooter />}
				{isDesktop && <TermsAndConditions />}
			</AuthTemplate>
		</View>
	);
};

function styleMainContent(): TStyle {
	return {
		marginTop: getResponsiveStyle(40),
	};
}

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		minWidth: '100vw',
		height: '100vh',
	},
});

export default ResetPassword;
