import { useLazyQuery } from '@apollo/client';
import { LOGOUT } from '../../graphql/auth/queries';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { useAppDispatch } from '../../redux/store';
import { resetState, setGlobalLoading } from '../../redux/uiSlice';
import { TLogoutResponse } from '../../types/authTypes';

const useLogout = () => {
	const dispatch = useAppDispatch();
	const { setAlert } = useSetAlert();

	const onCompleted = (data: TLogoutResponse) => {
		if (data.logout.success) {
			setAlert(data.logout.message, 'normal');
			dispatch(resetState());
		} else {
			setAlert(data.logout.message, 'danger');
		}
	};

	const [logout] = useLazyQuery<TLogoutResponse>(LOGOUT, {
		onCompleted,
	});

	const executeLogoutQuery = () => {
		dispatch(setGlobalLoading(true));
		logout();
	};

	return { executeLogoutQuery };
};

export default useLogout;
