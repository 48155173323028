import { View, Text, Image, StyleSheet, Dimensions } from 'react-native';
import React, { useLayoutEffect } from 'react';
import { LineChart } from 'react-native-chart-kit';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Chart from './ChartGraph';
import Loader from '../loader/Loader';
type Props = {
	data1?: number[];
	data2?: number[];
};
const ChartComponent = ({ data1, data2 }: Props) => {
	return (
		<View style={Styles.container}>
			{data1 && data2 ? (
				<Chart data1={data1} data2={data2} />
			) : (
				<Loader />
			)}
		</View>
	);
};

const Styles = StyleSheet.create({
	container: {
		width: '100%',
		height: getResponsiveStyle(500),
	},
	image: {
		width: '100%',
		height: '100%',
	},
});
export default ChartComponent;
