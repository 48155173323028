
import React from 'react';
import { useGetAllOrders } from '../../api/order/useGetOrders';
import OrderComponent from './OrderComponent';
type Props = {
	heading?: string
	hasViewAllButton?: boolean
}
const OrderSection = ({ heading, hasViewAllButton = false }: Props) => {

	const {
		getAllOrders,
		data: AllOrders,
		loading: loadingOrders,
	} = useGetAllOrders({ find: { orderStatus: 'IN_PROGRESS', paymentStatus: "PENDING" }, limit: 10 });


	return (
		<OrderComponent hasViewAllButton={hasViewAllButton} heading={heading} data={AllOrders} loading={loadingOrders} action={getAllOrders} limit={10} />

	)
};
export default OrderSection
