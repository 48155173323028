import React from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ChartComponent from '../chart-components/ChartComponent';
import ChartDetailItem from '../chart-components/ChartDetailItem';
import { CategoriesDropdown } from '../filter-categories/CategoriesDropdown';

const SORT_BY_FILTER = [
	{
		label: 'Revenue',
		value: 'revenue',
	},
	{
		label: 'Orders',
		value: 'orders',
	},
	{
		label: 'Value',
		value: 'value',
	},
	{
		label: 'Refund',
		value: 'refund',
	},
];
const DATE_FILTER = [
	{
		label: 'Last week',
		value: 'last_year',
	},
	{
		label: 'Last month',
		value: 'last_month',
	},
	{
		label: 'Last 3 months',
		value: 'last_3_months',
	},
	{
		label: 'Last year',
		value: 'last_year',
	},
];

type Props = {
	pageHeader: string;
	prevData?:number[];
	currData?:number[];
	chartItemValues: {
		title: string;
		percentageDifference: number;
		percentageIncrease: boolean;
		value: number | string;
		showPercentage?: boolean;
	}[];
};

function PageHeader(props: Props) {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return (
		<View style={[Styles.container]}>
			<View style={Styles.headerContainer}>
				<Text style={Styles.headerText}>{props.pageHeader}</Text>
				{!isLessThanDesktopBase && (
					<View style={Styles.filterLegendContainer}>
						<View style={Styles.filterLegendItemContainer}>
							<View style={Styles.filterLegendItemIndicator1} />
							<Text style={Styles.filterLegendItemText}>
								Last Month
							</Text>
						</View>
						<View style={Styles.filterLegendItemContainer}>
							<View style={Styles.filterLegendItemIndicator2} />
							<Text style={Styles.filterLegendItemText}>
								This Month
							</Text>
						</View>
					</View>
				)}

				{/* <View style={Styles.filterContainer}>
					<CategoriesDropdown
						categories={SORT_BY_FILTER}
						label=""
						placeholder="Sort by"
					/>
					<CategoriesDropdown
						categories={DATE_FILTER}
						label=""
						placeholder="Date"
					/>
				</View> */}
			</View>
			{isLessThanDesktopBase && (
				<View
					style={[
						Styles.filterLegendContainer,
						{
							justifyContent: 'center',
						},
					]}
				>
					<View style={Styles.filterLegendItemContainer}>
						<View style={Styles.filterLegendItemIndicator1} />
						<Text style={Styles.filterLegendItemText}>
							Last Month
						</Text>
					</View>
					<View style={Styles.filterLegendItemContainer}>
						<View style={Styles.filterLegendItemIndicator2} />
						<Text style={Styles.filterLegendItemText}>
							This Month
						</Text>
					</View>
				</View>
			)}
			<ScrollView
				contentContainerStyle={Styles.chartDetailsSection}
				horizontal={true}
				showsVerticalScrollIndicator={false}
				showsHorizontalScrollIndicator={false}
			>
				{props.chartItemValues.map((item, index) => (
					<ChartDetailItem
						heading={item.title}
						value={item.value}
						percentage={item.percentageDifference}
						percentageIncrease={item.percentageIncrease}
						key={index}
						showPercentage={item.showPercentage}
					/>
				))}
			</ScrollView>
			<ChartComponent data1={props.prevData} data2={props.currData} />
		</View>
	);
}

const Styles = StyleSheet.create({
	container: {
		width: '100%',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		zIndex: 5000

	},
	headerText: {
		fontWeight: 'bold',
		color: palettes.dark[0],
		fontSize: getResponsiveStyle(24, 'font'),
	},
	filterLegendContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignContent: 'center',
		gap: getResponsiveStyle(36),
	},
	filterLegendItemContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(11),
	},
	filterLegendItemText: {
		fontSize: 14,
		fontWeight: '400',
		color: palettes.dark[0],
	},
	filterLegendItemIndicator1: {
		width: getResponsiveStyle(10, 'dimensions'),
		height: getResponsiveStyle(10, 'dimensions'),
		borderRadius: getResponsiveStyle(10 / 2, 'dimensions'),
		borderWidth: 2,
		borderColor: palettes.red[0],
	},
	filterLegendItemIndicator2: {
		width: getResponsiveStyle(10, 'dimensions'),
		height: getResponsiveStyle(10, 'dimensions'),
		borderRadius: getResponsiveStyle(10 / 2, 'dimensions'),

		borderWidth: 2,
		borderColor: palettes.purple[0],
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: getResponsiveStyle(20.51, 'spacing'),
		zIndex: 5000
	},
	chartDetailsSection: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		// justifyContent: 'space-between',
		marginTop: getResponsiveStyle(24),
		marginBottom: getResponsiveStyle(52),
		gap: getResponsiveStyle(16),
	},
});

export default PageHeader;
