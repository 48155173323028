import React, { useEffect } from 'react';
import { Image, Pressable, StyleSheet, View } from 'react-native';
import { DraggableScrollView } from '../..';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TItemNewValues } from '../../../types/authTypes';
import { TStyle } from '../../../types/TStyle';
import { AcceptOrReject, TUserDataForTable } from '../../../types/userTypes';
import { createArray } from '../../../utils/createArray';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import { ProfileCard } from './ProfileCard';

type Props = {
	isBrand?: boolean;
	isRequest?: boolean;
	showGender?: boolean;
	newRequest: TUserDataForTable[];
	acceptOrRejectMutation?: (variables?: AcceptOrReject) => void;
};

const ProfileCards = ({
	isBrand = false,
	isRequest = false,
	showGender = false,
	newRequest,
	acceptOrRejectMutation
}: Props) => {
	const ToRender = createArray(10);
	const renderItem = (item:any) => (
		<ProfileCard isBrand={isBrand} isRequest={isRequest} newRequest={item} acceptOrRejectMutation={acceptOrRejectMutation}  hasMargin />
	);
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();
	useEffect(() => {
		console.log(newRequest);
	}, [newRequest]);
	return (
		<Pressable>
			<View style={[styles.container]}>
				<DraggableScrollView
					horizontal
					showsHorizontalScrollIndicator={false}
					style={styleDraggableScroll()}
					data={newRequest}
					renderItem={renderItem}
				/>
				{!isLessThanDesktopBase && (
					<Image
						source={require('../../../assets/slider-gradient.png')}
						style={styles.scrollGradient}
					/>
				)}
			</View>
		</Pressable>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		maxWidth: 'max-content',
		// zIndex: 1,
		position: 'relative',
	},
	scrollGradient: {
		width: '102px',
		height: '100%',
		position: 'absolute',
		right: '-50px',
		zIndex: 2,
		cursor: 'auto',
	},
});

const styleDraggableScroll = (): TStyle => {
	return [
		styles.container,
		{
			maxWidth: '100%',
			paddingBottom: getResponsiveStyle(20),
		},
	];
};

export default ProfileCards;
