import React, { useState, useMemo, useEffect, useCallback } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import {
	Platform,
	Pressable,
	ScrollView,
	TextInputProps,
	View,
	StyleSheet,
	Text,
	Image,
} from 'react-native';

import { ChevronDown, Badge, Input, Flag } from '../..';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { TDropdownObject, TODO } from '../../../types/types';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import styled from 'rn-css';
import Label, { LabelProps } from '../label/Label';
import Loader from '../loader/Loader';

type TDropdownPosition = 'top' | 'bottom';

type Props = {
	inputProps?: Omit<
		TextInputProps,
		'editable' | 'selectTextOnFocus' | 'placeholderTextColor'
	>;
	isMulti?: boolean;
	containerStyles?: TStyle;
	labelProps?: LabelProps;
	isCountry?: boolean;
	isGender?: boolean;
	values?: TDropdownObject | TDropdownObject[];
	setValue?: React.Dispatch<React.SetStateAction<TODO>>;

	options?: TDropdownObject[];
	isSearchable?: boolean;
	onChangeValue?: (itemSelected: TDropdownObject, index: number) => void;
	error?: string;
	onPressCrossOnBadge?: (value: string) => void;
	loading?: boolean;
	dropdownPosition?: TDropdownPosition;
};

const DropdownTextContainer = styled(Pressable)`
	cursor: pointer;
`;

const DropdownText = styled(Text)<{ isItemSelected: boolean }>`
	color: ${({ isItemSelected }) =>
		isItemSelected ? palettes.dark[0] : palettes.grey[0]};
	&:hover {
		color: ${palettes.dark[0]};
	}
`;

const Dropdown = ({
	values: values,
	options: optionsProps = [],
	onChangeValue: onChangeValueProps,
	onPressCrossOnBadge: onPressCrossOnBadgeFromProps,
	isMulti: isMultiProps = false,
	containerStyles,
	isCountry = false,
	isGender = false,
	inputProps,
	labelProps,
	isSearchable = false,
	setValue,
	error,
	loading = false,
	dropdownPosition = 'bottom',
}: Props) => {
	delete inputProps?.placeholder;
	delete inputProps?.onBlur;

	const ContentContainer = styled.View``;
	const [showMenu, setShowMenu] = useState(false);
	const [options, setOptions] = useState(optionsProps);
	const [searchQuery, setSearchQuery] = useState('');
	const [isMenuOpened, setIsMenuOpened] = useState(
		showMenu && (!loading ? options.length > 0 : true)
	);
	const [inputContainerHeight, setInputContainerHeight] = useState(
		getResponsiveStyle(64, 'dimensions')
	);
	const [isInputFocused, setIsInputFocused] = useState(false);

	const lastItemIndex = useMemo(() => options.length - 1, [options]);

	const isMulti = isMultiProps ? isMultiProps : Array.isArray(values);

	useEffect(() => {
		const _isMenuOpened =
			showMenu && (!loading ? options.length > 0 : true);
		setIsMenuOpened(_isMenuOpened);
	}, [showMenu, options, optionsProps, loading]);

	useEffect(() => {
		setOptions(optionsProps);
	}, [optionsProps]);

	const onChangeSearchValue = (text: string) => {
		return setOptions(
			optionsProps.filter(
				({ label, value }) =>
					value.toLowerCase().includes(text) ||
					label.toLowerCase().includes(text)
			)
		);
	};

	const handleHideMenu = () => {
		setShowMenu(false);
	};

	const toggleShowMenu = () => {
		setShowMenu(prev => !prev);
	};

	const isItemSelected = (item: TDropdownObject) => {
		if (isMulti) {
			return values.some(i => i.value === item.value);
		} else return item.value === values?.value;
	};

	const onChangeValue = useCallback((item: TDropdownObject) => {
		if (!setValue) return;
		isMulti ? setValue([...values, item]) : setValue(item);
	}, []);

	const onPressCrossOnBadge = (value: string) => {
		if (!isMulti || !setValue) return;
		const filteredValues: any = Array.from(values).filter(
			v => v.value !== value
		);
		setValue(filteredValues ?? []);
	};

	return (
		<View style={[styles.container, containerStyles]}>
			{labelProps?.label && <Label error={error} {...labelProps} />}
			<ClickAwayListener onClickAway={handleHideMenu}>
				<View
					onLayout={event => {
						setInputContainerHeight(
							event.nativeEvent.layout.height
						);
					}}
					style={[styles.innerContainer]}
				>
					<Pressable
						style={[
							styles.chevronOuterContainer,
							styleChevronOuterContainer(),
						]}
					>
						<ChevronDown
							style={[styles.chevron, outlineNoneForWeb()]}
							containerStyles={[
								styles.chevronInnerContainer,
								styleChevronInnerContainer(),
								outlineNoneForWeb(),
							]}
							onPress={toggleShowMenu}
						/>
					</Pressable>
					{isMulti && (
						<Pressable
							onPress={toggleShowMenu}
							style={[
								styles.multiBadgeOuterContainer,
								showMenu && {
									borderColor: `white`,
								},
							]}
						>
							<View
								style={[
									styles.multiBadgeInnerContainer,
									styleMultiBadgeInnerContainer(),
									showMenu && {
										borderColor: 'white',
									},
								]}
							>
								<View style={styles.badgesContainer}>
									{values.map(({ label, value }, i) => (
										<Badge
											key={value}
											label={label}
											value={value}
											isLastItem={i === value.length - 1}
											onPress={() =>
												onPressCrossOnBadgeFromProps
													? onPressCrossOnBadgeFromProps(
															value
													  )
													: onPressCrossOnBadge(value)
											}
										/>
									))}
								</View>
							</View>
						</Pressable>
					)}
					{!isMulti && isCountry && (
						<Pressable
							onPress={toggleShowMenu}
							style={[
								styles.multiBadgeOuterContainer,
								showMenu && {
									borderColor: `white`,
								},
							]}
						>
							<View
								style={[
									styles.multiBadgeInnerContainer,
									!!error && { borderColor: 'red' },
									styleMultiBadgeInnerContainer(),
									showMenu && {
										borderColor: 'white',
									},
								]}
							>
								{values ? (
									<View style={styles.secContainer}>
										<Image
											source={{
												uri: `https://flagcdn.com/w40/${values?.value}.png`,
											}}
											style={{
												width: 21.5,
												height: 16,
												marginRight: 15,
											}}
										/>

										<Text style={styles.secContainer}>
											{values?.label}
										</Text>
									</View>
								) : (
									<Text style={styles.secTextContainer}>
										Search ...
									</Text>
								)}
							</View>
						</Pressable>
					)}
					{!isMulti && isGender && (
						<Pressable
							onPress={toggleShowMenu}
							style={[
								styles.multiBadgeOuterContainer,
								
								showMenu && {
									borderColor: `white`,
								},
							]}
						>
							<View
								style={[
									styles.multiBadgeInnerContainer,
									!!error && { borderColor: 'red' },
									styleMultiBadgeInnerContainer(),
									showMenu && {
										borderColor: 'white',
									},
								]}
							>
								{values ? (
									<View style={styles.secContainer}>
										<Text style={styles.secContainer}>
											{values?.label}
										</Text>
									</View>
								) : (
									<Text style={styles.secTextContainer}>
										Search ...
									</Text>
								)}
							</View>
						</Pressable>
					)}
					{!isMulti && !isCountry && !isGender && (
						<Pressable onPress={toggleShowMenu}>
							<Input
								editable={isSearchable}
								value={
									isInputFocused
										? searchQuery
										: !isMulti && !!values?.value
										? values?.label
										: ''
								}
								selectTextOnFocus={isSearchable}
								onChangeText={text => {
									const _text = text.trim().toLowerCase();
									onChangeSearchValue(_text);
									setSearchQuery(_text);
								}}
								onFocus={() => setIsInputFocused(true)}
								onBlur={() => setIsInputFocused(false)}
								placeholder={
									!isMulti && !!values?.value
										? values?.label
										: inputProps?.placeholder ?? 'Search...'
								}
								placeholderTextColor={stylePlaceholderTextColor(
									isMulti,
									values
								)}
								innerContainerStyles={styleSearchInputInnerContainer(
									showMenu,
									['white']
								)}
								error={error}
								style={[
									styleSearchInput(),
									Platform.OS === 'web' &&
										!isSearchable &&
										({ cursor: 'pointer' } as any),

									showMenu && {
										borderColor: 'white',
									},
								]}
								{...inputProps}
							/>
						</Pressable>
					)}
					{(isMenuOpened || searchQuery !== '') && (
						<ScrollView
							style={[
								styles.dropdownContainer,
								styleDropdownContainer(
									inputContainerHeight,
									dropdownPosition
								),
							]}
						>
							{loading ? (
								<Loader size={'small'} />
							) : (
								options.map((item, index) => (
									<DropdownTextContainer
										onPress={() => {
											onChangeValueProps
												? onChangeValueProps(
														item,
														index
												  )
												: onChangeValue(item);
											setShowMenu(false);
											setSearchQuery('');
										}}
										key={item.value}
										style={
											[
												styleDropdownTextContainer(
													index,
													lastItemIndex
												),
												styles.dropdownTextContainer,
												isItemSelected(item) && {
													borderLeftColor: 'black',
												},
											] as TStyle
										}
									>
										<DropdownText
											isItemSelected={isItemSelected(
												item
											)}
											style={styleDropdownText()}
										>
											{item.label}
										</DropdownText>
									</DropdownTextContainer>
								))
							)}
						</ScrollView>
					)}
				</View>
			</ClickAwayListener>
		</View>
	);
};

const styles = StyleSheet.create({
	dropdownTextContainer: {
		display: 'flex',
		justifyContent: 'center',
		borderLeftWidth: 2,
		borderLeftColor: 'transparent',
	},
	dropdownContainer: {
		position: 'absolute',
		left: 3,
		right: 3,
		minHeight: 100,
		borderRadius: 6,
		marginTop: 10,
		backgroundColor: 'white',
		shadowOffset: {
			width: 0,
			height: 15,
		},
		shadowRadius: 25,
		shadowColor: 'rgba(124, 136, 155, 0.12)',
		shadowOpacity: 1,
		borderWidth: 1,
		borderColor: palettes.grey[3],
	},
	badgesContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		// alignItems: 'center',
	},
	secContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(14, 'font'),
		alignItems: 'center',
		display: 'flex',
	},
	secTextContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		fontSize: getResponsiveStyle(16, 'font'),
		color: palettes.grey[0],
		lineHeight: getResponsiveStyle(14, 'font'),
		alignItems: 'center',
		display: 'flex',
	},
	multiBadgeInnerContainer: {
		width: '100%',
		borderWidth: 1,
		borderColor: palettes.grey[4],
		borderRadius: 6,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowRadius: 4,
		shadowColor: 'rgba(180, 187, 198, 0.1)',
		shadowOpacity: 1,
		height: 'auto',
	},
	multiBadgeOuterContainer: {
		borderWidth: 3,
		borderRadius: 8,
		borderColor: 'transparent',
	},
	chevronInnerContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 15,
		width: 15,
	},
	chevron: {
		height: 15,
		width: 15,
	},
	chevronOuterContainer: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		zIndex: 5001,
	},
	container: {
		zIndex: 5000,
		width: '100%',
	},
	innerContainer: {
		position: 'relative',
	},
});

export default Dropdown;
function styleDropdownText(): any {
	return {
		lineHeight: getResponsiveStyle(16, 'font'),
		fontSize: getResponsiveStyle(16, 'font'),
	};
}

function styleDropdownTextContainer(index: number, lastItemIndex: number) {
	return {
		paddingHorizontal: getResponsiveStyle(24),
		marginBottom: index === lastItemIndex ? 0 : getResponsiveStyle(8),
		paddingVertical: getResponsiveStyle(8),
	};
}

function styleDropdownContainer(
	inputContainerHeight: number,
	dropdownPosition: TDropdownPosition
) {
	return {
		paddingVertical: getResponsiveStyle(20),
		maxHeight: getResponsiveStyle(250, 'dimensions'),
		[dropdownPosition === 'top' ? 'bottom' : 'top']: inputContainerHeight,
	};
}

function styleSearchInput() {
	return { paddingRight: getResponsiveStyle(32) };
}

function styleSearchInputInnerContainer(
	showMenu: boolean,
	primaryColors: string[]
): TStyle {
	return {
		borderColor: showMenu ? primaryColors[1] : 'transparent',
		borderRadius: 8,
	};
}

function stylePlaceholderTextColor(
	isMulti: boolean,
	values: TDropdownObject | undefined
) {
	return !isMulti && !!values?.value ? palettes.dark[0] : palettes.grey[0];
}

function styleMultiBadgeInnerContainer() {
	return {
		minHeight: getResponsiveStyle(48, 'dimensions'),
		paddingLeft: getResponsiveStyle(24),
		paddingRight: getResponsiveStyle(32),
		paddingVertical: getResponsiveStyle(16),
	};
}

function outlineNoneForWeb() {
	return (
		Platform.OS === 'web' &&
		({
			outline: 'none',
		} as any)
	);
}

function styleChevronInnerContainer() {
	return {
		marginTop: getResponsiveStyle(8),
	};
}

function styleChevronOuterContainer() {
	return {
		right: getResponsiveStyle(16),
		top: getResponsiveStyle(23) - 7,
	};
}
