import { gql } from '@apollo/client';
import { USER_RESPONSE_FRAGMENT } from './fragments';

const SIGN_IN = gql`
	${USER_RESPONSE_FRAGMENT}
	mutation adminLogin($email: String!, $password: String!) {
		adminLogin(email: $email, password: $password) {
			...UserResponseFragment
		}
	}
`;

const CREATE_PASSWORD_LINK = gql`
	${USER_RESPONSE_FRAGMENT}
	mutation createPasswordLink($email: String) {
		createPasswordLink(email: $email) {
			...UserResponseFragment
		}
	}
`;

const  CREATE_NEW_USER = gql`
${USER_RESPONSE_FRAGMENT}
	mutation createUser($args: UserInput){
		createUser(args: $args){
			...UserResponseFragment
		}
	}
`;

const DELETE_ADMIN_INVITE =gql`
${USER_RESPONSE_FRAGMENT}
	mutation deleteAdminInvite($invitedAdminEmail: String!){
		deleteAdminInvite(invitedAdminEmail: $invitedAdminEmail){
			...UserResponseFragment
		}
	}
`;

const CREATE_NEW_PASSWORD = gql`
	${USER_RESPONSE_FRAGMENT}
	mutation newPassword($password: String, $token: String) {
		newPassword(password: $password, token: $token) {
			...UserResponseFragment
		}
	}
`;

const CREATE_ADMIN_WITHDRAWAL = gql`
	mutation createAdminWithdrawal($amount: Float, $bankId: String) {
		createAdminWithdrawal(
			amount:$amount,
			bankId: $bankId
		){
			statusCode
			success
			message
		}
	}
`

export {
	SIGN_IN,
	CREATE_PASSWORD_LINK,
	CREATE_NEW_PASSWORD,
	CREATE_NEW_USER,
	DELETE_ADMIN_INVITE,
	CREATE_ADMIN_WITHDRAWAL
};
