import React, { useEffect, useState } from 'react';
import {
	Image,
	Pressable,
	ScrollView,
	StyleSheet,
	Text,
	View,
	FlatList
} from 'react-native';
import StarRating from 'react-native-star-rating-widget';
import { palettes } from '../../../config';
import { Product } from '../../../types/productTypes';
import useGetReviewsAndRating from '../../../api/product/useGetReviewsAndRating';
import Loader from '../../common/loader/Loader';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import Profile from '../../dashboard/profile-list/Profile';
import { placeholderImage } from '../../common/table/TableContent';

const renderItem = ({ item }: { item: any }) => {
	return (
		<View style={{ gap: '1rem' }}>
			<Profile
				name={item?.ratedBy?.email}
				profileImage={item.ratedBy.personalInformation.profileImageLink || placeholderImage}
				isBrandProfile={false}
				time={new Date(+item?.createdAt).toDateString()}
				id={item?.ratedBy?._id}
				country={item?.ratedBy?.personalInformation?.address?.countryLabel}
				city={item?.ratedBy?.personalInformation?.address?.city}
			/>
			<StarRating
				starSize={20}
				color={palettes?.red[0]}
				rating={item?.rating}
				enableHalfStar={false}
				minRating={1}
			// style={styles.rating}
			/>
			<Text>{item?.review}</Text>
		</View>
	);
};

const ProductDescription = (product: Product) => {
	const productData = product?.variants[product.defaultVariant]

	const [ratingsReviews, setRatingsReviews] = useState<
		any[]
	>([]);

	const { executeGetReviewsAndRating, loading: reviewLoading } =
		useGetReviewsAndRating({
			onCompleted(data: any) {
				setRatingsReviews(data.getEntityRating.data.data as any);
			},
		});

	useEffect(() => {
		executeGetReviewsAndRating({
			args: {
				find: {
					_id: product?._id,
				},
				populate: ['ratedBy'],
			},
		});
	}, []);

	return (
		<>
			<Text style={styles.shortDesc}>
				{product?.name + ' ' + (productData?.value ? productData?.value : "")}
			</Text>

			{/* <Text style={styles.title}>
				{product?.variants[product.defaultVariant]?.variator}: {product?.variants[product.defaultVariant]?.value}
			</Text> */}
			<View style={{ flexDirection: 'row' }}>
				{product?.variants?.map((variant: any) => (
					<Image
						style={styles.image}
						source={{
							uri: variant?.image?.src,
						}}
					/>
				))}
			</View>
			<View>
				<Text style={styles.title}>SHORT DESCRIPTION</Text>
				<View>
					<Text style={styles.desc}>
						{product?.shortDescription}
					</Text>
				</View>
				<Text style={styles.title}>DESCRIPTION</Text>
				<View>
					<Text style={styles.desc}>
						{product?.description}
					</Text>
				</View>
				<Text style={styles.title}>REVIEWS & RATINGS</Text>
				{reviewLoading && (
					<Loader
						size="small"
						containerStyles={{ marginBottom: 20 }}
					/>
				)}
				<FlatList
					data={ratingsReviews}
					keyExtractor={(item: any) => item?._id}
					renderItem={renderItem}
					style={{ marginBottom: getResponsiveStyle(10) }}
				/>
			</View>
		</>
	);
};

const styles = StyleSheet.create({
	image: {
		width: 56,
		height: 56,
		marginRight: 10,
		borderRadius: 6,
	},
	title: {
		fontSize: 12,
		fontStyle: 'normal',
		fontWeight: 'bold',
		color: palettes.dark[0],
		marginBottom: 10,
		marginTop: 15,
	},
	shortDesc: {
		fontWeight: '400',
		fontSize: 18,
		fontStyle: 'normal',
		lineHeight: 28,
		color: palettes.dark[0],
	},
	desc: {
		fontSize: 14,
		fontWeight: 'normal',
		color: palettes.dark[4],
		lineHeight: 24,
	},
	downloadBtn: {
		height: 40,
		justifyContent: 'center',
		alignItems: 'center',
		border: '1px solid #6645F4',
		borderRadius: 6,
		// position: 'absolute',
		// bottom: 10,
		// width: '90%',
	},
	downloadText: {
		fontSize: 12,
		fontStyle: 'normal',
		fontWeight: '600',
		color: '#6645F4',
	},
});

export default ProductDescription;
