import React from "react";
import { Text } from "react-native";

const Progress = () => {
  return (
    <>
      <Text>Create Account</Text>
    </>
  );
};

export default Progress;
