import React, { useState, useEffect, useMemo } from 'react';
import {
	Button,
	DropDownPickerLarge,
	Input,
	Upload,
} from '../../../components';
import Dropdown2 from '../../common/dropdown2/dropdown2';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/store';
import { View } from 'react-native';
import { StyleSheet } from 'react-native';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import countryNameswithCode from '../../../utils/countryNameswithCode';
import { PlusIcon } from '../../../components/common/icons';
import { createRequiredLabels } from '../../../utils/createRequiredLabels';
import { Formik } from 'formik';
import { addSignupValidationSchema, initialValues } from './FormikConstant';
import { DropzoneProps } from 'react-dropzone';
import { useCreateMedia } from '../../../api/common/useCreateMedia';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { useCheckUsernameEmailAvailability } from '../../../api/auth/useCheckUsernameEmailAvailability';
import { useAppDispatch } from '../../../redux/store';
import { setCreateUserDetails } from '../../../redux/createUserSlice';
import { useCreateUser } from '../../../api/auth/useCreateUser';
import { TCreateNewUser, TDeleteAdminInvite } from '../../../types/authTypes';
import { useDeleteAdminInvite } from '../../../api/auth/useDeleteAdminInvite';
import useLogin from '../../../api/auth/useLogin';

function SignupForm() {
	// const {addSignupValidationSchema, addSignupInitalValues} = formik()
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { setAlert } = useSetAlert();
	const [profile, setProfile] = useState('');
	const [verify, setVerify] = useState(false);
	const onCompletedUploadMedia = (data: TMediaResponse) => {
		if (data.createMedia.success && data.createMedia.data) {
			setAlert('Image uploaded successfully', 'normal');
			setProfile(data.createMedia.data.src);
		} else {
			setAlert('Image uploading failed', 'danger');
		}
	};
	const { uploadMedia, loading: loading_uploadMedia } = useCreateMedia({
		onCompleted: onCompletedUploadMedia,
	});
	const onDropProfileImage: DropzoneProps['onDrop'] = acceptedFiles => {
		if (acceptedFiles.length > 0) {
			uploadMedia(acceptedFiles[0], 'USER', 'profile-image');
		}
	};
	const DropdownGenderArray = [
		{
			label: 'Male',
			value: 'MALE',
		},
		{
			label: 'Female',
			value: 'FEMALE',
		},
		{
			label: 'Other',
			value: 'OTHER',
		},
	];

	const newUser = useAppSelector(state => state.createNewUser.newUser);

	const onCompletedDeleteAdminInvite = (data: TDeleteAdminInvite) => {
		const email = newUser.email;
		const password = newUser.password;

		if (data && data.deleteAdminInvite && data.deleteAdminInvite.success) {
			executeLoginMutation({ email, password });
		} else {
			setAlert(data?.deleteAdminInvite?.message, 'danger');
		}
	};
	const onCompletedCreateUser = (data: TCreateNewUser) => {
		const email = newUser.email;
		data?.createUser?.success
			? executeDeleteMutation({ email })
			: setAlert(data.createUser.message, 'danger');
	};

	const { setCreateUserVariables, loading: loadingSignUp } = useCreateUser(
		onCompletedCreateUser
	);
	const { executeDeleteMutation, loading: loadingDelete } =
		useDeleteAdminInvite(onCompletedDeleteAdminInvite);
	const { executeLoginMutation, loading: loadingLogin } = useLogin();

	const signupData = (values: any) => {
		return {
			username: values.username,
			email: values.email,
			typeOfAccount: newUser.typeOfAccount,
			password: values.password,
			gender: values.gender,
			affiliatedBrand: newUser?.affiliatedBrand,
			role: newUser.role,
			personalInformation: {
				firstName: values.firstName,
				lastName: values.lastName,
				phoneNumber: values.phoneNumber,
				profileImageLink: values.profileImage,
				address: {
					address1: values.address1,
					address2: values.address2,
					city: values.city,
					zipCode: values.zipCode,
					countryCode: values.countryCode,
					countryLabel: values.country,
				},
			},
		};
	};

	useEffect(() => {
		if (verify) {
			setCreateUserVariables();
		}
	}, [newUser]);

	return (
		<Formik
			initialValues={initialValues(newUser)}
			validationSchema={addSignupValidationSchema}
			onSubmit={(values, Dropdown2) => {
				setVerify(true);
				dispatch(setCreateUserDetails(signupData(values)));
			}}
		>
			{({
				handleBlur,
				handleChange,
				handleSubmit,
				touched,
				errors,
				dirty,
				isValid,
				setFieldValue,
				values,
			}) => (
				<View style={stylesForm()}>
					<View style={styleRow()}>
						<Input
							label={createRequiredLabels(t('Username'))}
							style={[styles.input]}
							value={values.username}
							onChangeText={handleChange('username')}
							onBlur={handleBlur('username')}
							error={
								touched.username && errors.username
									? errors.username
									: ''
							}
						/>
					</View>
					<View style={styleRow()}>
						<Input
							label={createRequiredLabels(t('Email'))}
							style={[styles.input]}
							onChangeText={handleChange('email')}
							onBlur={handleBlur('email')}
							value={values.email}
							error={
								touched.email && errors.email
									? errors.email
									: ''
							}
							disabled={true}
						/>
					</View>
					<View style={styleRow()}>
						<Input
							label={createRequiredLabels(t('Password'))}
							style={[styles.input]}
							secureTextEntry
							onChangeText={handleChange('password')}
							onBlur={handleBlur('password')}
							error={
								touched.password && errors.password
									? errors.password
									: ''
							}
						/>
					</View>
					<View style={styleRow()}>
						<Input
							label={createRequiredLabels(t('Confirm Password'))}
							style={[styles.input]}
							secureTextEntry
							onChangeText={handleChange('conf_password')}
							onBlur={handleBlur('conf_password')}
							error={
								touched.conf_password && errors.conf_password
									? errors.conf_password
									: ''
							}
						/>
					</View>
					<View style={styleRowContainer()}>
						<Upload
							label={'Profile Image'}
							hint={'Add Image'}
							containerStyles={{
								width: getResponsiveStyle(128, 'dimensions'),
								height: getResponsiveStyle(136, 'dimensions'),
								marginBottom: getResponsiveStyle(24),
							}}
							uploadIcon={<PlusIcon height={14} width={14} />}
							accept={'image/*'}
							onDrop={onDropProfileImage}
							loading={loading_uploadMedia}
						/>
					</View>
					<View style={styleRowContainer()}>
						<Input
							label={createRequiredLabels(t('First Name'))}
							containerStyles={styles.flex1}
							onChangeText={handleChange('firstName')}
							onBlur={handleBlur('firstName')}
							error={
								touched.firstName && errors.firstName
									? errors.firstName
									: ''
							}
							value={values.firstName}
						/>
						<Input
							label={createRequiredLabels(t('Last Name'))}
							containerStyles={styles.flex1}
							onChangeText={handleChange('lastName')}
							onBlur={handleBlur('lastName')}
							error={
								touched.lastName && errors.lastName
									? errors.lastName
									: ''
							}
							value={values.lastName}
						/>
					</View>

					<View style={[styleRowContainer(), {zIndex:9888}]}>
						<Dropdown2
							options={DropdownGenderArray}
							key={'dropdowngen'}
							isMulti={false}
							isGender={true}
							labelProps={{
								label: 'Gender*',
							}}
							containerStyles={styles.flex1}
							values={DropdownGenderArray.find(
								item => item.value === values.gender
							)}
							onChangeValue={valueObject => {
								// console.log(valueObject);
								setFieldValue('gender', valueObject.value);
							}}
							error={
								touched.gender && errors.gender
									? errors.gender
									: ''
							}
						/>
						<Input
							label={createRequiredLabels(t('Phone Number'))}
							style={[]}
							containerStyles={styles.flex1}
							onChangeText={handleChange('phoneNumber')}
							onBlur={handleBlur('phoneNumber')}
							error={
								touched.phoneNumber && errors.phoneNumber
									? errors.phoneNumber
									: ''
							}
						/>
					</View>

					<View style={styleRow()}>
						<Input
							label={createRequiredLabels(t('Address'))}
							style={[]}
							onChangeText={handleChange('address1')}
							onBlur={handleBlur('address1')}
							error={
								touched.address1 && errors.address1
									? errors.address1
									: ''
							}
						/>
						<Input
							label="Address 2"
							style={[]}
							onChangeText={handleChange('address2')}
							onBlur={handleBlur('address2')}
							error={
								touched.address2 && errors.address2
									? errors.address2
									: ''
							}
						/>
					</View>
					<View style={styleRowContainer()}>
						<Input
							label={createRequiredLabels(t('City'))}
							containerStyles={styles.flex1}
							onChangeText={handleChange('city')}
							onBlur={handleBlur('city')}
							error={
								touched.city && errors.city ? errors.city : ''
							}
						/>
						<Input
							label={createRequiredLabels(t('Zip Code'))}
							containerStyles={styles.flex1}
							onChangeText={handleChange('zipCode')}
							onBlur={handleBlur('zipCode')}
							error={
								touched.zipCode && errors.zipCode
									? errors.zipCode
									: ''
							}
						/>
					</View>
					<Dropdown2
						options={countryNameswithCode}
						key={'dropdown'}
						isMulti={false}
						isCountry
						labelProps={{
							label: 'Country*',
						}}
						values={countryNameswithCode.find(
							item => item.value === values.countryCode
						)}
						onChangeValue={valueObject => {
							// console.log(valueObject);
							setFieldValue('country', valueObject.label);
							setFieldValue('countryCode', valueObject.value);
						}}
						error={
							touched.countryCode && errors.countryCode
								? errors.countryCode
								: ''
						}
					/>

					<View style={styleRow()}>
						<Button
							title="Signup"
							onPress={handleSubmit}
							variant="primary"
							outerContainerProps={{
								style: styleSignupButtonOuterContainer(),
							}}
							size="lg"
							loading={
								loadingSignUp || loadingDelete || loadingLogin
							}
						/>
					</View>
				</View>
			)}
		</Formik>
	);
}
const styles = StyleSheet.create({
	container: {
		flexDirection: 'column',
		minWidth: '100vw',
		height: '100vh',
	},
	input: {
		width: '100%',
	},
	flex1: {
		flex: 1,
	},
});

export default SignupForm;

function styleSignupButtonOuterContainer() {
	return {
		marginBottom: getResponsiveStyle(20),
	};
}
function stylesProfileImageContainer(): TStyle {
	return {
		width: getResponsiveStyle(128, 'dimensions'),
		height: getResponsiveStyle(136, 'dimensions'),
		marginBottom: getResponsiveStyle(24),
	};
}
function stylesForm(): TStyle {
	return {
		marginTop: getResponsiveStyle(24),
		marginBottom: getResponsiveStyle(40),
	};
}
function styleRow(): TStyle {
	return {
		marginTop: getResponsiveStyle(10),
	};
}
const styleRowContainer = () => {
	return {
		flexDirection: 'row',
		marginTop: getResponsiveStyle(10),
	} as TStyle;
};
