import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TCommonUserResponseData } from '../types/authTypes';
import { TTypeOfAccount } from '../types/types';


type TBalance = {
	totalEarning: number;
	availableBalance: number;
	totalWithdrawals: number
}
export type TLoggedInUserDetails = {
	typeOfAccount: TTypeOfAccount;
	username: string;
	email: string;
	firstName: string;
	lastName: string;
	city: string;
	country: string;
	_id: string;
};

export type TTotalPendingTransfers = {
	totalPendingTransfers: number,
	totalPendingTransfersBrands: number,
	totalPendingTransfersSellers: number,
	totalPendingTransfersNowAvailableOnStripe: number,
    totalPendingTransfersPendingOnStripe: number
}

type TAuthSliceInitialState = {
	// initialUsername: string,
	// initialEmail: string,
	isUserLoggedIn: boolean;
	loggedInUserDetails?: TCommonUserResponseData;
	adminBalance: TBalance;
	availableAdminBalanceOnStripe: number;
	pendingBalanceInStripe: number
	totalPendingTransfers: TTotalPendingTransfers
};

const initialState: TAuthSliceInitialState = {
	// initialUsername:"",
	// initialEmail: "",
	isUserLoggedIn: false,
	loggedInUserDetails: undefined,
	adminBalance: {} as TBalance,
	availableAdminBalanceOnStripe: 0,
	pendingBalanceInStripe: 0,
	totalPendingTransfers: {} as TTotalPendingTransfers
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setIsUserLoggedIn(state, action: PayloadAction<boolean>) {
			state.isUserLoggedIn = action.payload;
		},
		setLoggedInUserDetails(
			state,
			action: PayloadAction<
				TCommonUserResponseData & {
					isUserLoggedIn: boolean;
				}
			>
		) {
			state.loggedInUserDetails = action.payload;
			state.isUserLoggedIn = action.payload.isUserLoggedIn;
		},
		setAdminBalance(state, action: PayloadAction<TBalance>){
			state.adminBalance = action.payload
		},
		setAvailableAdminBalanceOnStripe(state, action: PayloadAction<number>){
			state.availableAdminBalanceOnStripe = action.payload
		},
		setPendingBalanceInStripe(state, action: PayloadAction<number>){
			state.pendingBalanceInStripe = action.payload
		},
		setTotalPendingTransfer(state, action: PayloadAction<TTotalPendingTransfers>){
			state.totalPendingTransfers = action.payload
		}

	},
});

export default authSlice.reducer;
export const { setIsUserLoggedIn, setLoggedInUserDetails, setTotalPendingTransfer, setAdminBalance, setAvailableAdminBalanceOnStripe, setPendingBalanceInStripe } = authSlice.actions;
