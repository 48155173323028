import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { DropDownPickerSmall } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import ClickAwayListener from 'react-click-away-listener';
import { TDropdownObject, TDropdownValue } from '../../../types/types';

type Props = {
	categories: TDropdownObject[];
	label: string;
	placeholder?: string;
};

export const CategoriesDropdown = ({
	categories: propsCategories,
	label,
	placeholder = 'All Categories',
}: Props) => {
	const { width } = useIsSpecificViewportWidth();
	const [categories, setCategories] = useState<TDropdownObject[]>([]);
	const [dropdownCategoryValue, setDropdownCategoryValue] =
		useState<TDropdownValue>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const handleClickAway = () => {
		setIsDropdownOpen(false);
	};

	useEffect(() => {
		setCategoriesResponsively(width, label, setCategories, propsCategories);
	}, [propsCategories, label]);

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<DropDownPickerSmall
				placeholder={placeholder}
				items={categories}
				value={dropdownCategoryValue}
				setValue={setDropdownCategoryValue}
				open={isDropdownOpen}
				setOpen={setIsDropdownOpen}
				dropDownContainerStyle={[
					styles.dropDownContainerStyle,
					{
						width: getResponsiveStyle(329, 'dimensions'),
						top: getResponsiveStyle(24, 'dimensions'),
						paddingTop: getResponsiveStyle(20),
						paddingBottom: getResponsiveStyle(6),
					},
				]}
				listItemContainerStyle={{
					paddingHorizontal: getResponsiveStyle(24),
					height: getResponsiveStyle(20, 'dimensions'),
					marginBottom: getResponsiveStyle(14),
				}}
				textStyle={{
					color: isDropdownOpen ? palettes.dark[0] : palettes.grey[0],
					lineHeight: getResponsiveStyle(14, 'font'),
					fontSize: getResponsiveStyle(14, 'font'),
				}}
				listItemLabelStyle={{
					lineHeight: getResponsiveStyle(50, 'font'),
				}}
				listMessageTextStyle={{
					lineHeight: 100,
				}}
			/>
		</ClickAwayListener>
	);
};

const styles = StyleSheet.create({
	dropDownContainerStyle: {
		right: 0,
		maxHeight: 'fit-content',
		zIndex: 999,
	},
});

const setCategoriesResponsively = (
	width: number,
	label: string,
	setCategories: React.Dispatch<React.SetStateAction<TDropdownObject[]>>,
	categories: TDropdownObject[]
) => {
	if (width < 1280) {
		setCategories([
			{ label: `${label} selected for you`, value: 'selected for you' },
			...categories,
		]);
	} else return setCategories(categories);
};
