import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { palettes } from '../../../config';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	heading: string;
	text?: string;
	containerStyles?: TStyle;
};

const HeadingLarge = ({ heading, text, containerStyles }: Props) => {
	return (
		<View style={[styles.container, containerStyles]}>
			<Text style={styles.heading}>{heading}</Text>
			{!!text && <Text style={styles.text}>{text}</Text>}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		marginBottom: getResponsiveStyle(64),
	},
	heading: {
		fontWeight: '700',
		color: palettes.dark[0],
		textAlign: 'center',
		fontSize: getResponsiveStyle(34, 'font'),
		lineHeight: getResponsiveStyle(32, 'font'),
		marginBottom: getResponsiveStyle(13),
	},
	text: {
		fontWeight: '400',
		color: palettes.dark[4],
		textAlign: 'center',
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(22, 'font'),
		marginBottom: getResponsiveStyle(36),
	},
});

export default HeadingLarge;
