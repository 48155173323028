import React from 'react';
import { Image, StyleSheet, View, Text, Pressable } from 'react-native';
import { Ratings } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { Product, Variant } from '../../../types/productTypes';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	isLastItem: boolean;
	containerStyles?: TStyle;
	product: any;
};

const ProductCard = ({ isLastItem, containerStyles, product }: Props) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return (
		<View
			style={[
				styles.container,
				styleContainer(isLastItem, isLessThanDesktopBase),
				containerStyles,
			]}
		>
			<Image
				source={{ uri: product.image?.src }}
				style={[styles.image, styleImage()]}
			/>
			<Text
				style={[styles.description, styleDescription()]}
				numberOfLines={2}
			>
				{product.associatedProduct?.shortDescription}
			</Text>
			<View style={styles.priceRatingContainer}>
				<Text style={[styles.price]}>$ {product?.option?.values[0].price}</Text>
				<Ratings
					ratings={3}
					containerStyles={styles.ratingsContainer}
				/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		borderRadius: 6,
		flex: 1,
	},
	image: {
		width: '100%',
		borderRadius: 6,
	},
	description: {
		color: palettes.dark[2],
	},
	price: {
		color: palettes.dark[0],
		fontWeight: '700',
		flex: 1,
	},
	priceRatingContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		width: '100%',
	},
	ratingsContainer: {
		paddingBottom: 0,
	},
});

export default ProductCard;
function styleDescription() {
	return {
		fontSize: getResponsiveStyle(12, 'font'),
		lineHeight: getResponsiveStyle(18, 'font'),
		marginBottom: getResponsiveStyle(12, 'font'),
	};
}

function styleImage() {
	return {
		height: getResponsiveStyle(240, 'dimensions'),
		marginBottom: getResponsiveStyle(8),
	};
}

function styleContainer(
	isLastItem: boolean,
	isLessThanDesktopBase: boolean
): TStyle {
	return {
		minWidth: getResponsiveStyle(208, 'dimensions'),
		marginRight: getResponsiveStyle(
			isLastItem && !isLessThanDesktopBase ? 40 : 16
		),
		maxWidth: getResponsiveStyle(250),
	};
}
