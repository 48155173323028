import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TDropdownObject } from '../types/types';
type Tags = {
	INTEREST_TAGS: TDropdownObject[];
	DROPDOWN_CATEGORIES: TDropdownObject[];
	PILL_CATEGORIES: TDropdownObject[];
	BRAND_PILL_CATEGORIES: TDropdownObject[];
};
const initialState: Tags = {
	INTEREST_TAGS: [],
	DROPDOWN_CATEGORIES: [],
	PILL_CATEGORIES: [],
	BRAND_PILL_CATEGORIES: [],
};
const tagSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		setInteresTags(state, action: PayloadAction<TDropdownObject[]>) {
			state.INTEREST_TAGS = action.payload;
		},
		setDropDownCategories(state, action: PayloadAction<TDropdownObject[]>) {
			state.DROPDOWN_CATEGORIES = action.payload;
		},
		setPillCategories(state, action: PayloadAction<TDropdownObject[]>) {
			state.PILL_CATEGORIES = action.payload;
		},
		setBrandPillCategories(
			state,
			action: PayloadAction<TDropdownObject[]>
		) {
			state.BRAND_PILL_CATEGORIES = action.payload;
		},

		resetState() {},
	},
});
export const {
	setBrandPillCategories,
	setDropDownCategories,
	setInteresTags,
	setPillCategories,
} = tagSlice.actions;
export default tagSlice.reducer;
