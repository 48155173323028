import React, { useMemo } from 'react';
import { View } from 'react-native';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

const getSpacing = (isLastItem: boolean, spacing: number) => {
	return isLastItem ? 0 : getResponsiveStyle(spacing);
};

type Props = {
	children: JSX.Element[];
	orientation?: 'row' | 'column';
	spacing?: number;
	withEqualWidth?: boolean;
	containerStyles?: TStyle;
};

const Spacing = ({
	orientation = 'row',
	spacing = 8,
	withEqualWidth = false,
	containerStyles,
	children,
}: Props) => {
	return (
		<View
			style={[
				{ flexDirection: withEqualWidth ? 'row' : orientation },
				containerStyles,
				{ zIndex: 5000 },
			]}
		>
			{withEqualWidth
				? children.map((item, index) => {
						const isLastItem = index === children.length - 1;
						return (
							<View
								key={index}
								style={[
									{
										flex: 1,
										marginRight: isLastItem
											? 0
											: getResponsiveStyle(spacing),
									},
									{
										zIndex: 5000,
									},
								]}
							>
								{item}
							</View>
						);
				  })
				: children.map((item, index) => {
						const isLastItem = index === children.length - 1;
						return (
							<View
								key={index}
								style={[
									orientation === 'row'
										? {
												marginRight: getSpacing(
													isLastItem,
													spacing
												),
										  }
										: {
												marginBottom: getSpacing(
													isLastItem,
													spacing
												),
										  },
									
									item.key === 'dropdown' && {
										zIndex: 5000,
									},
								]}
							>
								{item}
							</View>
						);
				  })}
		</View>
	);
};

export default Spacing;
