import React from 'react';
import { StyleSheet, View } from 'react-native';
import DropDownPicker, {
	DropDownPickerProps,
} from 'react-native-dropdown-picker';
import { ChevronDown, Label } from '../..';
import { palettes } from '../../../config';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TStyle } from '../../../types/TStyle';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';

type Props = {
	label?: string;
	outerContainerStyles?: TStyle;
} & DropDownPickerProps;

const DropdownLarge = ({
	containerStyle,
	textStyle,
	style,
	dropDownContainerStyle,
	listItemContainerStyle,
	open,
	setOpen,
	label,
	outerContainerStyles,
	...props
}: Props) => {
	const { isLessThanDesktopMedium, isLessThanDesktopBase } =
		useIsSpecificViewportWidth();
	return (
		<View style={[styles.outerContainer, outerContainerStyles]}>
			{label && <Label label={label} />}
			<DropDownPicker
				{...props}
				listMode="FLATLIST"
				showTickIcon={false}
				disableBorderRadius={false}
				open={open}
				setOpen={setOpen}
				scrollViewProps={{
					showsVerticalScrollIndicator: false,
					showsHorizontalScrollIndicator: false,
				}}
				style={[
					styles.main,
					styleMain(
						isLessThanDesktopMedium,
						isLessThanDesktopBase,
						open
					),
					style,
					open && styles.mainOpen,
				]}
				textStyle={[styles.text, styleText(), textStyle]}
				containerStyle={[
					styles.container,
					containerStyle,
					open && styles.containerOpen,
				]}
				dropDownContainerStyle={[
					styles.dropdownContainer,
					styleDropdownContainer(),
					dropDownContainerStyle,
				]}
				listItemContainerStyle={[
					styles.listItemContainer,
					styleListItemContainer(),
					listItemContainerStyle,
				]}
				listItemLabelStyle={styles.listItemLabel}
				selectedItemContainerStyle={styles.selectedItemContainer}
				selectedItemLabelStyle={styles.selectedItemLabel}
				ArrowDownIconComponent={() => (
					<ChevronDown
						style={styleChevron('down')}
						onPress={() => setOpen(!open)}
						containerStyles={[
							styleChevronContainer(),
							styles.chevronContaniner,
						]}
					/>
				)}
				ArrowUpIconComponent={() => (
					<ChevronDown
						style={[styles.chevronUp, styleChevron('up')]}
						onPress={() => setOpen(!open)}
						containerStyles={[
							styleChevronContainer(),
							styles.chevronContaniner,
						]}
					/>
				)}
			/>
		</View>
	);
};

export default DropdownLarge;

const styles = StyleSheet.create({
	chevronContaniner: {
		justifyContent: 'center',
	},
	outerContainer: {
		width: '100%',
		height: 'max-content',
		zIndex: 5000,
	},
	main: {
		flexDirection: 'row',
		borderRadius: 6,
		borderWidth: 1,
		borderColor: palettes.grey[4],
	},
	mainOpen: { borderColor: palettes.primary[0], borderWidth: 2 },
	container: {
		borderWidth: 3,
		borderColor: 'transparent',
	},
	containerOpen: {
		borderColor: palettes.primary[1],
		borderRadius: 8,
	},
	text: {
		color: palettes.grey[0],
		marginRight: 4,
	},
	dropdownContainer: {
		borderRadius: 6,
		backgroundColor: 'white',
		borderColor: palettes.grey[3],
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		shadowOffset: {
			width: 0,
			height: 15,
		},
		shadowRadius: 25,
		shadowColor: 'rgba(124, 136, 155, 0.12)',
		shadowOpacity: 1,
	},
	listItemContainer: {
		borderLeftWidth: 2,
		borderLeftColor: 'transparent',
	},
	listItemLabel: {
		display: 'flex',
		alignItems: 'center',
	},
	selectedItemContainer: {
		borderLeftWidth: 2,
		borderLeftColor: palettes.primary[0],
	},
	selectedItemLabel: {
		color: palettes.dark[0],
	},
	chevronUp: {
		transform: [{ rotate: '180deg' }],
	},
});

const styleMain = (
	isLessThanDesktopMedium: boolean,
	isLessThanDesktopBase: boolean,
	open: boolean
) => {
	const paddingTop = isLessThanDesktopBase
		? 18
		: isLessThanDesktopMedium
		? 14
		: 22;
	const paddingLeft = getResponsiveStyle(16);
	const _paddingTop = open ? paddingTop - 1 : paddingTop;
	const _paddingLeft = open ? paddingLeft - 1 : paddingLeft;
	return {
		// paddingTop: _paddingTop,
		paddingLeft: _paddingLeft,
		paddingRight: getResponsiveStyle(18),
		height: getResponsiveStyle(48, 'dimensions'),
	};
};

const styleChevron = (type: 'up' | 'down') => {
	return {
		width: 8,
		height: getResponsiveStyle(6, 'dimensions'),
		marginTop: type === 'down' && 'calc(50% - 4px)',
		marginBottom: type === 'up' && 'calc(50% - 4px)',
	} as TStyle;
};

const styleListItemContainer = () => {
	return {
		marginBottom: getResponsiveStyle(6),
		height: getResponsiveStyle(25, 'dimensions'),
		paddingHorizontal: getResponsiveStyle(8),
	};
};

const styleDropdownContainer = () => {
	return {
		paddingTop: getResponsiveStyle(6),
		marginTop: getResponsiveStyle(8),
	};
};

const styleText = () => {
	return {
		fontSize: getResponsiveStyle(16, 'font'),
		lineHeight: getResponsiveStyle(16, 'font'),
	};
};
function styleChevronContainer() {
	return {
		height: getResponsiveStyle(48, 'dimensions'),
	};
}
