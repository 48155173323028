import React from 'react';
import { useIsSpecificViewportWidth } from '../../../hooks/useIsSpecificViewportWidth';
import { TGetAllEventsData } from '../../../types/eventTypes';
import { createArray } from '../../../utils/createArray';
import { AllEventsDesktop } from './AllEventsDesktop';
import { AllEventsMobile } from './AllEventsMobile';

type Props = {
	data: TGetAllEventsData[];
};

const AllEventsSection = ({data}: Props) => {
	const { isLessThanDesktopBase } = useIsSpecificViewportWidth();

	return (
		<>
			{isLessThanDesktopBase ? (
				<AllEventsMobile events={data} />
			) : (
				<AllEventsDesktop events={data} />
			)}
		</>
	);
};

export default AllEventsSection;
