import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { palettes } from '../../../config';
import { getResponsiveStyle } from '../../../utils/getResponsiveStyle';
import DateComponent from '../dateComponent/DateComponent';
import { useSetAlert } from '../../../hooks/useSetAlerts';
import { ActionButtons } from '../../common/profile-cards/ActionButtons';
import { TUseNavigation } from '../../../types/types';
import { useMutation } from '@apollo/client';
import { EDIT_EVENT_STATUS } from '../../../graphql/events/mutations';
import { Get_ALL_EVENTS } from '../../../graphql/events/queries';
import { CardBottom } from '../../common/event-card/CardBottom';
import CardBottomAction from '../../common/event-card/CardBottomAction';

type Props = {
	title: string;
	subtitle: string;
	startDate: Date;
	id: string;
	endDate: Date;
	request: boolean;
};

function DescriptionHeader({
	title,
	subtitle,
	startDate,
	request = false,
	id,
}: Props) {
	const { setAlert } = useSetAlert();
	const startDateGMT = new Date(+startDate).toString().split(' ');
	const constructedDate = (date: any) => {
		return `${date[1]} ${date[2]} ${date[0]} ${date[4]} ${date[5]}`;
	};
	const constructedStartDate = constructedDate(startDateGMT);
	const [acceptOrRejectEvent, { loading: acceptOrRejectEventLoading }] =
		useMutation(EDIT_EVENT_STATUS, {
			awaitRefetchQueries: true,
			refetchQueries: [Get_ALL_EVENTS],
		});

	const navigation = useNavigation<TUseNavigation>();
	// useEffect(() => {
	// 	if (data?.deleteEvent?.success) {
	// 		navigation.navigate('Home');
	// 		setAlert(t('Deleted Successfully'), 'normal');
	// 	}
	// }, [data]);

	return (
		<>
			<View style={[Styles.container, styleContainer()]}>
				<View style={Styles.headerContainer}>
					<DateComponent date={startDate} />
					<View style={Styles.headerContainer}>
						<View style={Styles.titleContainer}>
							<Text style={Styles.title}>{title}</Text>
							<View style={Styles.subtitleRapper}>
								<View style={Styles.subtitle}>
									<Text style={Styles.subtitleEvent}>
										{subtitle}
									</Text>
								</View>
								<Text style={Styles.eventDate}>
									{constructedStartDate}
								</Text>
							</View>
						</View>
						
					</View>
				</View>
				<View>
							{request && (
								<CardBottomAction
									id={id}
									acceptOrRejectMutation={acceptOrRejectEvent}
								/>
							)}
						</View>
			</View>
		</>
	);
}

const Styles = StyleSheet.create({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		gap: '10px',
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 8,
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		
		// flexWrap: 'wrap',
		// gap: '10px',
	},
	dateContainer: {
		width: getResponsiveStyle(72),
		height: getResponsiveStyle(64),
		border: '1px solid #E0E4E9',
		borderRadius: 6,
		marginRight: getResponsiveStyle(16),
	},
	deleteModalContent: {
		marginBottom: getResponsiveStyle(20),
		paddingBottom: getResponsiveStyle(20),
		color: palettes.grey[0],
		borderBottomColor: palettes.grey[5],
		borderBottomWidth: 1,
	},
	deleteModalActionBtn: {
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	dateContainerTop: {
		height: getResponsiveStyle(48),
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dateContainerTopText: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	dateContainerBottom: {
		height: getResponsiveStyle(16),
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: palettes.red[0],
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
	},
	dateContainerBottomText: {
		fontSize: getResponsiveStyle(12, 'font'),
		fontWeight: '600',
		color: '#FFF',
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: getResponsiveStyle(9),
	},
	title: {
		fontSize: getResponsiveStyle(24, 'font'),
		fontWeight: 'bold',
		color: palettes.dark[0],
	},
	subtitleRapper: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	subtitle: {
		backgroundColor: '#EFF1F4',
		borderRadius: 50,
		width: getResponsiveStyle(72, 'dimensions'),
		height: getResponsiveStyle(24, 'dimensions'),
		justifyContent: 'center',
		alignItems: 'center',
	},
	subtitleEvent: {
		fontSize: getResponsiveStyle(9, 'font'),
		fontWeight: '700',
		color: palettes.dark[4],
	},
	eventDate: {
		color: '#616E82',
		fontWeight: '400',
		fontSize: getResponsiveStyle(14, 'font'),
		lineHeight: getResponsiveStyle(16, 'spacing'),
		marginLeft: getResponsiveStyle(5, 'spacing'),
	},
});
export default DescriptionHeader;

function styleContainer() {
	return {
		marginTop: getResponsiveStyle(32),
		marginBottom: getResponsiveStyle(41),
	};
}
