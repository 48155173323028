import React from "react";
import { Text, StyleSheet } from "react-native";
import { palettes } from "../../config";

type Props = {
  heading: string;
  subHeading?: string;
  alignment?: "center" | "left" | "right";
};

const PageHeading = ({ heading, subHeading, alignment = "center" }: Props) => {
  return (
    <Text style={[styles.container, { textAlign: alignment }]}>
      <Text style={styles.heading}>{heading}</Text>
      {subHeading && <Text style={styles.subHeading}>{subHeading}</Text>}
    </Text>
  );
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 40,
    flexDirection: "column",
    display: "flex",
  },
  heading: {
    fontSize: 34,
    lineHeight: 32,
    fontWeight: "700",
    marginBottom: 13,
    color: palettes.dark[0],
  },
  subHeading: {
    fontSize: 14,
    lineHeight: 22,
    color: palettes.dark[4],
  },
});

export default PageHeading;
