import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { EDIT_PRODUCT } from '../../graphql/product/mutation';
import { useSetAlert } from '../../hooks/useSetAlerts';
import { TCreateProduct, TUseNavigation } from '../../types/types';
const useEditProduct = () => {
	const { setAlert } = useSetAlert();
	const navigation = useNavigation<TUseNavigation>();
	const [editProduct, states] = useMutation(EDIT_PRODUCT, {
		onCompleted: data => {
			if (data.editProduct.success) {
				setAlert('Product Edited Successfully', 'normal');
			} else {
				setAlert(data.createProduct.message, 'danger');
			}
		},
		onError: error => {
			setAlert(error.message, 'danger');
		},
	});

	const executeProductEditMutation = (_id: string, data: TCreateProduct) => {
		editProduct({
			variables: {
				_id,
				args: data,
			},
		});
	};
	return { executeProductEditMutation, ...states };
};

export default useEditProduct;
