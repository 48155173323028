import { gql } from '@apollo/client';

export const EDIT_USER = gql`
	mutation editUser($email: String!, $args: EditUserInputs!) {
		editUser(email: $email, args: $args) {
			... on Response {
				statusCode
				success
				message
				data {
					... on User {
						_id
						username
						email
					}
				}
			}
		}
	}
`;

export const DISABLE_PROFILE = gql`
	mutation disableProfile($email: String!) {
		disableProfile(email: $email) {
			... on Response {
				statusCode
				success
				message
				data {
					... on User {
						_id
						username
						email
					}
				}
			}
		}
	}
`;
