import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import React, { useEffect, useMemo, useState } from 'react';
import { PageDetailsHero } from '../components';
import Loader from '../components/common/loader/Loader';
import ProfileEvents from '../components/common/Profile-Events/ProfileEvents';
import ProfileOrders from '../components/common/Profile-Orders/ProfileOrders';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import OrderSection from '../components/dashboard/OrderSection';
import { palettes } from '../config';
import { CREATE_TRANSFER_FROM_PENDING_TRFS, DELETE_PENDING_TRANSFER } from '../graphql/brands/mutations';
import { GET_BRAND_STATS, GET_PENDING_TRANSFER, GET_SINGLE_BRAND } from '../graphql/brands/queries';
import { TUseRoute } from '../types/exportedTypes';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';

const BrandScreen = () => {
	const routes = useRoute<TUseRoute<'BrandProfile'>>();

	const { _id } = routes.params;
	const { data: brandDataValue, loading: brandLoading } = useQuery(
		GET_SINGLE_BRAND,
		{
			variables: {
				_id: _id,
			},
		}
	);
	const outlineColor = {
		CONSUMER: palettes.red[0],
		SELLER: palettes.purple[0],
		HOST: palettes.yellow[0],
		SUPER_ADMIN: palettes.purple[0],
		BRAND: palettes.grey[0],
	};

	const { data: brandStatValue, loading: brandStatsLoading } = useQuery(
		GET_BRAND_STATS,
		{
			variables: {
				_ids: [_id],
			},
			onCompleted: data => {
				console.log(data);
			},
		}
	);
	const UserData: any = brandDataValue?.getBrand?.data;
	const UserStats = brandStatValue?.getBrandStats?.data.data[0];

	const STATS = [
		{
			label: 'Followers',
			value: UserStats?.followersCount,
		},
		// {
		// 	label: t('Views'),
		// 	value: 453,
		// },

		{
			label: 'Events',
			value: UserStats?.eventsCount,
		},
	];
	const BRAND_STATS = [
		{
			label: 'Followers',
			value: UserStats?.followersCount || 0,
		},

		{
			label: 'Events',
			value: UserStats?.eventsCount || 0,
		},
	];

	const BRANDTAB = ['All Events', 'All Orders'];
	const [selectedTab, setSelectedTab] = React.useState(BRANDTAB[0]);
	const onPressTab = (tab: string) => {
		setSelectedTab(tab);
	};

	const unifiedLoading = useMemo(
		() => getSingleLoadingState(brandLoading, brandStatsLoading),
		[brandLoading, brandStatsLoading]
	);

	const [createTransferFromPending] = useMutation(
		CREATE_TRANSFER_FROM_PENDING_TRFS
	);
	const [deletePendingTransfer] = useMutation(DELETE_PENDING_TRANSFER);
	const [getPendingTransfer] = useLazyQuery(GET_PENDING_TRANSFER, {
		onCompleted: data => {
			if (data?.getPendingTransfer?.data?.total) {
				createTransferFromPending({
					variables: {
						sellerId: _id,
					},
					// onCompleted: () => {
					// 	deletePendingTransfer({
					// 		variables: {
					// 			args: { sellerId: _id },
					// 		},
					// 	});
					// },
				});
			}
		},
	});

	useEffect(() => {
		if (
			brandDataValue?.getBrand?.data?.connectAccountInfo &&
			brandDataValue?.getBrand?.data.connectAccountInfo.connectAccountId
		) {
			getPendingTransfer({
				variables: {
					args: { sellerId: brandDataValue?.getBrand?.data._id },
				},
			});
		}
	}, [brandDataValue]);


	return (
		<DesktopTemplate navigationBarProps={{}}>
			{unifiedLoading ? (
				<Loader />
			) : (
				<>
					<PageDetailsHero
						_id={UserData?._id}
						blockUserButton={false}
						city={UserData?.address?.city}
						countryCode={UserData?.address?.countryCode}
						countryLabel={UserData?.address?.countryLabel}
						outlineColor={outlineColor['BRAND']}
						name={UserData?.name}
						profileImage={UserData?.profileImage}
						key={'Profile'}
						tabs={BRANDTAB}
						onPressTab={onPressTab}
						selectedTab={selectedTab}
						stats={BRAND_STATS}
						isProfile
						verified={UserData?.isVerified}
						type={'BRAND'}
						bankAccounts={UserData?.bankAccount}
						email={UserData?.email}
						line1={UserData?.address?.address1}
					/>

					{selectedTab === 'All Events' && (
						<ProfileEvents id={_id} typeOfAccount={'BRAND'} />
					)}
					{selectedTab === 'All Orders' && <ProfileOrders id={_id} />}
					{selectedTab === 'Total Points' && <OrderSection />}
				</>
			)}
		</DesktopTemplate>
	);
};
export default BrandScreen;
