import { gql } from '@apollo/client';

export const LIVE_EVENT_FRAGMENT = gql`
	fragment LiveEventFragment on LiveEvent {
		_id
		name
		description
		startingEventDateTime
		typeOfEvent
		isApproved
		eventMedia
		isRejected
		invitedPeople {
			...on User{
				_id
				personalInformation{
					profileImageLink
				}
			}
		}
	}
`;

export const PHYSICAL_EVENT_FRAGMENT = gql`
	fragment PhysicalEventFragment on PhysicalEvent {
		_id
		name
		description
		startingEventDateTime
		typeOfEvent
		isApproved
		isRejected
		eventMedia
		invitedPeople {
			...on User{
				_id
				personalInformation{
					profileImageLink
				}
			}
		}
	}
`;

export const Get_ALL_EVENTS = gql`
	${LIVE_EVENT_FRAGMENT}
	${PHYSICAL_EVENT_FRAGMENT}
	query getAllEvents(
		$find: JSON
		$status: StatusOfEvent
		$current: Int
		$limit: Int
		$sort: JSON
	) {
		getAllEvents(
			find: $find
			status: $status
			current: $current
			limit: $limit
			sort: $sort
			populate: { path: "invitedPeople", model: "User" }
		) {
			... on AllResponse {
				statusCode
				success
				message
				data {
					... on All {
						total
						totalUpcoming
						totalActive
						newRequest
						data {
							... on LiveEvent {
								...LiveEventFragment
							}
							... on PhysicalEvent {
								...PhysicalEventFragment
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_EVENT_GRAPH_DATA = gql`
	query getEventsGraphData {
		getEventsGraphData {
			statusCode
			success
			message
			data {
				... on GraphDataResponse {
					previousMonth {
						dataPoint
						week
					}
					currentMonth {
						dataPoint
						week
					}
				}
			}
		}
	}
`;
export const GET_UPCOMING_EVENTS = gql`
	${LIVE_EVENT_FRAGMENT}
	${PHYSICAL_EVENT_FRAGMENT}
	query getAllEvents(
		$find: JSON
		$current: Int
		$limit: Int
		
	) {
		getAllEvents(
			sort: { startingEventDateTime: 1 }
			find: $find
			current: $current
			limit: $limit
			populate: { path: "invitedPeople", model: "User" }
		) {
			statusCode
			message
			success
			data {
				total
				totalUpcoming
				totalActive
				newRequest
				data {
					... on LiveEvent {
						...LiveEventFragment
					}
					... on PhysicalEvent {
						...PhysicalEventFragment
					}
				}
			}
		}
	}
`;
export const GET_SINGLE_EVENT = gql`
	query getEvent($_id: ID!) {
		getEvent(_id: $_id) {
			statusCode
			message
			success
			data {
				_id
				name
				description
				startingEventDateTime
				typeOfEvent
				eventMedia
				affiliatedHosts {
					_id
					username
					email
					followers
					gender
					attendedEvents
					personalInformation {
						profileImageLink
						firstName
						lastName
						address {
							city
							countryLabel
							countryCode
						}
					}
				}
				affiliatedSeller {
					_id
					username
					email
					followers
					gender
					attendedEvents
					personalInformation {
						description
						profileImageLink
						firstName
						lastName
						address {
							city
							countryCode
						}
					}
				}
				invitedPeople {
					_id
					username
					email
					followers
					gender
					attendedEvents
					personalInformation {
						profileImageLink
						firstName
						lastName
						address {
							city
							countryCode
							countryLabel
						}
					}
				}
				eventLocation {
					address1
					address2
					city
					zipCode
					countryCode
					countryLabel
				}
				affiliatedBrands {
					_id
					name
					profileImage
					address {
						city
						countryCode
					}
				}
				typeOfEvent
				isApproved
				startingEventDateTime
				eventMedia
				isSellerApplicationOpen
				createdAt
			}
		}
	}
`;