import * as React from 'react';
import { Pressable } from 'react-native';
import Svg, { SvgProps, Path } from 'react-native-svg';

const MenuIcon = (props: SvgProps) => (
	<Pressable onPress={props.onPress}>
		<Svg viewBox="0 0 24 24" width={24} height={24} {...props}>
			<Path fill="none" d="M0 0h24v24H0z" />
			<Path
				d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"
				fill="rgba(152,162,178,1)"
			/>
		</Svg>
	</Pressable>
);

export default MenuIcon;
