import { gql } from '@apollo/client';
export const GET_PRODUCT = gql`
	query getProduct($_id: ID!, $populate: JSON) {
		getProduct(_id: $_id, populate: $populate) {
			statusCode
			success
			data {
				... on Product {
					_id
					name
					shortDescription
					description
					category
					publishedStatus
					createdAt
					defaultVariant

					category
					brand {
						_id
					}

					variants {
						_id
						variator
						value
						GTIN
						weight
						weightUnit

						image {
							src
							_id
						}
						option {
							name
							values {
								compareAtPrice
								price
								name
							}
							variatorValues
							inventory {
								quantity
								isTracked
								isAvailable
							}
						}
						vat
					}
				}
			}
		}
	}
`;
export const GET_ALL_PRODUCTS = gql`
	query getAllProducts($find: ProductQueryParam, $populate: JSON, $limit: Int, $current: Int) {
		getAllProducts(find: $find, populate: $populate, limit: $limit, current: $current) {
			statusCode
			success
			data {
				total
				data {
					... on Product {
						_id
						name
						shortDescription
						description
						category
						publishedStatus
						createdAt
						defaultVariant
						brand {
							_id
						}

						variants {
							_id
							variator
							value
							GTIN
							weight
							weightUnit

							image {
								src
								_id
							}
							option {
								name
								values {
									compareAtPrice
									price
									name
								}
								variatorValues
								inventory {
									quantity
									isTracked
									isAvailable
								}
							}
							vat
						}
					}
					
				}
			}
		}
	}
`;

export const GET_RATINGS_AND_REVIEWS = gql`
	query getEntityRating($args: ReviewAndRantingQuery!) {
		getEntityRating(args: $args) {
			statusCode
			data {
				data {
					... on Rating {
						_id
						rating
						review
						createdAt
						ratedBy {
							_id
							username
							email
							personalInformation {
								profileImageLink
								address {
									city
									country
									countryCode
									countryLabel
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_DISCOUNTED_PRODUCTS = gql`
	query getAllDiscountedProducts($populate: JSON) {
		getAllDiscountedProducts(populate: $populate) {
			statusCode
			success
			data {
				total
				data {
					... on Product {
						_id
						name
						shortDescription
						description
						publishedStatus
						category
						createdAt
						defaultVariant
						brand {
							_id
						}

						variants {
							_id
							variator
							value
							GTIN
							weight
							weightUnit

							image {
								src
								_id
							}
							option {
								name
								values
								inventory {
									quantity
									isTracked
									isAvailable
								}
								compareAtPrice
								price
							}
							vat
						}
					}
				}
			}
		}
	}
`;
