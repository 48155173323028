import { gql } from '@apollo/client';

export const ADDRESS_FRAGMENT = gql`
	fragment AddressFragment on Address {
		city
		countryCode
		countryLabel
		zipCode
	}
`;

export const PERSONAL_INFORMATION = gql`
	${ADDRESS_FRAGMENT}
	fragment PersonalInformation on PersonalInformation {
		profileImageLink
		firstName
		lastName
		address {
			...AddressFragment
		}
	}
`;
