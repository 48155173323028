import { useQuery } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import React, { useMemo, useState } from 'react';
import { PageDetailsHero } from '../components';
import Loader from '../components/common/loader/Loader';
import ProfileEvents from '../components/common/Profile-Events/ProfileEvents';
import ProfileOrders from '../components/common/Profile-Orders/ProfileOrders';
import DesktopTemplate from '../components/common/templates/DesktopTemplate';
import OrderSection from '../components/dashboard/OrderSection';
import { palettes } from '../config';
import userType from '../config/userType';
import { GET_USER_BY_ID, GET_USER_STATS } from '../graphql/auth/queries';
import {
	TCommonResponseData,
	TCommonUserResponseData,
} from '../types/authTypes';
import { TUseRoute } from '../types/exportedTypes';
import { TTypeOfAccount, UserDashboardStats } from '../types/types';
import { getSingleLoadingState } from '../utils/getSingleLoadingState';
import EventsScreen from './EventsScreen';
const ProfileScreen = () => {
	const [UserData, setUserData] = useState<TCommonResponseData>();
	const routes = useRoute<TUseRoute<'UserProfile'>>();

	const val = routes.params;
	const { data: userDataValue, loading: UserLoading } = useQuery(
		GET_USER_BY_ID,
		{
			variables: {
				_id: val?.userId,
			},
			onCompleted: data => {
				if (data.getUserById?.data && data.getUserById?.success	&& !UserData) {
					setUserData(data.getUserById?.data);
				}
			},
		}
	);
	const outlineColor = {
		CONSUMER: palettes.red[0],
		SELLER: palettes.purple[0],
		HOST: palettes.yellow[0],
		SUPER_ADMIN: palettes.purple[0],
		BRAND: palettes.grey[0],
	};
	const { data: userStatValue, loading: UserStatsLoading } = useQuery(
		GET_USER_STATS,
		{
			variables: {
				_id: val?.userId,
			},
			onCompleted: data => {
				console.log(data);
			},
		}
	);

	const UserStats = userStatValue?.getUserDashboardStats?.data;

	const STATS = [
		{
			label: 'Followers',
			value: UserStats?.followersCount || 0,
		},
		// {
		// 	label: t('Views'),
		// 	value: 453,
		// },

		{
			label: 'Events',
			value: UserStats?.eventsCount || 0,
		},
	];
	const GUEST_STATS = [
		{
			label: 'Events',
			value: UserStats?.eventsInvitedCount || 0,
		},
	];
	const TAB = ['All Events', 'All Orders'];
	const BRANDTAB = ['All Events', 'All Orders'];
	const [selectedTab, setSelectedTab] = React.useState(TAB[0]);
	const onPressTab = (tab: string) => {
		setSelectedTab(tab);
	};

	const getTabs = (userType?: TTypeOfAccount) => {
		return userType !== 'BRAND' ? TAB : BRANDTAB;
	};
	const unifiedLoading = useMemo(
		() => getSingleLoadingState(UserLoading, UserStatsLoading),
		[UserStatsLoading, UserLoading]
	);

	return (
		<DesktopTemplate navigationBarProps={{}}>
			{unifiedLoading ? (
				<Loader />
			) : (
				<>
					{UserData && (
						<>
							<PageDetailsHero
								_id={UserData?._id}
								blockUserButton={true}
								city={
									UserData?.personalInformation?.address?.city
								}
								countryCode={
									UserData?.personalInformation?.address
										?.countryCode
								}
								outlineColor={
									outlineColor[UserData?.typeOfAccount]
								}
								name={
									UserData?.personalInformation?.firstName +
									' ' +
									UserData?.personalInformation?.lastName
								}
								profileImage={
									UserData?.personalInformation
										?.profileImageLink
								}
								key={'Profile'}
								tabs={getTabs(UserData?.typeOfAccount)}
								onPressTab={onPressTab}
								selectedTab={selectedTab}
								stats={
									UserData?.typeOfAccount === 'CONSUMER'
										? GUEST_STATS
										: STATS
								}
								isProfile
								verified={UserData?.isVerified}
							/>

							{selectedTab === 'All Events' && (
								<ProfileEvents
									id={UserData?._id}
									typeOfAccount={UserData?.typeOfAccount}
								/>
							)}
							{selectedTab === 'All Orders' && (
								<ProfileOrders id={UserData?._id} />
							)}
						</>
					)}
				</>
			)}
		</DesktopTemplate>
	);
};
export default ProfileScreen;
