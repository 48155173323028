import { useLazyQuery } from '@apollo/client';
import React, { useState } from 'react'
import { GET_BALANCE } from '../../graphql/stripe/queries';

type TParams = {
    connectAccountId: string
}
const useGetBrandConnectBalance = ({connectAccountId}: TParams) => {
    const [availableBalance, setAvailableBalance] = useState(0)
    const [pendingBalance, setPendingBalance] = useState(0)
    const [getConnectBalance] = useLazyQuery(GET_BALANCE, {
		variables: {
			args: {
				accountId: connectAccountId,
			},
		},
		onCompleted: data => {
			const amount = data?.getBalance?.data?.available?.[0]?.amount;

			const pendingAmount = data?.getBalance?.data?.pending?.[0]?.amount;
			setAvailableBalance(amount);
			setPendingBalance(pendingAmount);
		},
	});

    return {getConnectBalance, availableBalance, pendingBalance}
}

export default useGetBrandConnectBalance