import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { Image, ImageProps, Pressable } from 'react-native';
import { TUseNavigation } from '../../types/types';
import { placeholderImage } from './table/TableContent';

type idProp={
	userId: string
}
const ProfileIcon = ({
	style,
	defaultSource = { uri: placeholderImage },
	source,
	userId,
	...props
}: ImageProps & idProp) => {
	const navigator = useNavigation<TUseNavigation>();

	// console.log(profileImage, 'profileImage');

	const handleNavigation = () => {
		navigator.navigate('BrandProfile', {_id: userId});
	};

	return (
		<Pressable onPress={handleNavigation}>
			<Image
				source={source ? source : defaultSource}
				{...props}
				style={[style, { borderRadius: 9999 }]}
			/>
		</Pressable>
	);
};

export default ProfileIcon;
