import { gql } from '@apollo/client';

const CREATE_MESSAGE = gql`
	mutation createMessage(
		$sender: ID!
		$recipients: ID!
		$message: String!
		$messageType: String!
		$conversationId: String
	) {
		createMessage(
			MessageInput: {
				sender: $sender
				recipients: [$recipients]
				message: $message
				messageType: $messageType
				conversationId: $conversationId
			}
		) {
			statusCode
			message
		}
	}
`;

export { CREATE_MESSAGE };
