import { gql } from '@apollo/client';

export const NOTIFICATION_SUBSCRIPTION = gql`
	subscription notificationSubscription($userId: String!) {
		notificationSubscription(userId: $userId) {
			# _id
			# message
			# # user
			# notificationType
			# eventId
			# createdAt
			# read
			# # parentId
			_id
			message
			notificationType
			eventId {
				_id
				name
				description
				typeOfEvent
				startingEventDateTime
				
			}
			createdAt
			read
			eventHostingId
			invitation
			event {
				_id
				name
				description
				typeOfEvent
				startingEventDateTime
				
			}
			eventType
			sentBy {
				_id
				username
				personalInformation {
					profileImageLink
					firstName
				}
			}
			user {
				_id
			}
		}
	}
`;
