import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import uiReducer from './uiSlice';
import authReducer from './authSlice';
import addUserReducer from './addUserSlice';
import mobileSidebarReducer from './mobileSidebarSlice';
import productsReducer from './productsSlice';
import createProduct from './createProduct';
import notificationReducer from './notificationSlice';
import createNewUserReducer from './createUserSlice';
import eventProductReducer from './eventProductDetail';
import getEventReducer from './getEventSlice';
import tagReducer from './tagsSlice';
import chatReducer from './chatSlice';

const appReducer = combineReducers({
	ui: uiReducer,
	auth: authReducer,
	addUser: addUserReducer,
	mobileSidebar: mobileSidebarReducer,
	products: productsReducer,
	addProduct: createProduct,
	notification: notificationReducer,
	eventProduct: eventProductReducer,
	getEvent: getEventReducer,
	createNewUser: createNewUserReducer,
	chat: chatReducer,
	tag: tagReducer,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === 'ui/resetState') {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};
const store = configureStore({
	reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
