import * as yup from 'yup';
import { Product, Variant } from '../../../types/productTypes';
import { yupsRequiredString } from '../../../utils/yupsRequiredString';
import {
	TAddVariantFormValues,
	addVariantFormValSchema,
	TAddValuesFormValue,
	addVariantFormValSchemaOld,
} from './VariantFormConstants';

export enum TStatus {
	DRAFT = 'DRAFT',
	ACITVE = 'ACTIVE',
}

export const addSingleProductValidationSchemaOld = yup.object({
	productName: yupsRequiredString(),
	shortDescription: yupsRequiredString(),
	description: yupsRequiredString(),
	defaultVariant: yup.string(),
	brand: yupsRequiredString(),
	attributeSet: yup.string(),
	productCategory: yup.array().of(yup.string()).required(),
	status: yup.mixed<TStatus>().oneOf(Object.values(TStatus)),
	variants: yup
		.array()
		.of(addVariantFormValSchemaOld)
		.compact()
		.required()
		.min(1),
});


export const addSingleProductValidationSchema = yup.object({
	productName: yupsRequiredString(),
	shortDescription: yupsRequiredString(),
	description: yupsRequiredString(),
	defaultVariant: yup.string(),
	brand: yupsRequiredString(),
	attributeSet: yup.string(),
	productCategory: yup.array().of(yup.string()).required(),
	status: yup.mixed<TStatus>().oneOf(Object.values(TStatus)),
	// variantType: yupsRequiredString(),
	// value: yupsRequiredString(),
	GTIN: yupsRequiredString(),
	_id: yup.string(),
	image: yup.object({
		_id: yup.string(),
		src: yup.string(),
	}),
	vat: yupsRequiredString(),
	weight: yup.string().nullable(),
	weightUnit: yup.string().nullable(),
	variants: yup
		.array()
		.of(addVariantFormValSchema)
		.compact()
		.required()
		.min(1),
});

export type TAddSingleProductFormValues = yup.InferType<
	typeof addSingleProductValidationSchema
>;
export const emptyValue: TAddValuesFormValue = {
	name: '',
	price: '',
	compareAtPrice: '',
};
export const emptyVariant: TAddVariantFormValues = {
	variantType: '',
	value: '',
	options: {
		name: '',
		values: [emptyValue],
		inventory: {
			quantity: 0,
			isAvailable: true,
			isTracked: true,
		},
	},
	GTIN: '',
	image: { _id: '', src: '' },
	_id: '',
	vat: '',
	weight: '',
	weightUnit: 'KG',
};
export const addSingleProductInitialValues: TAddSingleProductFormValues = {
	productName: '',
	shortDescription: '',
	defaultVariant: '0',
	attributeSet: '',
	description: '',
	brand: '',
	vat: '',
	weight: '',
	GTIN: '',
	productCategory: [],
	status: TStatus.DRAFT,
	variants: [emptyVariant],
};

export const handleEditProducts = (product: Product) => {
	console.log({ editData: product });

	const editSingleProductInitialValues: TAddSingleProductFormValues = {
		productName: product?.name,
		shortDescription: product?.shortDescription,
		description: product?.description,
		brand: product?.brand?._id,
		defaultVariant: product?.defaultVariant,
		productCategory: product?.category,
		GTIN: product?.variants?.[0]?.GTIN,
		vat: Number(product?.variants?.[0]?.vat),
		status: product?.publishedStatus as TStatus,
		variants: product?.variants?.[0]?.option?.variatorValues?.map(variant => {
			return {
				variantType: variant.variator,
				value: product?.variants?.[0]?.value,
				GTIN: product?.variants?.[0]?.GTIN,
				image: product?.variants?.[0]?.image,
				_id:
					product?.variants?.[0]?._id && product?.variants?.[0]?._id.length
						? product?.variants?.[0]?._id
						: undefined,
				vat: Number(product?.variants?.[0]?.vat),
				weight: Number(product?.variants?.[0]?.weight),
				weightUnit: product?.variants?.[0]?.weightUnit,
				options: {
					...product?.variants?.[0]?.option,
				},
			};
		}),
	};
	return editSingleProductInitialValues;
};


export const handleEditProductsOld = (product: Product) => {
	const editSingleProductInitialValues: TAddSingleProductFormValues = {
		productName: product?.name,
		shortDescription: product?.shortDescription,
		description: product?.description,
		brand: product?.brand?._id,
		defaultVariant: product?.defaultVariant,
		productCategory: product?.category,
		status: product?.publishedStatus as TStatus,
		variants: product?.variants.map(variant => {
			return {
				variantType: variant.variator,
				value: variant.value,
				GTIN: variant?.GTIN,
				image: variant?.image,
				_id:
					variant?._id && variant?._id.length
						? variant?._id
						: undefined,
				vat: Number(variant?.vat),
				weight: Number(variant?.weight),
				weightUnit: variant.weightUnit,
				options: {
					...variant.option,
				},
			};
		}),
	};
	return editSingleProductInitialValues;
};
